import MaterialTable from "material-table";
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
/**Import Icons */
import Add from "@material-ui/icons/Add";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";
import Close from "@material-ui/icons/Close";
/** import Tabs */
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    "aria-controls": `nav-tabpanel-${index}`,
  };
}

function LinkTab(props) {
  return (
    <Tab
      component="a"
      onClick={(event) => {
        event.preventDefault();
      }}
      {...props}
    ></Tab>
  );
}

export const tableStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  rootTab: {
    flexGrow: 1,
    backgroundColor: "#fff",
  },
  backgroundTable: {
    backgroundColor: "#fff",
    width: "100%",
  },
  titleTableCustom: {
    color: "#000",
    fontSize: 17,
    fontWeight: 400,
    padding: "5px 20px",
    fontFamily: '"Open Sans",sans-serif',
  },
  buttonPrimary: {
    backgroundColor: "#007bff",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#007bff",
      color: "#fff",
    },
  },
  iconTable: {
    color: "#3f4d67",
    "&:hover": {
      color: "#007bff",
    },
  },
  hidden: {
    display: "none",
  },
}));
const LockSmithMainTable = (props) => {
  const [viewAdd, setViewAdd] = useState(false);
  const [viewUpdate, setViewUpdate] = useState(false);

  const classes = tableStyles();
  const [selectedItem, setSelectedItem] = useState({});
  const [value, setValue] = React.useState(0);
  //** Add Item Controls */
  const [newItem, setNewItem] = useState(false);
  const openNewItem = () => {
    setValue(1);
    setNewItem(true);
    setViewAdd(true);
  };
  const closeNewItem = (e) => {
    if (e) e.stopPropagation();
    setValue(0);
    setViewAdd(false);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  //** Update Item Controls */
  const [updItem, setUpdItem] = useState(false);
  const openUptItem = (data) => {
    setSelectedItem(data);
    setUpdItem(true);
    setValue(2);
    setViewUpdate(true);
  };
  const closeUpdItem = (e) => {
    if (e) e.stopPropagation();
    setValue(0);
    setViewUpdate(false);
  };

  //** Delete Item Controls */
  const [deleteItem, setDeleteItem] = useState(false);
  const openDelItem = (data) => {
    setSelectedItem(data);
    setDeleteItem(true);
  };
  const closeDelItem = () => {
    setDeleteItem(false);
  };

  let AddItemComponent = null;
  if (props.addComponent) {
    AddItemComponent = React.createElement(props.addComponent, {
      openDialog: newItem,
      closeDialog: closeNewItem,
    });
  }
  let UpdateItemComponent = null;
  if (props.updateComponent) {
    UpdateItemComponent = React.createElement(props.updateComponent, {
      openDialog: updItem,
      closeDialog: closeUpdItem,
      data: selectedItem,
    });
  }
  let DeleteItemComponent = null;
  if (props.deleteComponent) {
    DeleteItemComponent = React.createElement(props.deleteComponent, {
      title: props.title,
      openDialog: deleteItem,
      closeDialog: closeDelItem,
      data: selectedItem,
      deleteAction: props.deleteAction,
      reducer: props.reducer,
      itemId: props.itemId,
    });
  }

  return (
    <React.Fragment>
      <div className={classes.rootTab}>
        <AppBar position="static">
          <Tabs
            className={classes.backgroundTable}
            indicatorColor="primary"
            value={value}
            onChange={handleChange}
            aria-label="nav tabs example"
          >
            <LinkTab label={props.title} {...a11yProps(0)}></LinkTab>
            <LinkTab
              className={{ [classes.hidden]: !viewAdd }}
              label={"Add " + props.title}
              {...a11yProps(1)}
              icon={<Close onClick={closeNewItem}></Close>}
            ></LinkTab>
            <LinkTab
              className={{ [classes.hidden]: !viewUpdate }}
              label={"Update " + props.title}
              {...a11yProps(2)}
              icon={<Close onClick={closeUpdItem}></Close>}
            >
              {" "}
            </LinkTab>
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <Box sx={{ overflow: "auto" }}>
            <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
            <MaterialTable
                columns={props.columns}
                data={props.data ? props.data : []}
                title={
                  <div className={classes.titleTableCustom}>
                    {props.title}
                  </div>
                }
                actions={[
                  {
                    hidden : props.addComponent ? false : true,
                    icon: () => (
                      <Button
                        className={classes.buttonPrimary}
                        variant="contained"
                        startIcon={<Add />}
                        children={<text>New</text>}
                      />                        
                    ),
                    tooltip: "Add " + props.title,
                    isFreeAction: true,
                    position: "toolbarOnSelect",
                    onClick: () => {
                      openNewItem();
                    },
                  },
                  (rowData) => ({
                    //hide edit button if Update Component is not provided or if Job Status is "Cancelled"
                    hidden:
                      !props.updateComponent || rowData.IdStatus === 7
                        ? true
                        : false,
                    icon: () => <Edit className={classes.iconTable} />,
                    tooltip: "Update " + props.title,
                    onClick: (event, rowData) => {
                      openUptItem(rowData);
                    },
                  }),
                  (rowData) => ({
                    //hide edit button If Delete Component is not Provided or if Job Status is "Cancelled"
                    hidden:
                      !props.deleteComponent || rowData.IdStatus === 7
                        ? true
                        : false,
                    icon: () => <Delete className={classes.iconTable} />,
                    tooltip: "Delete " + props.title,
                    onClick: (event, rowData) => {
                      openDelItem(rowData);
                    },
                  }),
                ]}
                options={{
                  actionsColumnIndex: -1,
                  searchFieldVariant: "outlined",
                  pageSize: 10,
                  headerStyle: {
                    paddingTop: 5,
                    paddingBottom: 5,
                    fontWeight: "bold",
                    fontSize: 14,
                    color: "#888",
                    fontFamily: "'Open Sans',sans-serif",
                    borderTop: "1px solid rgba(224, 224, 224, 1)",
                  },
                  rowStyle: {
                    fontWeight: "lighter",
                    fontSize: 14,
                    color: "#888",
                    fontFamily: "'Open Sans',sans-serif",
                    height: 40,
                  },
                  searchFieldStyle: {
                    paddingTop: 0,
                    paddingBottom: 0,
                    position: 'absolute',
                    left: -20,
                    top: -20,
                    zIndex: 30,
                    background: '#fff'
                  },
                }}
                localization={{
                  header: {
                    actions: "Actions",
                  },
                  body: {
                    emptyDataSourceMessage: "No " + props.title + " added yet",
                  },
                }}
              />
            </Box>
          </Box>
          {/*Add, Update, Delete Buttons*/}
          {DeleteItemComponent !== undefined ? DeleteItemComponent : null}
        </TabPanel>
        <TabPanel value={value} index={1}>
          {AddItemComponent}
        </TabPanel>
        <TabPanel value={value} index={2}>
          {UpdateItemComponent !== undefined ? UpdateItemComponent : null}
        </TabPanel>
      </div>
    </React.Fragment>
  );
};

export default LockSmithMainTable;
