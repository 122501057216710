/* eslint-disable array-callback-return */
import { Grid, InputBase, MenuItem, Select, styled } from "@material-ui/core";
import React, { useState } from "react";
import { useStateValue } from "../../../Context/store";
import { useForm } from "../../../hooks/useForm";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "& .MuiInputBase-input": {
        padding: "9px 2px",
    },
}));

const ClassTab = (props) => {
    const { handleClassData } = props;
    const [{ allClasses, allCategory, allSubCat }] = useStateValue();
    const { formData, handleInputChange } = useForm();

    /* Category Controls */
    const [catSelect, setCatSelect] = useState(true);
    const getCategoryData = (e) => {
        setCatSelect(false);
    };

    /* SubCategory Controls */
    const [subCatSelect, setSubCatSelect] = useState(true);
    const getSubCatData = (e) => {
        setSubCatSelect(false);
    };

    return (
        <Grid alignItems="center" container justifyContent="center" spacing={2} style={{marginBottom: 20}}>
            <Grid item xs={12} lg={4}>
                <text
                    style={{
                        fontSize: 14,
                        fontWeight: 400,
                        color: "rgb(136, 136, 136)"
                    }}
                >
                    Class
                </text>
                <Select
                    name="pidClass"
                    id="pidClass"
                    style={{
                        backgroundColor: "#FFF",
                        borderRadius: 5,
                        border:"1px solid #888",
                        width: "100%",
                        color:"#888"
                    }}
                    variant="outlined"
                    input={<BootstrapInput />}
                    value={formData.pidClass}
                    onChange={(e) => {
                        handleInputChange(e);
                        getCategoryData(e);
                    }}
                   
                >
                    {allClasses.class
                        ? allClasses.class.map((item, index) => {
                              return (
                                  <MenuItem
                                      key={index}
                                      style={{
                                          justifyContent: "center",
                                      }}
                                      value={item.IdClass}
                                  >
                                      {item.IdClass} - {item.Descr}
                                  </MenuItem>
                              );
                          })
                        : ""}
                </Select>
            </Grid>
            <Grid item xs={12} lg={4}>
                <text
                    style={{
                        fontSize: 14,
                        fontWeight: 400,
                        color: "rgb(136, 136, 136)"
                    }}
                >
                    Category
                </text>
                <Select
                    name="pidCategory"
                    style={{
                        backgroundColor: "#FFF",
                        borderRadius: 5,
                        border:"1px solid #888",
                        width: "100%",
                        color:"#888"
                    }}
                    variant="outlined"
                    input={<BootstrapInput />}
                
                    disabled={catSelect}
                    value={formData.pidCategory}
                    onChange={(e) => {
                        handleInputChange(e);
                        getSubCatData(e);
                    }}
                    
                >
                    {allCategory.category &&
                        allCategory.category.map((item, index) => {
                            if (item.IdClass === formData.pidClass) {
                                return (
                                    <MenuItem
                                        key={index}
                                        style={{
                                            justifyContent: "center",
                                        }}
                                        value={item.IdCategory}
                                    >
                                        {item.IdCategory} - {item.Descr}
                                    </MenuItem>
                                );
                            }
                        })}
                </Select>
            </Grid>
            <Grid item xs={12} lg={4}>
                <text
                    style={{
                        fontSize: 14,
                        fontWeight: 400,
                        color: "rgb(136, 136, 136)"
                    }}
                >
                    Subcategory*
                </text>
                <Select
                    name="pIdSubCategory"
                    style={{
                        backgroundColor: "#FFF",
                        borderRadius: 5,
                        border:"1px solid #888",
                        width: "100%",
                        color:"#888"
                    }}
                    variant="outlined"
                    input={<BootstrapInput />}
                   
                    disabled={subCatSelect}
                    value={formData.pIdSubCategory}
                    onChange={handleClassData}
                    required
                    
                >
                    {allSubCat.subcat &&
                        allSubCat.subcat.map((item, index) => {
                            if (item.IdCategory === formData.pidCategory) {
                                return (
                                    <MenuItem
                                        key={index}
                                        style={{
                                            justifyContent: "center",
                                        }}
                                        value={item.IdSubCategory}
                                    >
                                        {item.IdSubCategory} - {item.Descr}
                                    </MenuItem>
                                );
                            }
                        })}
                </Select>

            </Grid>
        </Grid>
    );
};

export default ClassTab;
