export const initialState = {
    pricelist: [],
};

const allPriceListReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_PRICELIST":
            return {
                ...state,
                pricelist: action.pricelist
            };

        default:
            return state;
    }
};

export default allPriceListReducer;