import React, { useEffect, useState } from "react";
import { useStateValue } from "../../Context/store";
import { deleteBank, getAllBanks } from "../../Actions/UserAction";
import AddBank from "./AddBank";
import LockSmithDeleteComponent from "../ReusableComponents/LockSmithDeleteComponent";
import UpdateBank from "./UpdateBank";
/*Import Reusable Components */
import LockSmithMainTable from "../ReusableComponents/LockSmithMainTable";

const columns = () => { 
    return [
    { title: "ID", field: "IdBank",cellStyle: {height: 40,padding:"0px 16px"} },
    { title: "Description", field: "Descr",cellStyle: {height: 40,padding:"0px 16px"} },
    ]
} ;

const Bank = () => {
    const [{ allBank }] = useStateValue();
    const [bank, setBank] = useState(null);

    useEffect(() => {
        console.log("All bank use effect")
        setBank(allBank ? allBank.bank : []);
    }, [allBank]);

    return (
        <React.Fragment>
            <LockSmithMainTable 
                title="Bank"
                data={bank}
                columns={columns()}
                addComponent={AddBank}
                updateComponent={UpdateBank}
                deleteComponent={LockSmithDeleteComponent}
                deleteAction={deleteBank}
                reducer={getAllBanks}
                itemId={columns().field}
            />
        </React.Fragment>
    );
};

export default Bank;
