import {
    Box,
    Button,
    Grid,
    Select,
    TextField,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { getAllAddressType, uptAddressType } from "../../Actions/UserAction";
import { useStateValue } from "../../Context/store";
import LocksmithEntryId from "../ReusableComponents/LockSmithEntryId";
import LockSmithTextField from "../ReusableComponents/LockSmithTextField";
/** Import Icons */
import Save from "@material-ui/icons/Save";
import Close from "@material-ui/icons/Close";

/** Theme & Styles Import */
import { dialogStyle } from "../../Assets/styles/Styles";

const UpdateAddressType = ({ openDialog, closeDialog, data }) => {
    const classes = dialogStyle();
    const [openBackdrop, setOpenBackdrop] = useState(false);
    // eslint-disable-next-line no-empty-pattern
    const [{ allATC }, dispatch] = useStateValue();
    const [addressTypeData, setAddressTypeData] = useState({});

    const dataValues = (e) => {
        const { name, value } = e.target;
        setAddressTypeData((last) => ({
            ...last,
            [name]: value,
        }));
    };

    const saveFunction = async (e) => {
        e.preventDefault();
        setOpenBackdrop(true);
        await uptAddressType(addressTypeData).then((response) => {
            if (response.status === 200) {
                setOpenBackdrop(false);
                dispatch({
                    type: "OPEN_SNACKBAR",
                    openMessage: {
                        open: true,
                        title: "Success",
                        message: response.data.output.pmensaje,
                        alert: "success",
                    },
                });
                getAllAddressType(dispatch);
                closeDialog();
            } else {
                setOpenBackdrop(false);
                dispatch({
                    type: "OPEN_SNACKBAR",
                    openMessage: {
                        open: true,
                        title: "Error",
                        message: response.data.msg,
                        alert: "error",
                    },
                });
            }
        });
    };

    useEffect(() => {
        setAddressTypeData({
            pIdType: data.IdType,
            pDescr: data.Descr,
            pIdClass: data.IdClass,
        });
    }, [data]);

    const resetForm = () => {
        setAddressTypeData({
            pIdType: data.IdType,
            pDescr: data.Descr,
            pIdClass: data.IdClass,
        });
    };

    return (
            <form onSubmit={saveFunction}>
                    <Grid
                        container
                        spacing={2}
                        alignContent="center"
                        justifyContent="center"
                    >
                        <Grid item sm={12} xs={12}>
                            <LocksmithEntryId
                                label="ID"
                                id={addressTypeData.pIdType}
                            />
                        </Grid>
                    </Grid>       
                    <Grid
                        container
                        spacing={2}
                        alignContent="center"
                        justifyContent="center"
                    >   
                        <Grid item sm={6} xs={6}>
                            <LockSmithTextField
                                    label="Description"
                                    labelWidth="32%"
                                    name="pDescr"
                                    value={addressTypeData.pDescr}
                                    onChange={dataValues}
                                    required
                                />
                        </Grid>
                        <Grid item sm={6} xs={6}>
                            <text
                               style={{ fontSize: 14, fontWeight: "400", color:"rgb(136,136,136)" }}
                            >
                                Address class
                            </text>
                        
                            <Select
                                labelId="emp-label"
                                name="pIdClass"
                                style={{
                                    backgroundColor: "#fff",
                                    borderRadius: 5,
                                    marginTop: 5
                                }}
                                value={addressTypeData.pIdClass}
                                id="pIdClass"
                                onChange={dataValues}
                                label="Select Address_Type_Class"
                                variant="outlined"
                                required
                                fullWidth
                                native
                            >
                                {allATC
                                    && allATC.atc.map((item) => (
                                            <option
                                                value={item.IdClass}
                                                key={item.IdClass}
                                            >
                                                {item.Descr}
                                            </option>
                                        ))}
                            </Select>
                        </Grid>
                        
                    </Grid>

                    <Grid
                        container
                        spacing={2}
                        alignContent="center"
                        justifyContent="flex-end"
                    >   
                        <Grid item xs={6} lg={3}>
                                <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                classes={{ root: classes.saveButton }}
                                size="large"
                                fullWidth
                                endIcon={(
                                    <div
                                        style={{
                                            width: 20,
                                            height: 20,
                                            marginTop: -14,
                                            marginLeft: 10,
                                        }}
                                    >
                                        <Save/>
                                    </div>
                                )}
                            >
                                Save
                            </Button>
                        </Grid>
                        <Grid item xs={6} lg={3}>
                            <Button
                            variant="contained"
                            onClick={resetForm}
                            classes={{ root: classes.cancelButton }}
                            size="large"
                            fullWidth
                            endIcon={(
                                <div
                                    style={{
                                        width: 20,
                                        height: 20,
                                        marginTop: -14,
                                        marginLeft: 10,
                                    }}
                                >
                                    <Close/>
                                </div>
                            )}
                        >
                            Reset
                        </Button>
                        </Grid>
                    </Grid>
            </form>
    );
};

export default UpdateAddressType;
