import React, { useEffect, useState } from "react";
import { useStateValue } from "../../Context/store";
import { delMUnit, getAllMUnit } from "../../Actions/UserAction";
import AddMUnit from "./AddMUnit";
import LockSmithDeleteComponent from "../ReusableComponents/LockSmithDeleteComponent";
import UpdateMUnit from "./UpdateMUnit";
/*Import Reusable Components */
import LockSmithMainTable from "../ReusableComponents/LockSmithMainTable";

const columns = () => { 
    return [
    { title: "ID", field: "IdUMeasure",cellStyle: {height: 40,padding:"0px 16px"} },
    { title: "Description", field: "Descr",cellStyle: {height: 40,padding:"0px 16px"} },
]};

const MUnit = () => {
    const [{ allMUnit }] = useStateValue();
    const [MUnit, setMUnit] = useState({});
    
    useEffect(() => {
        setMUnit(allMUnit ? allMUnit.munit : []);
    }, [allMUnit]);

    return (
        <React.Fragment>
            <LockSmithMainTable 
                title="MUnit"
                data={MUnit}
                columns={columns()}
                addComponent={AddMUnit}
                updateComponent={UpdateMUnit}
                deleteComponent={LockSmithDeleteComponent}
                deleteAction={delMUnit}
                reducer={getAllMUnit}
                itemId={columns().field}
            />
        </React.Fragment>
    );
};

export default MUnit;
