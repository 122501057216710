export const initialState = {
    userAuth: [],
};

const userAuthReducer = (state = initialState, action) => {
    switch (action.type) {
        case "USER_AUTH":
            return {
                ...state,
                userAuth: action.userAuth,
            };

        default:
            return state;
    }
};

export default userAuthReducer;
