import {
    Button,
    Grid,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useStateValue } from "../../Context/store";
import moment from "moment";
import { getAllContact, uptContact } from "../../Actions/UserAction";

/** Import Icons */
import Save from "@material-ui/icons/Save";
import Close from "@material-ui/icons/Close";
/**Import Components */
import LockSmithTextField from "../ReusableComponents/LockSmithTextField";
import LockSmithSelect from "../ReusableComponents/LockSmithSelect";
import LocksmithEntryId from "../ReusableComponents/LockSmithEntryId";

/** Theme & Styles Import */
import { dialogStyle } from "../../Assets/styles/Styles";

const UpdateContact = ({ openDialog, closeDialog, data }) => {
    const classes = dialogStyle();
    const [openBackdrop, setOpenBackdrop] = useState(false);
    // eslint-disable-next-line no-empty-pattern
    const [{allCompany}, dispatch] = useStateValue();
    const [contactData, setContactData] = useState({});

    const companyFields = ["IdCompany", "Desc"];

    const dataValues = (e) => {
        const { name, value } = e.target;
        setContactData((last) => ({
            ...last,
            [name]: value,
        }));
    };

    const saveFunction = async (e) => {
        e.preventDefault();
        setOpenBackdrop(true);
        await uptContact(contactData).then((response) => {
            if (response.status === 200) {
                setOpenBackdrop(false);
                dispatch({
                    type: "OPEN_SNACKBAR",
                    openMessage: {
                        open: true,
                        title: "Success",
                        message: response.data.output.pmensaje,
                        alert: "success",
                    },
                });
                getAllContact(dispatch);
                closeDialog();
            } else {
                setOpenBackdrop(false);
                dispatch({
                    type: "OPEN_SNACKBAR",
                    openMessage: {
                        open: true,
                        title: "Error",
                        message: response.data.msg,
                        alert: "error",
                    },
                });
            }
        });
    };

    const formatDates = () => {
        var date = moment(data.DateMod).format("YYYY-MM-DD");
        setContactData((last) => ({
            ...last,
            pDateMod: date,
        }));
    };

    useEffect(() => {
        setContactData({
            pIdContact: data.IdContact,
            pTit: data.Tit,
            pFirstName: data.FistName,
            pMiddleName: data.MiddleName,
            pLastName: data.LastName,
            pEmail: data.Email,
            pPhone: data.Phone,
            pExt: data.Ext,
            pMovil: data.Movil,
            pIdCompany: data.IdCompany,
        });
        formatDates();
    }, [data]);

    const resetForm=()=>{
        setContactData({
            pIdContact: data.IdContact,
            pTit: data.Tit,
            pFirstName: data.FistName,
            pMiddleName: data.MiddleName,
            pLastName: data.LastName,
            pEmail: data.Email,
            pPhone: data.Phone,
            pExt: data.Ext,
            pMovil: data.Movil,
            pIdCompany: data.IdCompany,
        });
        formatDates();
    };
    return (
            <form onSubmit={saveFunction}>
                    <Grid
                        container
                        spacing={2}
                        alignContent="center"
                        justifyContent="center"
                    >
                        <Grid item xs={12} sm={12}>
                        <LocksmithEntryId label="Id contact" id={contactData.pIdContact} />
                        </Grid>
                        <Grid item xs={3} sm={3}>
                            <LockSmithTextField
                                label="Title"
                                name="pTit"
                                onChange={dataValues}
                                value={contactData.pTit}
                            />
                        </Grid>
                        <Grid item xs={3} sm={3}>
                            <LockSmithTextField
                                label="First name*"
                                name="pFirstName"
                                labelWidth="100%"
                                onChange={dataValues}
                                value={contactData.pFirstName}
                                required
                            />
                        </Grid>
                        <Grid item xs={3} sm={3}>
                            <LockSmithTextField
                                label="Middle name"
                                name="pMiddleName"
                                labelWidth="100%"
                                onChange={dataValues}
                                value={contactData.pMiddleName}
                            />
                        </Grid>
                        <Grid item xs={3} sm={3}>
                            <LockSmithTextField
                                label="Last name*"
                                name="pLastName"
                                labelWidth="100%"
                                onChange={dataValues}
                                value={contactData.pLastName}
                                required
                            />
                        </Grid>
                        <Grid item xs={2} sm={2}>
                            <LockSmithTextField
                                label="Phone"
                                labelWidth="100%"
                                name="pPhone"
                                type="number"
                                onChange={dataValues}
                                value={contactData.pPhone}
                                
                            />
                        </Grid>
                        <Grid item xs={1} sm={1}>
                            <LockSmithTextField
                                label="Ext"
                                name="pExt"
                                type="number"
                                onChange={dataValues}
                                value={contactData.pExt}
                            />
                        </Grid>
                        <Grid item xs={3} sm={3}>
                            <LockSmithTextField
                                label="Mobile*"
                                name="pMovil"
                                type="number"
                                onChange={dataValues}
                                value={contactData.pMovil}
                                required
                            />
                        </Grid>
                        <Grid item xs={3} sm={3}>
                            <LockSmithTextField
                                label="Email"
                                name="pEmail"
                                onChange={dataValues}
                                value={contactData.pEmail}
                            />
                        </Grid>
                        <Grid item xs={3} sm={3}>
                            <LockSmithSelect
                                name="pIdCompany"
                                value={contactData.pIdCompany}
                                handler={dataValues}
                                data={allCompany}
                                label="Company"
                                labelWidth="22%"
                                fields={companyFields}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <text
                                style={{
                                    fontSize: 16,
                                    fontWeight: "bold",
                                    marginLeft: 15,
                                }}
                            >
                                DATE MOD
                            </text>
                            <text
                                style={{
                                    fontSize: 20,
                                    fontWeight: "bold",
                                    marginLeft: 30,
                                }}
                            >
                                {moment().format("LLLL").toUpperCase()}
                            </text>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        spacing={2}
                        alignContent="center"
                        justifyContent="center"
                    >
                        <Grid item xs={6} sm={6}></Grid>
                        <Grid item xs={3} sm={3}>
                            <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            classes={{ root: classes.saveButton }}
                            size="large"
                            fullWidth
                            endIcon={
                                <div
                                    style={{
                                        width: 20,
                                        height: 20,
                                        marginTop: -14,
                                        marginLeft: 10,
                                    }}
                                >
                                    <Save/>
                                </div>
                            }
                        >
                            Save
                        </Button>
                        </Grid>
                        <Grid item xs={3} sm={3}>
                                <Button
                                variant="contained"
                                onClick={resetForm}
                                classes={{ root: classes.cancelButton }}
                                size="large"
                                fullWidth
                                endIcon={
                                    <div
                                        style={{
                                            width: 20,
                                            height: 20,
                                            marginTop: -14,
                                            marginLeft: 10,
                                        }}
                                    >
                                        <Close/>
                                    </div>
                                }
                            >
                                Reset
                            </Button>
                        </Grid>
                    </Grid>
                    
                    
            </form>
    );
};

export default UpdateContact;
