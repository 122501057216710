export const initialState = {
    munit: [],
};

const allMUnitReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_MUNIT":
            return {
                ...state,
                munit: action.munit
            };

        default:
            return state;
    }
};

export default allMUnitReducer;