import React, { useEffect, useState } from "react";
import { useStateValue } from "../../Context/store";
import { delPriceList, getAllPriceList } from "../../Actions/UserAction";
import AddPriceList from "./AddPriceList";
import LockSmithDeleteComponent from "../ReusableComponents/LockSmithDeleteComponent";
import UpdatePriceList from "./UpdatePriceList";
/*Import Reusable Components */
import LockSmithMainTable from "../ReusableComponents/LockSmithMainTable";

const columns = () => { 
    return [
    { title: "ID", field: "IdListPrice",cellStyle: {height: 40,padding:"0px 16px"} },
    { title: "Name", field: "Name",cellStyle: {height: 40,padding:"0px 16px"} },
    { title: "Date", field: "Date", type: "datetime",cellStyle: {height: 40,padding:"0px 16px"} },
]};

const PriceList = () => {
    const [{ allPriceList }] = useStateValue();
    const [priceList, setPriceList] = useState(null);

    useEffect(() => {
        setPriceList(allPriceList ? allPriceList.pricelist : []);
    }, [allPriceList]);

    return (
        <React.Fragment>
            <LockSmithMainTable 
                title="Price List"
                data={priceList}
                columns={columns()}
                //addComponent={AddPriceList}
                updateComponent={UpdatePriceList}
                //deleteComponent={LockSmithDeleteComponent}
                deleteAction={delPriceList}
                reducer={getAllPriceList}
                itemId={columns().field}
            />
        </React.Fragment>
    );
};

export default PriceList;
