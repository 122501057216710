import { Button, Grid, TextField, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { insPriceProduct } from "../../../Actions/UserAction";
import SaveIcon from "../../../Assets/Icons/Remember_me_Yes.svg";
import { dialogStyle } from "../../../Assets/styles/Styles";
import { useStateValue } from "../../../Context/store";
import { LoadingComponent, useLoading } from "../../../hooks/useLoading";
import { getPriceProduct } from "../../../Actions/UserAction";
import Save from "@material-ui/icons/Save";

const useStyle = makeStyles({
  label: {
    fontSize: 16,
    fontWeight: "bold",
  },
  dropDown: {
    backgroundColor: "#FFF",
    borderRadius: 8,
    width: "100%",
  },
  icon: {
    width: 20,
    height: 20,
    margin: 10,
    color: "rgb(136,136,136)",
  },
  borderForm: {
    border: "solid 1px rgba(0, 0, 0, 0.23) !important",
    width: "100%",
    borderRadius: 5,
  },
  textForm: {
    fontSize: 14,
    fontWeight: "400",
    color: "rgb(136,136,136)",
    width: "100%",
  },
});

const PriceProductTab = (props) => {
  const classes = dialogStyle();
  const classesTabs = useStyle();
  const [{ allPriceList }, dispatch] = useStateValue();
  const { loading, openLoading, closeLoading } = useLoading();
  const [priceProduct, setPriceProduct] = useState([
    { IdListPrice: 1, IdProduct: props.data, Price: 0 },
    { IdListPrice: 2, IdProduct: props.data, Price: 0 },
    { IdListPrice: 3, IdProduct: props.data, Price: 0 },
    { IdListPrice: 4, IdProduct: props.data, Price: 0 },
    { IdListPrice: 5, IdProduct: props.data, Price: 0 },
    { IdListPrice: 6, IdProduct: props.data, Price: 0 },
  ]);
  //const { formData, handleInputChange, setFormData } = useForm({});

  const handlePrice = (e) => {
    //console.log(e.target.value)

    let updatedList = priceProduct.map((item) => {
      console.log(item.IdListPrice, e.target.id);
      if (item.IdListPrice == Number(e.target.id)) {
        //console.log("Match")
        return { ...item, Price: e.target.value }; //gets everything that was already in item, and updates "done"
      }
      return item; // else return unmodified item
    });
    setPriceProduct(updatedList); // set state to new object with updated list
  };

  const saveFunction = async (e) => {
    e.preventDefault();
    openLoading();
    await insPriceProduct(priceProduct).then((response) => {
      if (response.status === 200) {
        closeLoading();
        dispatch({
          type: "OPEN_SNACKBAR",
          openMessage: {
            open: true,
            title: "Success",
            message: response.data.output.pmensaje,
            alert: "success",
          },
        });
      } else {
        closeLoading();
        dispatch({
          type: "OPEN_SNACKBAR",
          openMessage: {
            open: true,
            title: "Error",
            message: response.data.msg,
            alert: "error",
          },
        });
      }
    });
  };

  const getProductPrice = async () => {
    openLoading();
    await getPriceProduct({ pIdProduct: props.data }).then((response) => {
      if (response.status === 200) {
        closeLoading();
        if (response.data.length > 0) {
          setPriceProduct(response.data);
        }
      }
    });
  };

  useEffect(() => {
    getProductPrice();
  }, []);

  return (
    <>
      <Grid alignItems="center" container justifyContent="center" spacing={2}>
        {allPriceList.pricelist.map((item, index) => {
          return (
            <>
              <Grid item sm={12} xs={12} lg={4}>
                <text className={classesTabs.textForm}>{item.Name}</text>
                <TextField
                  name={"Price" + index}
                  id={index + 1}
                  className={classesTabs.borderForm}
                  InputProps={{ disableUnderline: true }}
                  style={{ padding: "8px 0 0 8px" }}
                  value={priceProduct[index] ? priceProduct[index].Price : 0}
                  type="number"
                  onChange={handlePrice}
                />
              </Grid>
            </>
          );
        })}
        <Grid item sm={6} xs={6}></Grid>
          <Grid item sm={3} xs={3}></Grid>
        <Grid item sm={12} xs={12} lg={3}>
          <Button
            variant="contained"
            color="primary"
            classes={{ root: classes.saveButton }}
            size="large"
            fullWidth
            endIcon={
              <div
                style={{
                  width: 20,
                  height: 20,
                  marginTop: -14,
                  marginLeft: 10,
                }}
              >
                <Save style={{ color: "#fff" }} />
              </div>
            }
            onClick={saveFunction}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default PriceProductTab;
