import {
    Button,
    Grid,
} from "@material-ui/core";
import React from "react";
import { useStateValue } from "../../Context/store";

/** Import Icons */
import Save from "@material-ui/icons/Save";
import Close from "@material-ui/icons/Close";
/**Import Components */
import LockSmithTextField from "../ReusableComponents/LockSmithTextField";
import LockSmithSelect from "../ReusableComponents/LockSmithSelect";

/** Theme & Styles Import */
import { dialogStyle } from "../../Assets/styles/Styles";
import { LoadingComponent, useLoading } from "../../hooks/useLoading";
import { useForm } from "../../hooks/useForm";
import { getAllSubCat, insSubCat } from "../../Actions/UserAction";

const AddSubCat = ({ openDialog, closeDialog }) => {
    const classes = dialogStyle();
    const { loading, openLoading, closeLoading } = useLoading();
    const subCatFields = ["IdCategory", "Descr"];
    // eslint-disable-next-line no-empty-pattern
    const [{allCategory}, dispatch] = useStateValue();
    const { formData, handleInputChange, setFormData } = useForm({
        pDescr: "",
        pidCategory: 0,
    });

    const saveFunction = async (e) => {
        e.preventDefault();
        openLoading()
        await insSubCat(formData).then((response) => {
            if (response.status === 200) {
                closeLoading();
                dispatch({
                    type: "OPEN_SNACKBAR",
                    openMessage: {
                        open: true,
                        title: "Success",
                        message: response.data.output.pmensaje,
                        alert: "success",
                    },
                });
                getAllSubCat(dispatch);
                cancelFunction();
            } else {
                closeLoading();
                dispatch({
                    type: "OPEN_SNACKBAR",
                    openMessage: {
                        open: true,
                        title: "Error",
                        message: response.data.msg,
                        alert: "error",
                    },
                });
            }
        })
    };

    const cancelFunction = () => {
        Array.from(document.querySelectorAll("form input")).forEach(
            input => (input.value = "")
          );
        setFormData({})
    };

    return (
            <form onSubmit={saveFunction}>
                <Grid
                        container
                        spacing={2}
                        alignContent="center"
                        justifyContent="center"
                    >
                        <Grid item xs={12} lg={6}>
                            <LockSmithTextField
                                label="Description"
                                labelWidth="32%"
                                name="pDescr"
                                value={formData.pDescr}
                                onChange={handleInputChange}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <LockSmithSelect 
                                label="Select category" 
                                name="pidCategory" 
                                value={formData.pidCategory} 
                                valueId="IdClass" 
                                data={allCategory} 
                                handler={handleInputChange} 
                                fields={subCatFields}
                                required={true}
                            />
                        </Grid>
                        
                    </Grid>
                    <Grid
                        container
                        spacing={2}
                        alignContent="center"
                        justifyContent="flex-end"
                    >
                        <Grid item xs={6} lg={3}>
                                <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                classes={{ root: classes.saveButton }}
                                size="large"
                                fullWidth
                                endIcon={
                                    <div
                                        style={{
                                            width: 20,
                                            height: 20,
                                            marginTop: -14,
                                            marginLeft: 10,
                                        }}
                                    >
                                        <Save/>
                                    </div>
                                }
                            >
                                Save
                            </Button>
                        </Grid>
                        <Grid item xs={6} lg={3}>
                                <Button
                                variant="contained"
                                onClick={cancelFunction}
                                classes={{ root: classes.cancelButton }}
                                size="large"
                                fullWidth
                                endIcon={
                                    <div
                                        style={{
                                            width: 20,
                                            height: 20,
                                            marginTop: -14,
                                            marginLeft: 10,
                                        }}
                                    >
                                        <Close/>
                                    </div>
                                }
                            >
                                Reset
                            </Button>
                        </Grid>
                    </Grid>
            </form>
    );
};

export default AddSubCat;
