export const initialState = {
    skills: [],
};

const allSkilsReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_SKILLS":
            return {
                ...state,
                skills: action.skills,
            };

        default:
            return state;
    }
};

export default allSkilsReducer;
