export const initialState = {
    brand: [],
};

const allBrandReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_BRAND":
            return {
                ...state,
                brand: action.brand
            };

        default:
            return state;
    }
};

export default allBrandReducer;