import {
    Backdrop,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid
} from "@material-ui/core";
import React, { useState } from "react";
import { getAllRoles, insRole } from "../../Actions/UserAction";
import Save from "@material-ui/icons/Save";
import Close from "@material-ui/icons/Close";
/** Import Icons */
/**Import Components */
import LockSmithTextField from "../ReusableComponents/LockSmithTextField";
/** Theme & Styles Import */
import { dialogStyle } from "../../Assets/styles/Styles";
import { useStateValue } from "../../Context/store";



const AddRole = ({ openDialog, closeDialog }) => {
    const classes = dialogStyle();
    const [openBackdrop, setOpenBackdrop] = useState(false);
    // eslint-disable-next-line no-empty-pattern
    const [{}, dispatch] = useStateValue();
    const [roleData, setRoleData] = useState({});

    const dataValues = (e) => {
        const { name, value } = e.target;
        setRoleData((last) => ({
            ...last,
            [name]: value,
        }));
    };

    const saveFunction = async (e) => {
        e.preventDefault();
        setOpenBackdrop(true);
        await insRole(roleData).then((response) => {
            if (response.status === 200) {
                setOpenBackdrop(false);
                dispatch({
                    type: "OPEN_SNACKBAR",
                    openMessage: {
                        open: true,
                        title: "Success",
                        message: response.data.output.pmensaje,
                        alert: "success",
                    },
                });
                getAllRoles(dispatch);
                resetFunction();
            } else {
                setOpenBackdrop(false);
                dispatch({
                    type: "OPEN_SNACKBAR",
                    openMessage: {
                        open: true,
                        title: "Error",
                        message: response.data.msg,
                        alert: "error",
                    },
                });
            }
        });
    };

    const resetFunction = () => {
        setRoleData({});
        Array.from(document.querySelectorAll("form input")).forEach(
            input => (input.value = "")
          );
    };

    return (
        
            <form className={classes.content} onSubmit={saveFunction}>
                <Grid
                        container
                        spacing={2}
                        alignContent="center"
                        justifyContent="center"
                    >
                        <LockSmithTextField
                            label="Description"
                            labelWidth="32%"
                            name="pDescr"
                            value={roleData.pDescr}
                            onChange={dataValues}
                            required
                        />
                       
                </Grid>
                
                <Grid
                        container
                        spacing={2}
                        alignContent="center"
                        justifyContent="flex-end"
                    >
                
                        <Grid item xs={6} lg={3}>
                                <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                classes={{ root: classes.saveButton }}
                                size="large"
                                fullWidth
                                endIcon={
                                    <div
                                        style={{
                                            width: 20,
                                            height: 20,
                                            marginTop: -9,
                                            marginLeft: 10,
                                        }}
                                    >
                                        <Save/>
                                    </div>
                                }
                            >
                                Save
                            </Button>
                        </Grid>
                        <Grid item xs={6} lg={3}>
                                <Button
                                variant="contained"
                                onClick={resetFunction}
                                classes={{ root: classes.cancelButton }}
                                size="large"
                                fullWidth
                                endIcon={
                                    <div
                                        style={{
                                            width: 20,
                                            height: 20,
                                            marginTop: -9,
                                            marginLeft: 10,
                                        }}
                                    >
                                        <Close />
                                    </div>
                                }
                            >
                                Reset
                            </Button>
                        </Grid>
                </Grid>
                    
                    
            </form>
    );
};

export default AddRole;
