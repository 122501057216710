import {
    Backdrop,
    Box,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid
} from "@material-ui/core";
import MaterialTable from "material-table";
import { Alert } from '@material-ui/lab'
import React, { useEffect, useState } from "react";
import { getAllProfileDoc, insProfileDoc } from "../../Actions/UserAction";
import { useStateValue } from "../../Context/store";

/** Import icons */
import { ReactComponent as EmployeesIcon } from "../../Assets/Icons/Employees.svg";
import { ReactComponent as AddIcon } from "../../Assets/Icons/Add.svg";
/**Import Components */
import LockSmithSelect from "../ReusableComponents/LockSmithSelect";
import LocksmithEntryId from "../ReusableComponents/LockSmithEntryId";
import LockSmithComponentButtons from "../ReusableComponents/LockSmithComponentButtons";
import { getAllTypeEmpNotProfDoc } from "../../Actions/UserAction"

/** Theme & Styles Import */
import { dialogStyle } from "../../Assets/styles/Styles";

const columns = () => { 
    return [
    { title: "ID", field: "IdDocument" },
    { title: "DESCRIPTION", field: "DescrRol" },
]};

const UpdateProfileDoc = ({ openDialog, closeDialog, data }) => {
    const profileDocFields = ["IdTypeEmp", "Descr"];
    const classes = dialogStyle();
    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [{ allDocumentE, allTypeEmp }, dispatch] = useStateValue();
    const [open, setOpen] = useState(false);
    const [docRequired, setDocRequired] = useState(true);
    const [documentEData, setDocumentEData] = useState([]);
//    const [typeEmpData, setTypeEmpData] = useState([]);
    const [profileDocData, setProfileDocData] = useState({
        pidProfileDocs: [],
        //pidTypeEmp: 0,
    });

    const dataValues = (e) => {
        const { name, value } = e.target;
        setProfileDocData((last) => ({
            ...last,
            [name]: value,
        }));
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpen(false);
    }

    const handleDocumentSelection = (rows) => {
        let docs = [];
        for(let i = 0; i < rows.length; i++){
            docs.push(rows[i].IdDocument)
        }

        setProfileDocData(prevState => ({
            ...prevState,
            pidProfileDocs: docs,
        }));
        
    }

     const saveFunction = async (e) => {
        e.preventDefault();
        if(docRequired){
            setOpen(true);
            return;
        }
        setOpenBackdrop(true);
        await insProfileDoc(profileDocData).then((response) => {
            if (response.status === 200) {
                setOpenBackdrop(false);
                dispatch({
                    type: "OPEN_SNACKBAR",
                    openMessage: {
                        open: true,
                        title: "Success",
                        message: response.data.output.pmensaje,
                        alert: "success",
                    },
                });
                getAllProfileDoc(dispatch);
                cancelFunction();
            } else {
                setOpenBackdrop(false);
                dispatch({
                    type: "OPEN_SNACKBAR",
                    openMessage: {
                        open: true,
                        title: "Error",
                        message: response.data.msg,
                        alert: "error",
                    },
                });
            }
        });
    };

    const cancelFunction = () => {
        setDocumentEData(initDocData());
    };

    const initDocData = () => {
        let docsChecked = [];
        allDocumentE.documentE.forEach((item) => {

                if(data.idDocument.indexOf(item.IdDocument) > -1){
                    //console.log("found")
                    docsChecked.push( {
                        IdDocument: item.IdDocument,
                        DescrRol: item.Descr,
                        tableData: {checked: true},
                        });
                } else {
                    //console.log("not found")
                    docsChecked.push( {
                        IdDocument: item.IdDocument,
                        DescrRol: item.Descr,
                        tableData: {checked: false},
                        });
                    }

        });
        return docsChecked;
    }

/*     const getAllTypeEmpNotAssigned = async () => {
        await getAllTypeEmpNotProfDoc().then((response) => {
            if(response.status === 200){
                console.log("response", response.data);
                setTypeEmpData({"typeemp": response.data});
            }
        });
    } */

    useEffect(() => {
        //getAllTypeEmpNotAssigned();
        if(allDocumentE && allDocumentE.documentE && Object.keys(data).length != 0){
            setDocumentEData(initDocData())
            setProfileDocData((last) => ({
                ...last,
                pidProfileDocs: data.idDocument,
                pidTypeEmp: data.idTypeEmp,
            }));
        }   
    },[data]);

    useEffect(() => {
        if(profileDocData && profileDocData.pidProfileDocs.length > 0){
            setDocRequired(false);
            setOpen(false);
        }else{
            setDocRequired(true);
        }
    }
    ,[profileDocData]);

    return (
            <form onSubmit={saveFunction}>
                    <Grid
                        container
                        spacing={1}
                        alignContent="center"
                        justifyContent="center"
                    >
                        <LocksmithEntryId label="ID PROFILE DOC" id={profileDocData.pidTypeEmp} />
                        <LockSmithSelect 
                            label="Select emp type" 
                            name="pidTypeEmp" 
                            value={profileDocData.pidTypeEmp} 
                            valueId="pidTypeEmp" 
                            data={allTypeEmp} 
                            handler={dataValues} 
                            fields={profileDocFields} 
                            disabled={true}/>
                        <Grid item xs={12} sm={12}>
                            <div style={{opacity: open ? 1 : 0}}>
                                <Alert variant="filled" onClose={handleClose} severity="error" >
                                Select at least 1 document!
                                </Alert>
                            </div>
                            <MaterialTable
                                components={{ Container: "main" }}
                                title="Select documents:"
                                data={documentEData}
                                columns={columns()}
                                localization={{
                                    body: {
                                        emptyDataSourceMessage:
                                            "No Document added yet",
                                    },
                                    toolbar: {
                                        searchPlaceholder: "Search Document",
                                        nRowsSelected: "{0} document(s) selected",
                                    },
                                }}
                                options={{
                                    selection: true,
                                    showSelectAllCheckbox: false,
                                    cellStyle: {
                                        padding:5
                                      },
                                    headerStyle: {
                                        backgroundColor: "#369",
                                        fontWeight: "bolder",
                                        color:"#FFF",
                                        padding:5
                                    }
                                }}
                                onSelectionChange={(rows) => {
                                    handleDocumentSelection(rows);
                                }}
                            />
                        </Grid>
                    </Grid>
                <LockSmithComponentButtons closeHandler={cancelFunction}/>
            </form>
    );
};

export default UpdateProfileDoc;
