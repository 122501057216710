export const initialState = {
    account: [],
};

const allAccountReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_ACCOUNT":
            return {
                ...state,
                account: action.account
            };

        default:
            return state;
    }
};

export default allAccountReducer;