import {
  Button,
  Grid,
  InputBase,
  MenuItem,
  Select,
  styled,
  TextField,
  makeStyles,
} from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { dialogStyle } from "../../../Assets/styles/Styles";
import { useStateValue } from "../../../Context/store";
import { useForm } from "../../../hooks/useForm";
import Save from "@material-ui/icons/Save";
import Close from "@material-ui/icons/Close";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
    border: "none",
  },
  "& .MuiInput-underline:hover:not(.Mui-disabled):after": {
    border: "none",
  },
}));
const useStyle = makeStyles({
  icon: {
    width: 20,
    height: 20,
    margin: 10,
    color: "rgb(136,136,136)",
  },
  borderForm: {
    border: "solid 1px rgba(0, 0, 0, 0.23) !important",
    width: "100%",
    borderRadius: 5,
    outline: "none",
  },
  textForm: {
    fontSize: 14,
    fontWeight: "400",
    color: "rgb(136,136,136)",
    width: "100%",
  },
});
const DetailTab = (props) => {
  const classes = dialogStyle();
  const classesTab = useStyle();
  const { data, handleDetailData, saveFunction } = props;
  const [{ allBrand, allMUnit, allYear }] = useStateValue();
  //const { data, setFormData } = useForm();
  const yearFields = ["IdYear", "Year"];

  /*     useEffect(() => {
        setFormData({
            pidBrand: data.pidBrand,
            pidAnio: data.pidAnio,
            pCodeBar: data.pCodeBar,
            pLote: data.pLote,
            pModel: data.pModel,
            pidMed: data.pidMed,
            pDimension: data.pDimension,
            pWeight: data.pWeight,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]); */

  return (
    <>
      <Grid alignItems="center" container justifyContent="center" spacing={2}>
        <Grid item sm={12} xs={12} lg={3}>
          <text className={classesTab.textForm}>Brand*</text>
          <Select
            fullWidth
            IconComponent={() => (
              <div className={classesTab.icon}>
                <ArrowDropDown />
              </div>
            )}
            id="pidBrand"
            input={<BootstrapInput />}
            label="Select BRAND"
            name="pidBrand"
            onChange={handleDetailData}
            className={classesTab.borderForm}
            value={data.pidBrand}
            variant="outlined"
            defaultValue={data.pidBrand}
            required
          >
            {allBrand.brand
              ? allBrand.brand.map((item, index) => {
                  return (
                    <MenuItem
                      key={index}
                      style={{
                        justifyContent: "center",
                      }}
                      value={item.IdBrand}
                    >
                      {item.Descr}
                    </MenuItem>
                  );
                })
              : ""}
          </Select>
        </Grid>
        <Grid item sm={12} xs={12} lg={3}>
          <text className={classesTab.textForm}>Year*</text>
          <Select
            fullWidth
            IconComponent={() => (
              <div className={classesTab.icon}>
                <ArrowDropDown />
              </div>
            )}
            id="pidAnio"
            input={<BootstrapInput />}
            label="Select YEAR"
            className={classesTab.borderForm}
            name="pidAnio"
            onChange={handleDetailData}
            value={data.pidAnio}
            defaultValue={data.pidAnio}
            required={true}
          >
            {allYear
              ? allYear.year.map((item, index) => {
                  return (
                    <MenuItem
                      key={index}
                      style={{
                        justifyContent: "center",
                      }}
                      value={item.IdYear}
                    >
                      {item.Year}
                    </MenuItem>
                  );
                })
              : ""}
          </Select>
        </Grid>
        <Grid item sm={12} xs={12} lg={3}>
          <text className={classesTab.textForm}>Code bar</text>
          <TextField
            fullWidth
            name="pCodeBar"
            className={classesTab.borderForm}
            type="number"
            InputProps={{ disableUnderline: true }}
            style={{ padding: "8px 0 0 8px" }}
            value={data.pCodeBar}
            onChange={handleDetailData}
          />
        </Grid>

        <Grid item sm={12} xs={12} lg={3}>
          <text className={classesTab.textForm}>LOT</text>
          <TextField
            fullWidth
            InputProps={{ disableUnderline: true }}
            style={{ padding: "8px 0 0 8px" }}
            name="pLote"
            className={classesTab.borderForm}
            value={data.pLote}
            onChange={handleDetailData}
          />
        </Grid>
        <Grid item sm={12} xs={12} lg={3}>
          <text className={classesTab.textForm}>Model*</text>
          <TextField
            fullWidth
            InputProps={{ disableUnderline: true }}
            name="pModel"
            className={classesTab.borderForm}
            style={{ padding: "8px 0 0 8px" }}
            value={data.pModel}
            onChange={handleDetailData}
            required={true}
          />
        </Grid>
        <Grid item sm={12} xs={12} lg={3}>
          <text className={classesTab.textForm}>Measure*</text>
          <Select
            fullWidth
            IconComponent={() => (
              <div className={classesTab.icon}>
                <ArrowDropDown />
              </div>
            )}
            id="pidMed"
            input={<BootstrapInput />}
            label="Select ID MEASURE"
            name="pidMed"
            onChange={handleDetailData}
            className={classesTab.borderForm}
            value={data.pidMed}
            defaultValue={data.pidMed}
            required={true}
          >
            {allMUnit
              ? allMUnit.munit.map((item, index) => {
                  return (
                    <MenuItem
                      key={index}
                      style={{
                        justifyContent: "center",
                      }}
                      value={item.IdUMeasure}
                    >
                      {item.Descr}
                    </MenuItem>
                  );
                })
              : ""}
          </Select>
        </Grid>
        <Grid item sm={12} xs={12} lg={3}>
          <text className={classesTab.textForm}>Dimension</text>
          <TextField
            fullWidth
            InputProps={{ disableUnderline: true }}
            style={{ padding: "8px 0 0 8px" }}
            name="pDimension"
            className={classesTab.borderForm}
            value={data.pDimension}
            onChange={handleDetailData}
          />
        </Grid>

        <Grid item sm={12} xs={12} lg={3}>
          <text className={classesTab.textForm}>Weight</text>
          <TextField
            fullWidth
            InputProps={{ disableUnderline: true }}
            style={{ padding: "8px 0 0 8px" }}
            name="pWeight"
            className={classesTab.borderForm}
            type="number"
            value={data.pWeight}
            onChange={handleDetailData}
          />
        </Grid>
        <Grid item sm={6} xs={6}></Grid>
        <Grid item sm={3} xs={3}></Grid>
        <Grid item sm={12} xs={12} lg={3}>
          <Button
            variant="contained"
            color="primary"
            classes={{ root: classes.saveButton }}
            size="large"
            type="submit"
            fullWidth
            endIcon={
              <div
                style={{
                  width: 20,
                  height: 20,
                  marginTop: -14,
                  marginLeft: 10,
                }}
              >
                <Save />
              </div>
            }
            //onClick={saveFunction}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

DetailTab.propTypes = {
  handleDetailData: PropTypes.func.isRequired,
};

export default DetailTab;
