import React, { useEffect, useState } from "react";
import { useStateValue } from "../../Context/store";
import { delTax, getAllTax } from "../../Actions/UserAction";
import AddTAX from "./AddTAX";
import LockSmithDeleteComponent from "../ReusableComponents/LockSmithDeleteComponent";
import UpdateTAX from "./UpdateTAX";
/*Import Reusable Components */
import LockSmithMainTable from "../ReusableComponents/LockSmithMainTable";

const columns = () => { 
    return [
    { title: "ID", field: "IdTax",cellStyle: {height: 40,padding:"0px 16px"} },
    { title: "Description", field: "Descr",cellStyle: {height: 40,padding:"0px 16px"} },
    { title: "Percentage", field: "Porcentaje" ,cellStyle: {height: 40,padding:"0px 16px"}},
    { title: "Active", field: "Active",cellStyle: {height: 40,padding:"0px 16px"} },
]};

const TAX = () => {
    const [{ allTAX }] = useStateValue();
    const [TAX, setTAX] = useState(null);

    useEffect(() => {
        setTAX(allTAX ? allTAX.tax : []);
    }, [allTAX]);

    return (
        <React.Fragment>
            <LockSmithMainTable 
                title="Tax"
                data={TAX}
                columns={columns()}
                addComponent={AddTAX}
                updateComponent={UpdateTAX}
                deleteComponent={LockSmithDeleteComponent}
                deleteAction={delTax}
                reducer={getAllTax}
                itemId={columns().field}
            />
        </React.Fragment>
    );
};

export default TAX;
