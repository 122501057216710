import React, {Fragment, useState, useEffect } from "react";
import {
    Box, Backdrop, Button, CircularProgress, Snackbar, Table,
    TableBody, TableCell, TableHead, TableRow,  Grid
} from "@material-ui/core";
import { useStateValue } from "../../Context/store";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import AddBoxIcon from "@material-ui/icons/AddBox";
import CreateIcon from "@material-ui/icons/Create";
import DropDownMenu from "../../Utilities/DropDownMenu";
import { insCustomerContact, getAllCustomerContactByCustId } from "../../Actions/UserAction";
import moment from "moment";
import { Restore } from "@material-ui/icons";
import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";
import { makeStyles } from "@material-ui/core/styles";
//import Alert from "@material-ui/lab/Alert";
import CustomButton from "../../Utilities/CustomButton"


import Save from "@material-ui/icons/Save";
import Close from "@material-ui/icons/Close";
// Creating styles
const useStyles = makeStyles({
    root: {
        "& > *": {
            borderBottom: "unset",
        },
    },
    table: {
        minWidth: 500,
    },
    snackbar: {
        bottom: "104px",
    },
});
  
function ContactTableComponent({idCustomer, columnNames, addDisabled, data, callback, initDropDown, onCancel}) {
    // Creating style object
    const classes = useStyles();
    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [{allDepartment}, dispatch] = useStateValue();
    const [customerContacts, setCustomerContacts] = useState([]);
    // Defining a state named rows
    // which we can update by calling on setRows function
    const [rows, setRows] = useState([]);
    const [rowValue, setRowValue] = useState({pIdCustomer: idCustomer});
  
    // Initial states
    const [open, setOpen] = React.useState(false);
    const [saveDisabled, setSaveDisabled] = React.useState(true);
    const [resetDisabled, setResetDisabled] = React.useState(true);
    //const [isEdit, setEdit] = React.useState(false);
    //const [disable, setDisable] = React.useState(true);
    //const [showConfirm, setShowConfirm] = React.useState(false);
    //const [addDisabled, setAddDisabled] = React.useState(true);
    const departmentFields = ["IdDepartment", "Descr"];
    // Function For closing the alert snackbar
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };
  
    // Function For adding new row object
    const handleAdd = () => {
        setRows([
            ...rows,
            {
                id: data.IdContact,
                name: data.FistName + " " + data.LastName,
                details: data.Movil + " " + data.Email,
                contactId: data.IdContact
            },
        ]);
        setRowValue({...rowValue,
          details:[...rowValue.details,
          {
            
            pIdContact : data.IdContact,
            //pIdCustomer : idCustomer,
            pDate : moment().format("YYYY-MM-DD")
          }]
        })
        setSaveDisabled(false);
        setResetDisabled(false);
        callback(data.IdContact)
    };

    const handleReset = () => {
        initDropDown()
        setRows([])
        setRowValue([])
        setSaveDisabled(true)
        setResetDisabled(true)
        setCustomerContacts(getAllContactsByCustomerId(idCustomer))
    }
  
    // Function to handle edit
    const handleEdit = (i) => {
        // If edit mode is true setEdit will 
        // set it to false and vice versa
        //setEdit(!isEdit);
    };
  
    // Function to handle save
    const handleSave = async () => {
      setRowValue({pIdCustomer : idCustomer,
                    details : rowValue})
      console.log(rowValue)
      setOpenBackdrop(true);
      await insCustomerContact(rowValue).then((response) => {
        if (response.status === 200) {
          console.log(response.data)
          setOpenBackdrop(false);
          setSnackbarMessage(response.data.output.pmensaje);
          setOpen(true);
          //setDisable(true);
          setRows([]);
          setRowValue([])
          getAllContactsByCustomerId(idCustomer)
          setResetDisabled(true)
        } else {
          setOpenBackdrop(false);
          console.log(response)
          setOpen(true);
          //setDisable(true);
          setRows([]);
          setRowValue([])
          setResetDisabled(true)
        }
      });
    };

    const handleInputChange = (event) => {
      console.log("input change")
        const { name, value, id } = event.target;
        let tempArray = rowValue.details;
        let tempRows = rows;
        rowValue.details.forEach((item, index) => {
          if (item.pIdContact == id) {
            console.log("match")
            tempArray[index][name] = Number(value);
            rows.forEach((item, index) => {
              if(item.contactId == id){
                tempRows[index][name] = Number(value);
                setRows(tempRows);
              }
            })
            
          }
        })
        console.log(tempArray)
        setRowValue({...rowValue, details: tempArray})
        setSaveDisabled(false)
        setResetDisabled(false)
    } 
    
      const getAllContactsByCustomerId = async (id) => {
        await getAllCustomerContactByCustId(id).then((response) => {
          if (response.status === 200) {
            console.log(response.data)
            //construct the rows for Visual Display
            let tempArray = []
            response.data.forEach((item, index) => {
              tempArray[index] = {id : item.IdContact,
                                name : item.FistName + " " + item.LastName,
                                details : item.Movil + " " + item.Email,
                                contactId : item.IdContact,
                                pIdDepartment : item.IdDepartment,
                              }
            })
            console.log("tempArray", tempArray)
            //send Contact data back to the parent component to filter DropDown Menu
            initDropDown(tempArray)
            //construct the values for the DB
            let tempArray2 = []
            response.data.forEach((item, index) => {
              tempArray2[index] = {
                pIdContact : item.IdContact,
                //pIdCustomer : idCustomer,
                pDate : moment().format("YYYY-MM-DD"),
                pIdDepartment : item.IdDepartment
              }
            })
            console.log("tempArray2", tempArray2)
            setRows(tempArray)
            setRowValue({...rowValue, details: tempArray2})
          } else {
            console.log(response)
          }
        }).catch((error) => { console.log("Error getting Contacts By CustomerId: ", error) })
      }
  
    // The handleInputChange handler can be set up to handle
    // many different inputs in the form, listen for changes 
    // to input elements and record their values in state
/*     const handleInputChange = (e, index) => {
        setDisable(false);
        const { name, value } = e.target;
        const list = [...rows];
        list[index][name] = value;
        setRows(list);
    }; */
  
    // Showing delete confirmation to users
/*     const handleConfirm = () => {
        setShowConfirm(true);
    }; */
  
    // Handle the case of delete confirmation where 
    // user click yes delete a specific row of id:i
    const handleRemoveClick = (i) => {
      console.log("i: ", i);
        const list = [...rows];
        const list2 = [...rowValue.details];
        list.splice(i, 1);
        list2.splice(i, 1);
        setRows(list);
        setRowValue({...rowValue, details: list2});
       
        setSaveDisabled(false);
        setResetDisabled(false);
    };
  

    useEffect(() => {
        console.log("props.contact in Table",data)
        
    }, [data]);

    useEffect(() => {
      setCustomerContacts(getAllContactsByCustomerId(idCustomer))
      
  }, []);

  /**Function to update Contact Drop Down menu */
    useEffect(() => {
        initDropDown(rows)
    }, [rows]);
  
  return (
    <div>
      <Snackbar
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
        className={classes.snackbar}
        message={snackbarMessage}
      >
{/*         <Alert onClose={handleClose} severity="success">
          Record saved successfully!
        </Alert> */}
      </Snackbar>
      <Backdrop
          className={classes.backdrop} open={openBackdrop}>
          <CircularProgress color="primary" size={70} />
      </Backdrop>
      <Box margin={1}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            
              <div>
                <Button onClick={handleAdd} disabled={addDisabled}>
                  <AddBoxIcon onClick={handleAdd} />
                  ADD
                </Button>
                <Button align="right" onClick={handleReset} disabled={resetDisabled}>
                  <Restore />
                  RESET
                </Button>
              </div>
   
          </div>
        </div>
  
        <Table
          id="customer-contact-table"
          className={classes.table}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              {columnNames && columnNames.map((columnName) => {
                return (
                  <TableCell key={columnName}>{columnName}</TableCell>
                );
              })}
{/*               <TableCell align="center">U/M MXN</TableCell>
              <TableCell align="center">P/U MXN</TableCell>
              <TableCell align="center">Importe MXN</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, i) => (
                  <TableRow>  
                    
                      <Fragment>
                        <TableCell scope="row" contactId={row.contactId}>
                          {row.id}
                        </TableCell>
                        <TableCell scope="row">
                          {row.name}
                          <DropDownMenu
                            id={row.contactId}//needed for the 'handleInputChange' handler
                            //labelWidth={"300px"}
                            //showAddBtn={true}
                            onDropDownChange={handleInputChange}
                            //onAddBtnClick={openNewContact}
                            //Showsearch={true}
                            options={allDepartment}
                            optionFields={departmentFields}
                            name="pIdDepartment"
                            flex={"wrap"}
                            value={row.pIdDepartment}
                          />
                        </TableCell>
                        <TableCell scope="row">
                          {row.details}
                        </TableCell>
                        {/* <TableCell scope="row" align="center">
                          {row.measureUnit}
                        </TableCell>
                        <TableCell scope="row" align="center">
                          {row.unitPrice}
                        </TableCell>
                        <TableCell scope="row" align="center">
                          {row.amount}
                        </TableCell>    */}
                      </Fragment>
                 
                
                      <>
                      <Button className="mr10" onClick={() => handleRemoveClick(i)}>
                        <DeleteOutlineIcon />
                      </Button>
                      {/* <Button align="right" onClick={handleEdit}>
                        <CreateIcon />
                      </Button> */}
                      </>
                  </TableRow>
              ))}
          </TableBody>
        </Table>
        <Grid
                        container
                        spacing={2}
                        alignContent="center"
                        justifyContent="center"
                >
                      <Grid item xs={8}></Grid>
                      <Grid item xs={4}>
                      <CustomButton 
                          clas=""
                          Icon={Save}
                          text="save contact"
                          handleClick={handleSave}
                          disabled={saveDisabled}
                      />
                      </Grid>
                </Grid>
        
      </Box>
    </div>
  );
}
  
export default ContactTableComponent;