import {
    Button,
    Grid,
    MenuItem,
    Select
} from "@material-ui/core";
import React, { useState } from "react";
import { getAllAddressType, insAddressType } from "../../Actions/UserAction";
import { useStateValue } from "../../Context/store";
import LockSmithTextField from "../ReusableComponents/LockSmithTextField";
/** Import Icons */
import Save from "@material-ui/icons/Save";
import Close from "@material-ui/icons/Close";

/** Theme & Styles Import */
import { dialogStyle } from "../../Assets/styles/Styles";

const AddAddressType = ({ openDialog, closeDialog }) => {
    const classes = dialogStyle();
    const [openBackdrop, setOpenBackdrop] = useState(false);
    // eslint-disable-next-line no-empty-pattern
    const [{ allATC }, dispatch] = useStateValue();
    const [addressTypeData, setAddressTypeData] = useState({});

    const dataValues = (e) => {
        const { name, value } = e.target;
        setAddressTypeData((last) => ({
            ...last,
            [name]: value,
        }));
    };

    const saveFunction = async (e) => {
        e.preventDefault();
        setOpenBackdrop(true);
        await insAddressType(addressTypeData).then((response) => {
            if (response.status === 200) {
                setOpenBackdrop(false);
                dispatch({
                    type: "OPEN_SNACKBAR",
                    openMessage: {
                        open: true,
                        title: "Success",
                        message: response.data.output.pmensaje,
                        alert: "success",
                    },
                });
                getAllAddressType(dispatch);
                cancelFunction();
            } else {
                setOpenBackdrop(false);
                dispatch({
                    type: "OPEN_SNACKBAR",
                    openMessage: {
                        open: true,
                        title: "Error",
                        message: response.data.msg,
                        alert: "error",
                    },
                });
            }
        });
    };

    const cancelFunction = () => {
        setAddressTypeData({});
        Array.from(document.querySelectorAll("form input")).forEach(
            input => (input.value = "")
          );
    };

    return (
            <form onSubmit={saveFunction}>
                    <Grid
                        container
                        spacing={2}
                        alignContent="center"
                        justifyContent="center"
                    >
                        <Grid item sm={6} xs={6}>
                            <LockSmithTextField
                                    label="Description"
                                    labelWidth="32%"
                                    name="pDescr"
                                    value={addressTypeData.pDescr}
                                    onChange={dataValues}
                                    required
                                />
                        </Grid>
                        <Grid item sm={6} xs={6}>
                            <text
                                style={{ fontSize: 14, fontWeight: "400", color:"rgb(136,136,136)" }}
                                >
                                Select address type
                            </text>          
                            <Select
                                labelId="emp-label"
                                name="pidClass"
                                style={{
                                    backgroundColor: "#fff",
                                    borderRadius: 5,
                                    marginTop: 5
                                }}
                                value={addressTypeData.pidClass}
                                id="pidClass"
                                onChange={dataValues}
                                label="Select Address_Type_Class"
                                variant="outlined"
                                required={true}
                                fullWidth
                            >
                                {allATC
                                    ? allATC.atc.map((item, index) => {
                                          return (
                                              <MenuItem
                                                  value={item.IdClass}
                                                  key={index}
                                              >
                                                  {item.Descr}
                                              </MenuItem>
                                          );
                                      })
                                    : ""}
                            </Select>            
                        </Grid>
                    </Grid>
                    
                    <Grid
                        container
                        spacing={2}
                        alignContent="center"
                        justifyContent="flex-end"
                    >
                        <Grid item xs={6} lg={3}>
                                <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                classes={{ root: classes.saveButton }}
                                size="large"
                                fullWidth
                                endIcon={
                                    <div
                                        style={{
                                            width: 20,
                                            height: 20,
                                            marginTop: -14,
                                            marginLeft: 10,
                                        }}
                                    >
                                        <Save/>
                                    </div>
                                }
                            >
                                Save
                            </Button>
                        </Grid>
                        <Grid item xs={6} lg={3}>
                                <Button
                                variant="contained"
                                onClick={cancelFunction}
                                classes={{ root: classes.cancelButton }}
                                size="large"
                                fullWidth
                                endIcon={
                                    <div
                                        style={{
                                            width: 20,
                                            height: 20,
                                            marginTop: -14,
                                            marginLeft: 10,
                                        }}
                                    >
                                        <Close/>
                                    </div>
                                }
                            >
                                Reset
                            </Button>
                        </Grid>
                    </Grid>
            </form>
    );
};

export default AddAddressType;
