
export const handleApiResponse = (response, closeLoading, dispatch, getAllReducer, cancelAction, actionName ) => {
    if (response.status === 200 && response.data.output.pmensaje.toUpperCase() == "OK") {
        closeLoading();
        dispatch({
            type: "OPEN_SNACKBAR",
            openMessage: {
                open: true,
                title: "Success",
                message: response.data.output.pmensaje,
                alert: "success",
            },
        });
        getAllReducer(dispatch);
        cancelAction();
    } else if(response.status === 200 ) {
        closeLoading();
        dispatch({
            type: "OPEN_SNACKBAR",
            openMessage: {
                open: true,
                title: "Success",
                message: response.data.output.pmensaje,
                alert: "success",
            },
        });
    } else if(response.status === 400 || response.status === 500) {
        closeLoading();
        dispatch({
            type: "OPEN_SNACKBAR",
            openMessage: {
                open: true,
                title: "Success",
                message: response.msg,
                alert: "success",
            },
        });
    } else {
        closeLoading();
        dispatch({
            type: "OPEN_SNACKBAR",
            openMessage: {
                open: true,
                title: "Error",
                message: "Could not " + actionName + ", check your information and try again",
                alert: "error",
            },
        });
    }
}