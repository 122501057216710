import { makeStyles } from "@material-ui/core";

export const dialogStyle = makeStyles((theme) => ({
    dialog: {
        backgroundColor: "#fff",
        border: "1px solid rgba(0,0,0,.2)",
        borderRadius: "0.3rem",
        overflowX:"hidden"
    },
    dialogTitle: {
        flexDirection: "row",
        display: "flex",
        padding: 0,
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        borderBottom: "1px solid #dee2e6",
        borderTopLeftRadius: "calc(0.3rem - 1px)",
        borderTopRightRadius: "calc(0.3rem - 1px)",
        color: "#111",
        fontWeight: 400,
        fontSize: 18,
        fontFamily: '"Open Sans",sans-serif',
        height:45
    },
    dialogTitleIcon: {
        marginTop: 10,
        display: "flex",
        width: "15%",
        alignItems: "center",
        justifyContent: "center",
        fontSize: 25
    },
    dialogButton: {
        display: "flex",
        justifyContent: "center",
        overflowX: "hidden",
        backgroundColor: "#fff",
        borderTop: "1px solid rgba(0,0,0,.2)",
        borderRadius: "0.3rem",
    },
    cancelButton: {
        backgroundColor: theme.buttons.gray,
        borderRadius: 5,
        fontSize: 15,
        color: theme.palette.primary.white,
        fontWeight: "300",
        "&:hover": {
            backgroundColor: theme.buttons.grayHover,
        },
    },
    saveButton: {
        backgroundColor: theme.buttons.blue,
        borderRadius: 5,
        color: theme.palette.primary.white,
        fontWeight: "400",
        "&:hover": {
            backgroundColor: theme.buttons.blueHover,
        },
    },

    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    }
}));

export const deleteDialogStyle = makeStyles((theme) => ({
    dialog: {
        backgroundColor: "#fff",
        border: "1px solid rgba(0,0,0,.2)",
        borderRadius: "0.3rem",
        overflowX:"hidden"
    },
    dialogTitle: {
        flexDirection: "row",
        display: "flex",
        padding: 0,
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        borderBottom: "1px solid #dee2e6",
        borderTopLeftRadius: "calc(0.3rem - 1px)",
        borderTopRightRadius: "calc(0.3rem - 1px)",
        color: "#111",
        fontWeight: 400,
        fontSize: 18,
        fontFamily: '"Open Sans",sans-serif',
        height:45
    },
    dialogTitleHead: {
        flexDirection: "row",
        display: "flex",
        padding: 10,
        width: "100%",
        alignItems: "center",
    },
    dialogTitleIcon: {
        marginTop: 10,
        display: "flex",
        width: "30%",
        alignItems: "center",
        justifyContent: "center"
    },
    dialogButton: {
        display: "flex",
        justifyContent: "center",
        overflowX: "hidden",
        backgroundColor: "#fff",
        borderTop: "1px solid rgba(0,0,0,.2)",
        borderRadius: "0.3rem",
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    deleteButton: {
        backgroundColor: "#f44236",
        color: theme.palette.primary.white,
        borderRadius: 5,
        fontWeight: "bolder",
        fontSize: 18,
        "&:hover": {
            backgroundColor: "#f44236",
        },
    },
    cancelButton: {
        backgroundColor: theme.buttons.gray,
        borderRadius: 5,
        color: theme.palette.primary.white,
        fontWeight: "400",
        "&:hover": {
            backgroundColor: theme.buttons.grayHover,
        },
    },
}));
