export const initialState = {
    city: [],
};

const allCtiesReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_CITIES":
            return {
                ...state,
                city: action.city,
            };

        default:
            return state;
    }
};

export default allCtiesReducer;
