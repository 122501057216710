import React, { useEffect, useState } from "react";
import { useStateValue } from "../../Context/store";
import { delBrand, getAllBrand } from "../../Actions/UserAction";
import AddBrand from "./AddBrand";
import UpdateBrand from "./UpdateBrand";
import LockSmithDeleteComponent from "../ReusableComponents/LockSmithDeleteComponent";
/*Import Reusable Components */
import LockSmithMainTable from "../ReusableComponents/LockSmithMainTable";

const columns = () => { 
    return [
    { title: "ID", field: "IdBrand",cellStyle: {height: 40,padding:"0px 16px"} },
    { title: "Description", field: "Descr",cellStyle: {height: 40,padding:"0px 16px"} },
]};

const Brand = () => {
    const [{ allBrand }] = useStateValue();
    const [brand, setBrand] = useState(null);

    useEffect(() => {
        setBrand(allBrand ? allBrand.brand : []);
    }, [allBrand]);

    return (
        <React.Fragment>
            <LockSmithMainTable 
                title="Brand"
                data={brand}
                columns={columns()}
                addComponent={AddBrand}
                updateComponent={UpdateBrand}
                deleteComponent={LockSmithDeleteComponent}
                deleteAction={delBrand}
                reducer={getAllBrand}
                itemId={columns().field}
            />
        </React.Fragment>
    );
};

export default Brand;
