import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
} from "@material-ui/core";
import React from "react";
import { getAllTax, insTax } from "../../Actions/UserAction";

/** Import Icons */
import Save from "@material-ui/icons/Save";
import Close from "@material-ui/icons/Close";

/** Theme & Styles Import */
import { dialogStyle } from "../../Assets/styles/Styles";
import { useStateValue } from "../../Context/store";

/** Custom components & hooks */
import LockSmithSwitch from "../ReusableComponents/LockSmithSwitch";
import LockSmithTextField from "../ReusableComponents/LockSmithTextField";
import { useForm } from "../../hooks/useForm";
import { LoadingComponent, useLoading } from "../../hooks/useLoading";

const AddTAX = ({ openDialog, closeDialog }) => {
    const classes = dialogStyle();
    const { loading, openLoading, closeLoading } = useLoading();
    // eslint-disable-next-line no-empty-pattern
    const [{}, dispatch] = useStateValue();
    const { formData, handleInputChange, setFormData } = useForm(/* {
        pActive: false,
    } */);

    const saveFunction = async (e) => {
        e.preventDefault();
        openLoading();
        await insTax(formData).then((response) => {
            if (response.status === 200) {
                closeLoading();
                dispatch({
                    type: "OPEN_SNACKBAR",
                    openMessage: {
                        open: true,
                        title: "Success",
                        message: response.data.output.pmensaje,
                        alert: "success",
                    },
                });
                getAllTax(dispatch);
                cancelFunction();
            } else {
                closeLoading();
                dispatch({
                    type: "OPEN_SNACKBAR",
                    openMessage: {
                        open: true,
                        title: "Error",
                        message: response.data.output.pmensaje,
                        alert: "error",
                    },
                });
            }
        });
    };

    const cancelFunction = () => {
        setFormData({});
        Array.from(document.querySelectorAll("form input")).forEach(
            input => (input.value = "")
          );
    };

    return (
            <form onSubmit={saveFunction}>
                    <Grid
                        container
                        spacing={2}
                        justifyContent="flex-start"
                        alignItems="center"
                    >
                        <Grid item sm={6}>
                            <LockSmithTextField
                                label="Description"
                                name="pDescr"
                                onChange={handleInputChange}
                                value={formData.pDescr}
                                required={true}
                            />
                        </Grid>
                        <Grid item sm={6}>
                            <LockSmithTextField
                                label="Percentage"
                                labelWidth="26%"
                                name="pPorcentaje"
                                onChange={handleInputChange}
                                type="number"
                                step="any"
                                min="0"
                                maxValue={100}
                                value={formData.pPorcentaje}
                                required={true}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        spacing={2}
                        justifyContent="flex-start"
                        alignItems="center"
                    >
                        <Grid item sm={6}></Grid>
                        <Grid item sm={3}>
                                <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                classes={{ root: classes.saveButton }}
                                size="large"
                                fullWidth
                                endIcon={
                                    <div
                                        style={{
                                            width: 20,
                                            height: 20,
                                            marginTop: -14,
                                            marginLeft: 10,
                                        }}
                                    >
                                        <Save/>
                                    </div>
                                }
                            >
                                Save
                            </Button>
                        </Grid>
                        <Grid item sm={3}>
                                <Button
                                variant="contained"
                                onClick={cancelFunction}
                                classes={{ root: classes.cancelButton }}
                                size="large"
                                fullWidth
                                endIcon={
                                    <div
                                        style={{
                                            width: 20,
                                            height: 20,
                                            marginTop: -14,
                                            marginLeft: 10,
                                        }}
                                    >
                                        <Close/>
                                    </div>
                                }
                            >
                                Reset
                            </Button>

                        </Grid>
                    </Grid>
                   
                    
            </form>
    );
};

export default AddTAX;
