import {
  Button,
  Grid,
  TextField,
  Typography
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import React, { useState } from "react";
import "./AuthStyles.css";



import { Link } from "react-router-dom";
import { login } from "../Actions/UserAction";
import useWindowDimensions from "../hooks/useWindowDimensions";

//** Import custom hooks */
import LockOpen from "@material-ui/icons/LockOpen";
import { useStateValue } from "../Context/store";
import { useForm } from "../hooks/useForm";
import { LoadingComponent, useLoading } from "../hooks/useLoading";

const Login = (props) => {
  const { height, width } = useWindowDimensions();
  const paperStyle = {
    padding: 30,
    height: "50vh",
    width: 300,

    borderRadius: 20,
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    textAlign: "center",
    boxShadow: "0 1px 20px 0 rgba(69,90,100,.08)",
    
  };
  const textInput = {
    background: "#f4f7fa",
    margin: "15px 0 0 0",
    borderRadius: 5,
    textAlign: "center",
    width: "95%",
    border: "1px solid #ced4da",
    borderRadius: "0.25rem",
    paddingLeft:10
  };
  const btnstyle = {
    margin: "15px 0 0 0",
    background: "#038fcf",
    color: "white",
    borderRadius: 0,
    width: "100%",
  };
  const label={
    color:"#888"
  }

  const [{}, dispatch] = useStateValue();

  const { formData, handleInputChange } = useForm({
    username: "",
    password: "",
  });

  const [showPassword, setShowPassword] = useState(false);

  const { loading, openLoading, closeLoading } = useLoading();

  const handleLogin = (e) => {
    e.preventDefault();
    openLoading();
    login(formData)
      .then((response) => {
        console.log(response.status)
        if (response.status === 200) {
          closeLoading();
          localStorage.setItem("token", response.data.token);
          dispatch({
            type: "OPEN_SNACKBAR",
            openMessage: {
              open: true,
              title: "Success",
              message: "Successful Login",
              alert: "success",
            },
          });
          props.history.push("/");
          window.location.reload();
        } else {
          closeLoading();
          dispatch({
            type: "OPEN_SNACKBAR",
            openMessage: {
              open: true,
              title: "Error",
              message: response.data.msg,
              alert: response.data.alert,
            },
          });
        }
      })
      .catch((err) => {
        closeLoading();
        console.log(err);
      });
  };

  return (
    <>
      <div class="auth-bg">
      <LoadingComponent openLoading={loading} />
      <Grid
        style={{
          height: height,
          width: width,
        }}
      >
        <Grid
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          
          <form>
            <Grid style={paperStyle} className="backdrop-filter-blur">
            <LockOpen style={{color:"#007bff",fontSize:40}}></LockOpen>
              <TextField
                style={textInput}
                InputProps={{
                  disableUnderline: true,
                }}
                placeholder="Username"
                fullWidth
                required
                name="username"
                value={formData.username}
                onChange={handleInputChange}
              />
              
              <TextField
                style={textInput}
                type={showPassword ? "text" : "password"}
                InputProps={{
                  disableUnderline: true
                }}
                placeholder="Password"
                fullWidth
                required
                name="password"
                value={formData.password}
                onChange={handleInputChange}
              />
              
              

               <Grid item md={12} style={{
            display: "flex",
            justifyContent: "left",
            alignItems: "left"
          }}>
                <FormControlLabel
                  style={label}
                  control={<Checkbox name="checkedB" color="primary" />}
                  label="Save credentials"
                />
              </Grid>
              <Grid item md={12}>
                <Link className="linkButtons" to="/">
                  <Button
                    type="submit"
                    variant="contained"
                    style={btnstyle}
                    fullWidth
                    onClick={handleLogin}
                  >
                    Login
                  </Button>
                </Link>
              </Grid>
             <Grid item md={12} style={{borderTop:"1px solid rgba(0,0,0,.1)",marginTop:20}}>
                <Typography style={{ cursor: "pointer", padding:"15px 25px",color:"#6c757d",fontSize:13 }}>
                  Forgot password?
                </Typography>
              </Grid> 
              <Grid item md={12}>
                <Link className="linkButtons" to="/signup" style={{ cursor: "pointer", padding:"15px 25px",color:"#6c757d",fontSize:13  }}>
                  Don't have an account Signup?
                </Link>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
      </div>
    </>
  );
};

export default Login;
