export const initialState = {
    jobtypes: [],
};

const allJobTypeReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_JOBTYPE":
            return {
                ...state,
                jobtypes: action.jobtypes,
            };

        default:
            return state;
    }
};

export default allJobTypeReducer;
