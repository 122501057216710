import React from "react";
import { Route, Redirect } from "react-router-dom";

export const SafeRoutes = ({ component: Component, ...rest }) => {
  const token = localStorage.getItem("token");
  return (
    <Route
      {...rest}
      render={(props) =>
        (
        token ? <Component {...props} {...rest} /> : <Redirect to="/login" />
        )
      }
    />
  );
};

export const LoginRoute = ({ component: Component, ...rest }) => {
  return (
    <Route {...rest} render={(props) => <Component {...props} {...rest} />} />
  );
};
