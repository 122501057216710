import React, { useState } from "react";
import {
  Grid,
  Paper,
  Avatar,
  TextField,
  Button,
  Typography,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import "./AuthStyles.css";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import { ReactComponent as Employees_Selected } from "../Assets/Icons/Employees_Selected.svg";
import { ReactComponent as Password } from "../Assets/Icons/Password.svg";
import { Link } from "react-router-dom";

import background from "../Assets/images/Login_full.png";

import useWindowDimensions from "../hooks/useWindowDimensions";

const Signup = () => {
  const { height, width } = useWindowDimensions();
  const paperStyle = {
    padding: 20,
    paddingTop: 250,
    height: "70vh",
    width: 280,
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    textAlign: "center",
  };
  const textInput = {
    background: "white",
    margin: 10,
    borderRadius: 5,
    textAlign: "center",
    width: "100%",
  };
  const btnstyle = {
    margin: "5px 1px",
    background: "black",
    color: "white",
    borderRadius: 20,
    width: "50%",
  };

  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [roles, setRoles] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Grid
      style={{
        backgroundImage: `url(${background})`,
        backgroundPosition: "center center",
        backgroundSize: "cover",
        backgroundAttachment: "fixed",
        height: "100%",
        width: width,
      }}
    >
      <Grid
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid style={paperStyle}>
          <TextField
            style={textInput}
            InputProps={{
              disableUnderline: true,
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton>
                    <div style={{ width: 15, height: 15, marginBottom: 15 }}>
                      <Employees_Selected />
                    </div>
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            placeholder="EMAIL"
            fullWidth
            required
          />
          <TextField
            style={textInput}
            InputProps={{
              disableUnderline: true,
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton>
                    <div style={{ width: 15, height: 15, marginBottom: 15 }}>
                      <Employees_Selected />
                    </div>
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange={(e) => {
              setUsername(e.target.value);
            }}
            placeholder="USERNAME"
            fullWidth
            required
          />
          <TextField
            style={textInput}
            type={showPassword ? "text" : "password"}
            InputProps={{
              disableUnderline: true,
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton
                    onClick={() => {
                      setShowPassword(!showPassword);
                      console.log("test", showPassword);
                    }}
                  >
                    <div style={{ width: 15, height: 15, marginBottom: 15 }}>
                      <Password />
                    </div>
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            placeholder="PASSWORD"
            fullWidth
            required
          />
          <Grid item md={12}>
            <FormControlLabel
              control={<Checkbox name="checkedB" color="primary" />}
              label="Remember me"
            />
          </Grid>
          <Grid item md={12}>
            <Button
              type="submit"
              variant="contained"
              style={btnstyle}
              fullWidth
            >
              OPEN
            </Button>
          </Grid>
          <Grid item md={12}>
            <Typography style={{ cursor: "pointer" }}>
              ¿Forgot your keys?
            </Typography>
          </Grid>
          <Grid item md={12}>
            <Link className="linkButtons" to="/login">
              ¿Already have an account Login?
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Signup;
