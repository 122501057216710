import React, { useEffect, useState } from "react";
import { useStateValue } from "../../Context/store";
import { deleteJob, getAllJobs } from "../../Actions/UserAction";
import AddJob from "./AddJob";
import UpdateJob from "./UpdateJob";
import LockSmithDeleteComponent from "../ReusableComponents/LockSmithDeleteComponent";
/* Import Reusable Components */
import LockSmithMainTable from "../ReusableComponents/LockSmithMainTable";

const columns = () => { 
    return [
    { title: "IdJob", field: "idjob" ,cellStyle: {height: 40,padding:"0px 16px"}},
    { title: "Branch", field: "DescrBranch" ,cellStyle: {height: 40,padding:"0px 16px"}},
    { title: "Job Desc", field: "DescTypeJob" ,cellStyle: {height: 40,padding:"0px 16px"}},
    { title: "Customer Name", field: "NameCust" ,cellStyle: {height: 40,padding:"0px 16px"}},
    { title: "Address", field: "Address",cellStyle: {height: 40,padding:"0px 16px"} },
    { title: "JobStatus", field: "StatusJob" ,cellStyle: {height: 40,padding:"0px 16px"}},
    { title: "Branch", field: "DescrBranch" ,cellStyle: {height: 40,padding:"0px 16px"}},
    { title: "SubTotal", field: "SubTotal" ,cellStyle: {height: 40,padding:"0px 16px"}},
    { title: "Tax", field: "Tax" ,cellStyle: {height: 40,padding:"0px 16px"}},
    { title: "Total", field: "Total",cellStyle: {height: 40,padding:"0px 16px"} },

]};

const Job = () => {
    const [{ allJobs }] = useStateValue();
    const [jobs, setJobs] = useState([]);

    useEffect(() => {
        setJobs(allJobs ? allJobs.jobs : []);
    }, [allJobs]);

    return (
        <LockSmithMainTable
                title="Job"
                data={jobs}
                columns={columns()}
                addComponent={AddJob}
                updateComponent={UpdateJob}
                deleteComponent={LockSmithDeleteComponent}
                deleteAction={deleteJob}
                reducer={getAllJobs}
                itemId={columns().field}
        />
    );
};

export default Job;
