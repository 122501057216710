import {
    Box,
    TextField,
} from "@material-ui/core";



const LockSmithTextField = (props) => {
   return(
   <Box
        style={{
            display: "block",
            padding: 1,
            width: "100%",
            alignItems: "center",
        }}
    >
        <Box
            style={{
                flexDirection: "row",
                display: "flex",
                width: props.labelWidth ? props.labelWidth : "20%",
                paddingLeft: 0
            }}
        >
        <text
            style={{ fontSize: 14, fontWeight: 400, color:"#888", fontFamiliy:"'Open Sans',sans-serif" }}
            >
            {props.label}
        </text>
        </Box>
        <Box
            style={{
                flexDirection: "row",
                display: "flex",
                width: props.width ? props.width : "100%",
                alignItems: "center",
                justifyContent: "center"
            }}
        >
        <TextField
            fullWidth
            margin="normal"
            name={props.name}
            style={{
                backgroundColor: props.disabled ? "#e6e6e6" : "#fff",
                borderRadius: 5,
                marginTop: 8
            }}
            inputProps={{max : props.maxValue ? props.maxValue : "", min : props.minValue ? props.minValue : ""}}
            value={props.value}
            onChange={props.onChange}
            variant="outlined"
            required={props.required ? true : false}
            multiline={props.multiline}
            type={props.type ? props.type : "text"}
            step={props.step ? props.step : ""}
            min={props.min ? props.min : ""}
            rows={props.rows}
            size={props.size}
            placeholder={props.placeholder ? props.placeholder : ""}
            disabled={props.disabled ? props.disabled : false}
        />
        </Box>
    </Box>
   )
}

export default LockSmithTextField;