export const initialState = {
    branch: [],
};

const allBranchReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_BRANCH":
            return {
                ...state,
                branch: action.branch,
            };

        default:
            return state;
    }
};

export default allBranchReducer;