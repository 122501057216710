//** Custom Hook for managing forms */

import moment from "moment";
import { useState } from "react";

export const useForm = (initialState = {}) => {
	console.log("initial state: ",initialState)
	const [formData, setFormData] = useState(initialState);
	//console.log(formData);

	const handleInputChange = (e) => {
		const { name } = e.target;
		//console.log(moment.utc(e.target.value).format());
		const value =
			e.target.type === "checkbox"
				? e.target.checked
				: e.target.type === "file"
				? e.target.files
				: e.target.type === "time"
				? moment(e.target.value, ["h:mm A"]).format(
						"HH:mm:ss"
				  )
				: e.target.value;

		setFormData((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};
	return {
		formData,
		handleInputChange,
		setFormData,
	};
};
