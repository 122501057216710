import React, { useEffect, useState } from "react";
import { useStateValue } from "../../Context/store";
import { deleteAddressType, getAllAddressType } from "../../Actions/UserAction";
import AddAddressType from "./AddAddressType";
import LockSmithDeleteComponent from "../ReusableComponents/LockSmithDeleteComponent";
import UpdateAddressType from "./UpdateAddressType";
/*Import Reusable Components */
import LockSmithMainTable from "../ReusableComponents/LockSmithMainTable";

const columns = () => { 
    return [
    { title: "IdType", field: "IdType",cellStyle: {height: 40,padding:"0px 16px"} },
    { title: "Description", field: "Descr",cellStyle: {height: 40,padding:"0px 16px"} },
    { title: "Id Class", field: "IdClass",cellStyle: {height: 40,padding:"0px 16px"} },
]};

const AddressType = () => {
    const [{ allAddressType }] = useStateValue();
    const [addressType, setAddressType] = useState(null);

    useEffect(() => {
        setAddressType(allAddressType ? allAddressType.addresstype : []);
    }, [allAddressType]);

    return (
        <React.Fragment>
            <LockSmithMainTable 
            title="Address Type"
            data={addressType}
            columns={columns()}
            addComponent={AddAddressType}
            updateComponent={UpdateAddressType}
            deleteComponent={LockSmithDeleteComponent}
            deleteAction={deleteAddressType}
            reducer={getAllAddressType}
            itemId={columns().field}
        />
        </React.Fragment>
    );
};

export default AddressType;
