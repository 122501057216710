import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { Switch, useLocation } from "react-router-dom";
///icons
import ExitToApp from "@material-ui/icons/ExitToApp";
import Menu1 from "@material-ui/icons/Menu";

// hooks
import {
    InputAdornment,
    TextField,
    AppBar,
    IconButton,
    Snackbar,
    Toolbar,
    Grid,
    makeStyles,
} from "@material-ui/core";
import useWindowDimensions from "./hooks/useWindowDimensions";
// import bg image
import background from "./Assets/images/background.png";

import theme from "./Theme/Theme";
import Home from "./Components/Home";
// import Jobs from "./Components/Jobs/Jobs"
// import Technician from "./Components/Technician/Technician"
import Skill from "./Components/Skills/Skill";
import Level from "./Components/Levels/Level";
import TypeEmp from "./Components/TypeEmp/TypeEmp";
import DocumentE from "./Components/DocumentE/DocumentE";

import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';

import { useStateValue } from "./Context/store";
import {
    getAllAddress,
    getAllCities,
    getAllCompanies,
    getAllSkills,
    getAllLevels,
    getAllTypeEmp,
    getAllLevelSkill,
    getAllDocumentE,
    getAllProfileDoc,
    getAllBranch,
    getAllRoles,
    getAllBanks,
    getAllAddressType,
    getAllATC,
    getAllUser,
    getAllContact,
    getAllMoney,
    getAllTax,
    getAllMessage,
    getAllDepartment,
    getAllAccount,
    getAllBrand,
    getAllPriceList,
    getAllClasses,
    getAllCategory,
    getAllSubCat,
    getAllEmployee,
    getAllMUnit,
    getAllProduct,
    userInfo,
    getAllServices,
    getAllWarehouses,
    getAllCustomers,
    getAllYears,
    getAllCountry,
    getAllState,
    getAllDocumentC,
    getAllJobs,
    getAllJobTypes,
} from "./Actions/UserAction";
import LevelSkill from "./Components/LevelSkill/LevelSkill";
import Menu from "./Components/Menu";
import ProfileDoc from "./Components/ProfileDoc/ProfileDoc";
import Branch from "./Components/Branch/Branch";
import Role from "./Components/Role/Role";
import Bank from "./Components/Bank/Bank";
import AddressType from "./Components/AddressType/AddressType";
import User from "./Components/User/User";
import Money from "./Components/Money/Money";
import Brand from "./Components/Brand/Brand";
import Category from "./Components/Category/Category";
import SubCat from "./Components/SubCat/SubCat";
import MUnit from "./Components/MUnit/MUnit";
import TAX from "./Components/TAX/TAX";
import Product from "./Components/Product/Product";
import Contact from "./Components/Contact/Contact";
import Department from "./Components/Department/Department";
import Employee from "./Components/Employee/Employee";
import Account from "./Components/Account/Account";
import Company from "./Components/Company/Company";
// import Message from "./Components/Message/Message";
import Customer from "./Components/Customer/Customer";
import PriceList from "./Components/PriceList/PriceList";
import WareHouse from "./Components/WareHouse/WareHouse";
import Service from "./Components/Service/Service";
import DocumentC from "./Components/DocumentC/DocumentC";
import Job from "./Components/Jobs/Job";

import Settings from "@material-ui/icons/Settings";
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';

import { ReactComponent as Sheduler } from "./Assets/Icons/Sheduler.svg";
import { ReactComponent as Messages } from "./Assets/Icons/Messages.svg";
import { ReactComponent as Calls } from "./Assets/Icons/Calls.svg";
import { ReactComponent as Profile } from "./Assets/Icons/Profile.svg";
import { ReactComponent as MenuIconExpand } from "./Assets/Icons/Menu_Expand.svg";



import Login from "./Screens/Login";
import Signup from "./Screens/Signup";

import { LoginRoute, SafeRoutes } from "./hooks/SafeRoutes";

const drawerWidth = 240;

/** Navbar Styles */
export const navbarStyles = makeStyles((theme) => ({
    root: {
        display: "flex"
    },
    paper: {
        marginRight: theme.spacing(2),
      },
      settingsIcon:{
        fontSize:24,
        position: 'absolute',
        right: 20,
        color:"#3f4d67",
        "& :hover":{
            color:"#007bff"
        }
      },
    appBar: {
        backgroundColor: "#f4f6f8",
        borderWidth: 1,
        width: "100vw",
        zIndex: theme.zIndex.drawer + 1,
        flexGrow: 1,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        
        paddingTop: 0,
        
    },
    sidebar:{
        padding: 10,
    },
    menuButton: {
        marginRight: 0,
    },
    hide: {
        display: "none",
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: "nowrap",
        height: "100%",
        backgroundColor: "#3f4d67"
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        backgroundColor: "#3f4d67",
        overflowX: "hidden"
    },
    drawerClose: {
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: "hidden",
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up("sm")]: {
            width: theme.spacing(8) + 1,
        },
        backgroundColor: "#3f4d67",
        "&:hover":{
            width: drawerWidth
        }
    },
    toolbar: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: theme.spacing(0, 2),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        backgroundColor: "#FFD161",
        paddingTop: 50,

    },

    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        paddingTop: theme.spacing(10),
        marginTop: 0,
    },

    /** Root Menu */
    selectedMenu: {
        backgroundColor: theme.palette.primary.blue,
        fontSize:"15px",
        color: theme.palette.primary.white,
        backgroundColor:"#333f54",
        borderLeft:"solid 3px #1dc4e9",
        height:40,
        "&hover":{
            backgroundColor:"#333f54"
        }
    },
    unSelectedMenu: {
        backgroundColor: theme.palette.primary.blue,
        color:"#a9b7d0",
        fontSize:"15px",
        height:40,
        "&:hover":{
            color:"#1dc4e9"
        }
    },
    selectedMenuText: {
        fontWeight: "lighter",
    },
    unSelectedMenuText: {
        fontWeight: "lighter",
    },
    menuText: {
        fontFamily: "open sans,sans-serif",
        fontSize: "0.875rem",
        fontWeight: "light",        
 
    },

    /** Menu Elements */
    selectedSubMenu: {
        color: theme.palette.primary.white,
        height: 30,
        color:"#1dc4e9",
        borderLeft:"solid 3px #1dc4e9",
        "&:hover":{
            color:"#1dc4e9"
        }
    },
    unSelectedSubMenu: {
        color:"#a9b7d0",
        height: 30,
        borderLeft:"solid 3px #1dc4e9",
        "&:hover":{
            color:"#1dc4e9"
        }
    },
    subMenuText: {
        fontFamily: "Helvetica",
        src: "../Assets/fonts/HelveticaNeueLTCom-Md.ttf",
        fontSize: 12,
        fontWeight: "lighter",
        // textAlign: "center"
        marginLeft: 40
    },
    iconPointSubmenu:{
        fontSize: 15,
        marginLeft: 15,
        marginRight: "-30px"
    },

    /** Icons */
    icon: {
        width: 20,
        height: 20,
        marginRight:5
    },
    iconSizes: {
        width: 15,
        marginLeft: 10,
        marginRight: 10,
    },
    iconSizesPhone: {
        width: 15,
        marginLeft: 10,
    },

    // Temporary class to mark completed catalogs
    done: {
        
    },
    iconLogout:{
        color:"#3f4d67",
        float: "left"
    },
    iconMenu:{
        color:"#a9b7d0"
    },
    linkLogout:{
        color:"#3f4d67",
        textDecoration: "none",
    },
    mainLogo:{
        width:200,
        height:50,
        float:"left"
    },
    arrowDown:{
        fontSize: 20
    },
    arrowRight:{
        fontSize: 12
    }
}));

const NavigationContainer = () => {
    ///Settings 
    const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
    console.log(prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    
    prevOpen.current = open;
  })
    // eslint-disable-next-line no-empty-pattern
    const [{ snackbar }, dispatch] = useStateValue();
    const classes = navbarStyles();
    const [menu, setMenu] = useState(true);

    // to get route name
    const location = useLocation();
    // console.log("testing", location.pathname);

    const openMenu = () => {
        setMenu(!menu);
    };
    const closeMenu = () => {
        setMenu(!menu);
    };
    // const [initApp, setInitApp] = useState(false)

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
            getAllServices(dispatch);
            userInfo(dispatch);
            getAllAddress(dispatch);
            getAllCities(dispatch);
            getAllCompanies(dispatch);
            getAllProduct(dispatch);
            getAllSkills(dispatch);
            getAllLevels(dispatch);
            getAllTypeEmp(dispatch);
            getAllLevelSkill(dispatch);
            getAllDocumentE(dispatch);
            getAllProfileDoc(dispatch);
            getAllBranch(dispatch);
            getAllRoles(dispatch);
            getAllBanks(dispatch);
            getAllAddressType(dispatch);
            getAllATC(dispatch);
            getAllUser(dispatch);
            getAllContact(dispatch);
            getAllMoney(dispatch);
            getAllTax(dispatch);
            getAllMessage(dispatch);
            getAllDepartment(dispatch);
            getAllAccount(dispatch);
            getAllBrand(dispatch);
            getAllPriceList(dispatch);
            getAllClasses(dispatch);
            getAllCategory(dispatch);
            getAllSubCat(dispatch);
            getAllEmployee(dispatch);
            getAllMUnit(dispatch);
            getAllCustomers(dispatch);
            getAllWarehouses(dispatch);
            getAllYears(dispatch);
            getAllCountry(dispatch);
            getAllState(dispatch);
            getAllDocumentC(dispatch);
            getAllJobs(dispatch);
            getAllJobTypes(dispatch);
        }
    }, [dispatch]);

    const { height, width } = useWindowDimensions();
    return (
        <div className={classes.root}>
            
            <Snackbar
                    open={snackbar ? snackbar.open : false}
                    autoHideDuration={4000}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                    message={snackbar ? snackbar.message : ""}
                    onClose={() => {
                        dispatch({
                            type: "OPEN_SNACKBAR",
                            openMessage: {
                                open: false,
                                title: snackbar ? snackbar.title : "",
                                message: snackbar ? snackbar.message : "",
                                alert: snackbar ? snackbar.alert : "",
                            },
                        });
                    }}
            />
                {location.pathname !== "/login"
                && location.pathname !== "/signup" ? (
                    <>
                        <AppBar
                            elevation={0}
                            position="fixed"
                            className={clsx(classes.appBar, {
                                [classes.appBarShift]: menu,
                            })}
                        >
                            <Toolbar>
                                <Grid container>
                                    
                                    <Grid item md={11}>
                                        <Button onClick={() => {
                                            openMenu(!menu)
                                        }}><Menu1 /></Button>
                                    </Grid>
                                    <Grid item md={1}>
                                        <Button
                                        ref={anchorRef}
                                        aria-controls={open ? 'menu-list-grow' : undefined}
                                        aria-haspopup="true"
                                        onClick={handleToggle}
                                        className={classes.settingsIcon}
                                        >
                                        <Settings ></Settings>
                                        <KeyboardArrowDown></KeyboardArrowDown>
                                        </Button>
                                        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                                        {({ TransitionProps, placement }) => (
                                            <Grow
                                            {...TransitionProps}
                                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                                            >
                                            <Paper>
                                                <ClickAwayListener onClickAway={handleClose}>
                                                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                                    <MenuItem onClick={handleClose}>Profile</MenuItem>
                                                    <MenuItem onClick={handleClose}>My account</MenuItem>
                                                    <MenuItem onClick={handleClose}>
                                                        <a className={classes.linkLogout} href="#"  onClick={(e) => {
                                                e.preventDefault();
                                                localStorage.removeItem("token");
                                                window.location.reload();
                                                window.location.href = "/login";
                                        }}>
                                        <ExitToApp className={classes.iconLogout}/>Logout</a></MenuItem>
                                                </MenuList>
                                                </ClickAwayListener>
                                            </Paper>
                                            </Grow>
                                        )}
                                        </Popper>


                                        
                                    </Grid>
                                    {/* <Grid container item md={3}>
                                        <Grid
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "center",
                                                margin: 10,
                                            }}
                                            item
                                            md={12}
                                        >
                                            <div className={classes.iconSizes}>
                                                <Sheduler />
                                            </div>
                                            <div className={classes.iconSizes}>
                                                <Messages />
                                            </div>
                                            <div
                                                className={
                                                    classes.iconSizesPhone
                                                }
                                            >
                                                <Calls />
                                            </div>

                                            <div className={classes.iconSizes}>
                                                <Profile />
                                            </div>
                                        </Grid>
                                    </Grid> */}
                                </Grid>
                            </Toolbar>
                        </AppBar>
                        <Menu
                            menu={menu}
                            closeMenu={closeMenu}
                            classes={classes}
                        />
                    </>
                ) : null}

            {/* comment class */}
            <main
                className={
                    location.pathname !== "/login"
                    && location.pathname !== "/signup"
                        ? classes.content
                        : ""
                }
            >
                <Switch>
                    {/* Safe routes with token */}
                    <SafeRoutes path="/" exact component={Home} />
                    <SafeRoutes path="/skill" exact component={Skill} />
                    <SafeRoutes path="/level" exact component={Level} />
                    <SafeRoutes path="/typeemp" exact component={TypeEmp} />
                    <SafeRoutes path="/levelskill" exact component={LevelSkill} />
                    <SafeRoutes path="/documente" exact component={DocumentE} />
                    <SafeRoutes path="/documentc" exact component={DocumentC} />
                    <SafeRoutes path="/profiledoc" exact component={ProfileDoc} />
                    <SafeRoutes path="/branch" exact component={Branch} />
                    <SafeRoutes path="/role" exact component={Role} />
                    <SafeRoutes path="/bank" exact component={Bank} />
                    <SafeRoutes path="/addresstype" exact component={AddressType} />
                    <SafeRoutes path="/user" exact component={User} />
                    <SafeRoutes path="/money" exact component={Money} />
                    <SafeRoutes path="/brand" exact component={Brand} />
                    <SafeRoutes path="/category" exact component={Category} />
                    <SafeRoutes path="/subcat" exact component={SubCat} />
                    <SafeRoutes path="/munit" exact component={MUnit} />
                    <SafeRoutes path="/tax" exact component={TAX} />
                    <SafeRoutes path="/product" exact component={Product} />
                    <SafeRoutes path="/contact" exact component={Contact} />
                    <SafeRoutes path="/department" exact component={Department} />
                    <SafeRoutes path="/employee" exact component={Employee} />
                    <SafeRoutes path="/account" exact component={Account} />
                    <SafeRoutes path="/company" exact component={Company} />
                    {/* <SafeRoutes path="/message" exact component={Message} /> */}
                    <SafeRoutes path="/customer" exact component={Customer} />
                    <SafeRoutes path="/documentc" exact component={DocumentC} />
                    <SafeRoutes path="/pricelist" exact component={PriceList} />
                    <SafeRoutes path="/warehouse" exact component={WareHouse} />
                    <SafeRoutes path="/service" exact component={Service} />
                    <SafeRoutes path="/job" exact component={Job} />
                    {/*  Login Routes */}
                    <LoginRoute path="/login" exact component={Login} />
                    <LoginRoute path="/signup" exact component={Signup} />
                </Switch>
            </main>
        </div>
    );
};

export default NavigationContainer;
