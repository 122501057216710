import {
    Dialog,
    DialogContent,
    DialogTitle,
    Box,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useStateValue } from "../../Context/store";

import { getAllCustomers, insCustomer } from "../../Actions/UserAction";
import { useForm } from "../../hooks/useForm";
import { LoadingComponent, useLoading } from "../../hooks/useLoading";


import CustomerForm from "./CustomerForm"

/** Import Icons */
import { ReactComponent as CustomersIcon } from "../../Assets/Icons/Customers.svg";
import { ReactComponent as AddIcon } from "../../Assets/Icons/Add.svg";

/** Theme & Styles Import */
import { dialogStyle } from "../../Assets/styles/Styles";

const AddCustomer = ({ openDialog, closeDialog }) => {
    const { loading, openLoading, closeLoading } = useLoading();
    const classes = dialogStyle();
    // eslint-disable-next-line no-empty-pattern
    const [{userAuth}, dispatch] = useStateValue();
    const { formData, handleInputChange, setFormData } = useForm({pReciveJobs:false, pCredit:false});
    const [customerData, setCustomerData] = useState({});

/*     const getCustomerData = (data) => {
        setCustomerData((prev) => ({
            ...prev,
            data
        }))
    } 
*/

    const [invertColors, setInvertColors] = useState(false)
    const handleInvertColors = () => {
        setInvertColors(!invertColors)
    }


    const saveFunction = async (e) => {
        //console.log(formData)
        e.preventDefault();
        openLoading();
        await insCustomer(formData).then((response) => {
            //console.log(response);
            if (response.status === 200) {
                closeLoading();
                dispatch({
                    type: "OPEN_SNACKBAR",
                    openMessage: {
                        open: true,
                        title: "Success",
                        message: response.data.output.pmensaje,
                        alert: "success",
                    },
                });
                getAllCustomers(dispatch);
                cancelFunction();
            } else {
                closeLoading();
                dispatch({
                    type: "OPEN_SNACKBAR",
                    openMessage: {
                        open: true,
                        title: "Error",
                        message: response.msg,
                        alert: "error",
                    },
                });
            }
        });
    };

    const cancelFunction = (e) => {
        setFormData({});
        Array.from(document.querySelectorAll("form input")).forEach(
            input => (input.value = "")
          );
    };

     useEffect(() => {
        
         if(userAuth && userAuth.userAuth[0] != undefined){
          setFormData({
            ...formData,
            pIdUser: userAuth.userAuth[0].IdUser,
        })
    } 
    }, [])

    
    return (
                <CustomerForm
                    onCancel = {cancelFunction} 
                    onSave = {saveFunction}
                    onInvertColors={handleInvertColors}
                    onChange={handleInputChange}
                    //getCustomerData={getCustomerData}
                    data = {formData}
                />

    );
};

export default AddCustomer;
