export const initialState = {
    customer: [],
};

const allCustomerReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_CUSTOMER":
            return {
                ...state,
                customer: action.customer
            };

        default:
            return state;
    }
};

export default allCustomerReducer;