import React, { useEffect, useState } from "react";
import { useStateValue } from "../../Context/store";
import { deleteRole, getAllRoles } from "../../Actions/UserAction";
import AddRole from "./AddRole";
import LockSmithDeleteComponent from "../ReusableComponents/LockSmithDeleteComponent";
import UpdateRole from "./UpdateRole";
/*Import Reusable Components */
import LockSmithMainTable from "../ReusableComponents/LockSmithMainTable";

const columns = () => { 
    return [
    { title: "ID", field: "IdRole",cellStyle: {height: 40,padding:"0px 16px"}},
    { title: "Description", field: "Descr",cellStyle: {height: 40,padding:"0px 16px"} },
    { title: "Active", field: "Active",cellStyle: {height: 40,padding:"0px 16px"}},
]};

const Role = () => {
    const [{ allRole }] = useStateValue();
    const [role, setRole] = useState(null);

    useEffect(() => {
        setRole(allRole ? allRole.role : []);
    }, [allRole]);

    return (
        <React.Fragment>
            <LockSmithMainTable 
                title="Role"
                data={role}
                columns={columns()}
                addComponent={AddRole}
                updateComponent={UpdateRole}
                //deleteComponent={LockSmithDeleteComponent}
                //deleteAction={deleteRole}
                reducer={getAllRoles}
                itemId={columns().field}
            />
        </React.Fragment>
    );
};

export default Role;
