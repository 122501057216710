import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  InputBase,
  MenuItem,
  Select,
  styled,
  Tab,
  Tabs,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { PhotoCamera } from "@material-ui/icons";
import { withStyles } from "@material-ui/styles";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  getAllProduct,
  getPriceProduct,
  getProdPhotos,
  insProdPhotos,
  uptProduct,
  getSubCatTree,
  delProductPhoto,
} from "../../Actions/UserAction";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import Save from "@material-ui/icons/Save";
import Close from "@material-ui/icons/Close";
import SaveIcon from "../../Assets/Icons/Remember_me_Yes.svg";
import { ReactComponent as ScrollDown } from "../../Assets/Icons/Scroll_Down.svg";
import { dialogStyle } from "../../Assets/styles/Styles";
import { useStateValue } from "../../Context/store";
import { useForm } from "../../hooks/useForm";
import { LoadingComponent, useLoading } from "../../hooks/useLoading";
import LockSmithSwitch from "../ReusableComponents/LockSmithSwitch";
import LockSmithTextField from "../ReusableComponents/LockSmithTextField";
import DetailTab from "./Tabviews/DetailTab";
import DocumentsTab from "./Tabviews/DocumentsTab";
import PriceProductTab from "./Tabviews/PriceProductTab";
import ClassTab from "./Tabviews/ClassTab";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    padding: "0",
  },
}));

/* Styled Tabs */
const StyledTab = withStyles((theme) => ({
  root: {
    color: "#FFD162",
    backgroundColor: "#232323",
    fontSize: 16,
    fontWeight: "bold",
    border: "5px solid #232323",
    borderRadius: 15,
    marginBottom: 5,
    marginTop: 5,
  },
  selected: {
    color: "#232323",
    border: "2px solid #232323",
    backgroundColor: "#FFD162",
  },
}))((props) => <Tab disableRipple {...props} />);

const styleForm = makeStyles(() => ({
  root: {},
  borderForm: {
    border: "solid 1px rgba(0, 0, 0, 0.23) !important",
    width: "100%",
    borderRadius: 5,
  },
  textForm: {
    fontSize: 14,
    fontWeight: "400",
    color: "rgb(136,136,136)",
    width: "100%",
  },
  cardMargin: {
    margin: "10px 0",
  },
  subtabs: {
    boxShadow: "none",
  },
  subtabOption: {
    backgroundColor: "transparent !important",
  },
  hide: {
    display: "none",
  },
}));

function LinkTab(props) {
  return (
    <Tab
      component="a"
      onClick={(event) => {
        event.preventDefault();
      }}
      {...props}
    ></Tab>
  );
}
/* Tab Panel */
const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Typography>{children}</Typography>}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const UpdateProduct = ({ openDialog, closeDialog, data }) => {
  const classes = dialogStyle();
  const classesForm = styleForm();
  const { loading, openLoading, closeLoading } = useLoading();
  const [{ allMoney, allTAX, userAuth }, dispatch] = useStateValue();
  const { formData, handleInputChange, setFormData } = useForm({});
  const [pProduct, setPProduct] = useState([]);
  const [pClass, setPClass] = useState({});
  const [user, setUser] = useState([]);

  /* Images Handler */
  const [pImages, setPImages] = useState([]);
  const [images, setImages] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [saveImgState, setSaveImgState] = useState(true);
  const [deleteImgState, setDeleteImgState] = useState(true);
  const [selectImgDisabled, setSelectImgDisabled] = useState(false);

  const imgHandler = (e) => {
    setImages(e.target.files);
    if (e.target.files) {
      const fileArray = Array.from(e.target.files).map((file) =>
        window.URL.createObjectURL(file)
      );
      //setSelectedImages((prevImages) => prevImages.concat(fileArray));
      setSelectedImages(fileArray);
      Array.from(e.target.files).map((file) =>
        window.URL.revokeObjectURL(file)
      );
      //need to check if the event actually has a file
      if (e.target.files.length !== 0) {
        setSaveImgState(false);
      }
    }
  };
  const renderImages = (source) => {
    if (source.length !== 0) {
      return source.map((image, index) => {
        return (
          <Grid item sm={4}>
            <Avatar
              src={image}
              style={{
                width: 120,
                height: 120,
              }}
            />
          </Grid>
        );
      });
    } else {
      //display image in DB
      if (pImages.length !== 0) {
        return pImages.slice(0, 3).map((item, index) => {
          var path = item.Path;
          return (
            <Grid item sm={4}>
              <Avatar
                src={path}
                style={{
                  width: 120,
                  height: 120,
                }}
              />
            </Grid>
          );
        });
      } else {
        return (
          <>
            <Grid item sm={4} style={{ height: "120px" }}>
              <IconButton
                aria-label="upload picture"
                component="span"
                style={{
                  border: "solid 4px #3f4d67",
                  backgroundColor: "#3f4d67",
                }}
                disabled
              >
                <PhotoCamera
                  style={{
                    fontSize: 80,
                    color: "#FFF",
                  }}
                />
              </IconButton>
            </Grid>
            {/*  <Grid item sm={4}>
                            <IconButton
                                aria-label="upload picture"
                                component="span"
                                style={{
                                    border: "solid 4px #232323",
                                    backgroundColor: "#FFD162",
                                }}
                                disabled
                            >
                                <PhotoCamera
                                    style={{
                                        fontSize: 80,
                                        color: "#232323",
                                    }}
                                />
                            </IconButton>
                        </Grid>
                        <Grid item sm={4}>
                            <IconButton
                                aria-label="upload picture"
                                component="span"
                                style={{
                                    border: "solid 4px #232323",
                                    backgroundColor: "#FFD162",
                                }}
                                disabled
                            >
                                <PhotoCamera
                                    style={{
                                        fontSize: 80,
                                        color: "#232323",
                                    }}
                                />
                            </IconButton>
                        </Grid> */}
          </>
        );
      }
    }
  };

  const clearImages = () => {
    //console.log("clear");
    if (selectedImages.length > 0) {
      setSelectedImages([]);
    }
    if (pImages.length > 0) {
      setPImages([]);
    }
    setSaveImgState(true);
  };

  const saveImages = async (e) => {
    //console.log(images);
    const data = JSON.stringify(formData.pIdProduct);
    const dataForm = new FormData();
    dataForm.append("data", data);
    for (const key of Object.keys(images)) {
      dataForm.append("img", images[key]);
    }
    openLoading();
    await insProdPhotos(dataForm).then((response) => {
      if (response.status === 200) {
        closeLoading();
        dispatch({
          type: "OPEN_SNACKBAR",
          openMessage: {
            open: true,
            title: "Success",
            message: response.data.output.pmensaje,
            alert: "success",
          },
        });
        setSaveImgState(true);
        setSelectedImages([]);
        pPhotosData(data);
        //cancelFunction();
      } else {
        closeLoading();
        dispatch({
          type: "OPEN_SNACKBAR",
          openMessage: {
            open: true,
            title: "Error",
            message: response.data.msg,
            alert: "error",
          },
        });
      }
    });
  };

  const deleteImage = async (e) => {
    //console.log(images);
    const data = {
      pIdProduct: formData.pIdProduct,
      pIdPhoto: pImages[0].IdPhoto,
    };
    console.log(data);

    //openLoading();
    await delProductPhoto(data).then((response) => {
      if (response.status === 200) {
        closeLoading();
        dispatch({
          type: "OPEN_SNACKBAR",
          openMessage: {
            open: true,
            title: "Success",
            message: response.data.output.pmensaje,
            alert: "success",
          },
        });
        setPImages([]);
        setImages([]);
        setDeleteImgState(true);
        pPhotosData();
        //cancelFunction();
      } else {
        closeLoading();
        dispatch({
          type: "OPEN_SNACKBAR",
          openMessage: {
            open: true,
            title: "Error",
            message: response.data.msg,
            alert: "error",
          },
        });
      }
    });
  };

  /* Tab Handlers */
  const [tabValue, setTabValue] = useState(1);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  /* Data tabs handler */
  const handleData = (e) => {
    const { name } = e.target;
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const saveFunction = async (e) => {
    e.preventDefault();
    openLoading();
    await uptProduct(formData)
      .then((response) => {
        if (response.status === 200) {
          closeLoading();
          dispatch({
            type: "OPEN_SNACKBAR",
            openMessage: {
              open: true,
              title: "Success",
              message: response.data.output.pmensaje,
              alert: "success",
            },
          });
          getAllProduct(dispatch);
          cancelFunction();
        } else {
          closeLoading();
          dispatch({
            type: "OPEN_SNACKBAR",
            openMessage: {
              open: true,
              title: "Error",
              message: response.data.msg,
              alert: "error",
            },
          });
        }
      })
      .catch((e) => {
        console.log(e);
        closeLoading();
      });
  };

  const pPhotosData = async (idProd) => {
    console.log(idProd);
    openLoading();
    await getProdPhotos({ IdProduct: idProd })
      .then((response) => {
        //console.log(response)
        if (response.status === 200) {
          closeLoading();
          //console.log(response.data.length)
          setPImages(response.data);
          if (response.data.length > 0) {
            setDeleteImgState(false);
            setSelectImgDisabled(true);
          } else {
            setSelectImgDisabled(false);
          }
        }
      })
      .catch((e) => {
        console.log(e);
        closeLoading();
      });
  };

  const catProductData = async () => {
    openLoading();
    await getSubCatTree({ pidSubCategory: data.IdSubCategory }).then(
      (response) => {
        if (response.status === 200 && response.data[0]) {
          closeLoading();
          //setPClass(response.data[0]);
          //console.log(response.data[0]);
          setFormData((prevState) => ({
            ...prevState,
            pIdClass: response.data[0].IdClass,
            pIdCategory: response.data[0].IdCategory,
          }));
        }
      }
    );
  };

  const cancelFunction = () => {
    if (data.IdSubCategory) {
      catProductData();
    }
    if (Object.keys(data).length !== 0) {
      console.log(data);
      setFormData({
        pIdProduct: data.IdProduct,
        pCveProd: data.CveProd,
        pDescr: data.Descr,
        pCost: data.Cost,
        pCodeBar: data.CodeBar,
        pSerie: data.SerialN,
        pWeight: data.Weight,
        pDimension: data.Dimension,
        pLote: data.Lot,
        pModel: data.Model,
        pidTax: data.idTax,
        pidMed: data.idMed,
        pIdClass: data.IdClass,
        pIdCategory: data.IdCategory,
        pidSubCategory: data.idSubCategory,
        pidMon: data.idMoney,
        pidAnio: data.idYear,
        pidBrand: data.idBrand,
        pActive: data.Active,
      });
      console.log("ID Prod: ", data.IdProduct);
      pPhotosData(data.IdProduct);
    }
  };

  useEffect(() => {
    setUser(userAuth ? userAuth.userAuth : []);
    /*TODO: Fix this triggering when loading Product Module*/
    //catProductData()
  }, [userAuth]);

  useEffect(() => {
    if (Object.keys(data).length !== 0) {
      console.log(data);
      setFormData({
        pIdProduct: data.IdProduct,
        pCveProd: data.CveProd,
        pDescr: data.Descr,
        pCost: data.Cost,
        pCodeBar: data.CodeBar,
        pSerie: data.SerialN,
        pWeight: data.Weight,
        pDimension: data.Dimension,
        pLote: data.Lot,
        pModel: data.Model,
        pidTax: data.idTax,
        pidMed: data.idMed,
        pIdClass: data.IdClass,
        pIdCategory: data.IdCategory,
        pidSubCategory: data.idSubCategory,
        pidMon: data.idMoney,
        pidAnio: data.idYear,
        pidBrand: data.idBrand,
        pActive: data.Active,
      });
      console.log("ID Prod: ", data.IdProduct);
      pPhotosData(data.IdProduct);
    }
  }, [data]);

  useEffect(() => {
    if (data.IdSubCategory) {
      catProductData();
    }
  }, [data.IdSubCategory]);

  return (
    <>
      <form onSubmit={saveFunction}>
        <Grid container spacing={2}>
          <Grid item sm={12} xs={12} lg={4}>
            <text
              style={{
                fontSize: 14,
                fontWeight: "bold",
              }}
            >
              MOD DATE: {}
            </text>
            <text
              style={{
                fontSize: 14,
                fontWeight: "bold",
              }}
            >
              {moment().format("LLLL").toUpperCase()}
            </text>
            <br></br>
            <text
              style={{
                fontSize: 14,
                fontWeight: "bold",
              }}
            >
              User:
            </text>
            <text
              style={{
                fontSize: 14,
                fontWeight: "bold",
              }}
            >
              {user.length !== 0 ? user[0].UserName : "USER"}
            </text>
          </Grid>
          <Grid item sm={12} xs={12} lg={2}>
            <text
              style={{
                fontSize: 14,
                fontWeight: "bold",
              }}
            >
              Active
            </text>
            <LockSmithSwitch
              checked={formData.pActive}
              onChange={handleInputChange}
              name="pActive"
              value={formData.pActive}
            />
          </Grid>
          <Grid item sm={12} xs={12} lg={3}>
            <Box
              style={{
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
                width: "98%",
                marginTop: -20,
              }}
            >
              <IconButton
                //aria-label="upload picture"
                //component="span"
                style={{
                  border: "solid 4px #3f4d67",
                  backgroundColor: "#3f4d67",
                  cursor: "default",
                }}
                disableRipple
              >
                <PhotoCamera
                  style={{
                    fontSize: 80,
                    color: "#FFF",
                  }}
                />
              </IconButton>

              <div
                className="edit-icon"
                style={{
                  width: "0px",
                  marginTop: "110px",
                }}
              >
                <input
                  accept="image/*"
                  id="icon-button-file"
                  name="prodPhoto"
                  type="file"
                  style={{ width: "0px", opacity: 0 }}
                  onChange={imgHandler}
                  disabled={selectImgDisabled}
                />
                <label htmlFor="icon-button-file">
                  <IconButton
                    aria-label="upload picture"
                    component="span"
                    class="fas fa-pen"
                    style={{
                      cursor: selectImgDisabled ? "not-allowed" : "pointer",
                    }}
                    disableRipple
                  ></IconButton>
                </label>
              </div>
            </Box>
          </Grid>
          <Grid item sm={12} xs={12} lg={3}>
            <Grid container spacing={0}>
              <Grid item sm={12} xs={12}>
                {renderImages(selectedImages)}
              </Grid>
            </Grid>
            <Grid container spacing={0}>
              <Grid item sm={12} xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  classes={{ root: classes.saveButton }}
                  style={{ margin: 0, width: 200 }}
                  size="large"
                  endIcon={
                    <div
                      style={{
                        width: 20,
                        height: 20,
                        marginTop: -14,
                        marginLeft: 10,
                      }}
                    >
                      <img src={SaveIcon} alt="..." />
                    </div>
                  }
                  disabled={saveImgState}
                  onClick={saveImages}
                >
                  Save Photo
                </Button>
              </Grid>
              <Grid item sm={12} xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  classes={{ root: classes.saveButton }}
                  size="large"
                  style={{ marginTop: 10, width: 200 }}
                  endIcon={
                    <div
                      style={{
                        width: 20,
                        height: 20,
                        marginTop: -14,
                        marginLeft: 10,
                      }}
                    >
                      <img src={SaveIcon} alt="..." />
                    </div>
                  }
                  disabled={deleteImgState}
                  onClick={deleteImage}
                >
                  Delete Photo
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item sm={12} xs={12} lg={4}>
            <LockSmithTextField
              label="Cve Product"
              labelWidth="100%"
              name="pCveProd"
              onChange={handleInputChange}
              value={formData.pCveProd}
              width="100%"
              disabled
            />
          </Grid>
          <Grid item sm={12} xs={12} lg={8}>
            <LockSmithTextField
              label="Description*"
              labelWidth="17%"
              multiline={true}
              name="pDescr"
              onChange={handleInputChange}
              rows={5}
              value={formData.pDescr}
              required
            />
          </Grid>
          <Grid item sm={12} xs={12} lg={4}>
            <LockSmithTextField
              label="Cost*"
              labelWidth="44%"
              name="pCost"
              onChange={handleInputChange}
              value={formData.pCost}
              type="number"
              required
            />
          </Grid>
          <Grid item sm={12} xs={12} lg={4}>
            <text className={classesForm.textForm}>Money*</text>
            <Select
              fullWidth
              IconComponent={() => (
                <div
                  style={{
                    width: 20,
                    height: 20,
                    margin: 10,
                    color: "rgb(136,136,136)",
                  }}
                >
                  <ArrowDropDown />
                </div>
              )}
              id="pidMon"
              className={classesForm.borderForm}
              style={{ marginTop: 5 }}
              input={<BootstrapInput />}
              label="Select ID MONEY"
              name="pidMon"
              onChange={handleInputChange}
              value={formData.pidMon}
              required
            >
              {allMoney
                ? allMoney.money?.map((item, index) => {
                    return (
                      <MenuItem
                        key={index}
                        style={{
                          justifyContent: "center",
                        }}
                        value={item.IdMoney}
                      >
                        {item.Descr}
                      </MenuItem>
                    );
                  })
                : ""}
            </Select>
          </Grid>
          <Grid item sm={12} xs={12} lg={4}>
            <text className={classesForm.textForm}>Tax*</text>
            <Select
              fullWidth
              IconComponent={() => (
                <div
                  style={{
                    width: 20,
                    height: 20,
                    margin: 10,
                    color: "rgb(136,136,136)",
                  }}
                >
                  <ArrowDropDown />
                </div>
              )}
              id="pidTax"
              input={<BootstrapInput />}
              label="Select ID TAX"
              name="pidTax"
              onChange={handleInputChange}
              className={classesForm.borderForm}
              style={{ marginTop: 5 }}
              value={formData.pidTax}
              required
            >
              {allTAX
                ? allTAX.tax?.map((item, index) => {
                    return (
                      <MenuItem
                        key={index}
                        style={{
                          justifyContent: "center",
                        }}
                        value={item.IdTax}
                      >
                        {item.Descr}
                      </MenuItem>
                    );
                  })
                : ""}
            </Select>
          </Grid>
          <Grid item sm={12} xs={12}>
            <Tabs
              onChange={handleTabChange}
              variant="fullWidth"
              value={tabValue}
            >
              <LinkTab
                label="Class"
                {...a11yProps(0)}
                className={classes.subtabOption}
              ></LinkTab>
              <LinkTab
                label="Detail"
                {...a11yProps(1)}
                className={classes.subtabOption}
              ></LinkTab>
              <LinkTab
                label="Sales"
                {...a11yProps(2)}
                className={classes.subtabOption}
              ></LinkTab>
            </Tabs>
          </Grid>
          <Grid item sm={12} xs={12}>
            <TabPanel value={tabValue} index={0}>
              <ClassTab
                data={formData}
                handleClassData={handleInputChange}
                saveFunction={saveFunction}
                classProduct={pClass}
                //callback={catProductData}
              />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <DetailTab
                data={formData}
                handleDetailData={handleInputChange}
                saveFunction={saveFunction}
              />
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
              <PriceProductTab data={data.IdProduct} pProduct={pProduct} />
            </TabPanel>
            {/*  <TabPanel value={tabValue} index={3}>
                                        <DocumentsTab />
                                    </TabPanel> */}
          </Grid>
          <Grid item sm={6} xs={6}></Grid>
          <Grid item sm={3} xs={3}></Grid>
          <Grid item sm={12} xs={12} lg={3}>
            <Button
              variant="contained"
              onClick={cancelFunction}
              classes={{ root: classes.cancelButton }}
              size="large"
              fullWidth
              endIcon={
                <div
                  style={{
                    width: 20,
                    height: 20,
                    marginTop: -14,
                    marginLeft: 10,
                  }}
                >
                  <Close />
                </div>
              }
            >
              Reset
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default UpdateProduct;
