export const initialState = {
    country: [],
};

const allCountryReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_COUNTRY":
            return {
                ...state,
                country: action.country
            };

        default:
            return state;
    }
};

export default allCountryReducer;