import { Grid } from "@material-ui/core";
import React, { useState } from "react";
import { getAllBanks, insBank } from "../../Actions/UserAction";
import { useStateValue } from "../../Context/store";

/** Import Icons */

/**Import Components */
import LockSmithTextField from "../ReusableComponents/LockSmithTextField";
import LockSmithComponentButtons from "../ReusableComponents/LockSmithComponentButtons";
/** Custom components & hooks */
import { LoadingComponent, useLoading } from "../../hooks/useLoading";

/** Theme & Styles Import */
import { dialogStyle } from "../../Assets/styles/Styles";

const AddBank = ({ openDialog, closeDialog }) => {
  const classes = dialogStyle();
  const { loading, openLoading, closeLoading } = useLoading();
  const [openBackdrop, setOpenBackdrop] = useState(false);
  // eslint-disable-next-line no-empty-pattern
  const [{}, dispatch] = useStateValue();
  const [bankData, setBankData] = useState({});

  const dataValues = (e) => {
    const { name, value } = e.target;
    setBankData((last) => ({
      ...last,
      [name]: value,
    }));
  };

  const saveFunction = async (e) => {
    e.preventDefault();
    openLoading();
    await insBank(bankData).then((response) => {
      if (response.status === 200) {
        closeLoading();
        dispatch({
          type: "OPEN_SNACKBAR",
          openMessage: {
            open: true,
            title: "Success",
            message: response.data.output.pmensaje,
            alert: "success",
          },
        });
        getAllBanks(dispatch);
        cancelFunction();
      } else {
        closeLoading();
        dispatch({
          type: "OPEN_SNACKBAR",
          openMessage: {
            open: true,
            title: "Error",
            message: response.data.msg,
            alert: "error",
          },
        });
      }
    });
  };

  const cancelFunction = () => {
    Array.from(document.querySelectorAll("form input")).forEach(
      (input) => (input.value = "")
    );
    setBankData({});
  };
  return (
    <form onSubmit={saveFunction}>
      <Grid container spacing={2} alignContent="center" justifyContent="center">
        <LockSmithTextField
          label="Description"
          labelWidth="32%"
          name="pDescr"
          value={bankData.pDescr}
          onChange={dataValues}
          required
        />
      </Grid>

      <LockSmithComponentButtons closeHandler={cancelFunction} />
    </form>
  );
};

export default AddBank;
