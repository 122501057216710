import {
    Box,
} from "@material-ui/core";

const LocksmithEntryId = (props) => {
    return(
        <Box
            style={{
                flexDirection: "row",
                display: "flex",
                padding: 1,
                width: "100%",
            }}
        >
            <Box
                style={{
                    flexDirection: "row",
                    display: "flex",
                    width: "30%",
                }}
            >
            <text
                style={{ fontSize: 16}}
                >
                {props.label}
            </text>
            </Box>
            <Box
                style={{
                    flexDirection: "row",
                    display: "flex",
                    width: "75%",
                    marginLeft: 10,
                }}
            >
            <text style={{ fontSize: 16}}>
                {props.id}
            </text>
        </Box>
        </Box>
    )
}

export default LocksmithEntryId;