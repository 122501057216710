import { Button, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { getAllBanks, uptBank } from "../../Actions/UserAction";
import { useStateValue } from "../../Context/store";

/** Import Icons */
import Save from "@material-ui/icons/Save";
import Close from "@material-ui/icons/Close";
/**Import Components */
import LockSmithTextField from "../ReusableComponents/LockSmithTextField";
import LocksmithEntryId from "../ReusableComponents/LockSmithEntryId";

/** Theme & Styles Import */
import { dialogStyle } from "../../Assets/styles/Styles";

const UpdateBank = ({ openDialog, closeDialog, data }) => {
  const classes = dialogStyle();
  const [openBackdrop, setOpenBackdrop] = useState(false);
  // eslint-disable-next-line no-empty-pattern
  const [{}, dispatch] = useStateValue();
  const [bankData, setBankData] = useState({});

  const dataValues = (e) => {
    const { name, value } = e.target;
    setBankData((last) => ({
      ...last,
      [name]: value,
    }));
  };

  const saveFunction = async (e) => {
    e.preventDefault();
    setOpenBackdrop(true);
    await uptBank(bankData).then((response) => {
      if (response.status === 200) {
        setOpenBackdrop(false);
        dispatch({
          type: "OPEN_SNACKBAR",
          openMessage: {
            open: true,
            title: "Success",
            message: response.data.output.pmensaje,
            alert: "success",
          },
        });
        getAllBanks(dispatch);
        closeDialog();
      } else {
        setOpenBackdrop(false);
        dispatch({
          type: "OPEN_SNACKBAR",
          openMessage: {
            open: true,
            title: "Error",
            message: response.data.msg,
            alert: "error",
          },
        });
      }
    });
  };

  useEffect(() => {
    setBankData({
      pIdBank: data.IdBank,
      pDescr: data.Descr,
    });
  }, [data]);

  const resetForm = () => {
    setBankData({
      pIdBank: data.IdBank,
      pDescr: data.Descr,
    });
  };
  return (
    <React.Fragment>
      <form onSubmit={saveFunction}>
        <Grid
          container
          spacing={2}
          alignContent="center"
          justifyContent="center"
        >
          <Grid item xs={12}>
            <LocksmithEntryId label="Id Bank" id={bankData.pIdBank} />
          </Grid>
          <Grid item xs={12} lg={6}>
            <LockSmithTextField
              label="Description"
              labelWidth="32%"
              name="pDescr"
              value={bankData.pDescr}
              onChange={dataValues}
              required
            />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          alignContent="center"
          justifyContent="flex-end"
        >
          <Grid item xs={6} lg={3}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              classes={{ root: classes.saveButton }}
              size="large"
              fullWidth
              endIcon={
                <div
                  style={{
                    width: 20,
                    height: 20,
                    marginTop: -14,
                    marginLeft: 10,
                  }}
                >
                  <Save />
                </div>
              }
            >
              Save
            </Button>
          </Grid>
          <Grid item xs={6} lg={3}>
            <Button
              variant="contained"
              onClick={resetForm}
              classes={{ root: classes.cancelButton }}
              size="large"
              fullWidth
              endIcon={
                <div
                  style={{
                    width: 20,
                    height: 20,
                    marginTop: -14,
                    marginLeft: 10,
                  }}
                >
                  <Close />
                </div>
              }
            >
              Reset
            </Button>
          </Grid>
        </Grid>
      </form>
    </React.Fragment>
  );
};

export default UpdateBank;
