import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
} from "@material-ui/core";
import { Add } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { getAllCompanies, uptCompany } from "../../Actions/UserAction";
import { dialogStyle } from "../../Assets/styles/Styles";
import { useStateValue } from "../../Context/store";
import { useForm } from "../../hooks/useForm";
import { LoadingComponent, useLoading } from "../../hooks/useLoading";
import AddAccount from "../Account/AddAccount";
import AddAddress from "../Address/AddAddress";
import LockSmithComponentButtons from "../ReusableComponents/LockSmithComponentButtons";
import LockSmithSelect from "../ReusableComponents/LockSmithSelect";
import LockSmithSwitch from "../ReusableComponents/LockSmithSwitch";
import LockSmithTextField from "../ReusableComponents/LockSmithTextField";

const UpdateCompany = ({ openDialog, closeDialog, data }) => {
    const classes = dialogStyle();
    const { loading, openLoading, closeLoading } = useLoading();
    const { formData, handleInputChange, setFormData } = useForm();
    const accountFields = ["IdAccount", "Desc"];
    const addressFields = ["IdAddress", "Address"];
    // eslint-disable-next-line no-empty-pattern
    const [{ allAccount, allAddress }, dispatch] = useStateValue();
    const [checkControlActive, setCheckControlActive] = useState(false);
    const [checkControlCancel, setCheckControlCancel] = useState(false);
    const [checkControlClosing, setCheckControlClosing] = useState(false);
    const handlerSwitchActive = (e) =>{
        setCheckControlActive(e.target.checked);
        if (e.target.checked) {
            setFormData((last) => ({
                ...last,
                pActive: 1,
            }));
        } else {
            setFormData((last) => ({
                ...last,
                pActive: 0,
            }));
        }
    }
    const handlerSwitchCancel = (e) =>{
        setCheckControlCancel(e.target.checked);
            setFormData((last) => ({
                ...last,
                pNotifyCancel: e.target.checked,
            }));
       
    }
    const handlerSwitchClosing = (e) =>{
        setCheckControlClosing(e.target.checked);
        setFormData((last) => ({
            ...last,
            pNotifyClosing: e.target.checked,
        }));
    }
    /** Address Controls */
    const [newAddress, setNewAddress] = useState(false);
    const openNewAddress = () => {
        setNewAddress(true);
    };
    const closeNewAddress = () => {
        setNewAddress(false);
    };

    /** Account Controls */
    const [newAccount, setNewAccount] = useState(false);
    const openNewAccount = () => {
        setNewAccount(true);
    };
    const closeNewAccount = () => {
        setNewAccount(false);
    };

    const saveFunction = async (e) => {
        e.preventDefault();
        openLoading()
        await uptCompany(formData).then((response) => {
            if (response.status === 200) {
                closeLoading();
                dispatch({
                    type: "OPEN_SNACKBAR",
                    openMessage: {
                        open: true,
                        title: "Success",
                        message: response.data.output.pmensaje,
                        alert: "success",
                    },
                });
                getAllCompanies(dispatch);
                cancelFunction();
            } else {
                closeLoading();
                dispatch({
                    type: "OPEN_SNACKBAR",
                    openMessage: {
                        open: true,
                        title: "Error",
                        message: response.data.msg,
                        alert: "error",
                    },
                });
            }
        });
    };

    const cancelFunction = () => {
        closeDialog();
    };

    useEffect(() => {
        setFormData({
            pActive: data.Active,
            pIdCompany: data.IdCompany,
            pDescr: data.Desc,
            pComments: data.Comments,
            pCom: data.Com,
            pNotifyClosing: data.NotifyClosing,
            pNotifyCancel: data.NotifyCancel,
            pURL: data.URL,
            pPhone: data.Phone,
            pEIN: data.EIN,
            pIdAddress: data.IdAddress,
            pIdAccount: data.IdAccount,
            pEmailNotifica: data.EmailNotifica,
        });
        console.log("Data-->",data);
        setCheckControlActive(data.Active);
        setCheckControlCancel(data.NotifyCancel);
        setCheckControlClosing(data.NotifyClosing);
    }, [data]);
    const restoreFunction = () =>{
        setFormData({
            pActive: data.Active,
            pIdCompany: data.IdCompany,
            pDescr: data.Desc,
            pComments: data.Comments,
            pCom: data.Com,
            pNotifyClosing: data.NotifyClosing,
            pNotifyCancel: data.NotifyCancel,
            pURL: data.URL,
            pPhone: data.Phone,
            pEIN: data.EIN,
            pIdAddress: data.IdAddress,
            pIdAccount: data.IdAccount,
            pEmailNotifica: data.EmailNotifica,
        });
        setCheckControlActive(data.Active);
        setCheckControlCancel(data.NotifyCancel);
        setCheckControlClosing(data.NotifyClosing);
    }
    return (
     
            <form onSubmit={saveFunction}>
                    <Grid container spacing={2} justifyContent="center">
                        <Grid item sm={12} xs={12}>
                            <text
                                style={{
                                    fontSize: 16,
                                    fontWeight: "bold",
                                }}
                            >
                                Active
                            </text>
                            <LockSmithSwitch
                                checked={checkControlActive}
                                onChange={handlerSwitchActive}
                                name="pActive"
                                value={formData.pActive}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} justifyContent="center">
                        <Grid item sm={4} xs={12}>
                            <LockSmithTextField
                                label="Description*"
                                name="pDescr"
                                onChange={handleInputChange}
                                value={formData.pDescr}
                                size="small"
                                required={true}
                            />
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <LockSmithTextField
                                    label="Url"
                                    name="pURL"
                                    onChange={handleInputChange}
                                    value={formData.pURL}
                                    size="small"
                                />
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <LockSmithTextField
                                label="Phone*"
                                name="pPhone"
                                type="number"
                                onChange={handleInputChange}
                                value={formData.pPhone}
                                size="small"
                                required={true}
                            />
                           
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} justifyContent="center">
                        <Grid item sm={4} xs={12}>
                            <LockSmithTextField
                                label="Email"
                                name="pEmailNotifica"
                                onChange={handleInputChange}
                                value={formData.pEmailNotifica}
                                size="small"
                            />
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <LockSmithTextField
                                label="EIN"
                                name="pEIN"
                                onChange={handleInputChange}
                                value={formData.pEIN}
                                size="small"
                            />
                            
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <LockSmithTextField
                                label="Percentage Comission*"
                                labelWidth="70%"
                                name="pCom"
                                type="number"
                                maxValue={100}
                                onChange={handleInputChange}
                                value={formData.pCom}
                                size="small"
                                required={true}
                            />
                            
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} justifyContent="center">
                        <Grid item sm={11} xs={11} lg={3}>
                            <LockSmithSelect
                                name="pIdAddress"
                                value={formData.pIdAddress}
                                handler={handleInputChange}
                                data={allAddress}
                                label="Address*"
                                labelWidth="25%"
                                width="85%"
                                fields={addressFields}
                                required={true}
                            />
                        </Grid>
                        <Grid item sm={1} xs={1}>
                            <IconButton onClick={openNewAddress}>
                                <Add
                                    style={{
                                        marginTop: 28,
                                        marginLeft: -15,
                                    }}
                                    fontSize="large"
                                />
                            </IconButton>
                        </Grid>
                        <Grid item sm={11} xs={11} lg={3}>
                            <LockSmithSelect
                                name="pIdAccount"
                                value={formData.pIdAccount}
                                handler={handleInputChange}
                                data={allAccount}
                                label="Account*"
                                labelWidth="25%"
                                fields={accountFields}
                                required={true}
                            />
                        </Grid>
                        <Grid item sm={1} xs={1}>
                            <IconButton onClick={openNewAccount}>
                                <Add
                                    style={{
                                        marginTop: 28,
                                        marginLeft: -15,
                                    }}
                                    fontSize="large"
                                />
                            </IconButton>
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <LockSmithTextField
                                label="Comments"
                                multiline={true}
                                name="pComments"
                                onChange={handleInputChange}
                                rows={3}
                                value={formData.pComments}
                                size="small"
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} justifyContent="center">
                        <Grid item sm={4} xs={12}>
                            <text
                                style={{
                                    fontSize: 16,
                                    fontWeight: "bold",
                                }}
                            >
                                Notification cancel
                            </text>
                            <LockSmithSwitch
                                checked={checkControlCancel}
                                onChange={handlerSwitchCancel}
                                name="pNotifyCancel"
                                value={formData.pNotifyCancel}
                            />
                        </Grid>
                        
                        <Grid item sm={4} xs={12}>
                            <text
                                    style={{
                                        fontSize: 16,
                                        fontWeight: "bold",
                                    }}
                                >
                                    Notification closing
                                </text>
                                <LockSmithSwitch
                                    checked={checkControlClosing}
                                    onChange={handlerSwitchClosing}
                                    name="pNotifyClosing"
                                    value={formData.pNotifyClosing}
                                />
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            
                        </Grid>
                        
                        
                    </Grid>
                    <LockSmithComponentButtons closeHandler={restoreFunction} />

                    <Dialog
                        open={newAddress}
                        onClose={cancelFunction}
                        PaperProps={{ className: classes.dialog }}
                     >
                            <LoadingComponent openLoading={loading} />
                            <DialogTitle>
                                <Box className={classes.dialogTitle}>
                                    <Box
                                        style={{
                                            flexDirection: "row",
                                            display: "flex",
                                            width: "55%",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
            
                                        <text
                                            style={{
                                                fontSize: 26,
                                                fontWeight: "bold",
                                                marginLeft: 20,
                                            }}
                                        >
                                            Address
                                        </text>
                                    </Box>
                                </Box>
                            </DialogTitle>
                        <DialogContent>
                            <AddAddress openDialog={newAddress} closeDialog={closeNewAddress} />
                        </DialogContent>
                    </Dialog>

                    <Dialog
                        open={newAccount}
                        onClose={cancelFunction}
                        PaperProps={{ className: classes.dialog }}
                     >
                            <LoadingComponent openLoading={loading} />
                            <DialogTitle>
                                <Box className={classes.dialogTitle}>
                                    <Box
                                        style={{
                                            flexDirection: "row",
                                            display: "flex",
                                            width: "55%",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
            
                                        <text
                                            style={{
                                                fontSize: 26,
                                                fontWeight: "bold",
                                                marginLeft: 20,
                                            }}
                                        >
                                            Account
                                        </text>
                                    </Box>
                                </Box>
                            </DialogTitle>
                        <DialogContent>
                            <AddAccount openDialog={newAccount} closeDialog={closeNewAccount} />
                        </DialogContent>
                </Dialog>
                
            </form>
    );
};

export default UpdateCompany;
