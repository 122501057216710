import {
    Avatar,
    Box,
    Grid,
    IconButton
} from "@material-ui/core";
import { PhotoCamera } from "@material-ui/icons";
import moment from "moment";
import { PropTypes } from "prop-types";
import React, { useEffect, useState } from "react";
import {
    getAllServices,
    getPhoto,
    getSubCatService,
    uptService,
} from "../../Actions/UserAction";
import { dialogStyle } from "../../Assets/styles/Styles";
import { useStateValue } from "../../Context/store";
import { useForm } from "../../hooks/useForm";
import { LoadingComponent, useLoading } from "../../hooks/useLoading";
import LockSmithComponentButtons from "../ReusableComponents/LockSmithComponentButtons";
import LockSmithSelect from "../ReusableComponents/LockSmithSelect";
import LockSmithSwitch from "../ReusableComponents/LockSmithSwitch";
import LockSmithTextField from "../ReusableComponents/LockSmithTextField";
import ClassUptTab from "./TabViews/ClassUptTab";



const UpdateService = ({ openDialog, closeDialog, data }) => {
    const classes = dialogStyle();
    const [{ userAuth, allMoney, allTAX, allMUnit }, dispatch] = useStateValue();
    const { formData, handleInputChange, setFormData } = useForm();
    const { loading, openLoading, closeLoading } = useLoading();
    const [user, setUser] = useState([]);
    const [serviceClass, setServiceClass] = useState({});
    const moneyFields = ["IdMoney", "Descr"];
    const taxFields = ["IdTax", "Descr"];
    const munitFields = ["IdUMeasure", "Descr"];

    /* Images Handler */
    const [serviceImage, setServiceImage] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [images, setImages] = useState([]);
    const [selectedImages, setSelectedImages] = useState([]);

    const imgHandler = (e) => {
        setImages(e.target.files);
        if (e.target.files) {
            const fileArray = Array.from(e.target.files).map((file) =>
                window.URL.createObjectURL(file)
            );
            setSelectedImages(fileArray);
            Array.from(e.target.files).map((file) =>
                window.URL.revokeObjectURL(file)
            );
        }
    };

    const renderImages = (source) => {
        if (source.length !== 0) {
            return source.map((image, index) => {
                return (
                        <Avatar
                            src={image}
                            style={{
                                width: 80,
                                height: 80,
                            }}
                        />
                );
            });
        } else {
            if (serviceImage.length !== 0) {
                return serviceImage.map((item, index) => {
                    var path = item.Path;
                    return (
                            <Avatar
                                src={path}
                                style={{
                                    width: 80,
                                    height: 80,
                                }}
                            />
                    );
                });
            } else {
                return (
                    <>
                            <IconButton
                                aria-label="upload picture"
                                component="span"
                                style={{
                                    border: "solid 4px #3f4d67",
                                    backgroundColor: "#3f4d67",
                                }}
                                disabled
                            >
                                <PhotoCamera
                                    style={{
                                        fontSize: 80,
                                        color: "#fff",
                                        width: 80,
                                        height: 80,
                                    }}
                                />
                            </IconButton>
                        {/* <Grid item sm={4}>
                            <IconButton
                                aria-label="upload picture"
                                component="span"
                                style={{
                                    border: "solid 4px #232323",
                                    backgroundColor: "#FFD162",
                                }}
                                disabled
                            >
                                <PhotoCamera
                                    style={{
                                        fontSize: 80,
                                        color: "#232323",
                                    }}
                                />
                            </IconButton>
                        </Grid>
                        <Grid item sm={4}>
                            <IconButton
                                aria-label="upload picture"
                                component="span"
                                style={{
                                    border: "solid 4px #232323",
                                    backgroundColor: "#FFD162",
                                }}
                                disabled
                            >
                                <PhotoCamera
                                    style={{
                                        fontSize: 80,
                                        color: "#232323",
                                    }}
                                />
                            </IconButton>
                        </Grid> */}
                    </>
                );
            }
        }
    };

    const clearImages = () => {
        if(selectedImages.length > 0){
            setSelectedImages([]);
        }
        if(serviceImage.length > 0){
            setServiceImage([]);
        }
        setFormData({...formData, pPath: null});
    }



    /** Save Data  */
    const saveFunction = async (e) => {
        e.preventDefault();
        const data = JSON.stringify(formData)
        const dataForm = new FormData()
        dataForm.append("data", data)
        for(const key of Object.keys(images)) {
            dataForm.append("img", images[key])
        }
        openLoading();
        await uptService(dataForm).then((response) => {
            if (response.status === 200) {
                closeLoading();
                dispatch({
                    type: "OPEN_SNACKBAR",
                    openMessage: {
                        open: true,
                        title: "Success",
                        message: response.data.output.pmensaje,
                        alert: "success",
                    },
                });
                getAllServices(dispatch);
                closeDialog();
            } else {
                closeLoading();
                dispatch({
                    type: "OPEN_SNACKBAR",
                    openMessage: {
                        open: true,
                        title: "Error",
                        message: response.data.msg,
                        alert: "error",
                    },
                });
            }
        });
    };

    /** Cancel & Clean */
    const cancelFunction = () => {
        setFormData({
            pIdService: data.IdService,
            pDescr: data.descr,
            pIdPhoto: data.IdPhoto,
            pIdTax: data.IdTax,
            pIdMed: data.IdMed,
            pIdUser: data.IdUser,
            pIdSubCategory: data.IdSubCategory,
            pIdMon: data.IdMon,
            pType: data.Type,
            pCost: data.Cost,
            pActive: data.Active,
            pPath: data.PathPhoto,
        });
        setSelectedImages([]);
        setServiceImage([]);
        setImages([]);
    };

    /** Init Config */
    const photoData = async () => {
        if(data.IdPhoto != null){
        openLoading();
        await getPhoto({ pIdPhoto: data.IdPhoto }).then((response) => {
            if (response.status === 200) {
                closeLoading();
                setServiceImage(response.data);
            }else{
                //console.log(response);
                closeLoading();
            }
        });
        }
    };
    const catServiceData = async () => {
        setServiceClass();
        console.log("serviceData-->",data);
        await getSubCatService({ pidSubCategory: data.IdSubCategory }).then(
            (response) => {
                if (response.status === 200) {
                    console.log("serviceDataResp-->",response.data);
                    closeLoading();
                    setServiceClass(response.data);
                }
            }
        );
    };
    useEffect(() => {
        console.log(" Iniciando serviceData-->");
        setUser(userAuth ? userAuth.userAuth : []);
        catServiceData();
        setFormData({
            pIdService: data.IdService,
            pDescr: data.descr,
            pIdPhoto: data.IdPhoto,
            pIdTax: data.IdTax,
            pIdMed: data.IdMed,
            pIdUser: data.IdUser,
            pIdSubCategory: data.IdSubCategory,
            pIdMon: data.IdMon,
            pType: data.Type,
            pCost: data.Cost,
            pActive: data.Active,
            pPath: data.PathPhoto,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userAuth, data]);

    useEffect(() => {
        console.log("Photo");
        photoData();
    });

    return (
        <>
        <form onSubmit={saveFunction}>
            <Grid container spacing={2}>
                            <Grid item sm={12} xs={12} lg={4}> 
                                <text
                                        style={{
                                            fontSize: 14,
                                            fontWeight: "bold",
                                        }}
                                    >
                                        MOD DATE: {}
                                    </text>
                                    <text
                                        style={{
                                            fontSize: 14,
                                            fontWeight: "bold",
                                        }}
                                    >
                                        {moment().format("LLLL").toUpperCase()}
                                    </text><br></br>
                                    <text
                                style={{
                                    fontSize: 14,
                                    fontWeight: "bold",
                                }}
                                >
                                    User:
                                </text>
                                <text
                                    style={{
                                        fontSize: 14,
                                        fontWeight: "bold",
                                    }}
                                >
                                    {user.length !== 0 ? user[0].UserName : "USER"}
                                </text>
                            </Grid>
                            <Grid item sm={12} xs={12} lg={2}> 
                                
                                <text
                                        style={{
                                            fontSize: 14,
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Active
                                    </text>
                                    <LockSmithSwitch
                                        checked={formData.pActive}
                                        onChange={handleInputChange}
                                        name="pActive"
                                        value={formData.pActive}
                                        defaultValue={formData.pActive}
                                    />
                            </Grid>
                            <Grid item sm={12} xs={12} lg={3}>
                            <Box
                            style={{
                                alignItems: "center",
                                display: "flex",
                                justifyContent: "center",
                                marginTop: -15,
                                width: "98%",
                            }}
                        >
                            
                            <input
                                accept="image/*"
                                id="icon-button-file"
                                name="img"
                                type="file"
                                onChange={imgHandler}
                                hidden
                                multiple
                            />
                            <label htmlFor="icon-button-file">
                                <IconButton
                                    aria-label="upload picture"
                                    component="span"
                                    style={{
                                        border: "solid 4px #3f4d67",
                                        backgroundColor: "#3f4d67",
                                    }}
                                >
                                    <PhotoCamera
                                        style={{
                                            fontSize: 80,
                                            color: "#fff",
                                        }}
                                    />
                                </IconButton>
                            </label>
                            <div className='delete-icon' style={{zIndex:1000}}>
                            <input
                                //accept="image/*"
                                id="icon-button-trash"
                                name="img-trash"
                                type="button"
                                hidden
                            />
                            <label htmlFor="icon-button-trash">
                                <IconButton
                                    //aria-label="upload picture"
                                    //component="span"
                                    class="fas fa-trash-alt"
                                    onClick={clearImages}
                                    style={{
                                        cursor: "pointer",
                                        fontSize: "20px",
                                        marginTop: "120px",
                                    }}
                                    disableRipple
                                >

                                </IconButton>
                            </label>
                            </div>
                        </Box>
                            </Grid>
                            <Grid item sm={3} xs={3}>
                                {renderImages(selectedImages)}
                            </Grid>
            </Grid>
            
            
                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={4}>
                            <LockSmithTextField
                                        label="Type*"
                                        labelWidth="22%"
                                        name="pType"
                                        onChange={handleInputChange}
                                        value={formData.pType}
                                        required={true}
                                    />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                                 <LockSmithTextField
                                        label="Cost*"
                                        labelWidth="35%"
                                        name="pCost"
                                        onChange={handleInputChange}
                                        value={formData.pCost}
                                        required
                                    />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                                    <LockSmithSelect
                                        name="pIdMed"
                                        value={formData.pIdMed}
                                        handler={handleInputChange}
                                        data={allMUnit}
                                        label="Munit*"
                                        fields={munitFields}
                                        required
                                    />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                                    <LockSmithSelect
                                        name="pIdMon"
                                        value={formData.pIdMon}
                                        labelWidth="33%"
                                        handler={handleInputChange}
                                        data={allMoney}
                                        label="Money*"
                                        fields={moneyFields}
                                        required
                                    />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                                    <LockSmithSelect
                                        name="pIdTax"
                                        value={formData.pIdTax}
                                        handler={handleInputChange}
                                        data={allTAX}
                                        label="Tax*"
                                        fields={taxFields}
                                        required
                                    />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                                    <LockSmithTextField
                                        label="Description*"
                                        labelWidth="17%"
                                        multiline={true}
                                        name="pDescr"
                                        onChange={handleInputChange}
                                        rows={5}
                                        value={formData.pDescr}
                                        required
                                    />
                        </Grid>
                        <Grid item sm={4} xs={4}>
                            {/*<ClassUptTab
                                            handleClassData={handleInputChange}
                                            serviceData={serviceClass}
                                />*/}
                        </Grid>
                        
                        




                    </Grid>
                    <LockSmithComponentButtons closeHandler={cancelFunction} />
            </form>
            </>
    );
};

export default UpdateService;
