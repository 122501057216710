import {
  Box,
  Button,
  Grid,
  InputBase,
  MenuItem,
  Select,
  TextField,
  makeStyles,
} from "@material-ui/core";
import { styled } from "@material-ui/styles";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { getAllProduct, insProduct, userInfo } from "../../Actions/UserAction";
import Save from "@material-ui/icons/Save";
import Close from "@material-ui/icons/Close";

import ArrowDropDown from "@material-ui/icons/ArrowDropDown";

import { dialogStyle } from "../../Assets/styles/Styles";
import { useStateValue } from "../../Context/store";
import { useForm } from "../../hooks/useForm";
import { LoadingComponent, useLoading } from "../../hooks/useLoading";
import LockSmithSwitch from "../ReusableComponents/LockSmithSwitch";
import LockSmithTextField from "../ReusableComponents/LockSmithTextField";
import LockSmithSelect from "../ReusableComponents/LockSmithSelect";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    padding: "9px 2px",
  },
}));

const styleForm = makeStyles(() => ({
  root: {},
  borderForm: {
    border: "solid 1px rgba(0, 0, 0, 0.23) !important",
    width: "100%",
    borderRadius: 5,
  },
  textForm: {
    fontSize: 14,
    fontWeight: "400",
    color: "rgb(136,136,136)",
    width: "100%",
  },
  cardMargin: {
    margin: "10px 0",
  },
  subtabs: {
    boxShadow: "none",
  },
  subtabOption: {
    backgroundColor: "transparent !important",
  },
  hide: {
    display: "none",
  },
}));

const AddProduct = ({ openDialog, closeDialog }) => {
  const classes = dialogStyle();
  const classesForm = styleForm();
  const [
    {
      allMoney,
      allTAX,
      allBrand,
      allClasses,
      allCategory,
      allSubCat,
      allMUnit,
      allYear,
      userAuth,
    },
    dispatch,
  ] = useStateValue();
  const { formData, handleInputChange, setFormData } = useForm({
    pSerie: false,
  });
  const measureFields = ["IdUMeasure", "Descr"];
  const brandFields = ["IdBrand", "Descr"];
  const classFields = ["IdClass", "Descr"];
  const categoryFields = ["IdCategory", "Descr"];
  const subCatFields = ["IdSubCategory", "Descr"];
  const yearFields = ["IdYear", "Year"];
  const taxFields = ["IdTax", "Descr"];
  const moneyFields = ["IdMoney", "Descr"];
  const [user, setUser] = useState([]);
  const { loading, openLoading, closeLoading } = useLoading();

  /* Category Controls */
  const [catSelect, setCatSelect] = useState(true);
  const getCategoryData = (e) => {
    setCatSelect(false);
  };

  /* SubCategory Controls */
  const [subCatSelect, setSubCatSelect] = useState(true);
  const getSubCatData = (e) => {
    setSubCatSelect(false);
  };

  const saveFunction = async (e) => {
    e.preventDefault();
    openLoading();
    await insProduct(formData).then((response) => {
      if (response.status === 200) {
        closeLoading();
        dispatch({
          type: "OPEN_SNACKBAR",
          openMessage: {
            open: true,
            title: "Success",
            message: response.data.output.pmensaje,
            alert: "success",
          },
        });
        getAllProduct(dispatch);
        cancelFunction();
      } else {
        closeLoading();
        dispatch({
          type: "OPEN_SNACKBAR",
          openMessage: {
            open: true,
            title: "Error",
            message: response.data.msg,
            alert: "error",
          },
        });
      }
    });
  };

  const cancelFunction = () => {
    setFormData({ pSerie: false });
    Array.from(document.querySelectorAll("form input")).forEach(
      (input) => (input.value = "")
    );
  };

  useEffect(() => {
    setUser(userAuth ? userAuth.userAuth : []);
  }, [userAuth]);

  return (
    <form onSubmit={saveFunction}>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={8}>
          <text
            style={{
              fontSize: 14,
              fontWeight: "bold",
            }}
          >
            MOD DATE: {}
          </text>
          <text
            style={{
              fontSize: 14,
              fontWeight: "bold",
            }}
          >
            {moment().format("LLLL").toUpperCase()}
          </text>
        </Grid>
        <Grid item xs={12} lg={4}>
          <text
            style={{
              fontSize: 14,
              fontWeight: "bold",
            }}
          >
            USER:
          </text>
          <text
            style={{
              fontSize: 14,
              fontWeight: "bold",
            }}
          >
            {user.length !== 0 ? user[0].UserName : "USER"}
          </text>
        </Grid>
        <Grid item xs={12} lg={4}>
          <LockSmithTextField
            label="Cve Product*"
            labelWidth="23%"
            name="pCveProd"
            onChange={handleInputChange}
            value={formData.pCveProd}
            required={true}
          />
        </Grid>
        <Grid item xs={12} lg={1}>
          <text className={classesForm.textForm}>Serial Number</text>
          <LockSmithSwitch
            checked={formData.pSerie}
            onChange={handleInputChange}
            name="pSerie"
            value={formData.pSerie}
          />
        </Grid>
        <Grid item xs={12} lg={7}>
          <LockSmithTextField
            label="Description*"
            labelWidth="17%"
            multiline={true}
            name="pDescr"
            onChange={handleInputChange}
            rows={5}
            value={formData.pDescr}
            required={true}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <LockSmithTextField
            label="Cost*"
            labelWidth="44%"
            name="pCost"
            onChange={handleInputChange}
            value={formData.pCost}
            required={true}
            type="number"
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <LockSmithSelect
            label="Money*"
            labelWidth="30%"
            name="pidMon"
            value={formData.pidMon}
            valueId="pidMon"
            data={allMoney}
            handler={handleInputChange}
            fields={moneyFields}
            required={true}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <LockSmithSelect
            label="Tax*"
            labelWidth="30%"
            name="pidTax"
            value={formData.pidTax}
            valueId="pidTax"
            data={allTAX}
            handler={handleInputChange}
            fields={taxFields}
            required={true}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <text className={classesForm.textForm}>Brand*</text>
          <Select
            fullWidth
            IconComponent={() => (
              <div
                style={{
                  width: 20,
                  height: 20,
                  color: "rgb(136,136,136)",
                  marginLeft: -30,
                  marginTop: -5,
                }}
              >
                <ArrowDropDown />
              </div>
            )}
            id="pidBrand"
            input={<BootstrapInput />}
            label="Select BRAND"
            name="pidBrand"
            onChange={handleInputChange}
            className={classesForm.borderForm}
            value={formData.pidBrand}
            variant="outlined"
            defaultValue={formData.pidBrand}
            required={true}
          >
            {allBrand
              ? allBrand.brand.map((item, index) => {
                  return (
                    <MenuItem
                      key={index}
                      style={{
                        justifyContent: "center",
                      }}
                      value={item.IdBrand}
                    >
                      {item.Descr}
                    </MenuItem>
                  );
                })
              : ""}
          </Select>
        </Grid>
        <Grid item xs={12} lg={4}>
          <text className={classesForm.textForm}>Year*</text>
          <Select
            IconComponent={() => (
              <div
                style={{
                  width: 20,
                  height: 20,
                  color: "rgb(136,136,136)",
                  marginLeft: -30,
                  marginTop: -5,
                }}
              >
                <ArrowDropDown />
              </div>
            )}
            id="pidAnio"
            input={<BootstrapInput />}
            label="Select YEAR"
            name="pidAnio"
            onChange={handleInputChange}
            className={classesForm.borderForm}
            value={formData.pidAnio}
            variant="outlined"
            defaultValue={formData.pidAnio}
            required={true}
          >
            {allYear
              ? allYear.year.map((item, index) => {
                  return (
                    <MenuItem
                      key={index}
                      style={{
                        justifyContent: "center",
                      }}
                      value={item.IdYear}
                    >
                      {item.Year}
                    </MenuItem>
                  );
                })
              : ""}
          </Select>
        </Grid>
        <Grid item xs={12} lg={4}>
          <text className={classesForm.textForm}>Code bar</text>
          <TextField
            fullWidth
            name="pCodeBar"
            style={{
              backgroundColor: "#fff",
              borderRadius: 5,
            }}
            variant="outlined"
            type="number"
            value={formData.pCodeBar}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <text className={classesForm.textForm}>Lot</text>
          <TextField
            fullWidth
            name="pLote"
            style={{
              backgroundColor: "#fff",
              borderRadius: 5,
            }}
            variant="outlined"
            value={formData.pLote}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <text className={classesForm.textForm}>Model*</text>
          <TextField
            fullWidth
            name="pModel"
            style={{
              backgroundColor: "#fff",
              borderRadius: 5,
            }}
            variant="outlined"
            value={formData.pModel}
            onChange={handleInputChange}
            required={true}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <text className={classesForm.textForm}>Measure*</text>
          <Select
            className={classesForm.borderForm}
            IconComponent={() => (
              <div
                style={{
                  width: 20,
                  height: 20,
                  color: "rgb(136,136,136)",
                  marginLeft: -30,
                  marginTop: -5,
                }}
              >
                <ArrowDropDown />
              </div>
            )}
            id="pidMed"
            input={<BootstrapInput />}
            //label="Select ID MEASURE"
            name="pidMed"
            onChange={handleInputChange}
            value={formData.pidMed}
            variant="outlined"
            defaultValue={formData.pidMed}
            required={true}
          >
            {allMUnit
              ? allMUnit.munit.map((item, index) => {
                  return (
                    <MenuItem
                      key={index}
                      style={{
                        justifyContent: "center",
                      }}
                      value={item.IdUMeasure}
                    >
                      {item.Descr}
                    </MenuItem>
                  );
                })
              : ""}
          </Select>
        </Grid>
        <Grid item xs={12} lg={4}>
          <text className={classesForm.textForm}>Dimension</text>
          <TextField
            fullWidth
            name="pDimension"
            style={{
              backgroundColor: "#fff",
              borderRadius: 5,
            }}
            variant="outlined"
            value={formData.pDimension}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <text className={classesForm.textForm}>Weight</text>
          <TextField
            fullWidth
            name="pWeight"
            style={{
              backgroundColor: "#fff",
              borderRadius: 5,
            }}
            variant="outlined"
            type="number"
            value={formData.pWeight}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12} lg={4}></Grid>
        <Grid item xs={12} lg={4}>
          <text className={classesForm.textForm}>Class</text>
          <Select
            name="pidClass"
            id="pidClass"
            className={classesForm.borderForm}
            IconComponent={() => (
              <div
                style={{
                  width: 20,
                  height: 20,
                  color: "rgb(136,136,136)",
                  marginLeft: -30,
                  marginTop: -5,
                }}
              >
                <ArrowDropDown />
              </div>
            )}
            variant="outlined"
            input={<BootstrapInput />}
            value={formData.pidClass}
            onChange={(e) => {
              handleInputChange(e);
              getCategoryData(e);
            }}
          >
            {allClasses?.class
              ? allClasses.class.map((item, index) => {
                  return (
                    <MenuItem
                      key={index}
                      style={{
                        justifyContent: "center",
                      }}
                      value={item.IdClass}
                    >
                      {item.IdClass} - {item.Descr}
                    </MenuItem>
                  );
                })
              : ""}
          </Select>
        </Grid>
        <Grid item xs={12} lg={4}>
          <text className={classesForm.textForm}>Category</text>
          <Select
            name="pidCategory"
            className={classesForm.borderForm}
            IconComponent={() => (
              <div
                style={{
                  width: 20,
                  height: 20,
                  color: "rgb(136,136,136)",
                  marginLeft: -30,
                  marginTop: -5,
                }}
              >
                <ArrowDropDown />
              </div>
            )}
            variant="outlined"
            input={<BootstrapInput />}
            disabled={catSelect}
            value={formData.pidCategory}
            onChange={(e) => {
              handleInputChange(e);
              getSubCatData(e);
            }}
          >
            {allCategory?.category &&
              allCategory.category.map((item, index) => {
                if (item.IdClass === formData.pidClass) {
                  return (
                    <MenuItem
                      key={index}
                      style={{
                        justifyContent: "center",
                      }}
                      value={item.IdCategory}
                    >
                      {item.IdCategory} - {item.Descr}
                    </MenuItem>
                  );
                }
              })}
          </Select>
        </Grid>
        <Grid item xs={12} lg={4}>
          <text className={classesForm.textForm}>Subcategory*</text>
          <Select
            name="pidSubCategory"
            className={classesForm.borderForm}
            IconComponent={() => (
              <div
                style={{
                  width: 20,
                  height: 20,
                  color: "rgb(136,136,136)",
                  marginLeft: -30,
                  marginTop: -5,
                }}
              >
                <ArrowDropDown />
              </div>
            )}
            variant="outlined"
            input={<BootstrapInput />}
            disabled={subCatSelect}
            value={formData.pidSubCategory}
            onChange={handleInputChange}
            required
          >
            {allSubCat?.subcat &&
              allSubCat.subcat.map((item, index) => {
                if (item.IdCategory === formData.pidCategory) {
                  return (
                    <MenuItem
                      key={index}
                      style={{
                        justifyContent: "center",
                      }}
                      value={item.IdSubCategory}
                    >
                      {item.IdSubCategory} - {item.Descr}
                    </MenuItem>
                  );
                }
              })}
          </Select>
        </Grid>
      </Grid>

      <Grid container spacing={2} justifyContent="flex-end">
        <Grid item xs={6} sm={6} lg={3}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            type="submit"
            classes={{ root: classes.saveButton }}
            size="large"
            endIcon={
              <div
                style={{
                  width: 20,
                  height: 20,
                  marginTop: -10,
                  marginLeft: 10,
                }}
              >
                <Save />
              </div>
            }
          >
            Save
          </Button>
        </Grid>
        <Grid item xs={6} sm={6} lg={3}>
          <Button
            fullWidth
            variant="contained"
            onClick={cancelFunction}
            classes={{ root: classes.cancelButton }}
            size="large"
            endIcon={
              <div
                style={{
                  width: 20,
                  height: 20,
                  marginTop: -10,
                  marginLeft: 10,
                }}
              >
                <Close />
              </div>
            }
          >
            Reset
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default AddProduct;
