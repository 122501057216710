import {
    Button,
    FormControlLabel,
    FormGroup,
    Grid,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { getAllTax, uptTax } from "../../Actions/UserAction";
import { useStateValue } from "../../Context/store";

/**Import Icons */
import Save from "@material-ui/icons/Save";
import Close from "@material-ui/icons/Close";
import LockSmithSwitch from "../ReusableComponents/LockSmithSwitch";
import LocksmithEntryId from "../ReusableComponents/LockSmithEntryId";
import LockSmithTextField from "../ReusableComponents/LockSmithTextField";
/** Theme & Styles Import */
import { dialogStyle } from "../../Assets/styles/Styles";

const UpdateTAX = ({ openDialog, closeDialog, data }) => {
    const classes = dialogStyle();
    const [openBackdrop, setOpenBackdrop] = useState(false);
    // eslint-disable-next-line no-empty-pattern
    const [{}, dispatch] = useStateValue();
    const [TAXData, setTAXData] = useState({});
    const [active, setActive] = useState(false);
 
    const dataValues = (e) => {
        const { name } = e.target;
        const value = e.target.value;
        setTAXData((last) => ({
            ...last,
            [name]: value,
        }));

    };
    const changeSwitch = (e) => {
        setActive(e.target.checked);
        if (e.target.checked) {
            setTAXData((last) => ({
                ...last,
                pActive: 1,
            }));
        } else {
            setTAXData((last) => ({
                ...last,
                pActive: 0,
            }));
        }
       
    };

    const saveFunction = async (e) => {
        e.preventDefault();
        setOpenBackdrop(true);
        await uptTax(TAXData).then((response) => {
            if (response.status === 200) {
                setOpenBackdrop(false);
                dispatch({
                    type: "OPEN_SNACKBAR",
                    openMessage: {
                        open: true,
                        title: "Success",
                        message: response.data.output.pmensaje,
                        alert: "success",
                    },
                });
                getAllTax(dispatch);
                closeDialog();
            } else {
                setOpenBackdrop(false);
                dispatch({
                    type: "OPEN_SNACKBAR",
                    openMessage: {
                        open: true,
                        title: "Error",
                        message: response.data.msg,
                        alert: "error",
                    },
                });
            }
        });
    };

    useEffect(() => {
        setTAXData({
            pIdTax: data.IdTax,
            pDescr: data.Descr,
            pPorcentaje: data.Porcentaje,
            pActive: data.Active,
        });
        setActive(data.Active);
    }, [data]);

    const resetForm = () =>{
        setTAXData({
            pIdTax: data.IdTax,
            pDescr: data.Descr,
            pPorcentaje: data.Porcentaje,
            pActive: data.Active,
        });
        setActive(data.Active);
    }
    return (
            <form onSubmit={saveFunction}>
                    <Grid
                        container
                        spacing={2}
                        alignContent="center"
                        justifyContent="center"
                    >
                        <Grid item sm={12} xs={12}>
                            <LocksmithEntryId
                                label="ID Tax"
                                id={TAXData.pIdTax}
                            />
                        </Grid>
                    </Grid>           
                    <Grid
                        container
                        spacing={2}
                        alignContent="center"
                        justifyContent="center"
                    >
                        <Grid item sm={6} xs={6}>
                            <LockSmithTextField
                                label="Description"
                                labelWidth="32%"
                                name="pDescr"
                                value={TAXData.pDescr}
                                onChange={dataValues}
                                required
                            />
                        </Grid>  
                        <Grid item sm={6} xs={6}>
                            <LockSmithTextField
                                label="Percentage"
                                labelWidth="32%"
                                name="pPorcentaje"
                                value={TAXData.pPorcentaje}
                                onChange={dataValues}
                                required
                            />
                        </Grid>
                    </Grid>       
                    <Grid
                        container
                        spacing={2}
                        alignContent="center"
                        justifyContent="center"
                    >
                        <Grid item sm={3} xs={3}>
                            <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <LockSmithSwitch
                                                checked={active}
                                                onChange={changeSwitch}
                                                name="pActive"
                                                value={TAXData.pActive}
                                            />
                                        }
                                        label="Active"
                                        labelPlacement="start"
                                    />
                                </FormGroup>
                        </Grid>
                        <Grid item sm={9} xs={9}></Grid>
                    </Grid>
                    <Grid
                        container
                        spacing={2}
                        alignContent="center"
                        justifyContent="center"
                    >
                        <Grid item sm={6} xs={6}></Grid>      
                        <Grid item sm={3} xs={3}>
                                <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                classes={{ root: classes.saveButton }}
                                size="large"
                                fullWidth
                                endIcon={
                                    <div
                                        style={{
                                            width: 20,
                                            height: 20,
                                            marginTop: -14,
                                            marginLeft: 10,
                                        }}
                                    >
                                        <Save/>
                                    </div>
                                }
                            >
                                Save
                            </Button>
                        </Grid>
                        <Grid item sm={3} xs={3}>
                                <Button
                                variant="contained"
                                onClick={resetForm}
                                classes={{ root: classes.cancelButton }}
                                size="large"
                                fullWidth
                                endIcon={
                                    <div
                                        style={{
                                            width: 20,
                                            height: 20,
                                            marginTop: -14,
                                            marginLeft: 10,
                                        }}
                                    >
                                        <Close/>
                                    </div>
                                }
                            >
                                Reset
                            </Button>
                        </Grid>
                    </Grid>
            </form>
    );
};

export default UpdateTAX;
