import {
    Grid,
} from "@material-ui/core";
import React,{ useState, useEffect } from 'react'
import DropDownMenu from "../../Utilities/DropDownMenu"
import { useStateValue } from "../../Context/store";
import { makeStyles } from '@material-ui/core/styles';
//import AddAddress from "../Address/AddAddress";

const useStyles = makeStyles({
    root: {
    },
    textForm: {
        paddingLeft:10,
        fontSize: 14, 
        fontWeight: "400", 
        color:"rgb(136,136,136)"  
    },
    borderForm:{
        border:"solid 1px rgba(0, 0, 0, 0.23) !important",
        padding:"9px !important",
        width:"95% !important"
    }


  });
const Contact = ({ inputHandler, data, addressData, clickHandler, callback}) => {
//console.log("employee data in Contact",data)
    const classes = useStyles();
    const [{ allAddress }] = useStateValue();
    const [filteredAddress, setFilteredAddress] = useState({address:[]});
    const addressFields = ["IdAddress", "Street"]

/* 
    const [contactData,setContactData] = useState({
        phoneNumber:"",
        email:"",
        idAddress:"",
    }) */
/* 
    const handleInputChange = (e) => {
        console.log(e)
        setContactData({
            ...data,
            [e.target.name]:e.target.value
        })
    } */

    const filterAddressData = (data) => {
        let filteredData = data[Object.keys(data)].filter((element, i) => 
        data[Object.keys(data)].findIndex((s) => element.IdType === s.IdType) === i)
        //console.log("filteredData: ", filteredData)
    
        setFilteredAddress({address:[filteredData]});
    }


/*     useEffect(() => {
        onAddingContactData(contactData)
    },[contactData]) */

    useEffect(() => {
        callback()
    },[])

    useEffect(() => {
        if(addressData.address.length > 0){
            filterAddressData(addressData)
        }
        
    },[addressData])
    
/*     useEffect(() => {
        if(data)
            setContactData({
                phoneNumber:data.phonenumber,
                email:data.email,
                idAddress:data.idAddress
            })
    } ,[data]) */

    return (
    <>
            <Grid
                container
                spacing={2}
                alignContent="center"
                justifyContent="center"
            >
                <Grid item xs={12} lg={4}>
                        <text className={classes.textForm}>Phone*</text>
                        <input  
                            className={classes.borderForm}
                            onChange={inputHandler} 
                            name="pPhone" 
                            value={data.pPhone}
                            type="number"
                            required
                        />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <text className={classes.textForm}>Email</text>
                        <input 
                            className={classes.borderForm}
                            onChange={inputHandler}
                            name="pEmail" 
                            value={data.pEmail}
                        />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <DropDownMenu
                        label="Address*"
                        showAddBtn={true}
                        options={addressData}
                        optionFields={addressFields}
                        name="pIdAddress"
                        onDropDownChange={inputHandler}
                        value={data.pIdAddress}
                        onAddBtnClick={clickHandler}
                        //onInvertColors={onInvertColors}
                        required={true}
                        />
                    </Grid>
           
            </Grid>  
        


    </>
  )
}

export default Contact