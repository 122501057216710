export const initialState = {
    levelskill: [],
};

const allLevelSkillReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_LEVELSKILL":
            return {
                ...state,
                levelskill: action.levelskill,
            };

        default:
            return state;
    }
};

export default allLevelSkillReducer;
