import axios from "axios";
import HttpClient from "../Services/HttpClient";

const instancia = axios.create();
instancia.CancelToken = axios.CancelToken;

instancia.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        console.log("error", error);
        if (
            error.response?.status === 401 ||
            error.response?.status === 403
        ) {
            localStorage.removeItem("token");
            window.location.reload();
        } else if (
            error.response?.status === 500 ||
            error.response?.status === 404 ||
            error.response?.status === 400
        ){
            return error.response;
        }
        return error;
    }
);
instancia.interceptors.request.use(function (config) {
    const token = localStorage.getItem("token");
    //   console.log("token");
    if(token === undefined || token === null) return config;
    config.headers.Authorization = "Bearer " + token;

    return config;
}); 
// instancia.defaults.er
instancia.isCancel = axios.isCancel;

//************* LOGIN Actions *******************/
export const login = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .post("/auth/login", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

//************* Skill CRUD Actions *******************/

/** Get all skills */
export const getAllSkills = async (dispatch) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .get("/skill/skills")
                .then((response) => {
                    dispatch({
                        type: "GET_SKILLS",
                        skills: response.data,
                    });
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

/** Add new skill */
export const addSkill = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .post("/skill/addskill", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

/** Update skill */
export const updateSkill = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .put("/skill/modifyskill", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

/** Delete skill */
export const deleteSkill = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .put("/skill/deleteskill", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

//************* Level CRUD Actions ******************/

/** Get all levels */
export const getAllLevels = async (dispatch) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .get("/level/levels")
                .then((response) => {
                    dispatch({
                        type: "GET_LEVELS",
                        levels: response.data,
                    });
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

/** List all levelSkill */
export const getAllLevelsNotSkill = async () => {
    try {
        return await new Promise((resolve) => {
            instancia
                .get("/level/noskill")
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

/** Add new level */
export const addLevel = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .post("/level/addlevel", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

/** Update level */
export const updateLevel = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .put("/level/modifylevel", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

/** Delete level */
export const deleteLevel = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .put("/level/deletelevel", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

//************* Employee CRUD Actions ****************/

/** List all Employee */
export const getAllEmployee = async (dispatch) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .get("/employee/all")
                .then((response) => {
                    dispatch({
                        type: "GET_EMPLOYEE",
                        employee: response.data,
                    });
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

/** List all Active Employee */
export const getAllActiveEmployees = async () => {
    try {
        return await new Promise((resolve) => {
            instancia
                .get("/employee/all-active")
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

/** List all Tech and Admin Jobs */
export const getEmployeeJobs = async () => {
    try {
        return await new Promise((resolve) => {
            instancia
                .get("/employee/empolyee-jobs")
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

/** Add new Employee */
export const addEmployee = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .post("/employee/insert", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

/** Update Employee */
export const updateEmployee = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .put("/employee/update", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

/** Delete Employee */
export const deleteEmployee = async (id) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .post("/employee/delete", id)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

/** Insert Employee Photo*/
export const uploadImageEmp = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .post("/employee/upload-image", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

//************* TypeEmp CRUD Actions ****************/

/** List all typeEmp */
export const getAllTypeEmp = async (dispatch) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .get("/typeemp/all")
                .then((response) => {
                    dispatch({
                        type: "GET_TYPEEMP",
                        typeemp: response.data,
                    });
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

/** List all typeEmp not in ProfileDoc */
export const getAllTypeEmpNotProfDoc = async () => {
    try {
        return await new Promise((resolve) => {
            instancia
                .get("/typeemp/notprofdoc")
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

/** Add new typeEmp */
export const addTypeEmp = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .post("/typeemp/add", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

/** Update typeEmp */
export const updateTypeEmp = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .put("/typeemp/update", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

/** Delete typeEmp */
export const deleteTypeEmp = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .put("/typeemp/delete", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

//************* LevelSkill CRUD Actions *************/

/** List all levelSkill */
export const getAllLevelSkill = async (dispatch) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .get("/levelskill/all")
                .then((response) => {
                    dispatch({
                        type: "GET_LEVELSKILL",
                        levelskill: response.data,
                    });
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};


/** Add new levelSkill */
export const addLevelSkill = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .post("/levelskill/add", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

/** Delete levelSkill */
export const deleteLevelSkill = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .post("/levelskill/delete", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

//************* Document_E CRUD Actions *************/

//** Get all Document_E */
export const getAllDocumentE = async (dispatch) => {
    try {
        return await new Promise((resolve) => {
            instancia.get("/documente/all").then((response) => {
                dispatch({
                    type: "GET_DOCUMENT_E",
                    documentE: response.data,
                });
                resolve(response);
            });
        });
    } catch (error) {
        console.log(error);
    }
};

//** Insert new Document_E */
export const insDocumentE = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .post("/documente/insertdocumente", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

//** Update Document_E */
export const uptDocumentE = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .put("/documente/uptdocumente", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

//** Delete Document_E */
export const deleteDocumentE = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .post("/documente/delete", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

//************* Document_C CRUD Actions *************/

//** Get all Document_C */
export const getAllDocumentC = async (dispatch) => {
    try {
        return await new Promise((resolve) => {
            instancia.get("/documentc/all").then((response) => {
                dispatch({
                    type: "GET_DOCUMENT_C",
                    documentc: response.data,
                });
                resolve(response);
            });
        });
    } catch (error) {
        console.log(error);
    }
};

//** Get all Document_C by CustomerID */
export const getAllCustomerDocumentsByCustId = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .get("/documentc-customer/allByCustomer",{
                    params: {
                        pIdCustomer: data,
                    }
                }).then((response) => {
                resolve(response);
            });
        });
    } catch (error) {
        console.log(error);
    }
};

//** Insert new Document_C */
export const insDocumentC = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .post("/documentc/insertdocumente", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

//** Update Document_C */
export const uptDocumentC = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .put("/documentc/uptdocumente", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

//** Delete Document_C */
export const deleteDocumentC = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .post("/documentc/delete", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

//************* ProfileDoc CRUD Actions *******************/

//** Get all ProfileDoc */
export const getAllProfileDoc = async (dispatch) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .get("/profiledoc/all")
                .then((response) => {
                    dispatch({
                        type: "GET_PROFILEDOC",
                        profiledoc: response.data,
                    });
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

/** Insert ProfileDoc */
export const insProfileDoc = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .post("/profiledoc/insert", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

/** Delete ProfileDoc */
export const deleteProfileDoc = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .post("/profiledoc/delete", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

//************* Branch CRUD Actions *******************/

/** Get All Branch */
export const getAllBranch = async (dispatch) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .get("/branch/all")
                .then((response) => {
                    dispatch({
                        type: "GET_BRANCH",
                        branch: response.data,
                    });
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

//** Insert Branch */
export const insBranch = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .post("/branch/insert", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

//** Update Branch */
export const uptBranch = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .put("/branch/update", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

//** Delete Branch */
export const deleteBranch = async (data) => {
    try {
        console.log(data);
        return await new Promise((resolve) => {
            instancia
                .post("/branch/delete", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

//************* Role CRUD Actions *******************/

//** Get All Roles */
export const getAllRoles = async (dispatch) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .get("/role/all")
                .then((response) => {
                    dispatch({
                        type: "GET_ROLE",
                        role: response.data,
                    });
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

//** Insert Role */
export const insRole = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .post("/role/insert", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

//** Update Role */
export const uptRole = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .put("/role/update", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

//** Delete Role */
export const deleteRole = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .post("/role/delete", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

//************* Bank CRUD Actions *******************/

//** Get all banks */
export const getAllBanks = async (dispatch) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .get("/bank/all")
                .then((response) => {
                    dispatch({
                        type: "GET_BANK",
                        bank: response.data,
                    });
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

//** Insert Bank */
export const insBank = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .post("/bank/insert", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

//** Update Bank */
export const uptBank = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .put("/bank/update", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

//** Delete Bank */
export const deleteBank = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .post("/bank/delete", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

//************* Jobs CRUD Actions *******************/

//** Get all jobs */
export const getAllJobs = async (dispatch) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .get("/jobs/all")
                .then((response) => {
                    dispatch({
                        type: "GET_JOBS",
                        jobs: response.data,
                    });
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

//** Create new job */
export const insJob = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .post("/jobs/insert", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

//** Update selected job */
export const updateJob = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .put("/jobs/update", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

//** Delete selected job */
export const deleteJob = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .post("/jobs/delete", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

//************* JobsType CRUD Actions *******************/

//** Get all jobs Types */
export const getAllJobTypes = async (dispatch) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .get("/jobtype/all")
                .then((response) => {
                    dispatch({
                        type: "GET_JOBTYPE",
                        jobtypes: response.data,
                    });
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

//********************+ Technician CRUD Actions ***********************/

//** Get all technicians */
export const getAllTechnicians = async (dispatch) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .get("/technician/all")
                .then((response) => {
                    dispatch({
                        type: "GET_TECHNICIAN",
                        technician: response.data,
                    });
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

//** Create new technician */
export const createNewTechnician = async (technician) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .post("/technician/create", technician)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

//** Edit selected technician */
export const editTechnician = async (id, tech) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .put("/technician/modify", { id, tech })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

//** Delete selected technician */
export const deleteTechnician = async (id) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .delete("/technician/delete/" + id)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

//********************+ AddressType CRUD Actions ***********************/

//** Get all AddressType */
export const getAllAddressType = async (dispatch) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .get("/addresstype/all")
                .then((response) => {
                    dispatch({
                        type: "GET_ADDRESSTYPE",
                        addresstype: response.data,
                    });
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

//** Get all AddressType by Class */
export const getAllAddressTypeByClass = async (id) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .get("/addresstype/allByClass", {
                    params: {
                        IdClass: id,
                    }
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};
//** Insert AddressType */
export const insAddressType = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .post("/addresstype/insert", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

//** Update AddressType */
export const uptAddressType = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .put("/addresstype/update", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

//** Delete AddressType */
export const deleteAddressType = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .post("/addresstype/delete", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

//********************+ Country CRUD Actions ***********************/

//** Get all Country */
export const getAllCountry = async (dispatch) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .get("/country/all")
                .then((response) => {
                    dispatch({
                        type: "GET_COUNTRY",
                        country: response.data,
                    });
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

//** Insert Country */
export const insCountry = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .post("/country/insert", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

//** Update Country */
export const uptCountry = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .put("/country/update", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

//** Delete Country */
export const deleteCountry = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .post("/country/delete", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

//********************+ State CRUD Actions ***********************/

//** Get all State */
export const getAllState = async (dispatch) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .get("/state/all")
                .then((response) => {
                    dispatch({
                        type: "GET_STATE",
                        states: response.data,
                    });
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

//** Get all State by Country*/
export const getAllStatesByCountry = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .get("/state/allByCountry", {
                    params: {
                        pIdCountry: data,
                    }
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

//** Insert State */
export const insState = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .post("/state/insert", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

//** Update State */
export const uptState = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .put("/state/update", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

//** Delete State */
export const deleteState = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .post("/state/delete", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

//********************+ Address CRUD Actions ***********************/

//** Get all Address */
export const getAllAddress = async (dispatch) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .get("/address/all")
                .then((response) => {
                    dispatch({
                        type: "GET_ADDRESS",
                        address: response.data,
                    });
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

//** Get all Address by ClassID*/
export const getAllAddressByClass = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .get("/address/allByClass", {
                    params: {
                        pIdClass: data,
                    }
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

//** Get all Address by TypeID*/
export const getAllAddressByType = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .get("/address/allByType", {
                    params: {
                        pIdType: data,
                    }
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

//** Insert Address */
export const insAddress = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .post("/address/insert", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

//** Update Address */
export const uptAddress = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .put("/address/update", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

//** Delete Address */
export const deleteAddress = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .post("/address/delete", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

//********************+ AddressTypeClass CRUD Actions ***********************/

//** Get all Address */
export const getAllATC = async (dispatch) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .get("/atc/all")
                .then((response) => {
                    dispatch({
                        type: "GET_ATC",
                        atc: response.data,
                    });
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};



//******************** User CRUD Actions ***********************/

/** Get all users */
export const getAllUser = async (dispatch) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .get("/user/all")
                .then((response) => {
                    dispatch({
                        type: "GET_USER",
                        user: response.data,
                    });
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

/** User Info */
export const userInfo = async (dispatch) => {
    try {
        return await new Promise((resolve) => {
            HttpClient.get("/user/get")
                .then((response) => {
                    dispatch({
                        type: "USER_AUTH",
                        userAuth: response.data,
                    });
                    resolve(response);
                })
                .catch((err) => {
                    resolve(err.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

/** Insert new user */
export const insUser = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .post("/user/insert", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

/** Update User */
export const uptUser = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .put("/user/update", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

/** Delete User */
export const delUser = async (id) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .post("/user/delete", id)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

//******************** Money CRUD Actions ***********************/

/** Get all money */
export const getAllMoney = async (dispatch) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .get("/money/all")
                .then((response) => {
                    dispatch({
                        type: "GET_MONEY",
                        money: response.data,
                    });
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

/** Insert Money */
export const insMoney = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .post("/money/insert", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

/** Update Money */
export const uptMoney = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .put("/money/update", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

/** Delete Money */
export const delMoney = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .post("/money/delete", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

//******************** Brand CRUD Actions ***********************/

/** Get all Brand */
export const getAllBrand = async (dispatch) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .get("/brand/all")
                .then((response) => {
                    dispatch({
                        type: "GET_BRAND",
                        brand: response.data,
                    });
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

/** Insert Brand */
export const insBrand = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .post("/brand/insert", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

/** Update Brand */
export const uptBrand = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .put("/brand/update", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

/** Delete Brand */
export const delBrand = async (id) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .post("/brand/delete", id)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

//******************** Category CRUD Actions ***********************/

/** Get all Category */
export const getAllCategory = async (dispatch) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .get("/category/all")
                .then((response) => {
                    dispatch({
                        type: "GET_CATEGORY",
                        category: response.data,
                    });
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

/** Get category  */
export const getCategory = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .get("/category/get", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    resolve(err.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

/** Insert Category */
export const insCategory = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .post("/category/insert", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

/** Update Category */
export const uptCategory = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .put("/category/update", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

/** Delete Category */
export const delCategory = async (id) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .post("/category/delete", id)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

//******************** Tax CRUD Actions ***********************/

/** Get all Tax */
export const getAllTax = async (dispatch) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .get("/tax/all")
                .then((response) => {
                    dispatch({
                        type: "GET_TAX",
                        tax: response.data,
                    });
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

/** Insert Tax */
export const insTax = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .post("/tax/insert", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

/** Update Tax */
export const uptTax = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .put("/tax/update", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

/** Delete Tax */
export const delTax = async (id) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .post("/tax/delete", id)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

//******************** Product CRUD Actions ***********************/

/** Get all Product */
export const getAllProduct = async (dispatch) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .get("/product/all")
                .then((response) => {
                    dispatch({
                        type: "GET_PRODUCT",
                        product: response.data,
                    });
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

/** Insert Product */
export const insProduct = async (data) => {
    try {
        return await new Promise((resolve) => {
            HttpClient.post("/product/insert", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

/** Update Product */
export const uptProduct = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .put("/product/update", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

/** Delete Product */
export const delProduct = async (id) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .post("/product/delete", id)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};
//* Price Product */

/* Get Price Product by IdProduct */
export const getPriceProduct = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .get("/product/pget", {
                    params: {
                        pIdProduct: data.pIdProduct,
                    }
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    resolve(err.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

/* Insert Price Product */
export const insPriceProduct = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .post("/product/pinsert", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    resolve(err.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

//** Product Photo */

/* Get Product Photos */
export const getProdPhotos = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .get("/photo/pphotos", {
                    params: {
                        IdProduct:data.IdProduct,
                    }
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    resolve(err.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

/** Insert product Photos */
export const insProdPhotos = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .post("/product/pphoto-insert", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    resolve(err.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

/** Insert product Photos */
export const delProductPhoto = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .post("/product/pphoto-delete", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    resolve(err.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

//******************** Contact CRUD Actions ***********************/

/** Get all Contact */
export const getAllContact = async (dispatch) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .get("/contact/all")
                .then((response) => {
                    dispatch({
                        type: "GET_CONTACT",
                        contact: response.data,
                    });
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

/** Insert Contact */
export const insContact = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .post("/contact/insert", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

/** Update Contact */
export const uptContact = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .put("/contact/update", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

/** Delete Contact */
export const delContact = async (id) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .post("/contact/delete", id)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

//******************** Department CRUD Actions ***********************/

/** Get all Department */
export const getAllDepartment = async (dispatch) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .get("/department/all")
                .then((response) => {
                    dispatch({
                        type: "GET_DEPARTMENT",
                        department: response.data,
                    });
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

/** Insert Department */
export const insDepartment = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .post("/department/insert", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

/** Update Department */
export const uptDepartment = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .put("/department/update", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

/** Delete Department */
export const delDepartment = async (id) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .post("/department/delete", id)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

//******************** Account CRUD Actions ***********************/

/** Get all Account */
export const getAllAccount = async (dispatch) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .get("/account/all")
                .then((response) => {
                    dispatch({
                        type: "GET_ACCOUNT",
                        account: response.data,
                    });
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

/** Insert Account */
export const insAccount = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .post("/account/insert", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

/** Update Account */
export const uptAccount = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .put("/account/update", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

/** Delete Account */
export const delAccount = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .post("/account/delete", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

//******************** Message CRUD Actions ***********************/

/** Get all Message */
export const getAllMessage = async (dispatch) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .get("/message/all")
                .then((response) => {
                    dispatch({
                        type: "GET_MESSAGE",
                        message: response.data,
                    });
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

/** Insert Message */
export const insMessage = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .post("/message/insert", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

/** Update Message */
export const uptMessage = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .put("/message/update", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

//******************** PriceList CRUD Actions ***********************/

/** Get all PriceList */
export const getAllPriceList = async (dispatch) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .get("/pricelist/all")
                .then((response) => {
                    dispatch({
                        type: "GET_PRICELIST",
                        pricelist: response.data,
                    });
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

/** Insert PriceList */
export const insPriceList = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .post("/pricelist/insert", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

/** Update PriceList */
export const uptPriceList = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .put("/pricelist/update", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

/** Delete PriceList */
export const delPriceList = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .put("/pricelist/delete", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

//** Classes */

/**All Classes */
export const getAllClasses = async (dispatch) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .get("/class/all")
                .then((response) => {
                    dispatch({
                        type: "GET_CLASSES",
                        class: response.data,
                    });
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

/** Insert class */
export const insClass = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .post("/class/insert", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

/** Update class */
export const uptClass = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .put("/class/update", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

/** Delete class */
export const delClass = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .post("/class/delete", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.repsonse);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

//** SubCat */

/** All SubCat */
export const getAllSubCat = async (dispatch) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .get("/subcat/all")
                .then((response) => {
                    dispatch({
                        type: "GET_SUBCAT",
                        subcat: response.data,
                    });
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

/** Get SubCat */
export const getSubCategory = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .get("/subcat/get", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    resolve(err.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

/** Get SubCat tree */
export const getSubCatTree = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .post("/subcat/subcattree", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    resolve(err.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

/** Get SubCat Service */
export const getSubCatService = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .post("/subcat/subcatservice", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    resolve(err.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

/** Insert SubCat */
export const insSubCat = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .post("/subcat/insert", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

/** Update SubCat */
export const uptSubCat = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .put("/subcat/update", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

/** Delete SubCat */
export const delSubCat = async (id) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .post("/subcat/delete", id)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

//** Measure Unit */

/** All MUnit */
export const getAllMUnit = async (dispatch) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .get("/munit/all")
                .then((response) => {
                    dispatch({
                        type: "GET_MUNIT",
                        munit: response.data,
                    });
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

/** Insert MUnit */
export const insMUnit = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .post("/munit/insert", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

/** Update MUnit */
export const uptMUnit = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .put("/munit/update", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

/** Delete MUnit */
export const delMUnit = async (id) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .post("/munit/delete", id)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

//**************City *****************************/

/** Get all Cities */
export const getAllCities = async (dispatch) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .get("/city/all")
                .then((response) => {
                    dispatch({
                        type: "GET_CITIES",
                        city: response.data,
                    });
                    resolve(response);
                })
                .catch((err) => {
                    resolve(err.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

/** Get all Cities by State*/
export const getAllCitiesByState = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .get("/city/allByState", {
                    params: {
                        pIdState: data,
                    }
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    resolve(err.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

//************* Company ************************ */

/* Get Companies */
export const getAllCompanies = async (dispatch) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .get("/company/all")
                .then((response) => {
                    dispatch({
                        type: "GET_COMPANY",
                        company: response.data,
                    });
                    resolve(response);
                })
                .catch((err) => {
                    resolve(err.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

/* Get Active Companies */
export const getAllActiveCompanies = async () => {
    try {
        return await new Promise((resolve) => {
            instancia
                .get("/company/all-active")
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    resolve(err.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

/* Insert Company */
export const insCompany = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .post("/company/insert", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    resolve(err.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

/**Update Company */
export const uptCompany = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .put("/company/update", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    resolve(err.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

/** Delete Company */
export const delCompany = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .post("/company/delete", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    resolve(err.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

//** Service  */

/* Get all Services */
export const getAllServices = async (dispatch) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .get("/service/all")
                .then((response) => {
                    dispatch({
                        type: "GET_SERVICE",
                        service: response.data,
                    });
                    resolve(response);
                })
                .catch((err) => {
                    resolve(err.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

/** Insert Service */
export const insService = async (data) => {
    try {
        return await new Promise((resolve) => {
            HttpClient.post("/service/insert", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    resolve(err.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

/** Update Service */
export const uptService = async (data) => {
    try {
        return await new Promise((resolve) => {
            HttpClient.put("/service/update", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    resolve(err.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

/** Delete Service */
export const delService = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .post("/service/delete", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    resolve(err.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

//** PHOTO */

/** Get Photo by ID */
export const getPhoto = async (data) => {
    try {
        //console.log(data)
        return await new Promise((resolve) => {
            instancia
                .get("/photo/get", {
                    params: {
                        pIdPhoto: data.pIdPhoto,
                    }
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    resolve(err.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};
/** Insert Photo */
export const insPhoto = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .post("/photo/insert", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    resolve(err.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

//** Warehouse  */

/* Get all Warehouses */
export const getAllWarehouses = async (dispatch) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .get("/warehouse/all")
                .then((response) => {
                    dispatch({
                        type: "GET_WAREHOUSE",
                        warehouse: response.data,
                    });
                    resolve(response);
                })
                .catch((err) => {
                    resolve(err.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

/** Insert Warehouse */
export const insWarehouse = async (data) => {
    try {
        return await new Promise((resolve) => {
            HttpClient.post("/warehouse/insert", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    resolve(err.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

/** Update Warehouse */
export const uptWarehouse = async (data) => {
    try {
        return await new Promise((resolve) => {
            HttpClient.put("/warehouse/update", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    resolve(err.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

/** Delete Warehouse */
export const delWarehouse = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .post("/warehouse/delete", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    resolve(err.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

//** Customer  */

/* Get all Customers */
export const getAllCustomers = async (dispatch) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .get("/customer/all")
                .then((response) => {
                    dispatch({
                        type: "GET_CUSTOMER",
                        customer: response.data,
                    });
                    resolve(response);
                })
                .catch((err) => {
                    resolve(err.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

/** Insert Customer */
export const insCustomer = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .post("/customer/insert", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    resolve(err.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

/** Update Customer */
export const uptCustomer = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .put("/customer/update", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    resolve(err.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

/** Delete Customer */
export const delCustomer = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .post("/customer/delete", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    resolve(err.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

/** Get All Customer_Contact by Customer */
export const getAllCustomerContactByCustId = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .get("/customer-contact/allByCustomer", {
                    params: {
                        pIdCustomer: data,
                    }
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    resolve(err.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

/** Get All Customer_Address by Customer */
export const getAllCustomerAddressesByCustId = async (data) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .get("/customer-address/allByCustomer", {
                    params: {
                        pIdCustomer: data,
                    }
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    resolve(err.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

/** Insert Customer_Contact */
export const insCustomerContact = async (data) => {
    try {
        return await new Promise((resolve) => {
            HttpClient.post("/customer-contact/insert", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    resolve(err.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

/** Insert Customer_Document */
export const insCustomerDocument = async (data) => {
    try {
        return await new Promise((resolve) => {
            HttpClient.post("/documentc-customer/insert", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    resolve(err.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

/** Insert Customer_Address */
export const insCustomerAddress = async (data) => {
    try {
        return await new Promise((resolve) => {
            HttpClient.post("/customer-address/insert", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    resolve(err.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

//** Year  */

/* Get all Years */
export const getAllYears = async (dispatch) => {
    try {
        return await new Promise((resolve) => {
            instancia
                .get("/year/all")
                .then((response) => {
                    dispatch({
                        type: "GET_YEAR",
                        year: response.data,
                    });
                    resolve(response);
                })
                .catch((err) => {
                    resolve(err.response);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

