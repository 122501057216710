import {
    Button,
    Grid,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useStateValue } from "../../Context/store";
import moment from "moment";
import { getAllPriceList, uptPriceList } from "../../Actions/UserAction";

/** Import Icons */
import Save from "@material-ui/icons/Save";
import Close from "@material-ui/icons/Close";
/** Theme & Styles Import */
import { dialogStyle } from "../../Assets/styles/Styles";

/**Import Components */
import LockSmithTextField from "../ReusableComponents/LockSmithTextField";

const UpdatePriceList = ({ openDialog, closeDialog, data }) => {
    const classes = dialogStyle();
    const [openBackdrop, setOpenBackdrop] = useState(false);
    // eslint-disable-next-line no-empty-pattern
    const [{}, dispatch] = useStateValue();
    const [priceListData, setPriceListData] = useState({});

    const dataValues = (e) => {
        const { name, value } = e.target;
        setPriceListData((last) => ({
            ...last,
            [name]: value,
        }));
    };

    const saveFunction = async (e) => {
        e.preventDefault();
        setOpenBackdrop(true);
        await uptPriceList(priceListData).then((response) => {
            if (response.status === 200) {
                console.log(response);
                setOpenBackdrop(false);
                dispatch({
                    type: "OPEN_SNACKBAR",
                    openMessage: {
                        open: true,
                        title: "Success",
                        message: response.data.output.pmensaje,
                        alert: "success",
                    },
                });
                getAllPriceList(dispatch);
                closeDialog();
            } else {
                setOpenBackdrop(false);
                dispatch({
                    type: "OPEN_SNACKBAR",
                    openMessage: {
                        open: true,
                        title: "Error",
                        message: response.data.msg,
                        alert: "error",
                    },
                });
            }
        }).catch((error) => {console.log(error)});
    };

    const formatDates = () => {
        var date = moment(data.Date).format("YYYY-MM-DD");
        setPriceListData((last) => ({
            ...last,
            pDate: date,
        }));
    };

    useEffect(() => {
        setPriceListData({
            pIdListPrice: data.IdListPrice,
            pName: data.Name,
            pDesc1: data.Desc1,
            pDesc2: data.Desc2,
            pMargen: data.Margen,
        });
        //formatDates();
    }, [data]);
    const resetForm=()=>{
        setPriceListData({
            pIdListPrice: data.IdListPrice,
            pName: data.Name,
            pDesc1: data.Desc1,
            pDesc2: data.Desc2,
            pMargen: data.Margen,
        });
    }
    return (
            <form onSubmit={saveFunction}>
                    <Grid
                        container
                        spacing={2}
                        alignContent="center"
                        justifyContent="center"
                    >
                        <LockSmithTextField
                            label="NAME"
                            labelWidth="32%"
                            name="pName"
                            value={priceListData.pName}
                            onChange={dataValues}
                            required={true}
                        />
                    </Grid>
                    <Grid
                        container
                        spacing={2}
                        alignContent="center"
                        justifyContent="flex-end"
                    >
                        <Grid item xs={6} lg={3}>
                                <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                classes={{ root: classes.saveButton }}
                                size="large"
                                fullWidth
                                endIcon={
                                    <div
                                        style={{
                                            width: 20,
                                            height: 20,
                                            marginTop: -14,
                                            marginLeft: 10,
                                        }}
                                    >
                                        <Save/>
                                    </div>
                                }
                            >
                                Save
                            </Button>
                        </Grid>
                        <Grid item xs={6} lg={3}>
                                <Button
                                variant="contained"
                                onClick={resetForm}
                                classes={{ root: classes.cancelButton }}
                                size="large"
                                fullWidth
                                endIcon={
                                    <div
                                        style={{
                                            width: 20,
                                            height: 20,
                                            marginTop: -14,
                                            marginLeft: 10,
                                        }}
                                    >
                                        <Close/>
                                    </div>
                                }
                            >
                                Reset
                            </Button>

                        </Grid>
                    </Grid>
                    
                                </form>
    );
};

export default UpdatePriceList;
