import { Backdrop, CircularProgress, makeStyles } from "@material-ui/core";
import React, { useState } from "react";

export const loadingStyle = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 50,
        color: "#FFF",
    },
}));

export const useLoading = (initialState = false) => {
    const [loading, setLoading] = useState(initialState);

    /**Loading Controls */
    const openLoading = () => {
        setLoading(true);
    };
    const closeLoading = () => {
        setLoading(initialState);
    };

    return { loading, openLoading, closeLoading };
};

export const LoadingComponent = ({openLoading}) => {
    const classes = loadingStyle()
    return (
        <Backdrop open={openLoading} className={classes.backdrop} >
            <CircularProgress color="inherit" size={70} />
        </Backdrop>
    )
}