import React, { useEffect, useState } from "react";
import { useStateValue } from "../../Context/store";
import { delCustomer, getAllCustomers } from "../../Actions/UserAction";
import AddCustomer from "./AddCustomer";
import LockSmithDeleteComponent from "../ReusableComponents/LockSmithDeleteComponent";
import UpdateCustomer from "./UpdateCustomer";
/*Import Reusable Components */
import LockSmithMainTable from "../ReusableComponents/LockSmithMainTable";

const columns = () => { 
    return [
    {title: "ID", field: "IdCustomer",cellStyle: {height: 40,padding:"0px 16px"}},
    {title: "Name", field: "Name",cellStyle: {height: 40,padding:"0px 16px"}},
    {title: "EIN/RFC", field: "RFC",cellStyle: {height: 40,padding:"0px 16px"}},
    {title: "Email", field: "email",cellStyle: {height: 40,padding:"0px 16px"}},
    {title: "URL", field: "URL",cellStyle: {height: 40,padding:"0px 16px"}},
    {title: "Active", field: "Active",cellStyle: {height: 40,padding:"0px 16px"}}
]};

const Customer = () => {
    const [{ allCustomer }] = useStateValue();
    const [customer, setCustomer] = useState(null);

    useEffect(() => {
        setCustomer(allCustomer ? allCustomer.customer : []);
    }, [allCustomer]);

    return (
        <React.Fragment>
            <LockSmithMainTable 
                title="Customer"
                data={customer}
                columns={columns()}
                addComponent={AddCustomer}
                updateComponent={UpdateCustomer}
                //deleteComponent={LockSmithDeleteComponent}
                deleteAction={delCustomer}
                reducer={getAllCustomers}
                itemId={columns().field}
            />
        </React.Fragment>
    );
};

export default Customer;
