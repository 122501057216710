import React, { useEffect, useState } from "react";
import { useStateValue } from "../../Context/store";
import { deleteLevelSkill, getAllLevelSkill } from "../../Actions/UserAction";
import AddLevelSkill from "./AddLevelSkill";
import UpdateLevelSkill from "./UpdateLevelSkill";
/*Import Reusable Components */
import LockSmithMainTable from "../ReusableComponents/LockSmithMainTable";
import LockSmithDeleteComponent from "../ReusableComponents/LockSmithDeleteComponent";

const oldColumns = [
    { title: "SKILL", field: "Skill",cellStyle: {height: 40,padding:"0px 16px"} },
    { title: "SKILL DESCRIPTION", field: "DescrSkill",cellStyle: {height: 40,padding:"0px 16px"} },
    { title: "LEVEL", field: "Level",cellStyle: {height: 40,padding:"0px 16px"} },
    { title: "LEVEL DESCRIPTION", field: "DescrLevel",cellStyle: {height: 40,padding:"0px 16px"} },
];

const columns = () => { 
    return [
    { title: "LEVEL", field: "Level" },
    { title: "DESCRIPTION", field: "DescrLevel" },
    { title: "SKILLS", 
      field: "Skills",
      render: rowData => {  
        return (
          <div>
            {rowData.Skills.map((skill, index) => (
              //console.log(skill),
              <div>{skill}</div>
            ))}
          </div>
        );
      } },
      { title: "SKILLS DESCRIPTION",
      field: "SkillsDescription",
      render: rowData => {  
        return (
          <div>
            {rowData.SkillsDescription.map((skill) => (
              //console.log(skill),
              <div>{skill}</div>
            ))}
          </div>
        );
      } },
]};

const LevelSkill = () => {
    const [{ allLevelSkill }] = useStateValue();
    const [levelSkill, setLevelSkill] = useState([]);

    useEffect(() => {
        if(allLevelSkill != undefined && allLevelSkill.levelskill.length > 0){
            //console.log(allLevelSkill.levelskill);
            let skillByLevel = [];
            allLevelSkill.levelskill.map(levelSkill => {
                //console.log("levelSkill inside MAP",levelSkill);
              
                let skillFound = skillByLevel.find(skill => skill.Level == levelSkill.Level);
                if(skillFound){
                    skillFound.Skills.push(levelSkill.Skill);
                    skillFound.SkillsDescription.push(levelSkill.DescrSkill);
                }else{
                    skillByLevel.push({
                        Level: levelSkill.Level,
                        DescrLevel: levelSkill.DescrLevel,
                        Skills: [levelSkill.Skill],
                        SkillsDescription: [levelSkill.DescrSkill]
                    })
                }
            });
        
        //console.log("skillByLevel", skillByLevel);
        setLevelSkill(skillByLevel);
    }
        //setLevelSkill(allLevelSkill ? allLevelSkill.levelskill : []);
        
    }, [allLevelSkill]);

    return (
        <React.Fragment>
            <LockSmithMainTable
                title="Level Skill"
                data={levelSkill}
                columns={columns()}
                addComponent={AddLevelSkill}
                updateComponent={UpdateLevelSkill}
                deleteComponent={LockSmithDeleteComponent}
                deleteAction={deleteLevelSkill}
                reducer={getAllLevelSkill}
                itemId={columns().field}
            />
        </React.Fragment>
    );
};

export default LevelSkill;