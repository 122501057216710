export const initialState = {
    technician: [],
};

const allTechniciansReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_TECHNICIAN":
            return {
                ...state,
                technician: action.technician,
            };

        default:
            return state;
    }
};

export default allTechniciansReducer;