
import {
    Grid,
    IconButton,
    Dialog,
    DialogContent
} from "@material-ui/core";
import { Add } from "@material-ui/icons";
import React, { useState } from "react";
import { getAllWarehouses, insWarehouse } from "../../Actions/UserAction";
import { useStateValue } from "../../Context/store";
import { useForm } from "../../hooks/useForm";
import { LoadingComponent, useLoading } from "../../hooks/useLoading";

/** Import icons */
import { ReactComponent as EmployeesIcon } from "../../Assets/Icons/Employees.svg";
import { ReactComponent as AddIcon } from "../../Assets/Icons/Add.svg";
import AddAddress from "../Address/AddAddress";
import LockSmithComponentButtons from "../ReusableComponents/LockSmithComponentButtons";
import LockSmithSelect from "../ReusableComponents/LockSmithSelect";
import LockSmithTextField from "../ReusableComponents/LockSmithTextField";

/** Theme & Styles Import */
import {dialogStyle} from '../../Assets/styles/Styles'

const AddWareHouse = ({ openDialog, closeDialog }) => {
    const { loading, openLoading, closeLoading } = useLoading();
    const classes = dialogStyle();
    // eslint-disable-next-line no-empty-pattern
    const [{allAddress}, dispatch] = useStateValue();
    const { formData, handleInputChange, setFormData } = useForm({});
    const addressFields = ["IdAddress", "Street"];

    /** Address Controls */
    const [newAddress, setNewAddress] = useState(false);
    const openNewAddress = () => {
        setNewAddress(true);
    };
    const closeNewAddress = () => {
        setNewAddress(false);
    };

    const saveFunction = async (e) => {
        e.preventDefault();
        openLoading();
        await insWarehouse(formData).then((response) => {
            console.log(response.error);
            if (response.status === 200) {
                closeLoading();
                dispatch({
                    type: "OPEN_SNACKBAR",
                    openMessage: {
                        open: true,
                        title: "Success",
                        message: response.data.output.pmensaje,
                        alert: "success",
                    },
                });
                getAllWarehouses(dispatch);
                cancelFunction();
            } else {
                closeLoading();
                dispatch({
                    type: "OPEN_SNACKBAR",
                    openMessage: {
                        open: true,
                        title: "Error",
                        message: response.msg,
                        alert: "error",
                    },
                });
            }
        });
    };

    const cancelFunction = (e) => {
        Array.from(document.querySelectorAll("form input")).forEach(
            input => (input.value = "")
        );
        setFormData({});
    };

    return (
            <form onSubmit={saveFunction}>
            <Grid container spacing={2} justifyContent="center">
                <Grid item sm={12} xs={12} lg={6}>
                    <LockSmithTextField
                        label="Description"
                        name="pDescr"
                        onChange={handleInputChange}
                        value={formData.pDescr}
                        //size="small"
                        required={true}
                    />
                </Grid>
                <Grid item sm={12} xs={12} lg={6}>
                    <LockSmithTextField
                        label="Inv code"
                        name="pInvCode"
                        onChange={handleInputChange}
                        value={formData.pInvCode}
                        //size="small"
                    />
                </Grid>
                <Grid item sm={11} xs={11} lg={5}>
                    <LockSmithSelect
                        name="pIdAddress"
                        value={formData.pIdAddress}
                        handler={handleInputChange}
                        data={allAddress}
                        label="Address"
                        labelWidth="25%"
                        fields={addressFields}
                        required={true}
                    />
                </Grid>
                <Grid item sm={1} xs={1} lg={1}>
                    <IconButton onClick={openNewAddress}>
                        <Add
                            style={{
                                marginTop: 22,
                                marginLeft: -15,
                            }}
                            fontSize="large"
                        />
                    </IconButton>
                </Grid>
                <Grid item sm={12} xs={12} lg={6}>
                    <LockSmithTextField
                        label="Comments"
                        multiline={true}
                        name="pComments"
                        onChange={handleInputChange}
                        rows={3}
                        value={formData.pComments}
                        size="small"
                    />
                </Grid>
                
            </Grid>
                    <LockSmithComponentButtons closeHandler={cancelFunction} />
                    <Dialog
                open={newAddress}
                onClose={closeNewAddress}
                fullWidth>
                    <DialogContent>
                        <AddAddress openDialog={newAddress} closeDialog={closeNewAddress} />
                    </DialogContent>
                </Dialog>
    </form>
    );
};

export default AddWareHouse;
