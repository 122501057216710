import {
    Grid,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { updateJob, getAllJobs, getAllStatesByCountry, getAllCitiesByState } from "../../Actions/UserAction";
import { useStateValue } from "../../Context/store";

/** Import Icons */
/**Import Components */
import { useForm } from "../../hooks/useForm";
import LockSmithTextField from "../ReusableComponents/LockSmithTextField";
import LockSmithSelect from "../ReusableComponents/LockSmithSelect";
import LockSmithComponentButtons from "../ReusableComponents/LockSmithComponentButtons";
import LocksmithEntryId from "../ReusableComponents/LockSmithEntryId";

/** Theme & Styles Import */
import { dialogStyle } from "../../Assets/styles/Styles";
import { LoadingComponent, useLoading } from "../../hooks/useLoading";

const UpdateJob = ({ openDialog, closeDialog, data }) => {
    const classes = dialogStyle();
    // eslint-disable-next-line no-empty-pattern
    const [{allCompany, allBranch, allCountry, allState, allJobType}, dispatch] = useStateValue();
    const { loading, openLoading, closeLoading } = useLoading();
    const [states, setStates] = useState({states: []});
    const [cities, setCities] = useState({cities: []});

    const { formData, handleInputChange, setFormData } = useForm({});
    const companyFields = ["IdCompany", "Desc"];
    const branchFields = ["IdBranch", "Descr"];
    const countryFields = ["IdCountry", "Descr"];
    const stateFields = ["IdState", "Descr"];
    const cityFields = ["idCity", "Name"];
    const jobType = ["IdTypeJob", "Descr"];

    const saveJob = async (e) => {
        e.preventDefault();
        openLoading();
        console.log(formData);
        await updateJob(formData).then((response) => {
            console.log(response)
            if (response.status === 200 && response.statusText.toUpperCase() == "OK") {
                closeLoading();
                dispatch({
                    type: "OPEN_SNACKBAR",
                    openMessage: {
                        open: true,
                        title: "Success",
                        message: response.statusText.toUpperCase(),
                        alert: "success",
                    },
                });
                getAllJobs(dispatch);
                cancelJob();
            } else if(response.status === 200 ) {
                closeLoading();
                dispatch({
                    type: "OPEN_SNACKBAR",
                    openMessage: {
                        open: true,
                        title: "Success",
                        message: response.data.output.pmensaje,
                        alert: "success",
                    },
                });
            } else {
                console.log(response)
                closeLoading();
                dispatch({
                    type: "OPEN_SNACKBAR",
                    openMessage: {
                        open: true,
                        title: "Error",
                        message: "Could not Update job, check your data",
                        alert: "error",
                    },
                });
            }
        });
    };

    const cancelJob = () => {
        setFormData({});if(Object.keys(data).length !==0){
            setFormData({
                pIdJob: data.idjob,
                pIdCompany : data.IdCompany,
                pIdCustomer: data.IdCustomer,
                pIdBranch : data.idBranch,
                pName : data.NameCust,
                pAddress : data.Address,
                pIdCity : data.IdCity,
                pZipCode : data.ZipCode,
                pPhone : data.PHONECUST,
                pIdJobType : data.IdTypeJob,
                pComments : data.COMMENTSJOB,
                pDateReg : trimDatetime(data.DateReg)
            })
        }
    };

    const trimDatetime = (datetime) => {
        return (datetime.toString()).substring(0,10);
    }

    useEffect(() => {
        if(Object.keys(data).length !==0){
            setFormData({
                pIdJob: data.idjob,
                pIdCompany : data.IdCompany,
                pIdCustomer: data.IdCustomer,
                pIdBranch : data.idBranch,
                pName : data.NameCust,
                pAddress : data.Address,
                pIdCity : data.IdCity,
                pZipCode : data.ZipCode,
                pPhone : data.PHONECUST,
                pIdJobType : data.IdTypeJob,
                pComments : data.COMMENTSJOB,
                pDateReg : trimDatetime(data.DateReg)
            })
        }
    }, [data])

/*     useEffect(() => {
        if(openDialog){
              //get Texas Cities
            getAllCitiesByState(43).then((response) => {
                if (response?.status === 200) {
                    //console.log(response.data);
                    setCities({cities: response.data});
                }
        });
        }
    }, [openDialog]); */

    return (
            <form onSubmit={saveJob}>
                    <Grid
                        container
                        spacing={1}
                        alignContent="center"
                        justifyContent="center"
                    >
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <LocksmithEntryId
                                label="ID JOB"
                                id={formData.pIdJob}
                            />
                        </Grid>
                    </Grid>

                    <Grid
							container
							spacing={2}
							alignContent="center"
							alignItems="center"
							justifyContent="flex-end"
						>
							<Grid item sm={4} xs={4}>
								<LockSmithTextField
									label="Date*"
									labelWidth="20%"
									name="pDateReg"
									type="date"
									value={
										formData?.pDateReg
									}
									onChange={
										handleInputChange
									}
									required={true}
								/>
							</Grid>
							<Grid item sm={4} xs={4}>
								<LockSmithTextField
									label="Hr"
									labelWidth="20%"
									name="pTimeReg"
									type="time"
									value={
										formData?.pTimeReg
									}
									onChange={
										handleInputChange
									}
								/>
							</Grid>
                            <Grid item sm={4} xs={4}>
                                <LockSmithSelect
                                    label="Company"
                                    labelWidth="20%"
                                    name="pIdCompany" 
                                    value={formData.pIdCompany} 
                                    valueId="pIdCompany" 
                                    data={allCompany} 
                                    handler={handleInputChange} 
                                    fields={companyFields}
                                    disabled={true}
                                    required={true}
                                />
                        </Grid>
						</Grid>
                        <Grid
							container
							spacing={2}
							alignContent="center"
							alignItems="center"
							justifyContent="flex-end"
						>
                            <Grid item sm={4} xs={4}>
                                <LockSmithSelect
                                    label="Branch"
                                    labelWidth="20%"
                                    name="pIdBranch" 
                                    value={formData.pIdBranch} 
                                    valueId="pIdBranch" 
                                    data={allBranch} 
                                    handler={handleInputChange} 
                                    fields={branchFields}
                                    required={true}
                                    disabled={true}
                                />
                            </Grid>
                            <Grid item sm={4} xs={4}>
                                <LockSmithTextField
                                    label="Name*"
                                    //labelWidth="15%"
                                    name="pName"
                                    value={formData.pName}
                                    onChange={handleInputChange}
                                    required={true}
                                />
                            </Grid >
                            <Grid item sm={4} xs={4}>
                                <LockSmithTextField
                                    label="Zip Code"
                                    labelWidth="58%"
                                    name="pZipCode"
                                    type="number"
                                    value={formData.pZipCode}
                                    onChange={handleInputChange}
                                />
                            </Grid >
                        </Grid>
                        <Grid
							container
							spacing={2}
							alignContent="center"
							alignItems="center"
							justifyContent="flex-end"
						>
                            <Grid item sm={4} xs={4}>
                                <LockSmithTextField
                                    label="Phone*"
                                    labelWidth="33%"
                                    name="pPhone"
                                    type="number"
                                    value={formData.pPhone}
                                    onChange={handleInputChange}
                                    required={true}
                                />
                            </Grid>
                            <Grid item sm={4} xs={4}>
                                <LockSmithSelect
                                    label="Job Type*"
                                    labelWidth="20%"
                                    name="pIdJobType" 
                                    value={formData.pIdJobType} 
                                    valueId="pIdJobType" 
                                    data={allJobType} 
                                    handler={handleInputChange} 
                                    fields={jobType}
                                    required={true}
                                />
                            </Grid>
                            <Grid item sm={4} xs={4}>
                               
                            </Grid>
                        </Grid>
                        
                        <LockSmithTextField
                                    label="Address*"
                                    name="pAddress"
                                    multiline={true}
                                    rows={5}
                                    value={formData.pAddress}
                                    onChange={handleInputChange}
                                    required={true}
                                />
                        
                        <Grid item sm={12} xs={12}>
                            <LockSmithTextField
                                label="Comments*"
                                //labelWidth="15%"
                                name="pComments"
                                multiline={true}
                                rows={5}
                                value={formData.pComments}
                                onChange={handleInputChange}
                                required={true}
                            />
                        </Grid >

                    <LockSmithComponentButtons closeHandler={cancelJob} />
            </form>
    );
};

export default UpdateJob;
