import React, { useEffect, useState } from "react";
import { useStateValue } from "../../Context/store";
import { delUser, getAllUser } from "../../Actions/UserAction";
import AddUser from "./AddUser";
import LockSmithDeleteComponent from "../ReusableComponents/LockSmithDeleteComponent";
import UpdateUser from "./UpdateUser";
/*Import Reusable Components */
import LockSmithMainTable from "../ReusableComponents/LockSmithMainTable";

const columns = () => { 
    return [
    { title: "ID", field: "idUser",cellStyle: {height: 40,padding:"0px 16px"}},
    { title: "UserName", field: "UserName",cellStyle: {height: 40,padding:"0px 16px"} },
    { title: "Branch", field: "DESCRBRANCH",cellStyle: {height: 40,padding:"0px 16px"} },
    { title: "Role", field: "DESCRROLE",cellStyle: {height: 40,padding:"0px 16px"} },
]};

const User = () => {
    const [{ allUser }] = useStateValue();
    const [user, setUser] = useState(null);

    useEffect(() => {
        setUser(allUser ? allUser.user : []);
    }, [allUser]);

    return (
        <React.Fragment>
            <LockSmithMainTable 
                title="User"
                data={user}
                columns={columns()}
                addComponent={AddUser}
                updateComponent={UpdateUser}
                deleteComponent={LockSmithDeleteComponent}
                deleteAction={delUser}
                reducer={getAllUser}
                itemId={columns().field}
            />
        </React.Fragment>
    );
};

export default User;
