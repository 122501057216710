import React, { useEffect, useState } from "react";
import { useStateValue } from "../../Context/store";
import { deleteProfileDoc, getAllProfileDoc } from "../../Actions/UserAction";
import AddProfileDoc from "./AddProfileDoc";
import LockSmithDeleteComponent from "../ReusableComponents/LockSmithDeleteComponent";
import UpdateProfileDoc from "./UpdateProfileDoc";
/*Import Reusable Components */
import LockSmithMainTable from "../ReusableComponents/LockSmithMainTable";


const columns = () => { 
    return [
    { title: "Id Type Emp", field: "idTypeEmp",cellStyle: {height: 40,padding:"0px 16px"} },
    { title: "Employee Type Description", field: "DescrOpt",cellStyle: {height: 40,padding:"0px 16px"} },
    { title: "Id Document",
      field: "idDocument",
      render: rowData => {
        return (
          <div>
            {rowData.idDocument.map((doc, index) => (
              <div>{doc}</div>
            ))}
          </div>
        );
      } ,cellStyle: {height: 40,padding:"0px 16px"}},
      { title: "Document Description",
      field: "DescrRol",
      render: rowData => {
        return (
          <div>
            {rowData.DescrRol.map((doc, index) => (
              <div>{doc}</div>
            ))}
          </div>
        );
      } ,cellStyle: {height: 40,padding:"0px 16px"}}
]};

const ProfileDoc = () => {
    const [{ allProfileDoc }] = useStateValue();
    const [profileDoc, setProfileDoc] = useState([]);

    useEffect(() => {
        if(allProfileDoc != undefined && allProfileDoc.profiledoc.length > 0){
            let docByEmpType = [];
            allProfileDoc.profiledoc.forEach(profiledoc => {
                let docFound = docByEmpType.find(doc => doc.idTypeEmp === profiledoc.idTypeEmp);
                if(docFound){
                    docFound.idDocument.push(profiledoc.idDocument);
                    docFound.DescrRol.push(profiledoc.DescrRol);
                }else{
                    docByEmpType.push({
                        idTypeEmp: profiledoc.idTypeEmp,
                        DescrOpt: profiledoc.DescrOpt,
                        idDocument: [profiledoc.idDocument],
                        DescrRol: [profiledoc.DescrRol],
                    })
                }
            });
        setProfileDoc(docByEmpType);
    }
        
    }, [allProfileDoc]);

    return (
        <React.Fragment>
            <LockSmithMainTable 
                title="Profile Doc"
                data={profileDoc}
                columns={columns()}
                addComponent={AddProfileDoc}
                updateComponent={UpdateProfileDoc}
                deleteComponent={LockSmithDeleteComponent}
                deleteAction={deleteProfileDoc}
                reducer={getAllProfileDoc}
                itemId={columns().field}
            />
        </React.Fragment>
    );
};

export default ProfileDoc;
