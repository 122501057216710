export const initialState = {
    subcat: [],
};

const allSubCatReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_SUBCAT":
            return {
                ...state,
                subcat: action.subcat
            };

        default:
            return state;
    }
};

export default allSubCatReducer;