import {
    Button,
    Grid,
} from "@material-ui/core";
import React, { useState } from "react";
import { getAllBranch, insBranch } from "../../Actions/UserAction";
import { useStateValue } from "../../Context/store";

/** Import Icons */
import Save from "@material-ui/icons/Save";
import Close from "@material-ui/icons/Close";
/** Import Modules */
import LockSmithTextField from "../ReusableComponents/LockSmithTextField";

/** Theme & Styles Import */
import { dialogStyle } from "../../Assets/styles/Styles";

const AddBranch = ({ openDialog, closeDialog }) => {
    const classes = dialogStyle();
    const [openBackdrop, setOpenBackdrop] = useState(false);
    // eslint-disable-next-line no-empty-pattern
    const [{}, dispatch] = useStateValue();
    const [branchData, setBranchData] = useState({});

    const dataValues = (e) => {
        const { name, value } = e.target;
        setBranchData((last) => ({
            ...last,
            [name]: value,
        }));
    };

    const saveFunction = async (e) => {
        e.preventDefault();
        setOpenBackdrop(true);
        await insBranch(branchData).then((response) => {
            if (response.status === 200) {
                setOpenBackdrop(false);
                dispatch({
                    type: "OPEN_SNACKBAR",
                    openMessage: {
                        open: true,
                        title: "Success",
                        message: response.data.output.pmensaje,
                        alert: "success",
                    },
                });
                getAllBranch(dispatch);
                cancelFunction();
            } else {
                setOpenBackdrop(false);
                dispatch({
                    type: "OPEN_SNACKBAR",
                    openMessage: {
                        open: true,
                        title: "Error",
                        message: response.data.msg,
                        alert: "error",
                    },
                });
            }
        });
    };

    const cancelFunction = () => {
        setBranchData({});
        Array.from(document.querySelectorAll("form input")).forEach(
            input => (input.value = "")
          );
    };

    return (
        
            <form onSubmit={saveFunction}>
                    <Grid
                        container
                        spacing={2}
                        alignContent="center"
                        justifyContent="center"
                    >
                        <Grid item sm={12} xs={12}>
                            <LockSmithTextField
                                label="Description"
                                labelWidth="32%"
                                name="pDescr"
                                value={branchData.pDescr}
                                onChange={dataValues}
                                required={true}
                            />
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        spacing={2}
                        alignContent="center"
                        justifyContent="flex-end"
                    >
                        <Grid item xs={6} lg={3}>
                                <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                classes={{ root: classes.saveButton }}
                                size="large"
                                fullWidth
                                endIcon={
                                    <div
                                        style={{
                                            width: 20,
                                            height: 20,
                                            marginTop: -14,
                                            marginLeft: 10,
                                        }}
                                    >
                                        <Save/>
                                    </div>
                                }
                            >
                                Save
                            </Button>
                        </Grid>
                        <Grid item xs={6} lg={3}>
                                <Button
                                variant="contained"
                                onClick={cancelFunction}
                                classes={{ root: classes.cancelButton }}
                                size="large"
                                fullWidth
                                endIcon={
                                    <div
                                        style={{
                                            width: 20,
                                            height: 20,
                                            marginTop: -14,
                                            marginLeft: 10,
                                        }}
                                    >
                                        <Close/>
                                    </div>
                                }
                            >
                                Reset
                            </Button>
                        </Grid>
                    </Grid>
                    
                    

            </form>

    );
};

export default AddBranch;
