export const initialState = {
    contact: [],
};

const allContactReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_CONTACT":
            return {
                ...state,
                contact: action.contact
            };

        default:
            return state;
    }
};

export default allContactReducer;