export const initialState = {
    profiledoc: [],
};

const allProfileDocReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_PROFILEDOC":
            return {
                ...state,
                profiledoc: action.profiledoc,
            };

        default:
            return state;
    }
};

export default allProfileDocReducer;