import React, { useEffect, useState } from "react";
import { useStateValue } from "../../Context/store";
import { deleteDocumentE, getAllDocumentE } from "../../Actions/UserAction";
import AddDocumentE from "./AddDocumentE";
import LockSmithDeleteComponent from "../ReusableComponents/LockSmithDeleteComponent";
import UpdateDocumentE from "./UpdateDocumentE";
/*Import Reusable Components */
import LockSmithMainTable from "../ReusableComponents/LockSmithMainTable";

const columns = () => { 
    return [
    { title: "ID", field: "IdDocument",cellStyle: {height: 40,padding:"0px 16px"} },
    { title: "DESCRIPTION", field: "Descr",cellStyle: {height: 40,padding:"0px 16px"} },
]};

const DocumentE = () => {
    const [{ allDocumentE }] = useStateValue();
    const [documentE, setDocumentE] = useState(null);

    useEffect(() => {
        setDocumentE(allDocumentE ? allDocumentE.documentE : []);
    }, [allDocumentE]);

    return (
        <React.Fragment>
            <LockSmithMainTable 
                title="Document E"
                data={documentE}
                columns={columns()}
                addComponent={AddDocumentE}
                updateComponent={UpdateDocumentE}
                deleteComponent={LockSmithDeleteComponent}
                deleteAction={deleteDocumentE}
                reducer={getAllDocumentE}
                itemId={columns().field}
            />
        </React.Fragment>
    );
};

export default DocumentE;
