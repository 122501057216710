import {
    Backdrop,
    Box,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useStateValue } from "../../Context/store";
import { getAllEmployee, updateEmployee, uploadImageEmp, getPhoto, insPhoto } from "../../Actions/UserAction";

/* form */
import EmployeeForm from "./EmployeeForm"
import moment from "moment";

import { useForm } from "../../hooks/useForm";

/** Theme & Styles Import */
import { dialogStyle } from '../../Assets/styles/Styles'


const UpdateEmployee = ({ openDialog, closeDialog, data }) => {
    //console.log("data in Update Employee", data)
    const classes = dialogStyle();
    const [openBackdrop, setOpenBackdrop] = useState(false);
    // eslint-disable-next-line no-empty-pattern
    const [{ }, dispatch] = useStateValue();
    //const { loading, openLoading, closeLoading } = useLoading();
    const [image, setImage] = useState();
    const { formData, handleInputChange, setFormData } = useForm({pReciveJobs:false,pActive:false});
    //const [photoData, setPhotoData] = useState({pPath:"", pDescr:""});
    const [invertColors, setInvertColors] = useState(false)
    const handleInvertColors = () => {
        setInvertColors(!invertColors)
    }

    
    const getEmployeePhoto = async (id) => {
        //openLoading()
        await getPhoto({ pIdPhoto: id }).then((response) => {
            if (response.status === 200) {
                //closeLoading()
                //console.log("response", response.data)
                setImage(response.data)
            }
        });
    };

    const formatDates = () => {
        formData.pBirthDate = moment(formData.pBirthDate).format("YYYY-MM-DD HH:mm:ss");
        formData.pHireDate = moment(formData.pHireDate).format("YYYY-MM-DD HH:mm:ss");
        //formData.pSchedule = moment(formData.pSchedule).format("YYYY-MM-DD HH:mm:ss");
    }

    const updateImageId = (form, id, photoData) => {
        let newFormData = { ...form,
                        pIdPhoto: id,
                        pPathP:photoData.pPath,
                        pDescrP:photoData.pPath
                        };
        //console.log("newFormData", newFormData)
        return newFormData;
    }

    const saveFunction = async (e) => {
        e.preventDefault();
        setOpenBackdrop(true);
        formatDates();
        console.log(formData)
        //openLoading();
        /**If there is a new Photo, make an insert in Photo table first, then update Employee */
        if(formData.empPhoto){
            let photoData = {}
            let pIdPhoto;
            const imageDataForm = new FormData();
            imageDataForm.append("img", formData.empPhoto[0]);
            //console.log(employeeData.empPhoto)
            await uploadImageEmp(imageDataForm).then((res) => {
                console.log("uploadImage")
                if(res.status === 200){
                    photoData.pPath = res.data.data.location
                    photoData.pDescr = res.data.data.location
                    
                }
            }).catch((err) => {console.log("Error uploading image", err)})

            await insPhoto(photoData).then(response => {
                if(response.status === 200){
                    pIdPhoto = response.data.output.pmensaje;
                    //console.log("photo id", response.data.output.pmensaje)
                    //setFormData({...formData, pIdPhoto: response.data.output.pmensaje})
                    
                }
            }).catch((err) => {console.log("Error inserting image", err)})

            await updateEmployee(updateImageId(formData, pIdPhoto, photoData)).then(response => {
                //console.log("updateEmployee")
                if (response.status === 200) {
                    setOpenBackdrop(false);
                    //closeLoading();
                    dispatch({
                        type: "OPEN_SNACKBAR",
                        openMessage: {
                            open: true,
                            title: "Success",
                            message: response.data.output.pmensaje,
                            alert: "success",
                        },
                    });
                    getAllEmployee(dispatch);
                    setFormData({})
                    closeDialog();
                } else {
                    console.log("ERROR: ", response)
                    setOpenBackdrop(false);
                    //closeLoading();
                    dispatch({
                        type: "OPEN_SNACKBAR",
                        openMessage: {
                            open: true,
                            title: "Error",
                            message: "Error updating Employee, check your data",
                            alert: "error",
                        },
                    });
                }
            }).catch((err) => {console.log("Error Updating Employee", err)})

        /**IF There is no data for Photo, make a regular update */
        }else{
            console.log("no photo")
            await updateEmployee(formData).then(response => {
                //console.log(response)
                if (response.status === 200) {
                    setOpenBackdrop(false);
                    //closeLoading();
                    dispatch({
                        type: "OPEN_SNACKBAR",
                        openMessage: {
                            open: true,
                            title: "Success",
                            message: response.data.output.pmensaje,
                            alert: "success",
                        },
                    });
                    getAllEmployee(dispatch);
                    setFormData({})
                    closeDialog();
                } else {
                    console.log("ERROR: ", response)
                    setOpenBackdrop(false);
                    //closeLoading();
                    dispatch({
                        type: "OPEN_SNACKBAR",
                        openMessage: {
                            open: true,
                            title: "Error",
                            message: response.msg,
                            alert: "error",
                        },
                    });
                }
            })
        }
        
        };

    const cancelFunction = (e) => {
        //setEmployeeData();
        closeDialog();
    };
    const resetForm = (e) => {
        setFormData({
            pIdEmployee: data.IdEmployee,
            pActive: data.Active,
            pName: data.Name,
            pNickName: data.NickName,
            pLastName: data.LastName,
            pMname: data.Mname,
            pGender: data.Gender,
            pCom: data.PercComm,
            pSSN: data.SSN,
            pNoLicense: data.License,
            pReciveJobs: data.ReciveJobs,
            pComments: data.Comments,
            pDayOff: data.DayOff,
            pSchedule: data.Schedule,
            pPhone: data.Phone,
            pIdAddress: data.IdAddress,
            pIdTypeEmp: data.IdTypeEmp,
            pIdLevel: data.idlevel,
            pIdPhoto: data.IdPhoto,
            pEmail: data.Email,
            pIdPlaceBirth: data.PlaceBirth,
            pHireDate: moment(data.HireDate).format("YYYY-MM-DD"),
            pBirthDate: moment(data.BirthDate).format("YYYY-MM-DD"),
        })
    };
    useEffect(() => {
        //console.log("use effect with dataAAAAAAAAAAAAAAAAAAAAAAAAAAAAA")
        setFormData({
            pIdEmployee: data.IdEmployee,
            pActive: data.Active,
            pName: data.Name,
            pNickName: data.NickName,
            pLastName: data.LastName,
            pMname: data.Mname,
            pGender: data.Gender,
            pCom: data.PercComm,
            pSSN: data.SSN,
            pNoLicense: data.License,
            pReciveJobs: data.ReciveJobs,
            pComments: data.Comments,
            pDayOff: data.DayOff,
            pSchedule: data.Schedule,
            pPhone: data.Phone,
            pIdAddress: data.IdAddress,
            pIdTypeEmp: data.IdTypeEmp,
            pIdLevel: data.idlevel,
            pIdPhoto: data.IdPhoto,
            pEmail: data.Email,
            pIdPlaceBirth: data.PlaceBirth,
            pHireDate: moment(data.HireDate).format("YYYY-MM-DD"),
            pBirthDate: moment(data.BirthDate).format("YYYY-MM-DD"),
        })

    }, [data])

    useEffect(() => {
        if(image && image.length > 0){
            setFormData({
                ...formData,
                imgData: image,
                pPathP: image[0].Path,
                pDescrP: image[0].Descr,
            })
        }
    },[image])

    //get image only when IdPhoto is not null
    useEffect(() => {
        if(formData.pIdPhoto){
            getEmployeePhoto(formData.pIdPhoto);
        }
    } , [formData.pIdPhoto])

    return (
                <EmployeeForm
                    onCancel={resetForm}
                    onSave={saveFunction}
                    //getEmployeeData={getEmployeeData}
                    onInvertColors={handleInvertColors}
                    onChange={handleInputChange}
                    isUpdate={true}
                    data={formData}
                />
    );
};

export default UpdateEmployee;