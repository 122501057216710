import {
    Box,
    Grid,
} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import { ReactComponent as AddIcon } from "../../Assets/Icons/Add.svg";
import { dialogStyle } from "../../Assets/styles/Styles";
import { useStateValue } from "../../Context/store";
import { useForm } from "../../hooks/useForm";
import { LoadingComponent, useLoading } from "../../hooks/useLoading";
import { ReactComponent as CompanyIcon } from "../../Assets/Icons/Company.svg";
import LockSmithComponentButtons from "../ReusableComponents/LockSmithComponentButtons";
import LockSmithTextField from "../ReusableComponents/LockSmithTextField";
import LockSmithSelect from "../ReusableComponents/LockSmithSelect";
import LockSmithSwitch from "../ReusableComponents/LockSmithSwitch";
import { getAllAddress, getAllStatesByCountry, getAllCitiesByState, insAddress } from "../../Actions/UserAction";

const AddAddress = ({ openDialog, closeDialog, data, dataFields, callback, defaultAddress }) => {
    //console.log(data)
    const classes = dialogStyle();
    const { loading, openLoading, closeLoading } = useLoading();
    const [{ allAddressType, allCountry, allState, allCities }, dispatch] = useStateValue();
    /* States to handle the Drop Downs*/
    const [countries, setCountries] = useState({country: []});
    const [states, setStates] = useState({states: []});
    const [cities, setCities] = useState({cities: []});
    const [zipcodeDisabled, setZipcodeDisabled] = useState(true);
    const [statesDisabled, setStatesDisabled] = useState(true);
    const [citiesDisabled, setCitiesDisabled] = useState(true);

    const { formData, handleInputChange, setFormData } = useForm();
    const addressTypeFields = ["IdType", "Descr"];
    const countryFields = ["IdCountry", "Descr"];
    const stateFields = ["IdState", "Descr"];
    const cityFields = ["idCity", "Name"];

    const saveFunction = async (e) => {
        e.preventDefault();
        //console.log(formData);
        openLoading();
         await insAddress(formData).then((response) => {
            if (response.status === 200) {
                closeLoading();
                dispatch({
                    type: "OPEN_SNACKBAR",
                    openMessage: {
                        open: true,
                        title: "Success",
                        message: response.data.output.pmensaje,
                        alert: "success",
                    },
                });
                getAllAddress(dispatch);
                if(callback && !defaultAddress){
                    callback();
                } else if (callback && defaultAddress){
                    callback(defaultAddress);
                }
                cancelFunction();
            } else {
                closeLoading();
                dispatch({
                    type: "OPEN_SNACKBAR",
                    openMessage: {
                        open: true,
                        title: "Error",
                        message: response.data.msg,
                        alert: "error",
                    },
                });
            }
        });
    };

    const cancelFunction = () => {
        setFormData({});
        setStatesDisabled(true);
        setCitiesDisabled(true);
    };

    useEffect(() => {
        //console.log(formData);
        if(formData.pIdCountry !== undefined){
            getAllStatesByCountry(formData.pIdCountry).then((response) => {
                if (response.status === 200) {
                    //console.log(response.data);
                    setStates({states: response.data});
                    if(response.data.length > 0){
                        setStatesDisabled(false);
                    }
                }
            });
        }
    }, [formData.pIdCountry]);

    useEffect(() => {
        if(formData.pIdState !== undefined){
            getAllCitiesByState(formData.pIdState).then((response) => {
                if (response.status === 200) {
                    //console.log(response.data);
                    setCities({cities: response.data});
                    if(response.data.length > 0){
                        setCitiesDisabled(false);
                    }
                    
                }
            });
        }
    }, [formData.pIdState]);

    useEffect(() => {
         if(formData.pHaveZipCode === true){
            setZipcodeDisabled(false);
        }else{
            setFormData((prevState) => ({
                ...prevState,
                pZipCode: null,
            }))
            setZipcodeDisabled(true);
        
        }
    }, [formData.pHaveZipCode])

    useEffect(() => {
        if(defaultAddress && defaultAddress != 0){
        setFormData({...formData, pIdType: defaultAddress});
        }
    }, [defaultAddress])

    return (
        <>
 
                <form onSubmit={saveFunction}>
                        <Grid
                            container
                            spacing={2}
                            alignContent="flex-start"
                            //justifyContent="center"
                        >
                            <Grid item sm={6}>
                                <LockSmithTextField
                                    label="Street"
                                    name="pStreet"
                                    //labelWidth="33%"
                                    onChange={handleInputChange}
                                    value={formData.pStreet}
                                    required={true}
                                />
                            </Grid>
                            <Grid item sm={6}>
                                <LockSmithTextField
                                    label="#EXT"
                                    name="pNoExt"
                                    type="number"
                                    labelWidth="55%"
                                    onChange={handleInputChange}
                                    value={formData.pNoExt}
                                    required={true}
                                />
                            </Grid>
                            <Grid item sm={6}>
                                <LockSmithTextField
                                    label="#INT"
                                    name="pNoInt"
                                    type="number"
                                    labelWidth="33%"
                                    onChange={handleInputChange}
                                    value={formData.pNoInt}
                                    required={false}
                                />
                            </Grid>
                            <Grid item sm={6}>
                                <LockSmithTextField
                                    label="COL"
                                    name="pCol"
                                    onChange={handleInputChange}
                                    value={formData.pCol}
                                    required={false}
                                />
                            </Grid>
                            <Grid item sm={6}>
                                <LockSmithTextField
                                    label="Location"
                                    name="pLocation"
                                    onChange={handleInputChange}
                                    value={formData.pLocation}
                                    required={false}
                                />
                            </Grid>
                            <Grid item sm={6} xs={6}>
                                <LockSmithSelect
                                    name="pIdCountry"
                                    value={formData.pIdCountry}
                                    handler={handleInputChange}
                                    data={allCountry}
                                    label="Country"
                                    labelWidth="22%"
                                    fields={countryFields}
                                    //disabled={countriesDisabled}
                                    required={true}
                                />
                            </Grid>
                            <Grid item sm={6} xs={6}>
                                <LockSmithSelect
                                    name="pIdState"
                                    //value={formData.pIdState}
                                    handler={handleInputChange}
                                    data={states}
                                    label="State"
                                    labelWidth="22%"
                                    fields={stateFields}
                                    disabled={statesDisabled}
                                    required={true}
                                />
                            </Grid>
                            <Grid item sm={6} xs={6}>
                                <LockSmithSelect
                                    name="pIdCity"
                                    value={formData.pIdCity}
                                    handler={handleInputChange}
                                    data={cities}
                                    label="City"
                                    labelWidth="22%"
                                    fields={cityFields}
                                    disabled={citiesDisabled}
                                    required={true}
                                />
                            </Grid>
                            <Grid item sm={6} xs={6}>
                                <LockSmithTextField
                                    label="Zip code"
                                    labelWidth="55%"
                                    name="pZipCode"
                                    type="number"
                                    onChange={handleInputChange}
                                    value={formData.pZipCode}
                                    disabled={zipcodeDisabled}
                                />
                            </Grid>
                            <Grid item sm={6} xs={6}  style={{marginTop:"auto", marginBottom:"auto"}}>
                                    <text
                                        style={{
                                            fontSize: 14,
                                            fontWeight: "bold",
                                            marginLeft:14
                                        }}
                                    >
                                        Have Zip code?
                                    </text>
                                <LockSmithSwitch
                                    checked={formData.pHaveZipCode}
                                    onChange={handleInputChange}
                                    name="pHaveZipCode"
                                    value={formData.pHaveZipCode}
                                    
                                />
                            </Grid>
                            <Grid item sm={6} xs={6}>
                                <LockSmithSelect
                                    name="pIdType"
                                    value={formData.pIdType}
                                    handler={handleInputChange}
                                    data={data ? data : allAddressType}
                                    label="Address type"
                                    labelWidth="100%"
                                    fields={dataFields ? dataFields : addressTypeFields}
                                    required={true}
                                    defaultValue={defaultAddress ? defaultAddress : null}
                                    disabled={defaultAddress ? true : false}
                                />
                            </Grid>
                        </Grid>

                        <LockSmithComponentButtons
                            closeHandler={cancelFunction}
                        />

                </form>

        </>
    );
};

export default AddAddress;
