import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_API_URL; 

axios.interceptors.request.use(
    (config) => {
        const token = window.localStorage.getItem("token");
        if (token) {
            config.headers.Authorization = "Bearer " + token;
            return config;
        }
    },
    (error) => {
        return Promise.reject(error);
    }
);

const genericRequest = {
    get: (url) => axios.get(url),
    post: (url, body) => axios.post(url, body),
    put: (url, body) => axios.put(url, body),
    delete: (url, body) => axios.delete(url, body),
};

export default genericRequest;
