export const initialState = {
    product: [],
};

const allProductReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_PRODUCT":
            return {
                ...state,
                product: action.product
            };

        default:
            return state;
    }
};

export default allProductReducer;