import React from "react";
import { Button, Grid } from "@material-ui/core";

/** Theme & Styles Import */
import { dialogStyle } from "../../Assets/styles/Styles";

import Save from "@material-ui/icons/Save";
import Close from "@material-ui/icons/Close";
const LockSmithComponentButtons = (props) => {
  const classes = dialogStyle();

  return (
    <React.Fragment>
      <Grid container spacing={2} justifyContent="flex-end">
        <Grid item xs={6} lg={3}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            classes={{ root: classes.saveButton }}
            size="large"
            fullWidth
            endIcon={<Save />}
          >
            Save
          </Button>
        </Grid>
        <Grid item xs={6} lg={3}>
          <Button
            variant="contained"
            onClick={props.closeHandler}
            classes={{ root: classes.cancelButton }}
            size="large"
            fullWidth
            endIcon={<Close />}
          >
            Reset
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default LockSmithComponentButtons;
