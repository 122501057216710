export const initialState = {
    levels: [],
};

const allLevelsReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_LEVELS":
            return {
                ...state,
                levels: action.levels,
            };

        default:
            return state;
    }
};

export default allLevelsReducer;
