import React, { useState } from "react";
import { useStateValue } from "../../Context/store";
import { getAllEmployee, addEmployee, uploadImageEmp } from "../../Actions/UserAction";

/* form */
import EmployeeForm from "./EmployeeForm"
import { useForm } from "../../hooks/useForm";


/** Theme & Styles Import */
import { dialogStyle } from '../../Assets/styles/Styles'

/* connect to the backend */
//import {addEmployee} from "../../Actions/EmployeeCruds"

const AddEmployee = ({ openDialog, closeDialog }) => {
    const classes = dialogStyle();
    const [openBackdrop, setOpenBackdrop] = useState(false);
    // eslint-disable-next-line no-empty-pattern
    const [{ }, dispatch] = useStateValue();
    //const { loading, openLoading, closeLoading } = useLoading();
    const { formData, handleInputChange, setFormData } = useForm({pReciveJobs:false});
    const [invertColors, setInvertColors] = useState(false)
    const handleInvertColors = () => {
        setInvertColors(!invertColors)
    }

    
    const saveFunction = async (e) => {
        console.log("click submit")
        e.preventDefault();
        setOpenBackdrop(true);

        const imageDataForm = new FormData();
            if(formData.empPhoto){
                imageDataForm.append("img", formData.empPhoto[0]);
                await uploadImageEmp(imageDataForm).then((res) => {
                    if(res.status === 200){
                        formData.pPathP = res.data.data.location
                        formData.pDescrP = res.data.data.location
                    }
                })
            }
        
        await addEmployee(formData).then(response => {
            //console.log(response)
            if (response.status === 200) {
                setOpenBackdrop(false);
                //closeLoading();
                dispatch({
                    type: "OPEN_SNACKBAR",
                    openMessage: {
                        open: true,
                        title: "Success",
                        message: response.data.output.pmensaje,
                        alert: "success",
                    },
                });
                getAllEmployee(dispatch);
                cancelFunction();
            } else {
                console.log(response)
                setOpenBackdrop(false);
                //closeLoading();
                dispatch({
                    type: "OPEN_SNACKBAR",
                    openMessage: {
                        open: true,
                        title: "Error",
                        message: "Could not add Employee, check your data",
                        alert: "error",
                    },
                });
            }
        })
           
        };

    const cancelFunction = (e) => {
        Array.from(document.querySelectorAll("form input")).forEach(
            input => (input.value = "")
          );
        setFormData({pReciveJobs:false});
    };
    return (
        
                <EmployeeForm
                    onCancel={cancelFunction}
                    onSave={saveFunction}
                    //getEmployeeData={getEmployeeData}
                    onInvertColors={handleInvertColors}
                    onChange={handleInputChange}
                    data={formData}
                />

    );
};

export default AddEmployee;