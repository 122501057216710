import React, { useEffect, useState } from "react";
import { useStateValue } from "../../Context/store";
import { delContact, getAllContact } from "../../Actions/UserAction";
import AddContact from "./AddContact";
//import LockSmithDeleteComponent from "../ReusableComponents/LockSmithDeleteComponent";
import UpdateContact from "./UpdateContact";
/*Import Reusable Components */
import LockSmithMainTable from "../ReusableComponents/LockSmithMainTable";
import LockSmithDeleteComponent from "../ReusableComponents/LockSmithDeleteComponent";

const columns = () => { 
    return [
    { title: "ID", field: "IdContact",cellStyle: {height: 40,padding:"0px 16px"} },
    // { title: "Tit", field: "Tit" },
    { title: "First Name", field: "FistName",cellStyle: {height: 40,padding:"0px 16px"} },
    //{ title: "MiddleName", field: "MiddleName" },
    { title: "Last Name", field: "LastName",cellStyle: {height: 40,padding:"0px 16px"} },
    { title: "Email", field: "Email",cellStyle: {height: 40,padding:"0px 16px"} },
    { title: "Phone", field: "Phone",cellStyle: {height: 40,padding:"0px 16px"} },
    //{ title: "Ext", field: "Ext" },
    { title: "Mobile", field: "Movil",cellStyle: {height: 40,padding:"0px 16px"} }
]};

const Contact = () => {
    const [{ allContact }] = useStateValue();
    const [contact, setContact] = useState(null);

    useEffect(() => {
        setContact(allContact ? allContact.contact : []);
    }, [allContact]);

    return (
        <React.Fragment>
            <LockSmithMainTable 
                title="Contact"
                data={contact}
                columns={columns()}
                addComponent={AddContact}
                updateComponent={UpdateContact}
                deleteComponent={LockSmithDeleteComponent}
                deleteAction={delContact}
                reducer={getAllContact}
                itemId={columns().field}
            />
        </React.Fragment>
    );
};

export default Contact;
