export const initialState = {
    typeemp: [],
};

const allTypeEmpReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_TYPEEMP":
            return {
                ...state,
                typeemp: action.typeemp,
            };

        default:
            return state;
    }
};

export default allTypeEmpReducer;
