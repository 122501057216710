import snackbarReducer from "./snackbarReducer";
import allTechniciansReducer from "./allTechniciansReducer";
import allJobsReducer from "./allJobsReducer";
import allSkilsReducer from "./allSkillsReducer";
import allLevelsReducer from "./allLevelsReducer";
import allTypeEmpReducer from "./allTypeEmpReducer";
import allLevelSkillReducer from "./allLevelSkillReducer";
import allDocumentEReducer from "./allDocumentEReducer";
import allProfileDocReducer from "./allProfileDocReducer";
import allRoleReducer from "./allRoleReducer";
import allBankReducer from "./allBankReducer";
import allAddressTypeReducer from "./allAddressTypeReducer";
import allATCReducer from "./allATCReducer";
import allUserReducer from "./allUserReducer";
import allMoneyReducer from "./allMoneyReducer";
import allBrandReducer from "./allBrandReducer";
import allCategoryReducer from "./allCategoryReducer";
import allSubCatReducer from "./allSubCatReducer";
import allMUnitReducer from "./allMUnitReducer";
import allTAXReducer from "./allTaxReducer";
import allProductReducer from "./allProductsReducer";
import allContactReducer from "./allContactReducer";
import allDepartmentReducer from "./allDepartmentReducer";
import allEmployeeReducer from "./allEmployeeReducer";
import allAccountReducer from "./allAccountReducer";
import allCompanyReducer from "./allCompanyReducer";
import allHistoryComReducer from "./allCompanyReducer";
import allCountryReducer from "./allCountryReducer";
//import allMessageReducer from "./allMessageReducer";
import allCustomerReducer from "./allCustomerReducer";
import allPriceListReducer from "./allPriceListReducer";
import allDocEmployeeReducer from "./allDocEmployee";
import allDocumentCReducer from "./allDocumentCReducer";
import allDocCustomerReducer from "./allDocCustomerReducer";
import allBranchReducer from "./allBranchReducer";
import allClassesReducer from "./allClassesReducer";
import userAuthReducer from "./userAuthReducer";
import allAddressReducer from "./allAddressReducer";
import allCitiesReducer from "./allCitiesReducer";
import allServiceReducer from "./allServiceReducer";
import allWarehouseReducer from "./allWarehouseReducer";
import allYearReducer from "./allYearReducer";
import allStateReducer from "./allStateReducer";
import allJobTypeReducer from "./allJobTypeReducer";

export const mainReducer = (
    {
        snackbar,
        allTechnicians,
        allJobs,
        allSkills,
        allLevels,
        allTypeEmp,
        allLevelSkill,
        allDocumentE,
        allProfileDoc,
        allBranch,
        allRole,
        allBank,
        allAddressType,
        allATC,
        allUser,
        allMoney,
        allBrand,
        allCategory,
        allSubCat,
        allMUnit,
        allTAX,
        allProduct,
        allContact,
        allDepartment,
        allEmployee,
        allAccount,
        allCompany,
        allHistoryCom,
        allCountry,
        //allMessage,
        allCustomer,
        allPriceList,
        allDocEmployee,
        allDocumentC,
        allDocCustomer,
        allClasses,
        userAuth,
        allAddress,
        allCities,
        allService,
        allWarehouse,
        allYear,
        allState,
        allJobType,
        
    },
    action
) => {
    return {
        snackbar: snackbarReducer(snackbar, action),
        allTechnicians: allTechniciansReducer(allTechnicians, action),
        allJobs: allJobsReducer(allJobs, action),
        allSkills: allSkilsReducer(allSkills, action),
        allLevels: allLevelsReducer(allLevels, action),
        allTypeEmp: allTypeEmpReducer(allTypeEmp, action),
        allLevelSkill: allLevelSkillReducer(allLevelSkill, action),
        allDocumentE: allDocumentEReducer(allDocumentE, action),
        allProfileDoc: allProfileDocReducer(allProfileDoc, action),
        allBranch: allBranchReducer(allBranch, action),
        allRole: allRoleReducer(allRole, action),
        allBank: allBankReducer(allBank, action),
        allAddressType: allAddressTypeReducer(allAddressType, action),
        allATC: allATCReducer(allATC, action),
        allUser: allUserReducer(allUser, action),
        allMoney: allMoneyReducer(allMoney, action),
        allBrand: allBrandReducer(allBrand, action),
        allCategory: allCategoryReducer(allCategory, action),
        allSubCat: allSubCatReducer(allSubCat, action),
        allMUnit: allMUnitReducer(allMUnit, action),
        allTAX: allTAXReducer(allTAX, action),
        allProduct: allProductReducer(allProduct, action),
        allContact: allContactReducer(allContact, action),
        allDepartment: allDepartmentReducer(allDepartment, action),
        allEmployee: allEmployeeReducer(allEmployee, action),
        allAccount: allAccountReducer(allAccount, action),
        allCompany: allCompanyReducer(allCompany, action),
        allHistoryCom: allHistoryComReducer(allHistoryCom, action),
        allCountry: allCountryReducer(allCountry, action),
        //allMessage: allMessageReducer(allMessage, action),
        allCustomer: allCustomerReducer(allCustomer, action),
        allPriceList: allPriceListReducer(allPriceList, action),
        allDocEmployee: allDocEmployeeReducer(allDocEmployee, action),
        allDocumentC: allDocumentCReducer(allDocumentC, action),
        allDocCustomer: allDocCustomerReducer(allDocCustomer, action),
        allClasses: allClassesReducer(allClasses, action),
        userAuth: userAuthReducer(userAuth, action),
        allAddress: allAddressReducer(allAddress, action),
        allCities: allCitiesReducer(allCities, action),
        allService: allServiceReducer(allService, action),
        allWarehouse: allWarehouseReducer(allWarehouse, action),
        allYear: allYearReducer(allYear, action),
        allState: allStateReducer(allState, action),
        allJobType: allJobTypeReducer(allJobType, action),
    };
};
