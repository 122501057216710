export const initialState = {
    atc: [],
};

const allATCReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_ATC":
            return {
                ...state,
                atc: action.atc,
            };

        default:
            return state;
    }
};

export default allATCReducer;