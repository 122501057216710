import React, { useState } from "react";
import {
    Grid,
} from "@material-ui/core";
import { useStateValue } from "../../Context/store";
import { getAllAccount, insAccount } from "../../Actions/UserAction";

/** Import Icons */

/**Import Components */
import LockSmithTextField from "../ReusableComponents/LockSmithTextField";
import LockSmithSelect from "../ReusableComponents/LockSmithSelect";
import LockSmithComponentButtons from "../ReusableComponents/LockSmithComponentButtons";
/** Custom components & hooks */
import { LoadingComponent, useLoading } from "../../hooks/useLoading";
import { handleApiResponse } from "../../hooks/handleApiResponse";

/** Theme & Styles Import */
import { dialogStyle } from "../../Assets/styles/Styles";

const AddAccount = ({ openDialog, closeDialog }) => {
    const classes = dialogStyle();
    const { loading, openLoading, closeLoading } = useLoading();
    // eslint-disable-next-line no-empty-pattern
    const [{ allBank, allMoney }, dispatch] = useStateValue();
    const [accountData, setAccountData] = useState({});
    const bankFields = ["IdBank", "Descr"];
    const moneyFields = ["IdMoney", "Descr"];

    const dataValues = (e) => {
        const { name, value } = e.target;
        setAccountData((last) => ({
            ...last,
            [name]: value,
        }));
    };

    const saveFunction = async (e) => {
        e.preventDefault();
        openLoading();
        await insAccount(accountData)
            .then((response) => handleApiResponse(response, closeLoading, dispatch, getAllAccount, cancelFunction, "Add Account"));
    };

    const cancelFunction = () => {
        Array.from(document.querySelectorAll("form input")).forEach(
            input => (input.value = "")
          );
        setAccountData({});
    };
    return (
            <form onSubmit={saveFunction}>
                    <Grid
                        container
                        spacing={2}
                        alignContent="center"
                        justifyContent="center"
                    >
                        <Grid item xs={12} sm={4}>
                            <LockSmithTextField
                            label="Description"
                            name="pDescr"
                            labelWidth="33%"
                            onChange={dataValues}
                            value={accountData.pDesc}
                            required={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <LockSmithSelect 
                            label="Select Bank" 
                            name="pIdBank" 
                            value={accountData.pIdBank} 
                            valueId="pIdBank" 
                            data={allBank} 
                            handler={dataValues} 
                            fields={bankFields}
                            required={true}
                             />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <LockSmithSelect 
                                label="Select Money" 
                                name="pIdMon" 
                                value={accountData.pIdMon} 
                                valueId="pIdMon" 
                                data={allMoney} 
                                handler={dataValues} 
                                fields={moneyFields}
                                required={true}
                            />
                        </Grid>
                       
                        
                        
                    </Grid>
                    <LockSmithComponentButtons closeHandler={cancelFunction} />
            </form>
    );
};

export default AddAccount;
