export const initialState = {
    address: [],
};

const allAddressReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_ADDRESS":
            return {
                ...state,
                address: action.address,
            };
        default:
            return state;
    }
};

export default allAddressReducer;
