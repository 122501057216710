import React, { useEffect, useState } from "react";
import { useStateValue } from "../../Context/store";
import { deleteLevel, getAllLevels } from "../../Actions/UserAction";
import AddLevel from "./AddLevel";
import UpdateLevel from "./UpdateLevel";
import LockSmithDeleteComponent from "../ReusableComponents/LockSmithDeleteComponent";
/* Import Reusable Components */
import LockSmithMainTable from "../ReusableComponents/LockSmithMainTable";

const columns = () => { 
    return [
    { title: "KEY", field: "KEY" ,cellStyle: {height: 40,padding:"0px 16px"}},
    { title: "DESCRIPTION", field: "DESCRIPTION",cellStyle: {height: 40,padding:"0px 16px"} },
]};

const Level = () => {
    const [{ allLevels }] = useStateValue();
    const [levels, setLevels] = useState([]);

    useEffect(() => {
        setLevels(allLevels ? allLevels.levels : []);
    }, [allLevels]);

    return (
        <LockSmithMainTable
                title="Level"
                data={levels}
                columns={columns()}
                addComponent={AddLevel}
                updateComponent={UpdateLevel}
                deleteComponent={LockSmithDeleteComponent}
                deleteAction={deleteLevel}
                reducer={getAllLevels}
                itemId={columns().field}
        />
    );
};

export default Level;
