import {
    Avatar,
    Dialog,
    DialogTitle,
    DialogContent,
    Grid,
    IconButton,
} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { PhotoCamera } from "@material-ui/icons";
import moment from "moment";
import React,{useState,useEffect} from 'react'
import  "./AddEmployee.css"
import WorkForm from "./WorkForm"
import ContactForm from "./Contact"
import DocumentsForm from "./Documents"
import CustomButton from "../../Utilities/CustomButton"
import AddAddress from "../Address/AddAddress";
import AddLevel from "../Levels/AddLevel";
import AddTypeEmp from "../TypeEmp/AddTypeEmp";
import { getAllAddressByClass, getPhoto, getAllAddressTypeByClass } from "../../Actions/UserAction";

import Save from "@material-ui/icons/Save";
import Close from "@material-ui/icons/Close";

import ProfileIcon from "../../Assets/Icons/Profile.svg";
import LockSmithSwitch from "../ReusableComponents/LockSmithSwitch";
import DropDownMenu from "../../Utilities/DropDownMenu"

import LocksmithEntryId from "../ReusableComponents/LockSmithEntryId";
import LockSmithSelect from "../ReusableComponents/LockSmithSelect";
import LockSmithTextField from "../ReusableComponents/LockSmithTextField";

/** import Tabs */
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { useStateValue } from "../../Context/store";

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`nav-tabpanel-${index}`}
        aria-labelledby={`nav-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `nav-tab-${index}`,
      'aria-controls': `nav-tabpanel-${index}`,
    };
  }
  
  function LinkTab(props) {
    return (
      <Tab
        component="a"
        onClick={(event) => {
          event.preventDefault();
        }}
        {...props}
      />
    );
  }

const useStyles = makeStyles({
    root: {
    },
    rootTab: {
        flexGrow: 1,
        backgroundColor: "#f3f3f3",
      },
    backgroundTab:{
        backgroundColor:"#fff"
    },
    textForm: {
        fontSize: 14, 
        fontWeight: "400", 
        color:"rgb(136,136,136)",
        width:"100%"
    },
    borderForm:{
        border:"solid 1px rgba(0, 0, 0, 0.23) !important",
        padding:"9px !important",
        width:"92% !important"
    },
    cardMargin:{
        margin:"10px 0"
    },
    subtabs:{
        boxShadow:"none"
    },
    subtabOption:{
        backgroundColor:"transparent !important"
    }

  });

const EmployeeForm = ({ onSave, onCancel, getEmployeeData, onInvertColors, onChange, isUpdate, data}) => {
    //console.log("data in EmployeeForm",data)
    const classes = useStyles();
    const [{ userAuth, allCountry}, dispatch,] = useStateValue();
    const [user,setUser] = useState({})
    /* Images Handler */
    const [eImages, setEImages] = useState([]);
    const [image, setImage] = useState([]);
    const [selectedImages, setSelectedImages] = useState([]);
    const [saveImgState, setSaveImgState] = useState(true);
    const [addresses, setAddresses] = useState({address:[]})
    const [addressTypeByClass, setAddressTypeByClass] = useState({});
    const [filteredAddress, setFilteredAddress] = useState({address:[]});
    const addressTypeFields = ["IdType", "Descr"];
    const countryFields = ["IdCountry", "Descr"];
    
    const imgHandler = (e) => {
        //console.log("call image handler" , e.target.files)
        onChange(e);
        setImage(e.target.files);
        if (e.target.files) {
            const fileArray = Array.from(e.target.files).map((file) =>
                window.URL.createObjectURL(file)
            );
            setSelectedImages(fileArray);
            Array.from(e.target.files).map((file) =>
                window.URL.revokeObjectURL(file)
            );
            setSaveImgState(false);

        }
    };

    const clearImages = () => {
        if(selectedImages.length > 0){
            setSelectedImages([]);
        }
        if(eImages.length > 0){
            setEImages([]);
        }
    }

    const renderImages = (source) => {
        //console.log("render images")
        if (source.length !== 0) {
            /* Render Avatar with image selected */
            return source.map((image, index) => {
                return (
                    <div className='img-container'>
                    <img src={image} alt="avatar" />
                </div>

                );
            });
        } else {
            if (eImages.length !== 0) {
                return eImages.slice(0, 3).map((item, index) => {
                    var path = item.Path;
                    return (
                        <Grid item sm={12}>
                            <Avatar
                                src={path}
                                style={{
                                    width: 100,
                                    height: 100,
                                }}
                            />
                        </Grid>
                    );
                });
            } else {
                /* Render Avatar without any image */
                return (
                    <div className='img-container'>
                        <img src={ProfileIcon} alt="avatar" />
                    </div>
                );
            }
        }
    };

/*     const ePhotosData = async () => {
        //openLoading()
        await getPhoto({ IdPhoto: data.IdPhoto }).then((response) => {
            if (response.status === 200) {
                //closeLoading()
                setEImages(response.data);
            }
        });
    }; */
    
    /* To fetch the initial addresses by IdClass */
    const fetchAddresses = async () => {
        await getAllAddressByClass(3).then(res => {
            if(res.status === 200){
                setAddresses({address:res.data})

            }else{
                console.log(res)
            }
        })
    }

    const fetchAddressTypeByIdClass = async (id) => {
        await getAllAddressTypeByClass(id).then((response) => {
            if (response.status === 200) {
                setAddressTypeByClass({atypes:response.data})
            } else {
                console.log(response)
            }
        });
    }

  /* To filter the previous addresses by IdClass and IdType*/
    const filterAddressData = (addresses) => {
        console.log("addresses",addresses)
        let filteredData = addresses[Object.keys(addresses)].filter((element, i) => 
        addresses[Object.keys(addresses)].findIndex((s) => element.IdType === s.IdType) === i)
        console.log("filteredData",filteredData)
        setFilteredAddress({address:filteredData});
    }
        
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
      };
    const [workToggle,setWorkToggle] = useState(true)
    const [contactToggle,setContactToggle] = useState(false)
    const [addresToggle,setAddressToggle] = useState(false)
    const [documentsToggle,setDocumentsToggle] = useState(false)
    
     /** Address Controls */
    const [newAddress, setNewAddress] = useState(false);
     const openNewAddress = () => {
         setNewAddress(true);
     };
     const closeNewAddress = () => {
         setNewAddress(false);
     };

    /** Level Controls */
    const [newLevel, setNewLevel] = useState(false);
    const openNewLevel = () => {
        setNewLevel(true);
    };
    const closeNewLevel = () => {
        setNewLevel(false);
    };

    /** EmpType Controls */
    const [newTypeEmp, setNewTypeEmp] = useState(false);
    const openNewTypeEmp = () => {
        setNewTypeEmp(true);
    };
    const closeNewTypeEmp = () => {
        setNewTypeEmp(false);
    };

/*     const handleInputChange = (e) =>{
        setEmployeeData({
            ...employeeData,
            date:moment().format("LL"),
            [e.target.name]:e.target.value
        })
    }
    const handleUserActiveness = () => {
        setEmployeeData({
            ...employeeData,
            isActive: !employeeData.isActive
        })
    }
    const handleWorkData = (workdata) => {
        setEmployeeData({
            ...employeeData,
            ...workdata
        })
    }

    const handleContactData = (contactData) => {
        setEmployeeData({
            ...employeeData,
            ...contactData
        })
    }

    const handleAddressData = (addressData) => {
        setEmployeeData({
            ...employeeData,
            ...addressData
        })
    } */

/*      useEffect(() => {
        getEmployeeData(employeeData.empPhoto)
    },[employeeData.empPhoto]) */

    useEffect(() => { 
        fetchAddresses()
        fetchAddressTypeByIdClass(3)
    }, [])

    useEffect(() => {
        if(data.imgData){
            //console.log("EmployeeForm data with image",data.imgData)
            //ePhotosData()
            //setEmployeeData(data)
            setEImages(data.imgData);
        }
    },[data.imgData])

    useEffect(() => { 
        setUser(userAuth ? userAuth.userAuth[0] : {})
        //console.log(userAuth.userAuth[0])
    }, [userAuth])


/*     useEffect(() => {
        filterAddressData(addresses)
    }, [addresses]) */


const handleWorkToggle = () => {
    setWorkToggle(true)
    setContactToggle(false)
    setAddressToggle(false)
    setDocumentsToggle(false)
}
const handleContactToggle = () => {
    setContactToggle(true)
    setWorkToggle(false)
    setAddressToggle(false)
    setDocumentsToggle(false)
}
const handleAddressToggle = () => {
    setAddressToggle(true)
    setWorkToggle(false)
    setContactToggle(false)
    setDocumentsToggle(false)
    
}
const handleDocumentsToggle = () => {
    setDocumentsToggle(true)
    setWorkToggle(false)
    setContactToggle(false)
    setAddressToggle(false)
}


    return (
        <React.Fragment>
        <AppBar position="static"  className={classes.subtabs}>
            <Tabs
            value={value}
            onChange={handleChange}
            className={classes.backgroundTab}
            indicatorColor="primary"
            aria-label="nav tabs example"
           >
            <LinkTab label="Personal"   {...a11yProps(0)} className={classes.subtabOption}></LinkTab>
            <LinkTab  label={"Contact"}  {...a11yProps(1)} className={classes.subtabOption}/>
            <LinkTab label={"Work"} {...a11yProps(2)} className={classes.subtabOption}/>
            </Tabs>
        </AppBar>
            <form onSubmit={onSave} className="form employee-container" >
                <TabPanel value={value} index={0}>
                    <Grid
                                    container
                                    spacing={2}
                                    alignContent="flex-start"
                                    justifyContent="center"
                                >
                                  <Grid item xs={6} lg={3} justifyContent="center">
                                            <div className="avatar">
                                                {renderImages(selectedImages)}
                                                <div className='edit-icon' style={{
                                                        width: "20px",
                                                    }}>
                                                <input
                                                    accept="image/*"
                                                    id="icon-employee-button-file"
                                                    name="empPhoto"
                                                    type="file"
                                                    onChange={imgHandler}
                                                    //required={!isUpdate}
                                                />
                                                <label htmlFor="icon-employee-button-file">
                                                    <IconButton
                                                        aria-label="upload picture"
                                                        component="span"
                                                        class="fas fa-pen"
                                                        style={{
                                                            cursor: "pointer",
                                                            
                                                        }}
                                                        disableRipple
                                                    >

                                                    </IconButton>
                                                </label>
                                                </div>
                                                
                                            </div>
                                    </Grid>
                                    <Grid item xs={12} lg={3}>
                                        <LocksmithEntryId label="Hire date" id={moment().format("LL").toUpperCase()} />
                                        <LocksmithEntryId label="ID" id={data.pIdEmployee}/>
                                    </Grid>
                                    <Grid item xs={12} lg={3}>
                                        {isUpdate ? <>
                                            <span className="label">active</span>

                                        <LockSmithSwitch
                                                checked={data.pActive}
                                                onChange={onChange}
                                                name="pActive"
                                                value={data.pActive}
                                            /></>: null}
                                    </Grid>
                                  
                                    <Grid item xs={12}>
                                        <div className="identity">
                                            <div className="sub-title">
                                                <h3 >
                                                    <span className="label">User:</span>
                                                    {user.UserName}
                                                </h3>
                                            </div>

                                        </div>
                                    </Grid>
                    </Grid>
                            
                    <Grid
                            container
                            spacing={2}
                            alignContent="center"
                            justifyContent="center"
                        >
                                    <Grid item  xs={12} lg={3}>
                                        <LockSmithTextField label="Name*" 
                                                            labelWidth="32%" 
                                                            name="pName" 
                                                            value={data.pName} 
                                                            onChange={onChange}
                                                            required />
                                    </Grid>
                                    <Grid item  xs={12} lg={3}>
                                        <LockSmithTextField label="Nickname" 
                                                    labelWidth="32%" 
                                                    name="pNickName" 
                                                    value={data.pNickName} 
                                                    onChange={onChange}
                                                    />
                                    </Grid>
                                    <Grid item  xs={12} lg={3}>
                                        <LockSmithTextField label="Mname" 
                                                    labelWidth="32%" 
                                                    name="pMname" 
                                                    value={data.pMname} 
                                                    onChange={onChange}
                                                    />
                                    </Grid>
                                    <Grid item  xs={12} lg={3}>
                                        <LockSmithTextField label="Lastname*" 
                                                    labelWidth="32%" 
                                                    name="pLastName" 
                                                    value={data.pLastName} 
                                                    onChange={onChange}
                                                    required
                                                    />
                                    </Grid>
                    </Grid>
                                    

                    <Grid
                        container
                        spacing={2}
                        alignContent="center"
                        justifyContent="center"
                    >
                                    <Grid item  xs={12} lg={3}>
                                        <LockSmithTextField label="N° license" 
                                                    labelWidth="100%" 
                                                    name="pNoLicense" 
                                                    value={data.pNoLicense} 
                                                    onChange={onChange}
                                                    />
                                    </Grid>
                                    <Grid item  xs={12} lg={3}>
                                        <LockSmithTextField label="SSN" 
                                                    labelWidth="100%" 
                                                    name="pSSN" 
                                                    value={data.pSSN} 
                                                    onChange={onChange}
                                                    />
                                    </Grid>
                                    <Grid item  xs={12} lg={3}>
                                        <DropDownMenu
                                                label="Place of Birth*"
                                                labelWidth="100%"
                                                options={allCountry}
                                                optionFields={countryFields}
                                                name="pIdPlaceBirth"
                                                onDropDownChange={onChange}
                                                value={data.pIdPlaceBirth}
                                                required={true}
                                            />
                                    </Grid>
                                    <Grid item  xs={12} lg={3}>
                                    <text style={{ fontSize: 14, fontWeight: "400", color:"rgb(136,136,136)"}}>
                                        Gender*
                                    </text>
                                        <select name="pGender" value={data.pGender} onChange={onChange} required style={{marginTop:5}} className={classes.borderForm}>
                                            <option value="">Select</option>
                                            <option value="MALE">Male</option>
                                            <option value="FEMALE">Female</option>
                                        </select>
                                    </Grid>
                    </Grid>
                               
                    <Grid
                            container
                            spacing={2}
                            alignContent="center"
                            justifyContent="center"
                        >
                                    <Grid item  xs={12} lg={3}>
                                    <text className={classes.textForm}>Birth date*</text>
                                        <input
                                         className={classes.borderForm}
                                        style={{marginTop:5}} 
                                            onChange={onChange}
                                            type="date"
                                            name="pBirthDate"
                                            value={data.pBirthDate}
                                            placeholder="DD/MM/YYYY"
                                            required
                                        />
                                    </Grid>
                                    <Grid item  xs={12} lg={3}>
                                        <text className={classes.textForm}>COM*</text>
                                        <input 
                                         className={classes.borderForm}
                                        style={{marginTop:5}} 
                                            placeholder="%"
                                            onChange={onChange}
                                            name="pCom"
                                            value={data.pCom}
                                            type="number"
                                            required
                                        />  
                                    </Grid>
                                    <Grid item  xs={12} lg={3}>
                                         
                                    <text className={classes.textForm}>Comments</text>
                                                <textarea 
                                                className={classes.borderForm}
                                                style={{marginTop:5}}
                                                name="pComments"
                                                value={data.pComments}
                                                rows="6" 
                                                onChange={onChange}></textarea>

                                    </Grid>
                    </Grid>
        </TabPanel>
        <TabPanel value={value} index={1}>
            <ContactForm
                inputHandler={onChange}
                data={data}
                addressData={addresses}
                callback={fetchAddresses}
                clickHandler={openNewAddress}
                />
        </TabPanel>
        <TabPanel value={value} index={2}>                                          
            <WorkForm 
                inputHandler={onChange}
                data={data}
                //sonInvertColors={onInvertColors}
                clickHandler={[openNewTypeEmp, openNewLevel]}
            />
        </TabPanel>                           
        <Grid
            container
            spacing={2}
            alignContent="center"
            justifyContent="flex-end"
        >
                <Grid item xs={6} lg={3}>
                    <CustomButton 
                                clas=""
                                Icon={Save}
                                text="SAVE"
                                //handleClick={onSave}
                                type="submit"
                            />
                </Grid>
                <Grid item xs={6} lg={3}>
                    <CustomButton 
                                clas="invert"
                                Icon={Close}
                                text="RESET"
                                handleClick={onCancel}
                            />
                </Grid>
        </Grid>
                          
                           
                
                </form>
                <Dialog
                open={newAddress}
                onClose={closeNewAddress}
                fullWidth>
                    <DialogTitle className={classes.dialogTitle}>
                        <Box className={classes.dialogTitleHead}>
                            <Box
                                style={{
                                    flexDirection: "row",
                                    display: "flex",
                                    width: "100%",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <text
                                    style={{
                                        fontSize: 28,
                                        fontWeight: "bold",
                                        margin: "0 auto",
                                    }}
                                >
                                Add Address
                                </text>
                            </Box>
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        <AddAddress openDialog={newAddress} closeDialog={closeNewAddress} data={addressTypeByClass} dataFields={addressTypeFields} callback={fetchAddresses} />
                    </DialogContent>
                </Dialog>

                <Dialog
                 open={newLevel}
                 onClose={closeNewLevel}
                 fullWidth>
                    <DialogTitle className={classes.dialogTitle}>
                        <Box className={classes.dialogTitleHead}>
                            <Box
                                style={{
                                    flexDirection: "row",
                                    display: "flex",
                                    width: "100%",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <text
                                    style={{
                                        fontSize: 28,
                                        fontWeight: "bold",
                                        margin: "0 auto",
                                    }}
                                >
                                Add level
                                </text>
                            </Box>
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                    <AddLevel openDialog={newLevel} closeDialog={closeNewLevel} />
                    </DialogContent>
                </Dialog>
                
                <Dialog
                 open={newTypeEmp}
                 onClose={closeNewTypeEmp}
                 fullWidth>
                    <DialogTitle className={classes.dialogTitle}>
                        <Box className={classes.dialogTitleHead}>
                            <Box
                                style={{
                                    flexDirection: "row",
                                    display: "flex",
                                    width: "100%",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <text
                                    style={{
                                        fontSize: 28,
                                        fontWeight: "bold",
                                        margin: "0 auto",
                                    }}
                                >
                                Add Type Emp
                                </text>
                            </Box>
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                    <AddTypeEmp openDialog={newTypeEmp} closeDialog={closeNewTypeEmp} />
                    </DialogContent>
                </Dialog>
                
                </React.Fragment>
)
}

export default EmployeeForm