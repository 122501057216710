export const initialState = {
    money: [],
};

const allMoneyReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_MONEY":
            return {
                ...state,
                money: action.money
            };

        default:
            return state;
    }
};

export default allMoneyReducer;