import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { getAllAccount, uptAccount } from "../../Actions/UserAction";
import { useStateValue } from "../../Context/store";

/** Import Icons */

/**Import Components */
import LockSmithTextField from "../ReusableComponents/LockSmithTextField";
import LockSmithSelect from "../ReusableComponents/LockSmithSelect";
import LocksmithEntryId from "../ReusableComponents/LockSmithEntryId";
import LockSmithComponentButtons from "../ReusableComponents/LockSmithComponentButtons";

/** Theme & Styles Import */
import { dialogStyle } from "../../Assets/styles/Styles";

const UpdateAccount = ({ openDialog, closeDialog, data }) => {
  const classes = dialogStyle();
  const [openBackdrop, setOpenBackdrop] = useState(false);
  // eslint-disable-next-line no-empty-pattern
  const [{ allBank, allMoney }, dispatch] = useStateValue();
  const [accountData, setAccountData] = useState({});
  const bankFields = ["IdBank", "Descr"];
  const moneyFields = ["IdMoney", "Descr"];

  const dataValues = (e) => {
    const { name, value } = e.target;
    setAccountData((last) => ({
      ...last,
      [name]: value,
    }));
  };

  const saveFunction = async (e) => {
    e.preventDefault();
    setOpenBackdrop(true);
    await uptAccount(accountData).then((response) => {
      if (response.status === 200) {
        setOpenBackdrop(false);
        dispatch({
          type: "OPEN_SNACKBAR",
          openMessage: {
            open: true,
            title: "Success",
            message: response.data.output.pmensaje,
            alert: "success",
          },
        });
        getAllAccount(dispatch);
        closeDialog();
      } else {
        setOpenBackdrop(false);
        dispatch({
          type: "OPEN_SNACKBAR",
          openMessage: {
            open: true,
            title: "Error",
            message: response.data.msg,
            alert: "error",
          },
        });
      }
    });
  };

  useEffect(() => {
    setAccountData({
      pIdAccount: data.IdAccount,
      pDescr: data.Desc,
      pIdBank: data.IdBank,
      pIdMon: data.IdMon,
    });
  }, [data]);

  const resetForm = () => {
    setAccountData({
      pIdAccount: data.IdAccount,
      pDescr: data.Desc,
      pIdBank: data.IdBank,
      pIdMon: data.IdMon,
    });
  };

  return (
    <form onSubmit={saveFunction}>
      <Grid container spacing={2} alignContent="center" justifyContent="center">
        <Grid item xs={12}>
          <LocksmithEntryId label="Id Account" id={accountData.pIdAccount} />
        </Grid>
        <Grid item xs={12} lg={4}>
          <LockSmithTextField
            label="Description"
            name="pDescr"
            labelWidth="33%"
            onChange={dataValues}
            value={accountData.pDescr}
            required={true}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <LockSmithSelect
            label="Select Bank"
            name="pIdBank"
            value={accountData.pIdBank}
            valueId="pIdBank"
            data={allBank}
            handler={dataValues}
            fields={bankFields}
            required={true}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <LockSmithSelect
            label="Select Money"
            name="pIdMon"
            value={accountData.pIdMon}
            valueId="pIdMon"
            data={allMoney}
            handler={dataValues}
            fields={moneyFields}
            required={true}
          />
        </Grid>
      </Grid>

      <LockSmithComponentButtons closeHandler={resetForm} />
    </form>
  );
};

export default UpdateAccount;
