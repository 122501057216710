export const initialState = {
    year: [],
};

const allYearReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_YEAR":
            return {
                ...state,
                year: action.year
            };

        default:
            return state;
    }
};

export default allYearReducer;