import React, { useEffect, useState } from "react";
import { useStateValue } from "../../Context/store";
import { deleteSkill, getAllSkills } from "../../Actions/UserAction";
import AddSkill from "./AddSkill";
import UpdateSkill from "./UpdateSkill";
import LockSmithDeleteComponent from "../ReusableComponents/LockSmithDeleteComponent";
/*Import Reusable Components */
import LockSmithMainTable from "../ReusableComponents/LockSmithMainTable";

const columns = () => { 
    return [
    { title: "ID", field: "KEY" ,cellStyle: {height: 40,padding:"0px 16px"}},
    { title: "DESCRIPTION", field: "DESCRIPTION",cellStyle: {height: 40,padding:"0px 16px"} },
]};

const Skill = () => {
    const [{ allSkills }] = useStateValue();
    const [skills, setSkills] = useState(null);

    useEffect(() => {
        setSkills(allSkills ? allSkills.skills : []);
        //console.log(allSkills,"skills.js")
    }, [allSkills]);

    return (
        <React.Fragment>
            <LockSmithMainTable 
                title="Skills"
                data={skills}
                columns={columns()}
                addComponent={AddSkill}
                updateComponent={UpdateSkill}
                deleteComponent={LockSmithDeleteComponent}
                deleteAction={deleteSkill}
                reducer={getAllSkills}
                itemId={columns().field}
            />
        </React.Fragment>
    );
};

export default Skill;
