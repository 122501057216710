import {
    Backdrop,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { getAllRoles, uptRole } from "../../Actions/UserAction";
import { useStateValue } from "../../Context/store";

/**Import Icons */
import Save from "@material-ui/icons/Save";
import Close from "@material-ui/icons/Close";
/**Import Components */
import LockSmithTextField from "../ReusableComponents/LockSmithTextField";
import LockSmithSwitch from "../ReusableComponents/LockSmithSwitch";
import LocksmithEntryId from "../ReusableComponents/LockSmithEntryId";

/** Theme & Styles Import */
import { dialogStyle } from "../../Assets/styles/Styles";

const UpdateRole = ({ openDialog, closeDialog, data }) => {
    const classes = dialogStyle();
    const [openBackdrop, setOpenBackdrop] = useState(false);
    // eslint-disable-next-line no-empty-pattern
    const [{}, dispatch] = useStateValue();
    const [roleData, setRoleData] = useState({});
    const [checkControl, setCheckControl] = useState(false);

    const dataValues = (e) => {
        const { name, value } = e.target;
        setRoleData((last) => ({
            ...last,
            [name]: value,
        }));
    };

    const handleCheck = (e) => {
        setCheckControl(e.target.checked);
        if (e.target.checked) {
            setRoleData((last) => ({
                ...last,
                pAct: 1,
            }));
        } else {
            setRoleData((last) => ({
                ...last,
                pAct: 0,
            }));
        }
    };

    const saveFunction = async (e) => {
        e.preventDefault();
        setOpenBackdrop(true);
        await uptRole(roleData).then((response) => {
            if (response.status === 200) {
                setOpenBackdrop(false);
                dispatch({
                    type: "OPEN_SNACKBAR",
                    openMessage: {
                        open: true,
                        title: "Success",
                        message: response.data.output.pmensaje,
                        alert: "success",
                    },
                });
                getAllRoles(dispatch);
                closeDialog();
            } else {
                setOpenBackdrop(false);
                dispatch({
                    type: "OPEN_SNACKBAR",
                    openMessage: {
                        open: true,
                        title: "Error",
                        message: response.data.msg,
                        alert: "error",
                    },
                });
            }
        });
    };

    useEffect(() => {
        setRoleData({
            pIdRole: data.IdRole,
            pDescr: data.Descr,
            pAct: data.Active,
        });

    }, [data]);

    useEffect(() => {
        setCheckControl(data.Active);
    }, [data]);

    const restoreFunction = () =>{
        setRoleData({
            pIdRole: data.IdRole,
            pDescr: data.Descr,
            pAct: data.Active,
        });
        setCheckControl(data.Active);
    }
    return (
        <React.Fragment>
            
                <form onSubmit={saveFunction}>
                        <Grid
                            container
                            spacing={2}
                        >
                            <Grid item xs={6}>
                                <LocksmithEntryId 
                                    label="ID Role" 
                                    id={roleData.pIdRole}
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            spacing={2}
                        >
                            <Grid item  xs={12}>
                                <LockSmithTextField 
                                    label="Description" 
                                    labelWidth="32%" 
                                    name="pDescr" 
                                    value={roleData.pDescr} 
                                    onChange={dataValues} 
                                    required
                                />
                            </Grid>
                            
                            <Grid item  xs={12}>
                                    <text
                                        style={{
                                            fontSize: 14,
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Active
                                    </text>
                                    <LockSmithSwitch
                                        checked={checkControl}
                                        onChange={handleCheck}
                                        name="pActive"
                                        id="pActive"
                                        value={roleData.pAct}
                                    />
                            </Grid>
                        </Grid>
                    
                        <Grid
                            container
                            spacing={2}
                            alignContent="center"
                            justifyContent="flex-end"
                        >
                        <Grid item xs={6} lg={3}>
                                    <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    classes={{ root: classes.saveButton }}
                                    size="large"
                                    fullWidth
                                    endIcon={
                                        <div
                                            style={{
                                                width: 20,
                                                height: 20,
                                                marginTop: -9,
                                                marginLeft: 10,
                                            }}
                                        >
                                            <Save/>
                                        </div>
                                    }
                                >
                                    Save
                                </Button>    
                            </Grid>
                            <Grid item xs={6} lg={3}>
                                    <Button
                                    variant="contained"
                                    onClick={restoreFunction}
                                    classes={{ root: classes.cancelButton }}
                                    size="large"
                                    fullWidth
                                    endIcon={
                                        <div
                                            style={{
                                                width: 20,
                                                height: 20,
                                                marginTop: -9,
                                                marginLeft: 10,
                                            }}
                                        >
                                            <Close/>
                                        </div>
                                    }
                                >
                                    Restore
                                </Button>
                            </Grid>
                            
                        </Grid>
                        
                        
                </form>
        </React.Fragment>
    );
};

export default UpdateRole;
