import {
	Box,
	Grid,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import {
	insJob,
	getAllJobs,
	getAllStatesByCountry,
	getAllCitiesByState,
	getAllActiveCompanies,
} from "../../Actions/UserAction";
import { useStateValue } from "../../Context/store";


/**Import Components */
import { useForm } from "../../hooks/useForm";
import LockSmithTextField from "../ReusableComponents/LockSmithTextField";
import LockSmithSelect from "../ReusableComponents/LockSmithSelect";
import LockSmithComponentButtons from "../ReusableComponents/LockSmithComponentButtons";

/** Theme & Styles Import */
import { dialogStyle } from "../../Assets/styles/Styles";
import { useLoading } from "../../hooks/useLoading";

const AddJob = ({ openDialog, closeDialog }) => {
	const classes = dialogStyle();
	// eslint-disable-next-line no-empty-pattern
	const [
		{
			allCompany,
			allCountry,
			allState,
			allBranch,
			allAddress,
			allJobType,
		},
		dispatch,
	] = useStateValue();
	const { loading, openLoading, closeLoading } = useLoading();
	const [states, setStates] = useState({ states: [] });
	const [cities, setCities] = useState({ cities: [] });
	const [activeCompanies, setActiveCompanies] = useState({
		activeCompanies: [],
	});
	//const [statesDisabled, setStatesDisabled] = useState(true);
	//const [citiesDisabled, setCitiesDisabled] = useState(true);

	const { formData, handleInputChange, setFormData } = useForm({
		pIdCountry: 1,
		pIdState: 43,
		pIdCity: 1,
	});
	const companyFields = ["IdCompany", "Desc"];
	const branchFields = ["IdBranch", "Descr"];
	//const addressFields = ["IdAddress", "Address"];
	const countryFields = ["IdCountry", "Descr"];
	const stateFields = ["IdState", "Descr"];
	const cityFields = ["idCity", "Name"];
	const jobType = ["IdTypeJob", "Descr"];

	const saveJob = async (e) => {
		e.preventDefault();
		openLoading();
		await insJob(formData).then((response) => {
			if (
				response.status === 200 &&
				response.data.output.pmensaje.toUpperCase() == "OK"
			) {
				closeLoading();
				dispatch({
					type: "OPEN_SNACKBAR",
					openMessage: {
						open: true,
						title: "Success",
						message: response.data.output
							.pmensaje,
						alert: "success",
					},
				});
				getAllJobs(dispatch);
				cancelJob();
			} else if (response.status === 200) {
				closeLoading();
				dispatch({
					type: "OPEN_SNACKBAR",
					openMessage: {
						open: true,
						title: "Success",
						message: response.data.output
							.pmensaje,
						alert: "success",
					},
				});
			} else {
				console.log(response);
				closeLoading();
				dispatch({
					type: "OPEN_SNACKBAR",
					openMessage: {
						open: true,
						title: "Error",
						message: "Could not Add job, check your data",
						alert: "error",
					},
				});
			}
		});
	};

	const cancelJob = () => {
		setFormData({
			pIdCountry: 1,
			pIdState: 43,
			pIdCity: 1,
		});
		Array.from(document.querySelectorAll("form input")).forEach(
            input => (input.value = "")
          );
	};

	useEffect(() => {
		//console.log(formData);
		if (formData?.pIdCountry !== undefined) {
			getAllStatesByCountry(formData?.pIdCountry).then(
				(response) => {
					if (response?.status === 200) {
						//console.log(response.data);
						setStates({ states: response.data });
						/*                     if(response.data.length > 0){
                        setStatesDisabled(false);
                    } */
					}
				}
			);
		}
	}, [formData?.pIdCountry]);

	useEffect(() => {
		if (formData?.pIdState !== undefined) {
			//get Texas Cities
			getAllCitiesByState(formData?.pIdState).then(
				(response) => {
					if (response?.status === 200) {
						setCities({ cities: response.data });
					}
				}
			);
		}
	}, [formData?.pIdState]);

	useEffect(() => {
		if (openDialog && activeCompanies.activeCompanies.length === 0) {
			getAllActiveCompanies().then((response) => {
				if (response.status === 200) {
					setActiveCompanies({
						activeCompanies: response.data,
					});
				}
			});
		}
	}, [openDialog]);

	return (
			<form onSubmit={saveJob}>
					<Grid
						container
						spacing={2}
						alignContent="center"
						justifyContent="flex-end"
					>
							<Grid item sm={4} xs={4}>
								<LockSmithTextField
									label="Date*"
									labelWidth="20%"
									name="pDateReg"
									type="date"
									value={
										formData?.pDateReg
									}
									onChange={
										handleInputChange
									}
									required={true}
								/>
							</Grid>
							<Grid item sm={4} xs={4}>
								<LockSmithTextField
									label="Hr"
									labelWidth="20%"
									name="pTimeReg"
									type="time"
									value={
										formData?.pTimeReg
									}
									onChange={
										handleInputChange
									}
								/>
							</Grid>
							<Grid item sm={4} xs={4}>
								<LockSmithSelect
									label="Company*"
									labelWidth="20%"
									name="pIdCompany"
									value={
										formData?.pIdCompany
									}
									valueId="pIdCompany"
									data={activeCompanies}
									handler={
										handleInputChange
									}
									fields={companyFields}
									required={true}
								/>
							</Grid>
					</Grid>
					<Grid
						container
						spacing={2}
						alignContent="center"
						justifyContent="flex-end"
					>
						<Grid item sm={4} xs={4}>
							<LockSmithSelect
								label="Branch*"
								labelWidth="20%"
								name="pIdBranch"
								value={
									formData?.pIdBranch
								}
								valueId="pIdBranch"
								data={allBranch}
								handler={
									handleInputChange
								}
								fields={branchFields}
								required={true}
								defaultValue={5}
							/>
						</Grid>
						<Grid item sm={4} xs={4}>
							<LockSmithTextField
								label="Name*"
								//labelWidth="15%"
								name="pName"
								value={formData?.pName}
								onChange={
									handleInputChange
								}
								required={true}
							/>
						</Grid>
						<Grid item sm={4} xs={4}>
							<LockSmithSelect
								name="pIdCountry"
								value={
									formData?.pIdCountry
								}
								handler={
									handleInputChange
								}
								data={allCountry}
								label="Country*"
								labelWidth="22%"
								fields={countryFields}
								//disabled={countriesDisabled}
								required={true}
								defaultValue={1}
							/>
						</Grid>
					</Grid>	
					<Grid
						container
						spacing={2}
						alignContent="center"
						justifyContent="flex-end"
					>
						<Grid item sm={4} xs={4}>
							<LockSmithSelect
								name="pIdState"
								//value={formData?.pIdState}
								handler={
									handleInputChange
								}
								data={states}
								label="State*"
								labelWidth="22%"
								fields={stateFields}
								//disabled={statesDisabled}
								required={true}
								defaultValue={43}
							/>
						</Grid>
						<Grid item sm={4} xs={4}>
							<LockSmithSelect
								name="pIdCity"
								value={
									formData?.pIdCity
								}
								handler={
									handleInputChange
								}
								data={cities}
								label="City*"
								labelWidth="22%"
								fields={cityFields}
								//disabled={citiesDisabled}
								required={true}
								defaultValue={1}
							/>
						</Grid>
						<Grid item sm={4} xs={4}>
						</Grid>
					</Grid>
					<Grid
						container
						spacing={2}
						alignContent="center"
						justifyContent="flex-end"
					>
						<Grid item sm={12} xs={12}>
							<LockSmithTextField
								label="Address*"
								name="pAddress"
								multiline={true}
								rows={3}
								value={
									formData?.pAddress
								}
								onChange={
									handleInputChange
								}
								required={true}
							/>
						</Grid>
					</Grid>
					
					<Grid
						container
						spacing={2}
						alignContent="center"
						justifyContent="flex-end">
							<Grid item sm={4} xs={4}>
								<LockSmithTextField
									label="Zip Code"
									labelWidth="58%"
									name="pZipCode"
									type="number"
									value={
										formData?.pZipCode
									}
									onChange={
										handleInputChange
									}
								/>
							</Grid>
							<Grid item sm={4} xs={4}>
								<LockSmithTextField
									label="Phone*"
									labelWidth="33%"
									name="pPhone"
									type="number"
									value={formData?.pPhone}
									onChange={
										handleInputChange
									}
									required={true}
								/>
							</Grid>
							<Grid item sm={4} xs={4}>
								<LockSmithSelect
									label="Job Type*"
									labelWidth="20%"
									name="pIdJobType"
									value={
										formData?.pIdJobType
									}
									valueId="pIdJobType"
									data={allJobType}
									handler={
										handleInputChange
									}
									fields={jobType}
									required={true}
								/>
						</Grid>
						</Grid>
						
						
						
					<Grid
						container
						spacing={2}
						alignContent="center"
						justifyContent="flex-end">

						<Grid item sm={12} xs={12}>
							<LockSmithTextField
								label="Comments*"
								//labelWidth="15%"
								name="pComments"
								multiline={true}
								rows={4}
								value={
									formData?.pComments
								}
								onChange={
									handleInputChange
								}
								required={true}
							/>
						</Grid>
					</Grid>
								
					<LockSmithComponentButtons
						closeHandler={cancelJob}
					/>

			</form>
		
	);
};

export default AddJob;
