export const initialState = {
    jobs: [],
};

const allJobsReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_JOBS":
            return {
                ...state,
                jobs: action.jobs,
            };

        default:
            return state;
    }
};

export default allJobsReducer;
