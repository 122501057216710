import {
  Avatar,
  Box,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import { PhotoCamera } from "@material-ui/icons";
import { withStyles } from "@material-ui/styles";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { getAllServices, insService } from "../../Actions/UserAction";
import { ReactComponent as AddIcon } from "../../Assets/Icons/Add.svg";
import { ReactComponent as ProductsIcon } from "../../Assets/Icons/Products.svg";
import { dialogStyle } from "../../Assets/styles/Styles";
import { useStateValue } from "../../Context/store";
import { useForm } from "../../hooks/useForm";
import { LoadingComponent, useLoading } from "../../hooks/useLoading";
import LockSmithComponentButtons from "../ReusableComponents/LockSmithComponentButtons";
import LockSmithSelect from "../ReusableComponents/LockSmithSelect";
import LockSmithSwitch from "../ReusableComponents/LockSmithSwitch";
import LockSmithTextField from "../ReusableComponents/LockSmithTextField";
import ClassTab from "./TabViews/ClassTab";

/* Styled Tabs */
const StyledTab = withStyles((theme) => ({
  root: {
    color: "#FFD162",
    backgroundColor: "#232323",
    fontSize: 16,
    fontWeight: "bold",
    border: "5px solid #232323",
    borderRadius: 15,
    marginBottom: 5,
    marginTop: 5,
  },
  selected: {
    color: "#232323",
    border: "2px solid #232323",
    backgroundColor: "#FFD162",
  },
}))((props) => <Tab disableRipple {...props} />);

/* Tab Panel */
const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Typography>{children}</Typography>}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const AddService = ({ openDialog, closeDialog }) => {
  const classes = dialogStyle();
  const [{ userAuth, allMoney, allTAX, allMUnit }, dispatch] = useStateValue();
  const { formData, handleInputChange, setFormData } = useForm({
    pActive: true,
  });
  const { loading, openLoading, closeLoading } = useLoading();
  const [user, setUser] = useState([]);
  const moneyFields = ["IdMoney", "Descr"];
  const taxFields = ["IdTax", "Descr"];
  const munitFields = ["IdUMeasure", "Descr"];

  /** Images Handler */
  // eslint-disable-next-line no-unused-vars
  const [pImages, setPImages] = useState([]);
  const [images, setImages] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const imgHandler = (e) => {
    setImages(e.target.files);
    if (e.target.files) {
      const fileArray = Array.from(e.target.files).map((file) =>
        window.URL.createObjectURL(file)
      );
      setSelectedImages((prevImages) => prevImages.concat(fileArray));
      Array.from(e.target.files).map((file) =>
        window.URL.revokeObjectURL(file)
      );
    }
  };
  const renderImages = (source) => {
    if (source.length !== 0) {
      return source.map((image, index) => {
        return (
          <Grid item>
            <Avatar
              src={image}
              style={{
                width: 150,
                height: 150,
              }}
            />
          </Grid>
        );
      });
    } else {
      if (pImages.length !== 0) {
        return pImages.slice(0, 3).map((item, index) => {
          var path = item.Path;
          return (
            <Grid item sm={12}>
              <Avatar
                src={path}
                style={{
                  width: 100,
                  height: 100,
                }}
              />
            </Grid>
          );
        });
      } else {
        return (
          <>
            <Grid item sm={12} lg={4}>
              <IconButton
                aria-label="upload picture"
                component="span"
                style={{
                  border: "solid 4px #3f4d67",
                  backgroundColor: "#3f4d67",
                }}
                disabled
              >
                <PhotoCamera
                  style={{
                    fontSize: 80,
                    color: "#FFF",
                  }}
                />
              </IconButton>
            </Grid>
          </>
        );
      }
    }
  };

  /* Tab Handlers */
  const [tabValue, setTabValue] = useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  /** Save Data */
  const saveFunction = async (e) => {
    e.preventDefault();
    const data = JSON.stringify(formData);
    const dataForm = new FormData();
    dataForm.append("data", data);
    for (const key of Object.keys(images)) {
      dataForm.append("img", images[key]);
    }
    openLoading();
    await insService(dataForm).then((response) => {
      if (response.status === 200) {
        closeLoading();
        dispatch({
          type: "OPEN_SNACKBAR",
          openMessage: {
            open: true,
            title: "Success",
            message: response.data.output.pmensaje,
            alert: "success",
          },
        });
        getAllServices(dispatch);
        cancelFunction();
      } else {
        closeLoading();
        dispatch({
          type: "OPEN_SNACKBAR",
          openMessage: {
            open: true,
            title: "Error",
            message: response.data.msg,
            alert: "error",
          },
        });
      }
    });
  };

  /** Cancel and clean */
  const cancelFunction = () => {
    setTabValue(0);
    setSelectedImages([]);
    setImages([]);
    setFormData({});
    closeDialog();
  };

  /** Init Config */
  useEffect(() => {
    setUser(userAuth ? userAuth.userAuth : []);
  }, [userAuth]);

  return (
    <>
      <form onSubmit={saveFunction}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={4}>
            <text
              style={{
                fontSize: 14,
                fontWeight: "bold",
              }}
            >
              MOD DATE: {}
            </text>
            <text
              style={{
                fontSize: 14,
                fontWeight: "bold",
              }}
            >
              {moment().format("LLLL").toUpperCase()}
            </text>
            <br></br>
            <text
              style={{
                fontSize: 14,
                fontWeight: "bold",
              }}
            >
              User:
            </text>
            <text
              style={{
                fontSize: 14,
                fontWeight: "bold",
              }}
            >
              {user.length !== 0 ? user[0].UserName : "USER"}
            </text>
          </Grid>

          <Grid item sm={12} xs={12} lg={3}>
            <Box
              style={{
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
                marginTop: 0,
                width: "98%",
              }}
            >
              <input
                accept="image/*"
                id="icon-button-file"
                name="img"
                type="file"
                onChange={imgHandler}
                hidden
                multiple
              />
              <label htmlFor="icon-button-file">
                <IconButton
                  aria-label="upload picture"
                  component="span"
                  style={{
                    border: "solid 4px #3f4d67",
                    backgroundColor: "#3f4d67",
                  }}
                >
                  <PhotoCamera
                    style={{
                      fontSize: 80,
                      color: "#FFF",
                    }}
                  />
                </IconButton>
              </label>
              <div className="delete-icon" style={{ zIndex: 1000 }}>
                <input
                  //accept="image/*"
                  id="icon-button-trash"
                  name="img-trash"
                  type="button"
                  hidden
                />
              </div>
            </Box>
          </Grid>
          <Grid item sm={12} xs={12} lg={3} justifyContent="center">
            {renderImages(selectedImages)}
          </Grid>
        </Grid>

        <Grid
          alignItems="center"
          container
          justifyContent="flex-start"
          spacing={2}
        >
          <Grid item xs={12} lg={4}>
            <LockSmithTextField
              label="Type*"
              labelWidth="22%"
              name="pType"
              onChange={handleInputChange}
              value={formData.pType}
              required
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <LockSmithTextField
              label="Cost*"
              labelWidth="35%"
              name="pCost"
              onChange={handleInputChange}
              value={formData.pCost}
              type="number"
              required={true}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <LockSmithSelect
              name="pIdMed"
              value={formData.pIdMed}
              handler={handleInputChange}
              data={allMUnit}
              label="Munit*"
              fields={munitFields}
              required={true}
            />
          </Grid>
        </Grid>
        <Grid alignItems="center" container justifyContent="center" spacing={2}>
          <Grid item xs={12} lg={4}>
            <LockSmithSelect
              name="pIdMon"
              value={formData.pIdMon}
              labelWidth="33%"
              handler={handleInputChange}
              data={allMoney}
              label="Money*"
              fields={moneyFields}
              required={true}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <LockSmithSelect
              name="pIdTax"
              value={formData.pIdTax}
              handler={handleInputChange}
              data={allTAX}
              label="Tax*"
              fields={taxFields}
              required={true}
            />
          </Grid>
          <Grid item xs={12} lg={4}></Grid>
        </Grid>
        <Grid alignItems="center" container justifyContent="center" spacing={2}>
          <Grid item sm={12} xs={12}>
            <LockSmithTextField
              label="Description*"
              labelWidth="20%"
              multiline={true}
              name="pDescr"
              onChange={handleInputChange}
              rows={5}
              value={formData.pDescr}
              required={true}
            />
          </Grid>
          <ClassTab handleClassData={handleInputChange} />
        </Grid>

        <LockSmithComponentButtons closeHandler={cancelFunction} />
      </form>
    </>
  );
};

export default AddService;
