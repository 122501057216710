import {
    Backdrop,
    Box,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
} from "@material-ui/core";
import MaterialTable from "material-table";
import { Alert } from '@material-ui/lab'
import React, { useEffect, useState } from "react";
import { getAllProfileDoc, insProfileDoc } from "../../Actions/UserAction";
import { useStateValue } from "../../Context/store";

/** Import Icons */
import { ReactComponent as EmployeesIcon } from "../../Assets/Icons/Employees.svg";
import { ReactComponent as AddIcon } from "../../Assets/Icons/Add.svg";
/**Import Components */
import LockSmithSelect from "../ReusableComponents/LockSmithSelect";
import LockSmithComponentButtons from "../ReusableComponents/LockSmithComponentButtons";
import { getAllTypeEmpNotProfDoc } from "../../Actions/UserAction"

/** Theme & Styles Import */
import { dialogStyle } from "../../Assets/styles/Styles";

const columns = () => { 
    return [
    { title: "ID", field: "IdDocument" },
    { title: "DESCRIPTION", field: "Descr" },
]};

const AddProfileDoc = ({ openDialog, closeDialog }) => {
    const profileDocFields = ["IdTypeEmp", "Descr"];
    const classes = dialogStyle();
    const [openBackdrop, setOpenBackdrop] = useState(false);
    // eslint-disable-next-line no-empty-pattern
    const [{ allDocumentE, allTypeEmp, allProfileDoc }, dispatch] = useStateValue();
    const [open, setOpen] = useState(false);
    const [docRequired, setDocRequired] = useState(true);
    const [documentEData, setDocumentEData] = useState([]);
    const [typeEmpData, setTypeEmpData] = useState([]);
    const [profileDocData, setProfileDocData] = useState({
        pidProfileDocs: [],
        //pidTypeEmp: 0,
    });

    const dataValues = (e) => {
        const { name, value } = e.target;
        setProfileDocData((last) => ({
            ...last,
            [name]: value,
        }));
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpen(false);
    }

    const handleDocumentSelection = (rows) => {
        let docs = [];
        for(let i = 0; i < rows.length; i++){
            docs.push(rows[i].IdDocument)
        }

        setProfileDocData(prevState => ({
            ...prevState,
            pidProfileDocs: docs,
        }));
        
    }

    const saveFunction = async (e) => {
        e.preventDefault();
        if(docRequired){
            setOpen(true);
            return;
        }
        setOpenBackdrop(true);
        await insProfileDoc(profileDocData).then((response) => {
            if (response.status === 200) {
                setOpenBackdrop(false);
                dispatch({
                    type: "OPEN_SNACKBAR",
                    openMessage: {
                        open: true,
                        title: "Success",
                        message: response.data.output.pmensaje,
                        alert: "success",
                    },
                });
                getAllProfileDoc(dispatch);
                cancelFunction();
            } else {
                setOpenBackdrop(false);
                dispatch({
                    type: "OPEN_SNACKBAR",
                    openMessage: {
                        open: true,
                        title: "Error",
                        message: response.data.msg,
                        alert: "error",
                    },
                });
            }
        });
    };

    const cancelFunction = () => {
        setProfileDocData({
            pidProfileDocs: [],
            pidTypeEmp: 0
        });
        const stateUpdated = documentEData.map((item) => {
            if (item.tableData.checked) {
              return {
                ...item,
                tableData: {checked: false},
              };
            } else {
              return item;
            }
          });
        setDocumentEData(stateUpdated);
    };

    const getAllTypeEmpNotAssigned = async () => {
        await getAllTypeEmpNotProfDoc().then((response) => {
            if(response.status === 200){
                setTypeEmpData({"typeemp": response.data});
            }
        });
    }

    useEffect(() => {
        getAllTypeEmpNotAssigned();
        allDocumentE &&
        allDocumentE.documentE.map((item, index) => {
                return setDocumentEData((last) => [
                    ...last,
                    {
                        tableData: { checked: false },
                        IdDocument: item.IdDocument,
                        Descr: item.Descr,
                    },
                ]);
            });
    }, [allDocumentE]);

    useEffect(() => { 
        getAllTypeEmpNotAssigned();
     }, [allProfileDoc]);

     useEffect(() => {
        if(profileDocData && profileDocData.pidProfileDocs.length > 0){
            setDocRequired(false);
            setOpen(false);
        }else{
            setDocRequired(true);
        }
    }
    ,[profileDocData]);


    return (
            <form onSubmit={saveFunction}>
                    <Grid
                        container
                        spacing={1}
                        alignContent="center"
                        justifyContent="center"
                    >
                        <LockSmithSelect 
                            label="Select emp type" 
                            name="pidTypeEmp" 
                            value={profileDocData.pidTypeEmp} 
                            valueId="pidTypeEmp" 
                            data={allTypeEmp} 
                            handler={dataValues} 
                            fields={profileDocFields} 
                            required={true}
                        />
                        <Grid item xs={12} sm={12}>
                            <div style={{opacity: open ? 1 : 0}}>
                                <Alert variant="filled" onClose={handleClose} severity="error" >
                                Select at least 1 document!
                                </Alert>
                            </div>
                            <MaterialTable
                                components={{ Container: "main" }}
                                title="Select documents:"
                                data={documentEData}
                                columns={columns()}
                                localization={{
                                    body: {
                                        emptyDataSourceMessage:
                                            "No Documents added yet",
                                    },
                                    toolbar: {
                                        searchPlaceholder: "Search Documents",
                                        nRowsSelected: "{0} document(s) selected",
                                    },
                                }}
                                options={{
                                    selection: true,
                                    showSelectAllCheckbox: false,
                                    cellStyle: {
                                        padding:5
                                      },
                                    headerStyle: {
                                        backgroundColor: "#369",
                                        fontWeight: "bolder",
                                        color:"#FFF",
                                        padding:5
                                    }
                                }}
                                onSelectionChange={(rows) => {
                                    handleDocumentSelection(rows);
                                }}
                            />
                        </Grid>
                    </Grid>
                <LockSmithComponentButtons closeHandler={cancelFunction}/>

            </form>
    );
};

export default AddProfileDoc;
