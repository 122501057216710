import React, { useEffect, useState } from "react";
import { delService, getAllServices } from "../../Actions/UserAction";
import { useStateValue } from "../../Context/store";
import LockSmithDeleteComponent from "../ReusableComponents/LockSmithDeleteComponent";
import LockSmithMainTable from "../ReusableComponents/LockSmithMainTable";
import AddService from "./AddService";
import UpdateService from "./UpdateService";

const columns = () => { 
    return [
    { title: "ID", field: "IdService",cellStyle: {height: 40,padding:"0px 16px"} },
    { title: "Description", field: "descr",cellStyle: {height: 40,padding:"0px 16px"} },
    { title: "Tax", field: "DescTax" ,cellStyle: {height: 40,padding:"0px 16px"}},
    { title: "MUnit", field: "DescMed",cellStyle: {height: 40,padding:"0px 16px"} },
    { title: "User", field: "UserName",cellStyle: {height: 40,padding:"0px 16px"} },
    { title: "SubCat", field: "DescSubCat",cellStyle: {height: 40,padding:"0px 16px"} },
    { title: "Money", field: "DescMon",cellStyle: {height: 40,padding:"0px 16px"} },
    { title: "Type", field: "Type",cellStyle: {height: 40,padding:"0px 16px"} },
    { title: "Cost", field: "Cost",cellStyle: {height: 40,padding:"0px 16px"} },
    { title: "Active", field: "Active",cellStyle: {height: 40,padding:"0px 16px"} },
]};

const Service = () => {
    const [{ allService }] = useStateValue();
    const [service, setService] = useState(null);

    useEffect(() => {
        setService(allService ? allService.service : []);
    }, [allService]);

    return (
        <React.Fragment>
            <LockSmithMainTable
                title="Service"
                data={service}
                columns={columns()}
                addComponent={AddService}
                updateComponent={UpdateService}
                deleteComponent={LockSmithDeleteComponent}
                deleteAction={delService}
                reducer={getAllServices}
                itemId={columns().field}
            />
        </React.Fragment>
    );
};

export default Service;
