import React, { useEffect, useState } from "react";
import { useStateValue } from "../../Context/store";
import { delCompany, getAllCompanies } from "../../Actions/UserAction";
import LockSmithMainTable from "../ReusableComponents/LockSmithMainTable";
import AddCompany from "./AddCompany";
import LockSmithDeleteComponent from "../ReusableComponents/LockSmithDeleteComponent";
import UpdateCompany from "./UpdateCompany";

const columns = () => { 
    return [
    {title: "ID", field: "IdCompany",cellStyle: {height: 40,padding:"0px 16px"}},
    {title: "Description", field: "Desc",cellStyle: {height: 40,padding:"0px 16px"}},
    {title: "Comments", field: "Comments",cellStyle: {height: 40,padding:"0px 16px"}},
    {title: "%Com", field: "%Com",cellStyle: {height: 40,padding:"0px 16px"}},
    {title: "NotifyClosing", field: "NotifyClosing",cellStyle: {height: 40,padding:"0px 16px"}},
    {title: "NotifyCancel", field: "NotifyCancel",cellStyle: {height: 40,padding:"0px 16px"}},
    //{title: "URL", field: "URL"},
    {title: "Phone", field: "Phone",cellStyle: {height: 40,padding:"0px 16px"}},
    //{title: "EIN", field: "EIN"},
    {title: "Address", field: "Address",cellStyle: {height: 40,padding:"0px 16px"}},
    {title: "Account", field: "Account",cellStyle: {height: 40,padding:"0px 16px"}},
    {title: "Active", field: "Active",cellStyle: {height: 40,padding:"0px 16px"}},
    {title: "EmailNotifica", field: "EmailNotifica",cellStyle: {height: 40,padding:"0px 16px"}}
]};

const Company = () => {
    const [{ allCompany }] = useStateValue();
    const [company, setCompany] = useState(null);

    useEffect(() => {
        setCompany(allCompany ? allCompany.company : []);
    }, [allCompany]);

    return (
        <React.Fragment>
            <LockSmithMainTable 
                title="Company"
                data={company}
                columns={columns()}
                addComponent={AddCompany}
                updateComponent={UpdateCompany}
                //deleteComponent={LockSmithDeleteComponent}
                //deleteAction={delCompany}
                reducer={getAllCompanies}
                itemId={columns().field}
            />
        </React.Fragment>
    );
};

export default Company;
