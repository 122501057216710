import {
    Box,
    MenuItem,
    Select,
} from "@material-ui/core";


const renderElement = (data, fields) => {
    console.log("fields-->",fields);
    console.log("data-->",data);
    if (data) {
        data[Object.keys(data)].map((item, index) => {
            console.log("item-->",item[fields[1]]);
            console.log("index-->", index);
            return (
                <MenuItem
                    key={index}
                    value={item[fields[0]]}
                >
                {item[fields[1]]}
                {' '}
                {item[fields[2]]}
                </MenuItem>
                );
            });
    } else {
        return (
            <MenuItem>
                Select another option
            </MenuItem>
        );
        }
    };

    const LockSmithSelect = (props) => (
            <Box
                style={{
                    display: "block",
                    padding: 1,
                    width: "100%",
                    alignItems: "center",
                }}
            >
            <Box
                style={{
                    flexDirection: "row",
                    display: "flex",
                    width: props.labelWidth ? props.labelWidth : "100%"
                }}
            >
                <text
                    style={{ fontSize: 14, fontWeight: 400, color:"#888", fontFamiliy:"'Open Sans',sans-serif" }}
                >
                    {props.label}
                </text>
            </Box>
            <Box
                style={{
                    flexDirection: "row",
                    display: "flex",
                    width: props.width ? props.width : "100%",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "10px",
                    marginBottom: "10px"
                }}
            >
                <Select
                    labelId={props.name}
                    name={props.name}
                    value={props.value ?? ""}
                    style={{
                        backgroundColor: props.disabled ? "#e6e6e6" : "#fff",
                        borderRadius: 5,
                    }}
                    
                    id={props.name}
                    onChange={props.handler}
                    variant="outlined"
                    required={!!props.required}
                    fullWidth
                    disabled={props.disabled ? props.disabled : false}
                    defaultValue={props.defaultValue ? props.defaultValue : null}
                >
                       {props.data
                        ? props.data[Object.keys(props.data)].map((item, index) => (
                                    <MenuItem
                                        value={item[props.fields[0]]}
                                        key={index}
                                    >
                                    {item[props.fields[1]]}
                                    {' '}
                                    {item[props.fields[2]]}
                                    </MenuItem>
                                ))
                        : ""}

                </Select>
            </Box>
            </Box>
        );

    export default LockSmithSelect;
