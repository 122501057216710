import React, { useState, useEffect } from "react";
import { useStateValue } from "../../Context/store";
import { getAllCustomers, uptCustomer } from "../../Actions/UserAction";
/** Theme & Styles Import */
import { dialogStyle } from "../../Assets/styles/Styles";
/* form */
import CustomerForm from "./CustomerForm"
import { useForm } from "../../hooks/useForm";
import { LoadingComponent, useLoading } from "../../hooks/useLoading";
/** Import Icons */

/** Utility Libraries */
import moment from "moment";


const UpdateCustomer = ({ openDialog, closeDialog, data }) => {
    const classes = dialogStyle();
    const { loading, openLoading, closeLoading } = useLoading();
    // eslint-disable-next-line no-empty-pattern
    const [{}, dispatch] = useStateValue();
    const { formData, handleInputChange, setFormData } = useForm({pReciveJobs:false});
    const [invertColors, setInvertColors] = useState(false)
    const CustomerAddresesIds = [19,18,20]

    const handleInvertColors = () => {
        setInvertColors(!invertColors)
    }

    const saveFunction = async (e) => {
        e.preventDefault();
        await uptCustomer(formData).then((response) => {
            if (response.status === 200) {
                closeLoading();
                dispatch({
                    type: "OPEN_SNACKBAR",
                    openMessage: {
                        open: true,
                        title: "Success",
                        message: response.data.output.pmensaje,
                        alert: "success",
                    },
                });
                getAllCustomers(dispatch);
                cancelFunction();
            } else {
                closeLoading();
                dispatch({
                    type: "OPEN_SNACKBAR",
                    openMessage: {
                        open: true,
                        title: "Error",
                        message: response.msg,
                        alert: "error",
                    },
                });
            }
        })
    };

    const cancelFunction = (e) => {
        setFormData({
            pActive: data.Active,
            pName: data.Name,
            pPhone: data.Phone,
            pRFC: data.RFC,
            pURL: data.URL,
            pEmail: data.email,
            pComments: data.Comments,
            pBalance: data.Balance,
            pCredit: data.Credit,
            pCreditDays: data.CreditDays,
            pCreditLimit: data.CreditLimit,
            pDiscount: data.Discount,
            pDiscount2: data.Discount2,
            pDiscount3: data.Discount3,
            pIdCustomer: data.IdCustomer,
            pIdListPrice: data.IdListPrice,
            //pIdTypeEmp: data.IdTypeEmp,
            pIdMon: data.IdMon,
            pIdTax: data.IdTax,
            pIdUser: data.IdUser,
        })
    };

    useEffect(() => {
        setFormData({
            pActive: data.Active,
            pName: data.Name,
            pPhone: data.Phone,
            pRFC: data.RFC,
            pURL: data.URL,
            pEmail: data.email,
            pComments: data.Comments,
            pBalance: data.Balance,
            pCredit: data.Credit,
            pCreditDays: data.CreditDays,
            pCreditLimit: data.CreditLimit,
            pDiscount: data.Discount,
            pDiscount2: data.Discount2,
            pDiscount3: data.Discount3,
            pIdCustomer: data.IdCustomer,
            pIdListPrice: data.IdListPrice,
            //pIdTypeEmp: data.IdTypeEmp,
            pIdMon: data.IdMon,
            pIdTax: data.IdTax,
            pIdUser: data.IdUser,
        })
    }, [data])

    return (
                <CustomerForm
                    onCancel={cancelFunction}
                    onSave={saveFunction}
                    //getEmployeeData={getEmployeeData}
                    onInvertColors={handleInvertColors}
                    onChange={handleInputChange}
                    isUpdate={true}
                    addressesIds={CustomerAddresesIds}
                    data={formData}
                />
    );
};

export default UpdateCustomer;
