import React, { Suspense } from "react";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import theme from "./Theme/Theme";
import { BrowserRouter } from "react-router-dom";

import { CircularProgress } from "@material-ui/core";

import NavigationContainer from "./NavigationContainer";

function App() {
    return (
        <BrowserRouter>
            <MuiThemeProvider theme={theme}>
                <Suspense
                    fallback={
                        <div>
                            <CircularProgress color="secondary" size={90} />
                        </div>
                    }
                >
                    <NavigationContainer />
                </Suspense>
            </MuiThemeProvider>
        </BrowserRouter>
    );
}

export default App;
