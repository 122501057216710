export const initialState = {
    employee: []
};

const allEmployeeReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_EMPLOYEE":
            return {
                ...state,
                employee: action.employee
            };

        default:
            return state;
    }
};

export default allEmployeeReducer;