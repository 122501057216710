export const initialState = {
    documentE: [],
};

const allDocumentEReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_DOCUMENT_E":
            return {
                ...state,
                documentE: action.documentE,
            };

        default:
            return state;
    }
};

export default allDocumentEReducer;
