export const initialState = {
    doccustomer: [],
};

const allDocCustomerReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_DOCCUSTOMER":
            return {
                ...state,
                doccustomer: action.doccustomer
            };

        default:
            return state;
    }
};

export default allDocCustomerReducer;