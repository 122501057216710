import React, {Fragment, useState, useEffect } from "react";
import {
    Box, Backdrop, Button, CircularProgress, Snackbar, Table,
    TableBody, TableCell, TableHead, TableRow
} from "@material-ui/core";
import { useStateValue } from "../../Context/store";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import AddBoxIcon from "@material-ui/icons/AddBox";
import CreateIcon from "@material-ui/icons/Create";
import DropDownMenu from "../../Utilities/DropDownMenu";
import { insCustomerDocument, getAllCustomerDocumentsByCustId } from "../../Actions/UserAction";
import moment from "moment";
import { Restore } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import CustomButton from "../../Utilities/CustomButton"
import SaveIcon from "../../Assets/Icons/Remember_me_Yes.svg";

// Creating styles
const useStyles = makeStyles({
    root: {
        "& > *": {
            borderBottom: "unset",
        },
    },
    table: {
        minWidth: 500,
    },
    snackbar: {
        bottom: "104px",
    },
});
  
function DocumentTableComponent({idCustomer, columnNames, addDisabled, data, callback, initDropDown, onCancel}) {
    // Creating style object
    const classes = useStyles();
    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [customerContacts, setCustomerContacts] = useState([]);
    // Defining a state named rows
    // which we can update by calling on setRows function
    const [rows, setRows] = useState([]);
    const [rowValue, setRowValue] = useState({pIdCustomer: idCustomer});
  
    // Initial states
    const [open, setOpen] = React.useState(false);
    const [saveDisabled, setSaveDisabled] = React.useState(true);
    const [resetDisabled, setResetDisabled] = React.useState(true);
    //const [isEdit, setEdit] = React.useState(false);
    //const [disable, setDisable] = React.useState(true);
    //const [showConfirm, setShowConfirm] = React.useState(false);
    //const [addDisabled, setAddDisabled] = React.useState(true);
    // Function For closing the alert snackbar
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };
  
    // Function For adding new row object
    const handleAdd = () => {
        setRows([
            ...rows,
            {
                id: data.IdDocument,
                document: data.Desc,
                date: data.DateMod,
                path: data.Path
            },
        ]);
        setRowValue({...rowValue,
          details:[ ...rowValue.details,
          {
            pIdDocument : data.IdDocument,
            pDate : moment().format("YYYY-MM-DD")
          }]
        })
        setSaveDisabled(false);
        setResetDisabled(false);
        callback(data.IdDocument)
    };

    const handleReset = () => {
        initDropDown()
        setRows([])
        setRowValue([])
        setSaveDisabled(true)
        setResetDisabled(true)
        setCustomerContacts(getAllDocumentsByCustomerId(idCustomer))
    }
  
    // Function to handle edit
    const handleEdit = (i) => {
        // If edit mode is true setEdit will 
        // set it to false and vice versa
        //setEdit(!isEdit);
    };
  
    // Function to handle save
    const handleSave = async () => {
        console.log(rowValue)
        setOpenBackdrop(true);
        await insCustomerDocument(rowValue).then((response) => {
            if (response.status === 200) {
                console.log(response.data)
                setOpenBackdrop(false);
                setSnackbarMessage(response.data.output.pmensaje);
                setOpen(true);
                //setDisable(true);
                setRows([]);
                setRowValue([])
                getAllDocumentsByCustomerId(idCustomer)
                setResetDisabled(true)
            } else {
                setOpenBackdrop(false);
                console.log(response)
                setOpen(true);
                //setDisable(true);
                setRows([]);
                setRowValue([])
                setResetDisabled(true)
            }
        });
    };

/*     const handleInputChange = (event) => {
        const { name, value, id } = event.target;
        let tempArray = rowValue.details;
        rowValue.details.forEach((item, index) => {
          if (item.pIdContact == id) {
            console.log("match")
            tempArray[index][name] = Number(value);
            
          }
        })
        console.log(tempArray)
        setRowValue({...rowValue, details: tempArray})
        setSaveDisabled(false)
        setResetDisabled(false)
      }  */
    
      const getAllDocumentsByCustomerId = async (id) => {
         getAllCustomerDocumentsByCustId(id).then((response) => {
          if (response.status === 200) {
            console.log(response.data)
            //construct the rows for Visual Display
            let tempArray = []
            response.data.forEach((item, index) => {
              tempArray[index] = {id: item.IdDocument,
                                document: item.Desc,
                                date: moment(item.DateMod).format("YYYY-MM-DD"),
                                path: item.Path
                              }
            })
            console.log(tempArray)
            //send Contact data back to the parent component to filter DropDown Menu
            initDropDown(tempArray)
            //construct the values for the DB
            let tempArray2 = []
            response.data.forEach((item, index) => {
              tempArray2[index] = {
                pIdDocument : item.IdDocument,
                pDate : moment().format("YYYY-MM-DD"),
                pIdPath : item.Path
              }
            })
            setRows(tempArray)
            setRowValue({...rowValue, details: tempArray2})
          } else {
            console.log(response)
          }
        });
      }
  
    // The handleInputChange handler can be set up to handle
    // many different inputs in the form, listen for changes 
    // to input elements and record their values in state
/*     const handleInputChange = (e, index) => {
        setDisable(false);
        const { name, value } = e.target;
        const list = [...rows];
        list[index][name] = value;
        setRows(list);
    }; */
  
    // Showing delete confirmation to users
/*     const handleConfirm = () => {
        setShowConfirm(true);
    }; */
  
    // Handle the case of delete confirmation where 
    // user click yes delete a specific row of id:i
    const handleRemoveClick = (i) => {
      console.log("i: ", i);
        const list = [...rows];
        const list2 = [...rowValue.details];
        list.splice(i, 1);
        list2.splice(i, 1);
        setRows(list);
        setRowValue({...rowValue, details: list2});
        //setShowConfirm(false);
        setSaveDisabled(false);
        setResetDisabled(false);
    };
  

    useEffect(() => {
        console.log("props.contact in Table",data)
        
    }, [data]);

    useEffect(() => {
      setCustomerContacts(getAllDocumentsByCustomerId(idCustomer))
      
  }, []);

    /**Function to update Contact Drop Down menu */
    useEffect(() => {
      initDropDown(rows)
  }, [rows]);
  
  return (
    <div>
      <Snackbar
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
        className={classes.snackbar}
        message={snackbarMessage}
      >
{/*         <Alert onClose={handleClose} severity="success">
          Record saved successfully!
        </Alert> */}
      </Snackbar>
      <Backdrop
          className={classes.backdrop} open={openBackdrop}>
          <CircularProgress color="primary" size={70} />
      </Backdrop>
      <Box margin={1}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            
              <div>
                <Button onClick={handleAdd} disabled={addDisabled}>
                  <AddBoxIcon onClick={handleAdd} />
                  ADD
                </Button>
                <Button align="right" onClick={handleReset} disabled={resetDisabled}>
                  <Restore />
                  RESET
                </Button>
              </div>
   
          </div>
        </div>
  
        <Table
          id="customer-contact-table"
          className={classes.table}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              {columnNames && columnNames.map((columnName) => {
                return (
                  <TableCell key={columnName}>{columnName}</TableCell>
                );
              })}
{/*               <TableCell align="center">U/M MXN</TableCell>
              <TableCell align="center">P/U MXN</TableCell>
              <TableCell align="center">Importe MXN</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, i) => (
                  <TableRow>  
                      <Fragment>
                        <TableCell scope="row">
                          {row.id}
                        </TableCell>
                        <TableCell scope="row">
                          {row.document}
                        </TableCell>
                        <TableCell scope="row">
                          {row.date}
                        </TableCell>
                        <TableCell scope="row">
                          {row.path}
                        </TableCell>
                      </Fragment>
                      <>
                        <Button className="mr10" onClick={() => handleRemoveClick(i)}>
                            <DeleteOutlineIcon />
                        </Button>
                        <Button align="right" onClick={handleEdit}>
                            <CreateIcon />
                        </Button>
                      </>
                  </TableRow>
              ))}
          </TableBody>
        </Table>
        <div className="customer button-container">
                  <div className="buttons">
                      <CustomButton 
                          clas=""
                          Icon={SaveIcon}
                          text="save document"
                          handleClick={handleSave}
                          disabled={saveDisabled}
                      />
{/*                       <CustomButton 
                          clas="invert"
                          Icon={CancelIcon}
                          text="cancel"
                          handleClick={onCancel}
                      /> */}
                  </div>
          </div>
      </Box>
    </div>
  );
}
  
export default DocumentTableComponent;