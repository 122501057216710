const initialState = {
    open: false,
    title: "",
    message: "",
    alert: "",
};

const snackbarReducer = (state = initialState, action) => {
    switch (action.type) {
        case "OPEN_SNACKBAR":
            return {
                ...state,
                open: action.openMessage.open,
                title: action.openMessage.title,
                message: action.openMessage.message,
                alert: action.openMessage.alert,
            };

        default:
            return state;
    }
};

export default snackbarReducer;