import {
    Grid,
} from "@material-ui/core";
import React,{useState,useEffect} from 'react';
import "./DropDownMenu.css"
import DropDownOnIconOff from "../Assets/Icons/down-arrow.png"
/* webapp\src\Assets\Icons\down-arrow.png */
import DropDownOnIconOn from "../Assets/Icons/Dropdown_menu_On.svg"

const DropDownMenu = ({ flex, label, Showsearch, onAddBtnClick, name, id, showAddBtn, value, labelWidth, onDropDownChange, options, optionFields, required, defaultSelect, onInvertColors}) => {
    //console.log(options)
    const selectStyles={
        /* background: `url(${DropDownOnIconOff
}) 98% / 5.5% no-repeat #fff`, */
    }
   
/*     const [inputValue,setInputValue] = useState(value)

    
    const handleInputChange = (e) => {
        setInputValue(e.target.value)
    }
    useEffect(() => {
        onDropDownChange({
            [name]: inputValue
        })
    },[inputValue])
*/
    const handleBtnClick = (e) => {
        e.preventDefault()
        //onInvertColors()
        onAddBtnClick(name)
    }
    return (
        
        <div className="drop-down__container" style={{display: "block",margin: 0}}>
            <text style={{ display:"block",margin:"-3px 0 0 0",fontSize: 14, fontWeight: "400", color:"rgb(136,136,136)" }}>
                {label}
            </text>
            <Grid
                        container
                        spacing={0}
                    >
                        <Grid item xs={(showAddBtn?11:12)}>
                                    <div className="drop-down">
                                {Showsearch && <button><i className="fas fa-search"></i></button> }

                                <select
                                    style={{marginTop:16,border:"solid 1px rgba(0, 0, 0, 0.23)",padding:"9px",width:"95%"}}
                                    value={value}
                                    onChange={onDropDownChange}
                                    //style={selectStyles} 
                                    name={name} 
                                    id={id}
                                    required={required ? required : false}
                                    >
                                
                                {
                                    options[Object.keys(options)] &&
                                    options[Object.keys(options)].map((option, index) => {
                                        if(index==0){
                                            return (
                                                <React.Fragment>
                                                <option value="">{defaultSelect ? defaultSelect : "Select"}</option>
                                                <option key={index} value={option[optionFields[0]]}>{option[optionFields[1]]} {option[optionFields[2]]}</option>
                                                </React.Fragment>
                                            )
                                        }else{
                                            return (
                                                <option key={index} value={option[optionFields[0]]}>{option[optionFields[1]]} {option[optionFields[2]]}</option>
                                            )
                                        } 
                                        
                                    })
                                    
                                }
                                    
                                </select>
                        </div>
                        </Grid>
                        {showAddBtn && <Grid item xs={1}>
                             <button
                                        style={{marginTop:15}}
                                        className="add-btn"
                                        onClick={handleBtnClick}
                                        >
                            <i className="fas fa-plus"></i>
                            </button>
                        </Grid>}
                    </Grid>
            
                    
                
            
        </div>
    )
}
export default DropDownMenu
