
import {
    Grid,
} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import React,{useState,useEffect} from 'react';
import "./DropDownMenu.css"
import DropDownOnIconOff from "../Assets/Icons/down-arrow.png"
/* webapp\src\Assets\Icons\down-arrow.png */
import DropDownOnIconOn from "../Assets/Icons/Dropdown_menu_On.svg"


const useStyles = makeStyles({
    root: {
    },
    textForm: {
        fontSize: 14, 
        fontWeight: "400", 
        color:"rgb(136,136,136)",
        display: "block",
        margin: "10px 0"
    },
    borderForm:{
        border:"solid 1px rgba(0, 0, 0, 0.23) !important",
        padding:"9px !important",
        width:"90% !important"
    },
    cardMargin:{
        margin:"10px 0"
    }

  });

const DropDownMenuTable = ({ label, Showsearch = false, onAddBtnClick = () => {}, name = "", id="", required, showAddBtn = false, value = "", onDropDownChange = () => { }, options = [],onInvertColors=() => {}}) => {
    //console.log(value)
    const classes = useStyles();
    const selectStyles={
        /* background: `url(${DropDownOnIconOff
}) 98% / 5.5% no-repeat #fff`, */
    }
   
    const [skills,setSkills] = useState()


    const handleBtnClick = (e) => {
        e.preventDefault()
        //onInvertColors()
        onAddBtnClick(name)
    }

 /*    useEffect(() => {
        onDropDownChange({
            [name]: inputValue
        })
    },[inputValue]) */

    useEffect(() => {
        options.forEach(element => {
            if(element.Level == value){
                var tempSkill = []
                element.SkillsDescription.forEach((item) => {
                    tempSkill.push(item)
                })
                setSkills(tempSkill)
            }
            
        })
    },[options,value])

    return (
        <div>
        <div className="drop-down__container">
            <Grid
                container
                spacing={2}
                alignContent="center"
                justifyContent="center"
            >
                <Grid item xs={12} lg={4}>
                    <Grid
                        container
                        spacing={0}
                    >
                        <Grid item xs={11}>
                            <text className={classes.textForm}>{label}</text>
                            <div className="drop-down">
                                    {Showsearch && <button><i className="fas fa-search"></i></button> }
                                    <select
                                        className={classes.borderForm}
                                        value={value}
                                        onChange={onDropDownChange}
                                        style={selectStyles} 
                                        name={name} 
                                        id={id}
                                        required={required ? required : false}>
                                    {
                                        options.map((option, index) => {
                                            if(index==0){
                                            return (
                                                <React.Fragment>
                                                <option value="">Select</option>
                                                <option value={option.Level}>{option.DescrLevel}</option>
                                                </React.Fragment>
                                            )
                                            }else{
                                                return (
                                                    <option value={option.Level}>{option.DescrLevel}</option>
                                                )
                                            }
                                        }
                                        )
                                    }
                                        
                                    </select>
                            </div>
                        </Grid>
                        <Grid item xs={1}>
                            {showAddBtn && <button
                                        className="add-btn"
                                        style={{marginTop:48}}
                                        onClick={handleBtnClick}
                                        >
                                        <i className="fas fa-plus"></i>
                                </button>}
                        </Grid>
                    </Grid>
                    
                            
                    </Grid>
                    <Grid item xs={4}>
                        <h3 style={{marginBottom: "0px",}}>Skills</h3>
                            <div className="drop-down__table">
                                <ul>
                                    {skills && skills.map((element) => {
                                    return (
                                        <li>{element}</li>
                                    )
                                    })}
                                </ul>
                            </div>
                    </Grid>
                    <Grid item xs={4}></Grid>
            </Grid>
                    
         
                    
                
        </div>
        
    </div>
    )
}
export default DropDownMenuTable
