import React, {useState} from 'react';

//import SaveIcon from "../../Assets/Icons/Remember_me_Yes.svg";

const CustomButton = ({clas,Icon,text,type, handleClick, required, disabled, isUpdate}) => {
  const [filled, setFilled] = useState(false)

  const handleBtnClick = (e) => {
    e.preventDefault()
    if(required){
      setFilled(true)
    }
    handleClick(e)
  }

  const insertRequired = (text, required) => {
    if(!isUpdate && required){
      return (
        <input
              id={text}
              name={text}
              type="checkbox"
              checked={filled}
              style={{opacity:0, width:"1px",}}
              required
          />
      )
    }else{
      return (
        <input
              id={text}
              name={text}
              type="checkbox"
              checked={filled}
              style={{opacity:0, width:"1px",}}
          />
      )
    }
  }

  if (handleClick !== undefined){
    return (
      <button onClick={handleBtnClick} className={disabled? "btn " + "button-disabled " + clas : "btn " + clas} type={type ? type : "button"} disabled={disabled ? disabled: false}>
          {insertRequired(text, required)}
          <span className='label'>
          <span style={{verticalAlign: "super"}}>{text}</span>
              <span className='icon' >{<Icon/>}</span>
          </span>
      </button>
    )
  }else{
    return (
      <button className={disabled? "btn " + "button-disabled " + clas : "btn " + clas} type={type ? type : "button" } disabled={disabled ? disabled: false}>
          
          <span className='label'>
            <span style={{verticalAlign: "super"}}>{text}</span>
            <span className='icon'>{<Icon/>}</span>
          </span>
      </button>
    )
  }
  
}

export default CustomButton