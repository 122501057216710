export const initialState = {
    bank: [],
};

const allBankReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_BANK":
            return {
                ...state,
                bank: action.bank,
            };

        default:
            return state;
    }
};

export default allBankReducer;