import {
    Grid,
} from "@material-ui/core";
import React,{useState,useEffect} from 'react'
import DropDownMenu from "../../Utilities/DropDownMenu"
import DropDownMenuTable from "../../Utilities/DropDownMenuTable"
import { useStateValue } from "../../Context/store";
import LockSmithSwitch from "../ReusableComponents/LockSmithSwitch";

const WorkForm = ({ inputHandler, data, onInvertColors, clickHandler}) => {
    //console.log("employeeData in WorkForm",data)
    const [{ allTypeEmp,allLevelSkill }] = useStateValue();
    const [levelSkill, setLevelSkill] = useState([]);
    const dayOffObj = {"dayOff":[{"idDay":"MONDAY", "Descr":"Monday"},
                                {"idDay":"TUESDAY", "Descr":"Tuesday"},
                                {"idDay":"WEDBESDAY", "Descr":"Wednesday"},
                                {"idDay":"THURSDAY", "Descr":"Thursday"},
                                {"idDay":"FRIDAY", "Descr":"Friday"},
                                {"idDay":"SATURDAY", "Descr":"Saturday"},
                                {"idDay":"SUNDAY", "Descr":"Sunday"}]}

    const schedule = {"schedule":[{"idSched":"DAY", "Descr":"Day"},
                                {"idSched":"EVENINIG", "Descr":"Evening"},
                                {"idSched":"NIGHT", "Descr":"Night"}]}

    const typeEmpFields = ["IdTypeEmp", "Descr"]
    const dayOffFields = ["idDay", "Descr"]
    const scheduleFields = ["idSched", "Descr"]

    useEffect(() => {
            //console.log(tempEmp,"tempEmp")
            if(allLevelSkill != undefined && allLevelSkill.levelskill.length > 0){
                //console.log(allLevelSkill.levelskill);
                let skillByLevel = [];
                allLevelSkill.levelskill.map(levelSkill => {
                    let skillFound = skillByLevel.find(skill => skill.Level == levelSkill.Level);
                    if(skillFound){
                        skillFound.Skills.push(levelSkill.Skill);
                        skillFound.SkillsDescription.push(levelSkill.DescrSkill);
                    }else{
                        skillByLevel.push({
                            Level: levelSkill.Level,
                            DescrLevel: levelSkill.DescrLevel,
                            Skills: [levelSkill.Skill],
                            SkillsDescription: [levelSkill.DescrSkill]
                        })
                    }
                });
            
                //console.log("skillByLevel", skillByLevel);
                setLevelSkill(skillByLevel);
            }

    }, [allLevelSkill]); 

    return (
    <div className="workform-container">
        <Grid
                container
                spacing={2}
                alignContent="center"
                justifyContent="center"
            >
                <Grid item xs={12}>
                        <label className="label" style={{marginLeft:"10px"}}>receive jobs</label>
                        <LockSmithSwitch
                            checked={data.pReciveJobs}
                            onChange={inputHandler}
                            name="pReciveJobs"
                            value={data.pReciveJobs}
                        />
                    </Grid>
        </Grid>
        
         <Grid
                container
                spacing={2}
                alignContent="center"
                justifyContent="center"
            >
                <Grid item xs={12} lg={4}>
                            <DropDownMenu
                            onDropDownChange={inputHandler}
                            label="Day off"
                            options= {dayOffObj}
                            optionFields={dayOffFields}
                            name="pDayOff"
                            value={data.pDayOff}
                             />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                            <DropDownMenu
                            onDropDownChange={inputHandler}
                            label="Schedule"
                            options= {schedule}
                            optionFields={scheduleFields}
                            name="pSchedule"
                            value={data.pSchedule}
                            defaultSelect="None"
                            
                            />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <DropDownMenu
                        onDropDownChange={inputHandler}
                        onAddBtnClick={clickHandler[0]}
                        label="Type emp*" 
                        options={allTypeEmp}
                        optionFields={typeEmpFields}
                        showAddBtn={true}
                        name="pIdTypeEmp"
                        id="pIdTypeEmp"
                        value={data.pIdTypeEmp}
                        //onInvertColors={onInvertColors}
                        required={true}
                         />
                    </Grid>
                    
            </Grid>
            
                        <DropDownMenuTable
                            onDropDownChange={inputHandler}
                            label="Level*"
                            options={levelSkill}
                            onAddBtnClick={clickHandler[1]}
                            showAddBtn={true}
                            name="pIdLevel"
                            id="skill-idLevel"
                            value={data.pIdLevel}
                            //onInvertColors={onInvertColors}
                            required={true}
                            />


                        

            
            <div className="input-control__inner date">
                    {/* <label className="label">schedule</label>
                    <input 
                        value={data.pSchedule}
                        onChange={inputHandler} 
                        type="date" 
                        name="pSchedule"
                        style={{width:"auto"}}/> */}
                
            </div>
             <div className="update-screen">
             </div>

        

    </div>
  )
}

export default WorkForm