import {
    Backdrop,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    InputAdornment, TextField
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { getAllUser, uptUser, getAllActiveEmployees } from "../../Actions/UserAction";
import Save from "@material-ui/icons/Save";
import Close from "@material-ui/icons/Close";
import { ReactComponent as UsersIcon } from "../../Assets/Icons/Users.svg";
import { dialogStyle } from "../../Assets/styles/Styles";
import { useStateValue } from "../../Context/store";
import LocksmithEntryId from "../ReusableComponents/LockSmithEntryId";
import LockSmithSelect from "../ReusableComponents/LockSmithSelect";
import LockSmithTextField from "../ReusableComponents/LockSmithTextField";

const UpdateUser = ({ openDialog, closeDialog, data }) => {
    const classes = dialogStyle();
    const [openBackdrop, setOpenBackdrop] = useState(false);
    const employeeFields = ["IdEmployee", "Name", "LastName"];
    const branchFields = ["IdBranch", "Descr"];
    const roleFields = ["IdRole", "Descr"];

    // eslint-disable-next-line no-empty-pattern
    const [{ allBranch, allRole, allEmployee}, dispatch] = useStateValue();
    const [userData, setUserData] = useState({});
    const [showPass, setShowPass] = useState(false);
    const [activeEmployees, setActiveEmployees] = useState({activeEmployees: []});
    /** Show Pass Control */
    const handleShowPass = () => {
        setShowPass(!showPass);
    };

    const dataValues = (e) => {
        const { name, value } = e.target;
        setUserData((last) => ({
            ...last,
            [name]: value,
        }));
    };

    const saveFunction = async (e) => {
        e.preventDefault();
        setOpenBackdrop(true);
        await uptUser(userData).then((response) => {
            if (response.status === 200) {
                setOpenBackdrop(false);
                dispatch({
                    type: "OPEN_SNACKBAR",
                    openMessage: {
                        open: true,
                        title: "Success",
                        message: response.data.output.pmensaje,
                        alert: "success",
                    },
                });
                getAllUser(dispatch);
                restoreFunction();
            } else {
                setOpenBackdrop(false);
                dispatch({
                    type: "OPEN_SNACKBAR",
                    openMessage: {
                        open: true,
                        title: "Error",
                        message: response.data.msg,
                        alert: "error",
                    },
                });
            }
        });
    };

    useEffect(() => {
        setUserData({
            pidUser: data.idUser,
            pUsername: data.UserName,
            pPassword: data.Password,
            pidEmployee: data.IdEmployee,
            pidBranch: data.idBranch,
            pidRole: data.idRole,
        });
    }, [data]);

    useEffect(() => {
        if(openDialog && activeEmployees.activeEmployees.length === 0){
            getAllActiveEmployees().then((response) => {
                if (response.status === 200) {
                    setActiveEmployees({activeEmployees: response.data});
                }
            });
        }

    },[openDialog])

    const restoreFunction = () => {
        setUserData({
            pidUser: data.idUser,
            pUsername: data.UserName,
            pPassword: data.Password,
            pidEmployee: data.IdEmployee,
            pidBranch: data.idBranch,
            pidRole: data.idRole,
        });
        setShowPass(false);
    };

    return (
            <form onSubmit={saveFunction}>
                
                    <Grid
                        container
                        spacing={2}
                        alignContent="center"
                        justifyContent="center"
                    >
                        <Grid item xs={12} lg={6}>
                            <LocksmithEntryId label="ID User" id={userData.pidUser} />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        spacing={2}
                        alignContent="center"
                        justifyContent="center"
                    >
                        <Grid item xs={12} lg={6}>
                            <LockSmithTextField label="Username" labelWidth="32%" name="pUsername" value={userData.pUsername} onChange={dataValues} />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                                    <text
                                            style={{ fontSize: 14, fontWeight: "400",color:"#888", fontFamiliy:"'Open Sans',sans-serif" }}
                                            >
                                        New Password
                                    </text>
                                    <div style={{marginTop:"-2px"}}>
                                    <TextField
                                        fullWidth
                                        margin="normal"
                                        name="pPassword"
                                        style={{
                                            backgroundColor: "#fff",
                                            borderRadius: 5,
                                        }}
                                        value={userData.pPassword}
                                        onChange={dataValues}
                                        variant="outlined"
                                        type={showPass ? "text" : "password"}
                                        //required={true}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleShowPass}
                                                    >
                                                        {showPass ? (
                                                            <VisibilityOff />
                                                        ) : (
                                                            <Visibility />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    </div>

                              
                        </Grid>
                    </Grid>
                    
                       
                        <Grid
                        container
                        spacing={2}
                        alignContent="center"
                        justifyContent="center"
                        >
                            <Grid item xs={12} lg={6}>
                                <LockSmithSelect
                                label="Select Employee"
                                name="pidEmployee"
                                value={userData.pidEmployee}
                                valueId="IdEmployee"
                                data={activeEmployees}
                                handler={dataValues}
                                fields={employeeFields}
                                />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <LockSmithSelect
                                label="Select Branch"
                                name="pidBranch"
                                value={userData.pidBranch}
                                valueId="IdBranch"
                                data={allBranch}
                                handler={dataValues}
                                fields={branchFields}
                                />
                            </Grid>
                        </Grid>
                        
                        
                        <LockSmithSelect
                            label="Select Role"
                            name="pidRole"
                            value={userData.pidRole}
                            valueId="IdRole"
                            data={allRole}
                            handler={dataValues}
                            fields={roleFields}
                        />
                    

                    <Grid
                        container
                        spacing={2}
                        justifyContent="flex-end"
                    >
                        <Grid item xs={12} lg={6}>
                                <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                classes={{ root: classes.saveButton }}
                                size="large"
                                fullWidth
                                endIcon={
                                    <div
                                        style={{
                                            width: 20,
                                            height: 20,
                                            marginTop: -9
                                        }}
                                    >
                                       <Save/>
                                    </div>
                                }
                            >
                                Save
                            </Button>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                        <Button
                        variant="contained"
                        onClick={restoreFunction}
                        classes={{ root: classes.cancelButton }}
                        size="large"
                        fullWidth
                        endIcon={
                            <div
                                style={{
                                    width: 20,
                                    height: 20,
                                    marginTop: -9,
                                }}
                            >
                                <Close/>
                            </div>
                        }
                    >
                        Restore
                    </Button>
                        </Grid>
                    </Grid>                  
                    
                    

            </form>
    );
};

export default UpdateUser;
