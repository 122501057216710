import React, { useEffect, useState } from "react";
import { useStateValue } from "../../Context/store";
import { delCategory, getAllCategory } from "../../Actions/UserAction";
import AddCategory from "./AddCategory";
import LockSmithDeleteComponent from "../ReusableComponents/LockSmithDeleteComponent";
import UpdateCategory from "./UpdateCategory";
/*Import Reusable Components */
import LockSmithMainTable from "../ReusableComponents/LockSmithMainTable";

const columns = () => { 
    return [
    {title: "ID", field: "IdCategory",cellStyle: {height: 40,padding:"0px 16px"}},
    {title: "DESCRIPTION", field: "Descr",cellStyle: {height: 40,padding:"0px 16px"}},
    {title: "IDClass", field: "IdClass",cellStyle: {height: 40,padding:"0px 16px"}}
]};

const Category = () => {
    const [{ allCategory }] = useStateValue();
    const [category, setCategory] = useState(null);

    useEffect(() => {
        setCategory(allCategory ? allCategory.category : []);
    }, [allCategory]);

    return (
    <React.Fragment>
        <LockSmithMainTable 
            title="Category"
            data={category}
            columns={columns()}
            addComponent={AddCategory}
            updateComponent={UpdateCategory}
            deleteComponent={LockSmithDeleteComponent}
            deleteAction={delCategory}
            reducer={getAllCategory}
            itemId={columns().field}
        />
    </React.Fragment>
    );
};

export default Category;
