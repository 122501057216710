import React, { useEffect, useState } from "react";
import { useStateValue } from "../../Context/store";
import { deleteEmployee, getAllEmployee } from "../../Actions/UserAction";
import AddEmployee from "./AddEmployee";
import UpdateEmployee from "./UpdateEmployee";
import LockSmithDeleteComponent from "../ReusableComponents/LockSmithDeleteComponent";
/*Import Reusable Components */
import LockSmithMainTable from "../ReusableComponents/LockSmithMainTable";

const columns = () => {
    return [
        { title: "ID", field: "IdEmployee",cellStyle: {height: 40,padding:"0px 16px"} },
        { title: "Name", field: "Name",cellStyle: {height: 40,padding:"0px 16px"} },
        //{ title: "Middle Name", field: "MiddleName" },
        { title: "Last Name", field: "LastName",cellStyle: {height: 40,padding:"0px 16px"} },
        { title: "Nickname", field: "NickName",cellStyle: {height: 40,padding:"0px 16px"} },
        { title: "Gender", field: "Gender",cellStyle: {height: 40,padding:"0px 16px"} },
        { title: "Active", field: "Active",cellStyle: {height: 40,padding:"0px 16px"} },
        { title: "Receive Jobs", field: "ReciveJobs",cellStyle: {height: 40,padding:"0px 16px"} },
    ]
};

const Employee = () => {
    const [{ allEmployee }] = useStateValue();
    const [employee, setEmployee] = useState(null);

    useEffect(() => {
        setEmployee(allEmployee ? allEmployee.employee : []);
    }, [allEmployee]);

    return (
        <React.Fragment>
            <LockSmithMainTable 
                title="Employee"
                data={employee}
                columns={columns()}
                addComponent={AddEmployee}
                updateComponent={UpdateEmployee}
                //deleteComponent={LockSmithDeleteComponent}
                //deleteAction={deleteEmployee}
                reducer={getAllEmployee}
                itemId={columns().field}
            />
        </React.Fragment>
    );
};

export default Employee;
