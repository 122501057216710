import React, { useEffect, useState } from "react";
import { useStateValue } from "../../Context/store";
import { delSubCat, getAllSubCat } from "../../Actions/UserAction";
import AddSubCat from "./AddSubCat";
import LockSmithDeleteComponent from "../ReusableComponents/LockSmithDeleteComponent";
import UpdateSubCat from "./UpdateSubCat";
/*Import Reusable Components */
import LockSmithMainTable from "../ReusableComponents/LockSmithMainTable";

const columns = () => { 
    return [
    {title: "ID" , field: "IdSubCategory",cellStyle: {height: 40,padding:"0px 16px"}},
    {title: "DESCRIPTION" , field: "Descr",cellStyle: {height: 40,padding:"0px 16px"}},
    {title: "IDCategory" , field: "IdCategory",cellStyle: {height: 40,padding:"0px 16px"}},
]};

const SubCat = () => {
    const [{ allSubCat }] = useStateValue();
    const [subCat, setSubCat] = useState(null);

    useEffect(() => {
        setSubCat(allSubCat ? allSubCat.subcat : []);
    }, [allSubCat]);

    return (
        <React.Fragment>
            <LockSmithMainTable 
                title="Sub Category"
                data={subCat}
                columns={columns()}
                addComponent={AddSubCat}
                updateComponent={UpdateSubCat}
                deleteComponent={LockSmithDeleteComponent}
                deleteAction={delSubCat}
                reducer={getAllSubCat}
                itemId={columns().field}
            />
        </React.Fragment>
    );
};

export default SubCat;
