import React, { useEffect, useState } from "react";
import { useStateValue } from "../../Context/store";
import { delDepartment, getAllDepartment } from "../../Actions/UserAction";
import AddDepartment from "./AddDepartment";
import LockSmithDeleteComponent from "../ReusableComponents/LockSmithDeleteComponent";
import UpdateDepartment from "./UpdateDepartment";
/*Import Reusable Components */
import LockSmithMainTable from "../ReusableComponents/LockSmithMainTable";

const columns = () => { 
    return [
    { title: "ID", field: "IdDepartment",cellStyle: {height: 40,padding:"0px 16px"} },
    { title: "Description", field: "Descr",cellStyle: {height: 40,padding:"0px 16px"} },
]};

const Department = () => {
    const [{ allDepartment }] = useStateValue();
    const [department, setDepartment] = useState(null);

    useEffect(() => {
        setDepartment(allDepartment ? allDepartment.department : []);
    }, [allDepartment]);

    return (
        <React.Fragment>
            <LockSmithMainTable 
                title="Department"
                data={department}
                columns={columns()}
                addComponent={AddDepartment}
                updateComponent={UpdateDepartment}
                deleteComponent={LockSmithDeleteComponent}
                deleteAction={delDepartment}
                reducer={getAllDepartment}
                itemId={columns().field}
            />
        </React.Fragment>
    );
};

export default Department;
