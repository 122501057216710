export const initialState = {
    docemployee: [],
};

const allDocEmployeeReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_DOCEMPLOYEE":
            return {
                ...state,
                docemployee: action.docemployee
            };

        default:
            return state;
    }
};

export default allDocEmployeeReducer;