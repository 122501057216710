export const initialState = {
    user: [],
};

const allUserReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_USER":
            return {
                ...state,
                user: action.user
            };

        default:
            return state;
    }
};

export default allUserReducer;