export const initialState = {
    class: [],
};

const allClassesReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_CLASSES":
            return {
                ...state,
                class: action.class,
            };

        default:
            return state;
    }
};

export default allClassesReducer;