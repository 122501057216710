export const initialState = {
    role: [],
};

const allRoleReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_ROLE":
            return {
                ...state,
                role: action.role,
            };

        default:
            return state;
    }
};

export default allRoleReducer;