export const initialState = {
    service: [],
};

const allServiceReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_SERVICE":
            return {
                ...state,
                service: action.service,
            };

        default:
            return state;
    }
};

export default allServiceReducer;
