import React, { useEffect, useState } from "react";
import {
    Button,
    Grid,
} from "@material-ui/core";
import { useStateValue } from "../../Context/store";
import { getAllLevels, updateLevel } from "../../Actions/UserAction";

/**Import Icons */
import Save from "@material-ui/icons/Save";
import Close from "@material-ui/icons/Close";
/**Import Components */
import LockSmithTextField from "../ReusableComponents/LockSmithTextField";
import LocksmithEntryId from "../ReusableComponents/LockSmithEntryId";

/** Theme & Styles Import */
import {dialogStyle} from '../../Assets/styles/Styles'

const UpdateLevel = ({ openDialog, closeDialog, data }) => {
    const classes = dialogStyle();
    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [levelData, setLevelData] = useState({});
    // eslint-disable-next-line no-empty-pattern
    const [{}, dispatch] = useStateValue();

    const dataValues = (e) => {
        const { name, value } = e.target;
        setLevelData((last) => ({
            ...last,
            [name]: value,
        }));
    };

    const saveLevel = async (e) => {
        e.preventDefault();
        setOpenBackdrop(true);
        await updateLevel(levelData).then((response) => {
            if (response.status === 200) {
                setOpenBackdrop(false);
                dispatch({
                    type: "OPEN_SNACKBAR",
                    openMessage: {
                        open: true,
                        title: "Success",
                        message: response.data.output.pmensaje,
                        alert: "success",
                    },
                });
                getAllLevels(dispatch);
                cancelLevel();
            } else {
                setOpenBackdrop(false);
                dispatch({
                    type: "OPEN_SNACKBAR",
                    openMessage: {
                        open: true,
                        title: "Error",
                        message: response.data.msg,
                        alert: "error",
                    },
                });
            }
        });
    };

    const cancelLevel = () => {
        closeDialog();
    };
    const resetLevel=()=>{
        setLevelData({
            pIdLevel: data.KEY,
            pDescr: data.DESCRIPTION,
        });
    };
    useEffect(() => {
        setLevelData({
            pIdLevel: data.KEY,
            pDescr: data.DESCRIPTION,
        });
    }, [data]);

    return (
        <React.Fragment>
                <form onSubmit={saveLevel}>
                        <Grid
                            container
                            spacing={2}
                            alignContent="center"
                            justifyContent="center"
                        >
                            <Grid item xs={12}>
                                <LocksmithEntryId
                                    label="Id Money"
                                    id={levelData.pIdLevel}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <LockSmithTextField
                                    label="Description"
                                    labelWidth="32%"
                                    name="pDescr"
                                    value={levelData.pDescr}
                                    onChange={dataValues} 
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            spacing={2}
                            alignContent="center"
                            justifyContent="flex-end"
                        >
                            <Grid item xs={6} lg={3}>
                                    <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    classes={{ root: classes.saveButton }}
                                    size="large"
                                    fullWidth
                                    endIcon={
                                        <div
                                            style={{
                                                width: 20,
                                                height: 20,
                                                marginTop: -14,
                                                marginLeft: 10,
                                            }}
                                        >
                                            <Save/>
                                        </div>
                                    }
                                >
                                    SAVE
                                </Button>
                            </Grid>
                            <Grid item xs={6} lg={3}>
                                    <Button
                                    variant="contained"
                                    onClick={resetLevel}
                                    classes={{ root: classes.cancelButton }}
                                    size="large"
                                    fullWidth
                                    endIcon={
                                        <div
                                            style={{
                                                width: 20,
                                                height: 20,
                                                marginTop: -14,
                                                marginLeft: 10,
                                            }}
                                        >
                                            <Close/>
                                        </div>
                                    }
                                >
                                    Reset
                                </Button>
                            </Grid>
                        </Grid>
                        
                        

                </form>
        </React.Fragment>
    );
};

export default UpdateLevel;
