import {
    Backdrop,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
} from "@material-ui/core";

import React, { useState, useEffect } from "react";
import { useStateValue } from "../../Context/store";
/** Import Icons */
import Delete from "@material-ui/icons/Delete";
import Close from "@material-ui/icons/Close";
/** Theme & Styles Import */
import { deleteDialogStyle } from "../../Assets/styles/Styles";

const LockSmithDeleteComponent = (props) => {
    const classes = deleteDialogStyle();
    // eslint-disable-next-line no-empty-pattern
    const [{}, dispatch] = useStateValue();
    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [deleteItemData, setDeleteItemData] = useState({});

    const deleteFunction = async () => {
        try{

 
        setOpenBackdrop(true);
        await props.deleteAction(deleteItemData).then((response) => {
            if (response.status === 200) {
                setOpenBackdrop(false);
                dispatch({
                    type: "OPEN_SNACKBAR",
                    openMessage: {
                        open: true,
                        title: "Success",
                        message: response.data.output.pmensaje,
                        alert: "success",
                    },
                });
                props.reducer(dispatch);
                props.closeDialog();
            } else {
                setOpenBackdrop(false);
                dispatch({
                    type: "OPEN_SNACKBAR",
                    openMessage: {
                        open: true,
                        title: "Error",
                        message: response.data.msg,
                        alert: "error",
                    },
                });
            }
        });
    } catch (error) {
        console.log(error);
    }
    };
    let deleteMessage = "";
    if(props.title){
        deleteMessage = "DELETE " + props.title.toUpperCase();
    }else{
        deleteMessage = "DELETE COMPONENT";
    }

    useEffect(() => {
        setDeleteItemData({
            pId: props.data[props.itemId],
        });
    }, [props.data]);
    
    return(
        <Dialog
            open={props.openDialog}
            onClose={props.closeDialog}
            PaperProps={{ className: classes.dialog }}
        >
            <Backdrop className={classes.backdrop} open={openBackdrop}>
                <CircularProgress color="primary" size={70} />
            </Backdrop>
            <DialogTitle className={classes.dialogTitle}>
                <Box className={classes.dialogTitleHead}>
               

                    <Box
                        style={{
                            flexDirection: "row",
                            display: "flex",
                            width: "100%",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <text
                            style={{
                                fontSize: 28,
                                fontWeight: "bold",
                                marginLeft: 20,
                            }}
                        >
                           {deleteMessage}
                        </text>
                    </Box>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Typography variant="h6">
                    Are you sure you want to delete this {props.title}?
                </Typography>
            </DialogContent>
            <DialogActions className={classes.dialogButton}>
                <Button
                    variant="contained"
                    color="primary"
                    classes={{ root: classes.deleteButton }}
                    size="large"
                    fullWidth
                    onClick={deleteFunction}
                    endIcon={
                        <div
                            style={{
                                width: 20,
                                height: 20,
                                marginTop: -14,
                                marginLeft: 10,
                            }}
                        >
                            <Delete/>
                        </div>
                    }
                >
                    Delete
                </Button>
                <Button
                    classes={{ root: classes.cancelButton }}
                    variant="contained"
                    onClick={props.closeDialog}
                    size="large"
                    fullWidth
                    endIcon={
                        <div
                            style={{
                                width: 20,
                                height: 20,
                                marginTop: -14,
                                marginLeft: 10,
                            }}
                        >
                            <Close/>
                        </div>
                    }
                >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>        
    )
}

export default LockSmithDeleteComponent;