import React, { useEffect, useState } from "react";
import { useStateValue } from "../../Context/store";
import { delAccount, getAllAccount } from "../../Actions/UserAction";
import AddAccount from "./AddAccount";
//import LockSmithDeleteComponent from "../ReusableComponents/LockSmithDeleteComponent";
import UpdateAccount from "./UpdateAccount";
/*Import Reusable Components */
import LockSmithMainTable from "../ReusableComponents/LockSmithMainTable";
import LockSmithDeleteComponent from "../ReusableComponents/LockSmithDeleteComponent";

const columns = () => { 
    return [
        { title: "ID", field: "IdAccount",cellStyle: {height: 40,padding:"0px 16px"} },
        { title: "Description", field: "Desc",cellStyle: {height: 40,padding:"0px 16px"} },
        { title: "Bank", field: "Bank",cellStyle: {height: 40,padding:"0px 16px"} },
        { title: "Money", field: "Money",cellStyle: {height: 40,padding:"0px 16px"} },
    ]
} ;

const Account = () => {
    const [{ allAccount }] = useStateValue();
    const [account, setAccount] = useState(null);

    useEffect(() => {
        setAccount(allAccount ? allAccount.account : []);
    }, [allAccount]);

    return (
     <React.Fragment>
        <LockSmithMainTable 
            title="Account"
            data={account}
            columns={columns()}
            addComponent={AddAccount}
            updateComponent={UpdateAccount}
            deleteComponent={LockSmithDeleteComponent}
            deleteAction={delAccount}
            reducer={getAllAccount}
            itemId={columns().field}
        />
    </React.Fragment>
        
    );
};

export default Account;
