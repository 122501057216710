import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
} from "@material-ui/core";
import { Add } from "@material-ui/icons";
import React, { useState, useEffect } from "react";
import { getAllCompanies, getAllAddressByClass, insCompany } from "../../Actions/UserAction"; /*  */

import { dialogStyle } from "../../Assets/styles/Styles";
import { useStateValue } from "../../Context/store";
import { useForm } from "../../hooks/useForm";
import { LoadingComponent, useLoading } from "../../hooks/useLoading";
import AddAccount from "../Account/AddAccount";
import AddAddress from "../Address/AddAddress";
import LockSmithComponentButtons from "../ReusableComponents/LockSmithComponentButtons";
import LockSmithSelect from "../ReusableComponents/LockSmithSelect";
import LockSmithSwitch from "../ReusableComponents/LockSmithSwitch";
import LockSmithTextField from "../ReusableComponents/LockSmithTextField";

const AddCompany = ({ openDialog, closeDialog }) => {
    const classes = dialogStyle();
    const { loading, openLoading, closeLoading } = useLoading();
    const [{ allAddress, allAccount }, dispatch] = useStateValue();
    const { formData, handleInputChange, setFormData } = useForm({
        pNotifyClosing: false,
        pNotifyCancel: false,
        pCom: 0.00,
    });
    const accountFields = ["IdAccount", "Desc"];
    const addressFields = ["IdAddress", "Descr", "Street"];

    /** Address Controls */
    const [newAddress, setNewAddress] = useState(false);
    const openNewAddress = () => {
        setNewAddress(true);
    };
    const closeNewAddress = () => {
        setNewAddress(false);
    };
    /** All addresses of CompanyClass */
    const [companyAddress, setCompanyAddress] = useState();

    /** Account Controls */
    const [newAccount, setNewAccount] = useState(false);
    const openNewAccount = () => {
        setNewAccount(true);
    };
    const closeNewAccount = () => {
        setNewAccount(false);
    };

    const cancelFunction = () => {
        setFormData({
            pNotifyClosing: false,
            pNotifyCancel: false,
            pCom: 0.00,
        });
        closeDialog();
    };
    const resetFunction = () => {
        setFormData({
            pNotifyClosing: false,
            pNotifyCancel: false,
            pCom: 0.00,
            pComments:""
        });
        Array.from(document.querySelectorAll("form input")).forEach(
            input => (input.value = "")
          );
    };

    const saveFunction = async (e) => {
        e.preventDefault();
        openLoading();
        await insCompany(formData).then((response) => {
            console.log(response.error);
            if (response.status === 200) {
                closeLoading();
                dispatch({
                    type: "OPEN_SNACKBAR",
                    openMessage: {
                        open: true,
                        title: "Success",
                        message: response.data.output.pmensaje,
                        alert: "success",
                    },
                });
                getAllCompanies(dispatch);
                resetFunction();
            } else {
                closeLoading();
                dispatch({
                    type: "OPEN_SNACKBAR",
                    openMessage: {
                        open: true,
                        title: "Error",
                        message: response.msg,
                        alert: "error",
                    },
                });
            }
        });
    };

    /* To fetch the initial addresses by IdClass */
    const fetchAddresses = async () => {
        console.log("fetchADddress")
        await getAllAddressByClass(6).then(res => {
            if(res?.status === 200){
                console.log("Setting ocmpany address")
                setCompanyAddress({address:res.data})

            }else{
                console.log(res)
            }
        })
    }

    useEffect(() => { 
        openDialog && fetchAddresses()
    }, [openDialog])

    return (


            <form onSubmit={saveFunction}>
                    <Grid container spacing={2} justifyContent="center">
                        <Grid item xs={12} lg={4}>
                            <LockSmithTextField
                                label="Description*"
                                name="pDescr"
                                onChange={handleInputChange}
                                value={formData.pDescr}
                                size="small"
                                required
                            />
                        </Grid>
                        <Grid item  xs={12} lg={4}>
                            <LockSmithTextField
                                label="Url"
                                name="pURL"
                                onChange={handleInputChange}
                                value={formData.pURL}
                                size="small"
                                type="url"
                            />
                           
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <LockSmithTextField
                                label="Phone*"
                                name="pPhone"
                                onChange={handleInputChange}
                                value={formData.pPhone}
                                size="small"
                                type="number"
                                required
                            />
                           
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} justifyContent="center">
                        <Grid item sm={4} xs={12} lg={4}>
                            <LockSmithTextField
                                label="Email"
                                name="pEmailNotifica"
                                onChange={handleInputChange}
                                value={formData.pEmailNotifica}
                                size="small"
                            />
                            
                        </Grid>
                        <Grid item sm={4} xs={12} lg={4}>
                            <LockSmithTextField
                                label="EIN"
                                name="pEIN"
                                onChange={handleInputChange}
                                value={formData.pEIN}
                                size="small"
                            />
                            
                        </Grid>
                        <Grid item sm={4} xs={12} lg={4}>
                        <LockSmithTextField
                                label="Percentage commission*"
                                labelWidth="70%"
                                name="pCom"
                                onChange={handleInputChange}
                                value={formData.pCom}
                                size="small"
                                type="number"
                                maxValue={100}
                                placeholder="%"
                                required
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} justifyContent="center">
                        <Grid item sm={11} xs={11} lg={3}>
                            <LockSmithSelect
                                name="pIdAddress"
                                value={formData.pIdAddress}
                                handler={handleInputChange}
                                data={companyAddress}
                                label="Address*"
                                labelWidth="25%"
                                fields={addressFields}
                                required
                            />
                        </Grid>
                        <Grid item sm={1} xs={1}>
                            <IconButton onClick={openNewAddress}>
                                <Add
                                    style={{
                                        marginTop: 20,
                                        marginLeft: -15,
                                    }}
                                    fontSize="large"
                                />
                            </IconButton>
                        </Grid>
                        <Grid item sm={11} xs={11} lg={3}>
                            <LockSmithSelect
                                name="pIdAccount"
                                value={formData.pIdAccount}
                                handler={handleInputChange}
                                data={allAccount}
                                label="Account*"
                                labelWidth="25%"
                                fields={accountFields}
                                required
                            />
                        </Grid>
                        <Grid item sm={1} xs={1}>
                            <IconButton onClick={openNewAccount}>
                                <Add
                                    style={{
                                        marginTop: 20,
                                        marginLeft: -15,
                                    }}
                                    fontSize="large"
                                />
                            </IconButton>
                        </Grid>
                        <Grid item sm={4} xs={12} lg={4}>
                            <LockSmithTextField
                                    label="Comments"
                                    multiline
                                    name="pComments"
                                    onChange={handleInputChange}
                                    rows={3}
                                    value={formData.pComments}
                                    size="small"
                                />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} justifyContent="center">
                        <Grid item sm={4} xs={12} lg={4}>
                            <text
                                    style={{
                                        fontSize: 16,
                                        fontWeight: "bold",
                                    }}
                                >
                                    Notification closing
                                </text>
                                <LockSmithSwitch
                                    checked={formData.pNotifyClosing}
                                    onChange={handleInputChange}
                                    name="pNotifyClosing"
                                    value={formData.pNotifyClosing}
                                    // required={true}
                                />
                        </Grid>
                        <Grid item sm={4} xs={12} lg={4}>
                        <text
                                style={{
                                    fontSize: 16,
                                    fontWeight: "bold",
                                }}
                            >
                                Notification cancel
                            </text>
                            <LockSmithSwitch
                                checked={formData.pNotifyCancel}
                                onChange={handleInputChange}
                                name="pNotifyCancel"
                                value={formData.pNotifyCancel}
                                // required={true}
                            />
                        </Grid>
                        <Grid item sm={4} xs={12} lg={4}>
                       
                       </Grid>
                    </Grid>
                    
                    
                    <LockSmithComponentButtons closeHandler={resetFunction} />
           
                    <Dialog
                        open={newAccount}
                        onClose={cancelFunction}
                        PaperProps={{ className: classes.dialog }}
                     >
                            <LoadingComponent openLoading={loading} />
                            <DialogTitle>
                                <Box className={classes.dialogTitle}>
                                    <Box
                                        style={{
                                            flexDirection: "row",
                                            display: "flex",
                                            width: "55%",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
            
                                        <text
                                            style={{
                                                fontSize: 26,
                                                fontWeight: "bold",
                                                marginLeft: 20,
                                            }}
                                        >
                                            Account
                                        </text>
                                    </Box>
                                </Box>
                            </DialogTitle>
                        <DialogContent>
                            <AddAccount openDialog={newAccount} closeDialog={closeNewAccount} />
                        </DialogContent>
                    </Dialog>

                    <Dialog
                        open={newAddress}
                        onClose={cancelFunction}
                        PaperProps={{ className: classes.dialog }}
                     >
                            <LoadingComponent openLoading={loading} />
                            <DialogTitle>
                                <Box className={classes.dialogTitle}>
                                    <Box
                                        style={{
                                            flexDirection: "row",
                                            display: "flex",
                                            width: "55%",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
            
                                        <text
                                            style={{
                                                fontSize: 26,
                                                fontWeight: "bold",
                                                marginLeft: 20,
                                            }}
                                        >
                                            Address
                                        </text>
                                    </Box>
                                </Box>
                            </DialogTitle>
                        <DialogContent>
                            <AddAddress openDialog={newAddress} closeDialog={closeNewAddress} />    
                        </DialogContent>
                    </Dialog>
            
            
        </form>
    );
};

export default AddCompany;
