import React, { useEffect, useState } from "react";
import { useStateValue } from "../../Context/store";
import { delWarehouse, getAllWarehouses } from "../../Actions/UserAction";
import AddWareHouse from "./AddWareHouse";
import UpdateWareHouse from "./UpdateWareHouse";
import LockSmithMainTable from "../ReusableComponents/LockSmithMainTable";
import LockSmithDeleteComponent from "../ReusableComponents/LockSmithDeleteComponent";

const columns = () => { 
    return [
    { title: "ID", field: "IdPlace",cellStyle: {height: 40,padding:"0px 16px"} },
    { title: "Description", field: "Desc" ,cellStyle: {height: 40,padding:"0px 16px"}},
    { title: "InvCode", field: "InvCode",cellStyle: {height: 40,padding:"0px 16px"} },
    { title: "Active", field: "Active",cellStyle: {height: 40,padding:"0px 16px"} },
    { title: "Date Modified", field: "DateMod",cellStyle: {height: 40,padding:"0px 16px"} },
    { title: "Comments", field: "Comments",cellStyle: {height: 40,padding:"0px 16px"} },
    { title: "IdAddress", field: "IdAddress",cellStyle: {height: 40,padding:"0px 16px"} },
]};

const WareHouse = () => {
    const [{ allWarehouse }] = useStateValue();
    const [warehouse, setWarehouse] = useState(null);

    useEffect(() => {
        setWarehouse(allWarehouse ? allWarehouse.warehouse : []);
    }, [allWarehouse]);

    return (
        <React.Fragment>
            <LockSmithMainTable
                title="Warehouse"
                data={warehouse}
                columns={columns()}
                addComponent={AddWareHouse}
                updateComponent={UpdateWareHouse}
                deleteComponent={LockSmithDeleteComponent}
                deleteAction={delWarehouse}
                reducer={getAllWarehouses}
                itemId={columns().field}
            />
        </React.Fragment>
    );
};

export default WareHouse;
