import React, { useEffect, useState } from "react";
import { useStateValue } from "../../Context/store";
import { deleteTypeEmp, getAllTypeEmp } from "../../Actions/UserAction";
import AddTypeEmp from "./AddTypeEmp";
import UpdateTypeEmp from "./UpdateTypeEmp";
import LockSmithDeleteComponent from "../ReusableComponents/LockSmithDeleteComponent";
/*Import Reusable Components */
import LockSmithMainTable from "../ReusableComponents/LockSmithMainTable";

const columns = () => { 
    return [
    { title: "TYPE", field: "IdTypeEmp",cellStyle: {height: 40,padding:"0px 16px"} },
    { title: "DESCRIPTION", field: "Descr",cellStyle: {height: 40,padding:"0px 16px"} },
]};

const TypeEmp = () => {
    const [{ allTypeEmp }] = useStateValue();
    const [typeEmp, setTypeEmp] = useState([]);

    useEffect(() => {
        setTypeEmp(allTypeEmp ? allTypeEmp.typeemp : []);
    }, [allTypeEmp]);

    return (
        <React.Fragment>
            <LockSmithMainTable 
                title="Type Employee"
                data={typeEmp}
                columns={columns()}
                addComponent={AddTypeEmp}
                updateComponent={UpdateTypeEmp}
                deleteComponent={LockSmithDeleteComponent}
                deleteAction={deleteTypeEmp}
                reducer={getAllTypeEmp}
                itemId={columns().field}
            />
        </React.Fragment>
    );
};

export default TypeEmp;
