import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { initialState } from "./Context/initialState";
import { StateProvider } from "./Context/store";
import { mainReducer } from "./Context/Reducers";

ReactDOM.render(
    //<React.StrictMode>
    <>
        <StateProvider initialState={initialState} reducer={mainReducer}>
            <App />
        </StateProvider>
    </>,
    //</React.StrictMode>,
    document.getElementById("root")
);
