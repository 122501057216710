import React, { useEffect, useState } from "react";
import { useStateValue } from "../../Context/store";
import { deleteBranch, getAllBranch } from "../../Actions/UserAction";
import AddBranch from "./AddBranch";
import LockSmithDeleteComponent from "../ReusableComponents/LockSmithDeleteComponent";
import UpdateBranch from "./UpdateBranch";
/*Import Reusable Components */
import LockSmithMainTable from "../ReusableComponents/LockSmithMainTable";

const columns = () => { 
    return [
  { title: "ID", field: "IdBranch",cellStyle: {height: 40,padding:"0px 16px"} },
  { title: "Description", field: "Descr",cellStyle: {height: 40,padding:"0px 16px"} },
]};

const Branch = () => {
  const [{ allBranch }] = useStateValue();
  const [branch, setBranch] = useState(null);

  useEffect(() => {
    setBranch(allBranch ? allBranch.branch : []);
  }, [allBranch]);

  return (
    <React.Fragment>
        <LockSmithMainTable 
            title="Branch"
            data={branch}
            columns={columns()}
            addComponent={AddBranch}
            updateComponent={UpdateBranch}
            deleteComponent={LockSmithDeleteComponent}
            deleteAction={deleteBranch}
            reducer={getAllBranch}
            itemId={columns().field}
        />
    </React.Fragment>
  );
};

export default Branch;
