import React, { useEffect, useState } from "react";
import { useStateValue } from "../../Context/store";
import { delMoney, getAllMoney } from "../../Actions/UserAction";
import AddMoney from "./AddMoney";
import LockSmithDeleteComponent from "../ReusableComponents/LockSmithDeleteComponent";
import UpdateMoney from "./UpdateMoney";
/*Import Reusable Components */
import LockSmithMainTable from "../ReusableComponents/LockSmithMainTable";

const columns = () => { 
    return [
    { title: "ID", field: "IdMoney" ,cellStyle: {height: 40,padding:"0px 16px"}},
    { title: "Description", field: "Descr" ,cellStyle: {height: 40,padding:"0px 16px"}},
]};

const Money = () => {
    const [{ allMoney }] = useStateValue();
    const [money, setMoney] = useState([]);

    useEffect(() => {
        setMoney(allMoney ? allMoney.money : []);
    }, [allMoney]);

    return (
        <React.Fragment>
            <LockSmithMainTable 
                title="Money"
                data={money}
                columns={columns()}
                addComponent={AddMoney}
                updateComponent={UpdateMoney}
                deleteComponent={LockSmithDeleteComponent}
                deleteAction={delMoney}
                reducer={getAllMoney}
                itemId={columns().field}
            />
        </React.Fragment>
    );
};

export default Money;
