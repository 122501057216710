import {
    Backdrop,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Box,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { getAllDepartment, uptDepartment } from "../../Actions/UserAction";
import { useStateValue } from "../../Context/store";

/**Import Icons */
import { ReactComponent as CompanyIcon } from "../../Assets/Icons/Company.svg";
import { ReactComponent as AddIcon } from "../../Assets/Icons/Add.svg";
import Save from "@material-ui/icons/Save";
import Close from "@material-ui/icons/Close";
/** Import Modules */
import LockSmithTextField from "../ReusableComponents/LockSmithTextField";
import LocksmithEntryId from "../ReusableComponents/LockSmithEntryId";
/** Theme & Styles Import */
import { dialogStyle } from "../../Assets/styles/Styles";

const UpdateDepartment = ({ openDialog, closeDialog, data }) => {
    const classes = dialogStyle();
    const [openBackdrop, setOpenBackdrop] = useState(false);
    // eslint-disable-next-line no-empty-pattern
    const [{}, dispatch] = useStateValue();
    const [departmentData, setDepartmentData] = useState({});

    const dataValues = (e) => {
        const { name, value } = e.target;
        setDepartmentData((last) => ({
            ...last,
            [name]: value,
        }));
    };

    const saveFunction = async (e) => {
        e.preventDefault();
        setOpenBackdrop(true);
        await uptDepartment(departmentData).then((response) => {
            if (response.status === 200) {
                setOpenBackdrop(false);
                dispatch({
                    type: "OPEN_SNACKBAR",
                    openMessage: {
                        open: true,
                        title: "Success",
                        message: response.data.output.pmensaje,
                        alert: "success",
                    },
                });
                getAllDepartment(dispatch);
                closeDialog();
            } else {
                setOpenBackdrop(false);
                dispatch({
                    type: "OPEN_SNACKBAR",
                    openMessage: {
                        open: true,
                        title: "Error",
                        message: response.data.msg,
                        alert: "error",
                    },
                });
            }
        });
    };

    useEffect(() => {
        setDepartmentData({
            pIdDepartment: data.IdDepartment,
            pDescr: data.Descr,
        });
    }, [data]);

    const resetForm = () => {
        setDepartmentData({
            pIdDepartment: data.IdDepartment,
            pDescr: data.Descr,
        });
    }
    return (
            <form onSubmit={saveFunction}>

                    <Grid
                        container
                        spacing={2}
                        alignContent="center"
                        justifyContent="center"
                    >
                        <Grid item sm={12} xs={12}>
                            <LocksmithEntryId
                                label="ID ROLE"
                                id={departmentData.pIdDepartment}
                            />
                        </Grid>
                        <Grid item sm={12} xs={12}>
                            <LockSmithTextField
                                label="DESCRIPTION"
                                labelWidth="32%"
                                name="pDescr"
                                value={departmentData.pDescr}
                                onChange={dataValues}
                                required
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        spacing={2}
                        alignContent="center"
                        justifyContent="flex-end"
                    >
                        <Grid item xs={6} lg={3}>
                                <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                classes={{ root: classes.saveButton }}
                                size="large"
                                fullWidth
                                endIcon={
                                    <div
                                        style={{
                                            width: 20,
                                            height: 20,
                                            marginTop: -14,
                                            marginLeft: 10,
                                        }}
                                    >
                                        <Save/>
                                    </div>
                                }
                            >
                                Save
                            </Button>
                        </Grid>
                        <Grid item xs={6} lg={3}>
                                <Button
                                variant="contained"
                                onClick={resetForm}
                                classes={{ root: classes.cancelButton }}
                                size="large"
                                fullWidth
                                endIcon={
                                    <div
                                        style={{
                                            width: 20,
                                            height: 20,
                                            marginTop: -14,
                                            marginLeft: 10,
                                        }}
                                    >
                                        <Close/>
                                    </div>
                                }
                            >
                                Reset
                            </Button>
                        </Grid>
                    </Grid>
                    
                    
            </form>

    );
};

export default UpdateDepartment;
