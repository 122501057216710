import {
    Button,
    Grid,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { useStateValue } from "../../Context/store";

/** Import Icons */
import Save from "@material-ui/icons/Save";
import Close from "@material-ui/icons/Close";
/**Import Components */
import LocksmithEntryId from "../ReusableComponents/LockSmithEntryId";
import LockSmithTextField from "../ReusableComponents/LockSmithTextField";
import LockSmithSelect from "../ReusableComponents/LockSmithSelect";


/** Theme & Styles Import */
import { dialogStyle } from "../../Assets/styles/Styles";
import { LoadingComponent, useLoading } from "../../hooks/useLoading";
import { useForm } from "../../hooks/useForm";
import { getAllCategory, uptCategory } from "../../Actions/UserAction";

const UpdateCategory = ({ openDialog, closeDialog, data }) => {
    const classes = dialogStyle();
    const { loading, openLoading, closeLoading } = useLoading();
    const classFields = ["IdClass", "Descr"];
    // eslint-disable-next-line no-empty-pattern
    const [{ allClasses }, dispatch] = useStateValue();
    const { formData, handleInputChange, setFormData } = useForm({
        pIdCategory: 0,
        pDescr: "",
        pIdClass: 0,
    });

    const saveFunction = async (e) => {
        e.preventDefault();
        openLoading();
        await uptCategory(formData).then((response) => {
            if (response.status === 200) {
                closeLoading();
                dispatch({
                    type: "OPEN_SNACKBAR",
                    openMessage: {
                        open: true,
                        title: "Success",
                        message: response.data.output.pmensaje,
                        alert: "success",
                    },
                });
                getAllCategory(dispatch);
                closeDialog();
            } else {
                closeLoading();
                dispatch({
                    type: "OPEN_SNACKBAR",
                    openMessage: {
                        open: true,
                        title: "Error",
                        message: response.data.msg,
                        alert: "error",
                    },
                });
            }
        });
    };

    useEffect(() => {
        setFormData({
            pIdCategory: data.IdCategory,
            pDescr: data.Descr,
            pIdClass: data.IdClass,
        });
    }, [data]);

    const resetForm = () =>{
        setFormData({
            pIdCategory: data.IdCategory,
            pDescr: data.Descr,
            pIdClass: data.IdClass,
        }); 
    }
    return (
            <form onSubmit={saveFunction} >
                    <Grid
                        container
                        spacing={2}
                        alignContent="center"
                        justifyContent="center"
                    >
                        <Grid item xs={12}>
                            <LocksmithEntryId label="Id Category" id={formData.pIdCategory} />
                        </Grid>
                        <Grid item xs={6}>
                            <LockSmithTextField
                                label="Description"
                                labelWidth="32%"
                                name="pDescr"
                                value={formData.pDescr}
                                onChange={handleInputChange}
                                required={true}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <LockSmithSelect
                                label="Select class"
                                name="pIdClass"
                                value={formData.pIdClass}
                                valueId="IdClass"
                                data={allClasses}
                                handler={handleInputChange}
                                fields={classFields}
                                required={true}
                            />
                        </Grid>     
                    </Grid>
                    <Grid
                        container
                        spacing={2}
                        alignContent="center"
                        justifyContent="flex-end"
                    >
                        <Grid item xs={6} lg={3}>
                                <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                classes={{ root: classes.saveButton }}
                                size="large"
                                fullWidth
                                endIcon={
                                    <div
                                        style={{
                                            width: 20,
                                            height: 20,
                                            marginTop: -14,
                                            marginLeft: 10,
                                        }}
                                    >
                                        <Save/>
                                    </div>
                                }
                            >
                                Save
                            </Button>
                        </Grid>
                        <Grid item xs={6} lg={3}>
                                <Button
                                variant="contained"
                                onClick={resetForm}
                                classes={{ root: classes.cancelButton }}
                                size="large"
                                fullWidth
                                endIcon={
                                    <div
                                        style={{
                                            width: 20,
                                            height: 20,
                                            marginTop: -14,
                                            marginLeft: 10,
                                        }}
                                    >
                                        <Close/>
                                    </div>
                                }
                            >
                                Reset
                            </Button>
                        </Grid>
                    </Grid>
                    
                    
            </form>
    );
};

export default UpdateCategory;
