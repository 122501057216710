import React, { useEffect, useState } from 'react'
import { useStateValue } from '../../Context/store';
import { deleteDocumentC, getAllDocumentC } from "../../Actions/UserAction";
import AddDocumentC from './AddDocumentC';
import LockSmithDeleteComponent from "../ReusableComponents/LockSmithDeleteComponent";
import UpdateDocumentC from './UpdateDocumentC';
/*Import Reusable Components */
import LockSmithMainTable from "../ReusableComponents/LockSmithMainTable";

const columns = () => { 
    return [
    { title: "ID", field: "IdDocument" },
    { title: "DESCRIPTION", field: "Desc" },
]};

const DocumentC = () => {
    const [{ allDocumentC }] = useStateValue();
    const [documentC, setDocumentC] = useState(null);

    useEffect(() => {
        setDocumentC(allDocumentC ? allDocumentC.allDocumentC : []);
    }, [allDocumentC]);

    return (
        <React.Fragment>
            <LockSmithMainTable 
                title="Document E"
                data={documentC}
                columns={columns()}
                //addComponent={AddDocumentC}
                //updateComponent={UpdateDocumentC}
                //deleteComponent={LockSmithDeleteComponent}
                deleteAction={deleteDocumentC}
                reducer={getAllDocumentC}
                itemId={columns().field}
            />
        </React.Fragment>
    );
};

export default DocumentC;
