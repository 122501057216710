import {
    Backdrop,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    makeStyles,
} from "@material-ui/core";
import React, { useState } from "react";
import { useStateValue } from "../../Context/store";

const useStyles = makeStyles((theme) => ({
    dialogTitle: {
        backgroundColor: "#007ACB",
        color: "#FFF",
    },
    dialogButton: {
        display: "flex",
        justifyContent: "center",
    },
    redButton: {
        backgroundColor: "#b71c1c",
        color: "#FFF",
        "&:hover": {
            backgroundColor: "#7f0000",
        },
    },
    greenButton: {
        backgroundColor: "#1b5e20",
        color: "#FFF",
        "&:hover": {
            backgroundColor: "#003300",
        },
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
}));

const UpdateDocumentC = ({ openDialog, closeDialog, data }) => {
    const classes = useStyles();
    const [openBackdrop, setOpenBackdrop] = useState(false);
    // eslint-disable-next-line no-empty-pattern
    const [{}, dispatch] = useStateValue();
    const [documentCData, setDocumentCData] = useState({});

    const dataValues = (e) => {
        const { name, value } = e.target;
        setDocumentCData((last) => ({
            ...last,
            [name]: value,
        }));
    };

    const saveFunction = async (e) => {
        e.preventDefault();
        setOpenBackdrop(true);
    };

    return (
        <Dialog open={openDialog} onClose={closeDialog} maxWidth="xl">
            <Backdrop className={classes.backdrop} open={openBackdrop}>
                <CircularProgress color="primary" size={70} />
            </Backdrop>
            <DialogTitle className={classes.dialogTitle}>
                Update DocumentC
            </DialogTitle>
            <DialogContent>Form Here !</DialogContent>
            <DialogActions className={classes.dialogButton}>
                <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    classes={{ root: classes.greenButton }}
                    size="large"
                    fullWidth
                >
                    Save
                </Button>
                <Button
                    variant="contained"
                    onClick={closeDialog}
                    classes={{ root: classes.redButton }}
                    size="large"
                    fullWidth
                >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default UpdateDocumentC;
