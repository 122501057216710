import {
    Button,
    Grid,
    InputBase,
    MenuItem,
    Select,
    styled,
    makeStyles,
} from "@material-ui/core";
import React, { useState } from "react";
import SaveIcon from "../../../Assets/Icons/Remember_me_Yes.svg";
import { ReactComponent as ScrollDown } from "../../../Assets/Icons/Scroll_Down.svg";
import { dialogStyle } from "../../../Assets/styles/Styles";
import { useStateValue } from "../../../Context/store";
import { useForm } from "../../../hooks/useForm";
import LockSmithSwitch from "../../ReusableComponents/LockSmithSwitch";
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import Save from "@material-ui/icons/Save";
const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "& .MuiInputBase-input": {
        padding: "9px 2px",
    },
}));

const useStyle = makeStyles({
    label:{
        fontSize: 16,
        fontWeight: "bold"
    },
    dropDown: {
        backgroundColor: "#FFF",
        borderRadius: 8,
        width: "100%",
    },
    icon:{
        width: 20,
        height: 20,
        margin: 10,
        color:"rgb(136,136,136)"
    },
    borderForm:{
        border:"solid 1px rgba(0, 0, 0, 0.23) !important",
        width:"100%",
        borderRadius: 5
    },
    textForm: {
        fontSize: 14, 
        fontWeight: "400", 
        color:"rgb(136,136,136)",
        width:"100%"
    }
})

const ClassTab = (props) => {
    const classes = dialogStyle();
    const tabClasses = useStyle();
    const { data, handleClassData, saveFunction, classProduct, callback } = props;
    const [{ allClasses, allCategory, allSubCat }] = useStateValue();

    /* Save Control */
    const [saveDisabled, setSaveDisabled] = useState(true);

    return (
        <Grid alignItems="center" container justifyContent="center" spacing={2}>
            <Grid item sm={12} xs={12} lg={3}>
                <text
                className={tabClasses.textForm}
                >
                    Class
                </text>
                <Select
                    name="pIdClass"
                    id="pIdClass"
                    className={tabClasses.borderForm}
                    input={<BootstrapInput />}
                    IconComponent={() => (
                        <div
                        className={tabClasses.icon}
                        >
                            <ArrowDropDown />
                        </div>
                    )}
                    value={data.pIdClass || ''}
                    onChange={(e) => {
                        handleClassData(e);
                    }}
                    //defaultValue={data.pIdClass}
                >
                    {allClasses.class
                        ? allClasses.class.map((item, index) => {
                              return (
                                  <MenuItem
                                      key={index}
                                      style={{
                                          justifyContent: "center",
                                      }}
                                      value={item.IdClass}
                                  >
                                      {item.IdClass} - {item.Descr}
                                  </MenuItem>
                              );
                          })
                        : ""}
                </Select>
            </Grid>
            <Grid item sm={12} xs={12} lg={3}>
                <text
                className={tabClasses.textForm}
                >
                    Category
                </text>
                <Select
                    name="pIdCategory"
                    className={tabClasses.borderForm}
                    input={<BootstrapInput />}
                    IconComponent={() => (
                        <div
                        className={tabClasses.icon}
                        >
                            <ArrowDropDown />
                        </div>
                    )}
                    //disabled={catSelect}
                    value={data.pIdCategory || ''}
                    onChange={(e) => {
                        handleClassData(e);
                    }}
                    //defaultValue={data.pIdCategory}
                >
                    {allCategory.category &&
                        allCategory.category.map((item, index) => {
                            if (item.IdClass === data.pIdClass) {
                                return (
                                    <MenuItem
                                        key={index}
                                        style={{
                                            justifyContent: "center",
                                        }}
                                        value={item.IdCategory}
                                    >
                                        {item.IdCategory} - {item.Descr}
                                    </MenuItem>
                                );
                            }
                        })}
                </Select>
            </Grid>
            <Grid item sm={12} xs={12} lg={3}>
                <text
                className={tabClasses.textForm}
                >
                    Subcategory
                </text>
                <Select
                    name="pidSubCategory"
                    className={tabClasses.borderForm}
                    input={<BootstrapInput />}
                    IconComponent={() => (
                        <div
                        className={tabClasses.icon}
                        >
                            <ArrowDropDown />
                        </div>
                    )}
                    //disabled={subCatSelect}
                    value={data.pidSubCategory}
                    onChange={(e) => {
                        handleClassData(e);
                        setSaveDisabled(false);
                    }}
                    //defaultValue={data.pIdSubCategory}
                >
                    {allSubCat.subcat &&
                        allSubCat.subcat.map((item, index) => {
                            if (item.IdCategory === data.pIdCategory) {
                                return (
                                    <MenuItem
                                        key={index}
                                        style={{
                                            justifyContent: "center",
                                        }}
                                        value={item.IdSubCategory}
                                    >
                                        {item.IdSubCategory} - {item.Descr}
                                    </MenuItem>
                                );
                            }
                        })}
                </Select>
            </Grid>
            <Grid item sm={12} xs={12} lg={3}>
                <text
                    className={tabClasses.textForm}
                >
                    Serial Number
                </text>
                <LockSmithSwitch
                    checked={data.pSerie}
                    onChange={handleClassData}
                    name="pSerie"
                    value={data.pSerie}
                />
            </Grid>
            <Grid item sm={6} xs={6}></Grid>
          <Grid item sm={3} xs={3}></Grid>
            <Grid item sm={12} xs={12} lg={3}>
                <Button
                    variant="contained"
                    color="primary"
                    classes={{ root: classes.saveButton }}
                    size="large"
                    fullWidth
                    endIcon={
                        <div
                            style={{
                                width: 20,
                                height: 20,
                                marginTop: -14,
                                marginLeft: 10,
                            }}
                        >
                            <Save style={{color:"#fff"}}/>
                        </div>
                    }
                    disabled={saveDisabled}
                    onClick={saveFunction}
                >
                    Save
                </Button>
            </Grid>
        </Grid>
    );
};

export default ClassTab;
