import {
    Grid,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { getAllContact, insContact } from "../../Actions/UserAction";
import { useStateValue } from "../../Context/store";
import moment from "moment";

/** Import Icons */

/** Theme & Styles Import */
import { dialogStyle } from "../../Assets/styles/Styles";

/** Custom components & hooks */
import { LoadingComponent, useLoading } from "../../hooks/useLoading";
import { useForm } from "../../hooks/useForm";
import LockSmithTextField from "../ReusableComponents/LockSmithTextField";
import LockSmithSelect from "../ReusableComponents/LockSmithSelect";
import LockSmithComponentButtons from "../ReusableComponents/LockSmithComponentButtons";

const AddContact = ({ openDialog, closeDialog, hide }) => {
    const classes = dialogStyle();
    const { loading, openLoading, closeLoading } = useLoading();
    // eslint-disable-next-line no-empty-pattern
    const [{allCompany}, dispatch] = useStateValue();
    const { formData, handleInputChange, setFormData } = useForm({});
    //console.log(allCompany)
    const companyFields = ["IdCompany", "Desc"];

    const saveFunction = async (e) => {
        e.preventDefault();
        openLoading();
        try{
        await insContact(formData).then((response) => {
            if (response.status === 200) {
                closeLoading();
                dispatch({
                    type: "OPEN_SNACKBAR",
                    openMessage: {
                        open: true,
                        title: "Success",
                        message: response.data.output.pmensaje,
                        alert: "success",
                    },
                });
                getAllContact(dispatch);
                cancelFunction();
            } else {
                console.log(response)
                closeLoading();
                dispatch({
                    type: "OPEN_SNACKBAR",
                    openMessage: {
                        open: true,
                        title: "Error",
                        message: response.data.msg,
                        alert: "error",
                    },
                });
            }
        });
    }catch(error){
        console.log(error)
    }
    };

    const cancelFunction = () => {
        Array.from(document.querySelectorAll("form input")).forEach(
            input => (input.value = "")
          );
        setFormData({
            pIdCompany: 1,
        });

    };

    useEffect(() => {
        setFormData({
            pIdCompany: 1,
        })
    } , [])

    return (
            <form onSubmit={saveFunction}>
                    <Grid container spacing={2} justifyContent="flex-start">
                        <Grid item xs={3} sm={3}>
                            <LockSmithTextField
                                label="Title"
                                name="pTit"
                                onChange={handleInputChange}
                                value={formData.pTit}
                            />
                        </Grid>
                        <Grid item xs={3} sm={3}>
                            <LockSmithTextField
                                label="First name*"
                                name="pFirstName"
                                labelWidth="100%"
                                onChange={handleInputChange}
                                value={formData.pFirstName}
                                required
                            />
                        </Grid>
                        <Grid item xs={3} sm={3}>
                            <LockSmithTextField
                                label="Middle name"
                                labelWidth="100%"
                                name="pMiddleName"
                                onChange={handleInputChange}
                                value={formData.pMiddleName}
                            />
                        </Grid>
                        <Grid item xs={3} sm={3}>
                            <LockSmithTextField
                                label="Last name*"
                                labelWidth="100%"
                                name="pLastName"
                                onChange={handleInputChange}
                                value={formData.pLastName}
                                required
                            />
                        </Grid>
                        <Grid item xs={2} sm={2}>
                            <LockSmithTextField
                                label="Phone"
                                name="pPhone"
                                type="number"
                                onChange={handleInputChange}
                                value={formData.pPhone}
                            />
                        </Grid>
                        <Grid item xs={1} sm={1}>
                            <LockSmithTextField
                                label="Ext"
                                name="pExt"
                                type="number"
                                onChange={handleInputChange}
                                value={formData.pExt}
                            />
                        </Grid>
                        <Grid item xs={3} sm={3}>
                            <LockSmithTextField
                                label="Mobile*"
                                name="pMovil"
                                type="number"
                                onChange={handleInputChange}
                                value={formData.pMovil}
                                required
                            />
                        </Grid>
                        <Grid item xs={3} sm={3}>
                            <LockSmithTextField
                                label="Email"
                                name="pEmail"
                                tyoe="email"
                                onChange={handleInputChange}
                                value={formData.pEmail}
                            />
                        </Grid>
                        {!hide &&  
                        <Grid item xs={3} sm={3}>
                            <LockSmithSelect
                                name="pIdCompany"
                                value={formData.pIdCompany}
                                handler={handleInputChange}
                                data={allCompany}
                                label="Company"
                                labelWidth="22%"
                                fields={companyFields}
                                defaultValue={1}
                                
                            />
                        </Grid>}
                        <Grid item xs={12} sm={12}>
                            <text
                                style={{
                                    fontSize: 16,
                                    fontWeight: "bold",
                                    marginLeft: 15,
                                }}
                            >
                                DATE MOD
                            </text>
                            <text
                                style={{
                                    fontSize: 20,
                                    fontWeight: "bold",
                                    marginLeft: 30,
                                }}
                            >
                                {moment().format("LLLL").toUpperCase()}
                            </text>
                        </Grid>
                    </Grid>
                    <LockSmithComponentButtons closeHandler={cancelFunction} />
            </form>
    );
};

export default AddContact;
