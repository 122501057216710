import {
    Button,
    Grid,
} from "@material-ui/core";
import MaterialTable from "material-table";
import { Alert } from '@material-ui/lab';
import React, { useEffect, useState } from "react";
import { addLevelSkill, getAllLevelSkill, getAllLevelsNotSkill } from "../../Actions/UserAction";
import { useStateValue } from "../../Context/store";

/**Import Icons */

import Save from "@material-ui/icons/Save";
import Close from "@material-ui/icons/Close";
/**Import Components */
import LockSmithSelect from "../ReusableComponents/LockSmithSelect";

/** Theme & Styles Import */
import {dialogStyle} from '../../Assets/styles/Styles'

const columns = () => { 
    return [
    { title: "KEY", field: "KEY" },
    { title: "DESCRIPTION", field: "DESCRIPTION" },
]};

const AddLevelSkill = ({ openDialog, closeDialog }) => {
    
    const levelSkillFields = ["IdLevel", "Descr"];
    const classes = dialogStyle();
    const [{ allLevels, allSkills, allLevelSkill }, dispatch] = useStateValue();
    const [open, setOpen] = useState(false);
    const [skillsData, setSkillsData] = useState([]);
    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [skillRequired, setSkillRequired] = useState(true);
    const [levelData, setLevelData] = useState([]);
    const [levelSkillData, setLevelSkillData] = useState({
        pidSkill: [],
        //pidLevel: 0,
    });

    const dataValues = (e) => {
        const { name, value } = e.target;
        setLevelSkillData((last) => ({
            ...last,
            [name]: value,
        }));
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpen(false);
    }

    const handleSkillSelection = (rows) => {
        let skills = [];
        for(let i = 0; i < rows.length; i++){
            skills.push(rows[i].KEY)
        }

        setLevelSkillData(prevState => ({
            ...prevState,
            pidSkill: skills,
        }));
        
    }

    const saveLevelSkill = async (e) => {
        e.preventDefault();
        if(skillRequired){
            setOpen(true);
            return;
        }
        setOpenBackdrop(true);
        try{
        await addLevelSkill(levelSkillData).then((response) => {
            if (response.status === 200) {
                setOpenBackdrop(false);
                dispatch({
                    type: "OPEN_SNACKBAR",
                    openMessage: {
                        open: true,
                        title: "Success",
                        message: response.data.output.pmensaje,
                        alert: "success",
                    },
                });
                getAllLevelSkill(dispatch);
                cancelLevelSkill();
            } else {
                setOpenBackdrop(false);
                dispatch({
                    type: "OPEN_SNACKBAR",
                    openMessage: {
                        open: true,
                        title: "Error",
                        message: response.data.msg,
                        alert: "error",
                    },
                });
            }
        });
    }catch(error){
        console.log(error)
    }
    };

    const cancelLevelSkill = () => {
        setLevelSkillData({
            pidSkill: [],
            pidLevel: 0
        });
        const stateUpdated = skillsData.map((item) => {
            if (item.tableData.checked) {
              return {
                ...item,
                tableData: {checked: false},
              };
            } else {
              return item;
            }
          });
        setSkillsData(stateUpdated);
    };

    const getAllLevelsNotAssigned = async () => {
        await getAllLevelsNotSkill().then((response) => {
            console.log("Response status-->",response.status);
            if(response.status === 200){
                console.log("Response-->",response.data);
                setLevelData({"typeemp": response.data});
            }
        });
    }

    useEffect(() => {
        getAllLevelsNotAssigned();
        console.log("Skills-->",allSkills);
        allSkills &&
        allSkills.skills.map((item, index) => {
                return setSkillsData((last) => [
                    ...last,
                    {
                        tableData: { checked: false },
                        KEY: item.KEY,
                        DESCRIPTION: item.DESCRIPTION,
                    },
                ]);
            });
    }, [allSkills]);

    useEffect(() => {
            getAllLevelsNotAssigned();
    });

    useEffect(() => {
        if(levelSkillData && levelSkillData.pidSkill.length > 0){
            console.log("required false", levelSkillData.pidSkill.length)
            setSkillRequired(false);
            setOpen(false);
        }else{
            setSkillRequired(true);
        }
    }
    ,[levelSkillData]);

    return (
            <form onSubmit={saveLevelSkill}>
                    <Grid
                        container
                        spacing={2}
                        alignContent="center"
                        justifyContent="center"
                    >
                         <Grid item xs={12} sm={12}>
                            <LockSmithSelect 
                                label="Select level" 
                                name="pidLevel" 
                                value={levelSkillData.pidLevel} 
                                valueId="pidLevel" 
                                data={allLevels} 
                                handler={dataValues} 
                                fields={levelSkillFields}
                                required={true}
                                defaultValue={levelSkillData}
                            />
                         </Grid>
                        <Grid item xs={12} sm={12}>
                            <div style={{opacity: open ? 1 : 0}}>
                                <Alert variant="filled" onClose={handleClose} severity="error" >
                                Select at least 1 skill!
                                </Alert>
                            </div>
                            <MaterialTable
                                components={{ Container: "main" }}
                                title="Select skills:"
                                data={skillsData}
                                columns={columns()}
                                localization={{
                                    body: {
                                        emptyDataSourceMessage:
                                            "No Skills added yet",
                                    },
                                    toolbar: {
                                        searchPlaceholder: "Search Skill",
                                        nRowsSelected: "{0} skill(s) selected",
                                    },
                                }}
                                options={{
                                    selection: true,
                                    showSelectAllCheckbox: false,
                                    cellStyle: {
                                        padding:5
                                      },
                                    headerStyle: {
                                        backgroundColor: "#369",
                                        fontWeight: "bolder",
                                        color:"#FFF",
                                        padding:5
                                    }
                                }}
                                onSelectionChange={(rows) => {
                                    handleSkillSelection(rows);
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        spacing={2}
                        alignContent="center"
                        justifyContent="flex-end"
                    >
                         <Grid item xs={6} lg={3}>
                                <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                classes={{ root: classes.saveButton }}
                                size="large"
                                fullWidth
                                endIcon={
                                    <div
                                        style={{
                                            width: 20,
                                            height: 20,
                                            marginTop: -11,
                                            marginLeft: 10,
                                        }}
                                    >
                                        <Save/>
                                    </div>
                                }
                            >
                                Save
                            </Button>
                         </Grid>
                         <Grid item xs={6} lg={3}>
                                <Button
                                variant="contained"
                                onClick={cancelLevelSkill}
                                classes={{ root: classes.cancelButton }}
                                size="large"
                                fullWidth
                                endIcon={
                                    <div
                                        style={{
                                            width: 20,
                                            height: 20,
                                            marginTop: -11,
                                            marginLeft: 10,
                                        }}
                                    >
                                        <Close/>
                                    </div>
                                }
                            >
                                Reset
                            </Button>
                         </Grid>
                    </Grid>
                    
                    
            </form>
    );
};

export default AddLevelSkill;
