import React, { useState } from "react";
import {
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Collapse,
} from "@material-ui/core";
//import { CheckCircle } from "@material-ui/icons";
import { Link, useLocation } from "react-router-dom";
import clsx from "clsx";

///icons
import PeopleAlt from "@material-ui/icons/PeopleAlt";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Apartment from "@material-ui/icons/Apartment";
import AttachMoney from "@material-ui/icons/AttachMoney";
import AccountBalance from "@material-ui/icons/AccountBalance";
import LocalPhone from "@material-ui/icons/LocalPhone";
import ListAltSharp from "@material-ui/icons/ListAltSharp";
import Work from "@material-ui/icons/Work";
import FaceRounded from "@material-ui/icons/FaceRounded";
import ExtensionRounded from "@material-ui/icons/ExtensionRounded";
import ArrowForwardIosRounded from "@material-ui/icons/ArrowForwardIosRounded";
import MenuOpen from "@material-ui/icons/MenuOpen";
import Close from "@material-ui/icons/Close";
import RadioButtonChecked from "@material-ui/icons/RadioButtonChecked";


const Menu = ({menu, closeMenu, classes, isOpened }) => {
  //const theme = useTheme();
  const location = useLocation();
  const [employeeSubMenu, setEmployeeSubMenu] = useState(false);
  const [companySubMenu, setCompanySubMenu] = useState(false);
  const [productsSubMenu, setProductsSubMenu] = useState(false);
  const [usersSubMenu, setUsersSubMenu] = useState(false);
  const [customersSubMenu, setCustomersSubMenu] = useState(false);
  const [bankSubMenu, setBankSubMenu] = useState(false);
  const [inventorySubMenu, setInventorySubMenu] = useState(false)
  const [nominaSubMenu, setNominaSubMenu] = useState(false);
  const [viewMenu, setViewMenu] = useState(true);
  const [viewMenuHover, setViewMenuHover] = useState(true);
 
  const handleClickEmp = () => {
    setBankSubMenu(false);
    setCompanySubMenu(false);
    setCustomersSubMenu(false);
    setProductsSubMenu(false);
    setUsersSubMenu(false);
    setInventorySubMenu(false)
    setNominaSubMenu(false);
    setEmployeeSubMenu(!employeeSubMenu);
  };
  const handleClickCompany = () => {
    setBankSubMenu(false);
    setCustomersSubMenu(false);
    setEmployeeSubMenu(false);
    setProductsSubMenu(false);
    setUsersSubMenu(false);
    setInventorySubMenu(false)
    setNominaSubMenu(false);
    setCompanySubMenu(!companySubMenu);
  };
  const handleClickProducts = () => {
    setBankSubMenu(false);
    setCompanySubMenu(false);
    setCustomersSubMenu(false);
    setEmployeeSubMenu(false);
    setUsersSubMenu(false);
    setInventorySubMenu(false)
    setNominaSubMenu(false);
    setProductsSubMenu(!productsSubMenu);
  };
  const handleClickUsers = () => {
    setBankSubMenu(false);
    setCompanySubMenu(false);
    setCustomersSubMenu(false);
    setEmployeeSubMenu(false);
    setProductsSubMenu(false);
    setInventorySubMenu(false)
    setNominaSubMenu(false);
    setUsersSubMenu(!usersSubMenu);
  };
  const handleClickCustomers = () => {
    setBankSubMenu(false);
    setCompanySubMenu(false);
    setEmployeeSubMenu(false);
    setProductsSubMenu(false);
    setUsersSubMenu(false);
    setInventorySubMenu(false)
    setNominaSubMenu(false);
    setCustomersSubMenu(!customersSubMenu);
  };

  const handleClickBank = () => {
    setCompanySubMenu(false);
    setCustomersSubMenu(false);
    setEmployeeSubMenu(false);
    setProductsSubMenu(false);
    setUsersSubMenu(false);
    setInventorySubMenu(false)
    setNominaSubMenu(false);
    setBankSubMenu(!bankSubMenu);
  };

    const handleClickInventory = () => {
        setCompanySubMenu(false);
        setCustomersSubMenu(false);
        setEmployeeSubMenu(false);
        setProductsSubMenu(false);
        setUsersSubMenu(false);
        setBankSubMenu(false)
        setNominaSubMenu(false);
        setInventorySubMenu(!inventorySubMenu);
    };

    const handleClickNomina = () => {
        setCompanySubMenu(false);
        setCustomersSubMenu(false);
        setEmployeeSubMenu(false);
        setProductsSubMenu(false);
        setUsersSubMenu(false);
        setBankSubMenu(false)
        setNominaSubMenu(!nominaSubMenu);
        setInventorySubMenu();
    };

    const closeAll = () => {
        setBankSubMenu(false);
        setCompanySubMenu(false);
        setCustomersSubMenu(false);
        setEmployeeSubMenu(false);
        setProductsSubMenu(false);
        setUsersSubMenu(false);
        setInventorySubMenu(false);
        setNominaSubMenu(false);
        closeMenu();
    };
    const openMenu = () => {
        setViewMenu(!viewMenu);
        // if(viewMenu){
        //     closeAll();
        // }
        // closeMenu();
    };
    
    return (
        <Drawer
            variant="temporary"
            // onMouseOver={menuHover}
            // onMouseLeave={menuLeave}
            // className={clsx(classes.drawer, {
            //     [classes.drawerOpen]: viewMenu,
            //     [classes.drawerClose]: !viewMenu,
            // })}
            // classes={{
            //     paper: clsx({
            //         [classes.drawerOpen]: viewMenu,
            //         [classes.drawerClose]: !viewMenu,
            //     }),
            // }}
            open={viewMenu !== menu}
        >
            <div className={classes.sidebar} style={{backgroundColor: '#3f4d67'}}>
                <div className={classes.mainLogo} style={{backgroundColor: '#3f4d67'}}/>
                    <IconButton
                        edge="start"
                        style={{ color: "#FFF" }}
                        aria-label="menu"
                        onClick={openMenu}
                        className={clsx(classes.menuButton)}
                    >
                            <Close className={classes.iconMenu}/>
                    </IconButton>
            </div>
            <List style={{backgroundColor: '#3f4d67', flex: 1}}>
                {/*<ListItem
                    component={Link}
                    to="/"
                    button
                    className={
                        location.pathname === "/"
                            ? classes.selectedMenu
                            : classes.unSelectedMenu
                    }
                >
                    {menu && (
                        <ListItemText
                            primary="HOME"
                            classes={{ primary: classes.menuText }}
                            className={
                                location.pathname === "/"
                                    ? classes.selectedMenuText
                                    : classes.unSelectedMenuText
                            }
                        />
                    )}

                    <div className={classes.icon}>
                        {location.pathname === "/" ? (
                            <img src={HomeIconSelected} alt="..." />
                        ) : (
                            <img src={HomeIcon} alt="..." />
                        )}
                    </div>
                </ListItem>*/}
                   {/* User SubMenu */}
                   <ListItem
                    className={
                        usersSubMenu === true
                            ? classes.selectedMenu
                            : classes.unSelectedMenu
                    }
                    button
                    onClick={handleClickUsers}
                >
                    <PeopleAlt className={classes.icon}></PeopleAlt>
                    {(viewMenu || viewMenuHover) && (
                        <ListItemText
                            classes={{ primary: classes.menuText }}
                            className={
                                usersSubMenu === true
                                    ? classes.selectedMenuText
                                    : classes.unSelectedMenuText
                            }
                            primary="Users"
                        />
                    )}
                    <ArrowForwardIosRounded className={clsx(classes.arrowRight, 
                        {[classes.hide]: usersSubMenu})}></ArrowForwardIosRounded>
                    <ExpandMore className={clsx(classes.arrowDown, {[classes.hide]:!usersSubMenu})}></ExpandMore>
                  
                </ListItem>
                <Collapse in={usersSubMenu} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem
                            button
                            component={Link}
                            to="/user"
                            className={`${classes.nested} ${classes.done} ${
                                location.pathname === "/user"
                                    ? classes.selectedSubMenu
                                    : classes.unSelectedSubMenu
                            }`}
                        >
                            {
                                (location.pathname === "/user") && 
                                <RadioButtonChecked className={classes.iconPointSubmenu}/>
                            }
                            {(viewMenu || viewMenuHover) && (
                                <ListItemText
                                    classes={{ primary: classes.subMenuText }}
                                    primary="User"
                                />
                            )}
                        </ListItem>
                        <ListItem
                            button
                            component={Link}
                            to="/role"
                            className={`${classes.nested} ${classes.done} ${
                                location.pathname === "/role"
                                    ? classes.selectedSubMenu
                                    : classes.unSelectedSubMenu
                            }`}
                        >
                            {
                                (location.pathname === "/role") && 
                                <RadioButtonChecked className={classes.iconPointSubmenu}/>
                            }
                            {(viewMenu || viewMenuHover) && (
                                <ListItemText
                                    primary="Role"
                                    classes={{ primary: classes.subMenuText }}
                                />
                            )}

                        </ListItem>
                    </List>
                </Collapse>
                {/* Company SubMenu */}
                <ListItem
                    className={
                        companySubMenu === true
                            ? classes.selectedMenu
                            : classes.unSelectedMenu
                    }
                    button
                    onClick={handleClickCompany}
                >
                    <Apartment className={classes.icon}></Apartment>
                    {(viewMenu || viewMenuHover) && (
                        <ListItemText
                            classes={{ primary: classes.menuText }}
                            className={
                                companySubMenu === true
                                    ? classes.selectedMenuText
                                    : classes.unSelectedMenuText
                            }
                            primary="Company"
                        />
                    )}
                    <ArrowForwardIosRounded className={clsx(classes.arrowRight, 
                        {[classes.hide]: companySubMenu})}></ArrowForwardIosRounded>
                    <ExpandMore className={clsx(classes.arrowDown, {[classes.hide]:!companySubMenu})}></ExpandMore>
                </ListItem>
                <Collapse in={companySubMenu} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem
                            button
                            component={Link}
                            to="/company"
                            className={`${classes.nested} ${classes.done} ${
                                location.pathname === "/company"
                                    ? classes.selectedSubMenu
                                    : classes.unSelectedSubMenu
                            }`}
                        >
                            {
                                (location.pathname === "/company") && 
                                <RadioButtonChecked className={classes.iconPointSubmenu}/>
                            }
                            {(viewMenu || viewMenuHover) && (
                                <ListItemText
                                    primary="Company"
                                    classes={{ primary: classes.subMenuText }}
                                />
                            )}
                        </ListItem>
                        {/* <ListItem
                            button
                            component={Link}
                            to="/message"
                            className={`${classes.nested} ${classes.done} ${
                                location.pathname === "/message"
                                    ? classes.selectedSubMenu
                                    : classes.unSelectedSubMenu
                            }`}
                        >
                            {menu && (
                                <ListItemText
                                    primary="MESSAGE"
                                    classes={{ primary: classes.subMenuText }}
                                />
                            )}
                            {location.pathname === "/message" && (
                                <div className={classes.iconSizes}>
                                    <img src={SelectionIcon} alt="..." />
                                </div>
                            )}
                        </ListItem> */}
                        <ListItem
                            button
                            component={Link}
                            to="/job"
                            className={`${classes.nested} ${classes.done} ${
                                location.pathname === "/job"
                                    ? classes.selectedSubMenu
                                    : classes.unSelectedSubMenu
                            }`}
                        >
                            {
                                (location.pathname === "/job") && 
                                <RadioButtonChecked className={classes.iconPointSubmenu}/>
                            }
                            {(viewMenu || viewMenuHover) && (
                                <ListItemText
                                    primary="Job"
                                    classes={{ primary: classes.subMenuText }}
                                />
                            )}
                        </ListItem>
                        <ListItem
                            button
                            component={Link}
                            to="/department"
                            className={`${classes.nested} ${classes.done} ${
                                location.pathname === "/department"
                                    ? classes.selectedSubMenu
                                    : classes.unSelectedSubMenu
                            }`}
                        >
                            {
                                (location.pathname === "/department") && 
                                <RadioButtonChecked className={classes.iconPointSubmenu}/>
                            }
                            {(viewMenu || viewMenuHover) && (
                                <ListItemText
                                    primary="Department"
                                    classes={{ primary: classes.subMenuText }}
                                />
                            )}  
                        </ListItem>
                        <ListItem
                            button
                            component={Link}
                            to="/branch"
                            className={`${classes.nested} ${classes.done} ${
                                location.pathname === "/branch"
                                    ? classes.selectedSubMenu
                                    : classes.unSelectedSubMenu
                            }`}
                        >
                            {
                                (location.pathname === "/branch") && 
                                <RadioButtonChecked className={classes.iconPointSubmenu}/>
                            }
                            {(viewMenu || viewMenuHover) && (
                                <ListItemText
                                    primary="Branch"
                                    classes={{ primary: classes.subMenuText }}
                                />
                            )}
                        </ListItem>
                        <ListItem
                            button
                            component={Link}
                            to="/addresstype"
                            className={`${classes.nested} ${classes.done} ${
                                location.pathname === "/addresstype"
                                    ? classes.selectedSubMenu
                                    : classes.unSelectedSubMenu
                            }`}
                        >
                            {
                                (location.pathname === "/addresstype") && 
                                <RadioButtonChecked className={classes.iconPointSubmenu}/>
                            }
                            {(viewMenu || viewMenuHover) && (
                                <ListItemText
                                    primary="Address_Type"
                                    classes={{ primary: classes.subMenuText }}
                                />
                            )}
                        </ListItem>
                        <ListItem
                            button
                            component={Link}
                            to="/money"
                            className={`${classes.nested} ${classes.done} ${
                                location.pathname === "/money"
                                    ? classes.selectedSubMenu
                                    : classes.unSelectedSubMenu
                            }`}
                        >
                            {
                                (location.pathname === "/money") && 
                                <RadioButtonChecked className={classes.iconPointSubmenu}/>
                            }
                            {(viewMenu || viewMenuHover) && (
                                <ListItemText
                                    primary="Money"
                                    classes={{ primary: classes.subMenuText }}
                                />
                            )}
                        </ListItem>
                        <ListItem
                            button
                            component={Link}
                            to="/tax"
                            className={`${classes.nested} ${classes.done} ${
                                location.pathname === "/tax"
                                    ? classes.selectedSubMenu
                                    : classes.unSelectedSubMenu
                            }`}
                        >
                            {
                                (location.pathname === "/tax") && 
                                <RadioButtonChecked className={classes.iconPointSubmenu}/>
                            }
                            {(viewMenu || viewMenuHover) && (
                                <ListItemText
                                    primary="Tax"
                                    classes={{ primary: classes.subMenuText }}
                                />
                            )}
                        </ListItem>
                    </List>
                </Collapse>
                {/* Employees SubMenu */}
                <ListItem
                    className={
                        employeeSubMenu === true
                            ? classes.selectedMenu
                            : classes.unSelectedMenu
                    }
                    button
                    onClick={handleClickEmp}
                >
                    <Work className={classes.icon}></Work>
                    {(viewMenu || viewMenuHover) && (
                        <ListItemText
                            classes={{ primary: classes.menuText }}
                            className={
                                employeeSubMenu === true
                                    ? classes.selectedMenuText
                                    : classes.unSelectedMenuText
                            }
                            primary="Employees"
                        />
                    )}
                    <ArrowForwardIosRounded className={clsx(classes.arrowRight, 
                        {[classes.hide]: employeeSubMenu})}></ArrowForwardIosRounded>
                    <ExpandMore className={clsx(classes.arrowDown, {[classes.hide]:!employeeSubMenu})}></ExpandMore>
                </ListItem>
                <Collapse in={employeeSubMenu} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem
                            button
                            component={Link}
                            to="/employee"
                            className={`${classes.nested} ${classes.done} ${
                                location.pathname === "/employee"
                                    ? classes.selectedSubMenu
                                    : classes.unSelectedSubMenu
                            }`}
                        >
                            {
                                (location.pathname === "/employee") && 
                                <RadioButtonChecked className={classes.iconPointSubmenu}/>
                            }
                            {(viewMenu || viewMenuHover) && (
                                <ListItemText
                                    primary="Employee"
                                    classes={{ primary: classes.subMenuText }}
                                />
                            )}
                        </ListItem>
                        <ListItem
                            button
                            component={Link}
                            to="/skill"
                            className={`${classes.nested} ${classes.done} ${
                                location.pathname === "/skill"
                                    ? classes.selectedSubMenu
                                    : classes.unSelectedSubMenu
                            }`}
                        >
                            {
                                (location.pathname === "/skill") && 
                                <RadioButtonChecked className={classes.iconPointSubmenu}/>
                            }
                            {(viewMenu || viewMenuHover) && (
                                <ListItemText
                                    primary="Skill"
                                    classes={{ primary: classes.subMenuText }}
                                />
                            )}
                        </ListItem>
                        <ListItem
                            button
                            component={Link}
                            to="/level"
                            className={`${classes.nested} ${classes.done} ${
                                location.pathname === "/level"
                                    ? classes.selectedSubMenu
                                    : classes.unSelectedSubMenu
                            }`}
                        >
                            {
                                (location.pathname === "/level") && 
                                <RadioButtonChecked className={classes.iconPointSubmenu}/>
                            }
                            {(viewMenu || viewMenuHover) && (
                                <ListItemText
                                    primary="Level"
                                    classes={{ primary: classes.subMenuText }}
                                />
                            )}

                        </ListItem>
                        <ListItem
                            button
                            component={Link}
                            to="/levelskill"
                            className={`${classes.nested} ${classes.done} ${
                                location.pathname === "/levelskill"
                                    ? classes.selectedSubMenu
                                    : classes.unSelectedSubMenu
                            }`}
                        >
                            {
                                (location.pathname === "/levelskill") && 
                                <RadioButtonChecked className={classes.iconPointSubmenu}/>
                            }
                            {(viewMenu || viewMenuHover) && (
                                <ListItemText
                                    primary="Level_Skill"
                                    classes={{ primary: classes.subMenuText }}
                                />
                            )}
                        </ListItem>
                        <ListItem
                            button
                            component={Link}
                            to="/typeemp"
                            className={`${classes.nested} ${classes.done} ${
                                location.pathname === "/typeemp"
                                    ? classes.selectedSubMenu
                                    : classes.unSelectedSubMenu
                            }`}
                        >
                            {
                                (location.pathname === "/typeemp") && 
                                <RadioButtonChecked className={classes.iconPointSubmenu}/>
                            }
                            {(viewMenu || viewMenuHover) && (
                                <ListItemText
                                    primary="Type_Emp"
                                    classes={{ primary: classes.subMenuText }}
                                />
                            )}
                        </ListItem>
                        <ListItem
                            button
                            component={Link}
                            to="/documente"
                            className={`${classes.nested} ${classes.done} ${
                                location.pathname === "/documente"
                                    ? classes.selectedSubMenu
                                    : classes.unSelectedSubMenu
                            }`}
                        >
                            {
                                (location.pathname === "/documente") && 
                                <RadioButtonChecked className={classes.iconPointSubmenu}/>
                            }
                            {(viewMenu || viewMenuHover) && (
                                <ListItemText
                                    primary="Document_E"
                                    classes={{ primary: classes.subMenuText }}
                                />
                            )}
                        </ListItem>
                        <ListItem
                            button
                            component={Link}
                            to="/profiledoc"
                            className={`${classes.nested} ${classes.done} ${
                                location.pathname === "/profiledoc"
                                    ? classes.selectedSubMenu
                                    : classes.unSelectedSubMenu
                            }`}
                        >
                            {
                                (location.pathname === "/profiledoc") && 
                                <RadioButtonChecked className={classes.iconPointSubmenu}/>
                            }
                            {(viewMenu || viewMenuHover) && (
                                <ListItemText
                                    primary="Profile_Doc"
                                    classes={{ primary: classes.subMenuText }}
                                />
                            )}
                        </ListItem>
                    </List>
                </Collapse>
                {/* Products SubMenu */}
                <ListItem
                    className={
                        productsSubMenu === true
                            ? classes.selectedMenu
                            : classes.unSelectedMenu
                    }
                    button
                    onClick={handleClickProducts}
                >
                <ExtensionRounded className={classes.icon}></ExtensionRounded>

                    {(viewMenu || viewMenuHover) && (
                        <ListItemText
                            classes={{ primary: classes.menuText }}
                            className={
                                productsSubMenu === true
                                    ? classes.selectedMenuText
                                    : classes.unSelectedMenuText
                            }
                            primary="Products / Services"
                        />
                    )}
                     <ArrowForwardIosRounded className={clsx(classes.arrowRight, 
                        {[classes.hide]: productsSubMenu})}></ArrowForwardIosRounded>
                    <ExpandMore className={clsx(classes.arrowDown, {[classes.hide]:!productsSubMenu})}></ExpandMore>
                </ListItem>
                <Collapse in={productsSubMenu} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                    <ListItem
                            button
                            component={Link}
                            to="/service"
                            className={`${classes.nested} ${classes.done} ${
                                location.pathname === "/service"
                                    ? classes.selectedSubMenu
                                    : classes.unSelectedSubMenu
                            }`}
                        >
                            {
                                (location.pathname === "/service") && 
                                <RadioButtonChecked className={classes.iconPointSubmenu}/>
                            }
                            {(viewMenu || viewMenuHover) && (
                                <ListItemText
                                    primary="Service"
                                    classes={{ primary: classes.subMenuText }}
                                />
                            )}
                        </ListItem>
                        <ListItem
                            button
                            component={Link}
                            to="/product"
                            className={`${classes.nested} ${classes.done} ${
                                location.pathname === "/product"
                                    ? classes.selectedSubMenu
                                    : classes.unSelectedSubMenu
                            }`}
                        >
                            {
                                (location.pathname === "/product") && 
                                <RadioButtonChecked className={classes.iconPointSubmenu}/>
                            }
                            {(viewMenu || viewMenuHover) && (
                                <ListItemText
                                    primary="Product"
                                    classes={{ primary: classes.subMenuText }}
                                />
                            )}
                        </ListItem>
                        <ListItem
                            button
                            component={Link}
                            to="/pricelist"
                            className={`${classes.nested} ${classes.done} ${
                                location.pathname === "/pricelist"
                                    ? classes.selectedSubMenu
                                    : classes.unSelectedSubMenu
                            }`}
                        >
                            {
                                (location.pathname === "/pricelist") && 
                                <RadioButtonChecked className={classes.iconPointSubmenu}/>
                            }
                            {(viewMenu || viewMenuHover) && (
                                <ListItemText
                                    primary="Price_List"
                                    classes={{ primary: classes.subMenuText }}
                                />
                            )}
                        </ListItem>
                        <ListItem
                            button
                            component={Link}
                            to="/brand"
                            className={`${classes.nested} ${classes.done} ${
                                location.pathname === "/brand"
                                    ? classes.selectedSubMenu
                                    : classes.unSelectedSubMenu
                            }`}
                        >
                            {
                                (location.pathname === "/brand") && 
                                <RadioButtonChecked className={classes.iconPointSubmenu}/>
                            }
                            {(viewMenu || viewMenuHover) && (
                                <ListItemText
                                    primary="Brand"
                                    classes={{ primary: classes.subMenuText }}
                                />
                            )}
                        </ListItem>
                        <ListItem
                            button
                            component={Link}
                            to="/category"
                            className={`${classes.nested} ${classes.done} ${
                                location.pathname === "/category"
                                    ? classes.selectedSubMenu
                                    : classes.unSelectedSubMenu
                            }`}
                        >
                            {
                                (location.pathname === "/category") && 
                                <RadioButtonChecked className={classes.iconPointSubmenu}/>
                            }
                            {(viewMenu || viewMenuHover) && (
                                <ListItemText
                                    primary="Category"
                                    classes={{ primary: classes.subMenuText }}
                                />
                            )}
                        </ListItem>
                        <ListItem
                            button
                            component={Link}
                            to="/subcat"
                            className={`${classes.nested} ${classes.done} ${
                                location.pathname === "/subcat"
                                    ? classes.selectedSubMenu
                                    : classes.unSelectedSubMenu
                            }`}
                        >
                            {
                                (location.pathname === "/subcat") && 
                                <RadioButtonChecked className={classes.iconPointSubmenu}/>
                            }
                            {(viewMenu || viewMenuHover) && (
                                <ListItemText
                                    primary="Subcategory"
                                    classes={{ primary: classes.subMenuText }}
                                />
                            )}
                        </ListItem>
                        <ListItem
                            button
                            component={Link}
                            to="/munit"
                            className={`${classes.nested} ${classes.done} ${
                                location.pathname === "/munit"
                                    ? classes.selectedSubMenu
                                    : classes.unSelectedSubMenu
                            }`}
                        >
                            {
                                (location.pathname === "/munit") && 
                                <RadioButtonChecked className={classes.iconPointSubmenu}/>
                            }
                            {(viewMenu || viewMenuHover) && (
                                <ListItemText
                                    primary="Munit"
                                    classes={{ primary: classes.subMenuText }}
                                />
                            )}
                        </ListItem>
                    </List>
                </Collapse>
                {/* Bank SubMenu */}
                <ListItem
                    className={
                        bankSubMenu === true
                            ? classes.selectedMenu
                            : classes.unSelectedMenu
                    }
                    button
                    onClick={handleClickBank}
                >
                    <AccountBalance className={classes.icon}></AccountBalance>
                    {(viewMenu || viewMenuHover) && (
                        <ListItemText
                            classes={{ primary: classes.menuText }}
                            className={
                                bankSubMenu === true
                                    ? classes.selectedMenuText
                                    : classes.unSelectedMenuText
                            }
                            primary="Bank"
                        />
                    )}
                     <ArrowForwardIosRounded className={clsx(classes.arrowRight, 
                        {[classes.hide]: bankSubMenu})}></ArrowForwardIosRounded>
                    <ExpandMore className={clsx(classes.arrowDown, {[classes.hide]:!bankSubMenu})}></ExpandMore>
                </ListItem>
                <Collapse in={bankSubMenu} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem
                            button
                            component={Link}
                            to="/bank"
                            className={`${classes.nested} ${classes.done} ${
                                location.pathname === "/bank"
                                    ? classes.selectedSubMenu
                                    : classes.unSelectedSubMenu
                            }`}
                        >
                            {
                                (location.pathname === "/bank") && 
                                <RadioButtonChecked className={classes.iconPointSubmenu}/>
                            }
                            {(viewMenu || viewMenuHover) && (
                                <ListItemText
                                    primary="Bank"
                                    classes={{ primary: classes.subMenuText }}
                                />
                            )}
                        </ListItem>
                        <ListItem
                            button
                            component={Link}
                            to="/account"
                            className={`${classes.nested} ${classes.done} ${
                                location.pathname === "/account"
                                    ? classes.selectedSubMenu
                                    : classes.unSelectedSubMenu
                            }`}
                        >
                            {
                                (location.pathname === "/account") && 
                                <RadioButtonChecked className={classes.iconPointSubmenu}/>
                            }
                            {(viewMenu || viewMenuHover) && (
                                <ListItemText
                                    primary="Account"
                                    classes={{ primary: classes.subMenuText }}
                                />
                            )}
                        </ListItem>
                    </List>
                </Collapse>
                {/* Customers SubMenu */}
                <ListItem
                    className={
                        customersSubMenu === true
                            ? classes.selectedMenu
                            : classes.unSelectedMenu
                    }
                    button
                    onClick={handleClickCustomers}
                >
                    <FaceRounded className={classes.icon}></FaceRounded>
                    {(viewMenu || viewMenuHover) && (
                        <ListItemText
                            classes={{ primary: classes.menuText }}
                            className={
                                customersSubMenu === true
                                    ? classes.selectedMenuText
                                    : classes.unSelectedMenuText
                            }
                            primary="Customers"
                        />
                    )}
                     <ArrowForwardIosRounded className={clsx(classes.arrowRight, 
                        {[classes.hide]: customersSubMenu})}></ArrowForwardIosRounded>
                    <ExpandMore className={clsx(classes.arrowDown, {[classes.hide]:!customersSubMenu})}></ExpandMore>
                </ListItem>
                <Collapse in={customersSubMenu} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem
                            button
                            component={Link}
                            to="/customer"
                            className={`${classes.nested} ${classes.done} ${
                                location.pathname === "/customer"
                                    ? classes.selectedSubMenu
                                    : classes.unSelectedSubMenu
                            }`}
                        >
                            {
                                (location.pathname === "/customer") && 
                                <RadioButtonChecked className={classes.iconPointSubmenu}/>
                            }
                            {(viewMenu || viewMenuHover) && (
                                <ListItemText
                                    primary="Customer"
                                    classes={{ primary: classes.subMenuText }}
                                />
                            )}
                        </ListItem>
                        {/* <ListItem
                            button
                            component={Link}
                            to="/documentc"
                            className={`${classes.nested} ${classes.done} ${
                                location.pathname === "/documentc"
                                    ? classes.selectedSubMenu
                                    : classes.unSelectedSubMenu
                            }`}
                        >
                            {menu && (
                                <ListItemText
                                    primary="DOCUMENT_C"
                                    classes={{ primary: classes.subMenuText }}
                                />
                            )}

                            {location.pathname === "/documentc" && (
                                <div className={classes.iconSizes}>
                                <img src={SelectionIcon} alt="..." />
                                </div>
                            )}
                        </ListItem> */}
                    </List>
        </Collapse>


            {/* inventory submenu */}
        <ListItem
          className={
            inventorySubMenu
              ? classes.selectedMenu
              : classes.unSelectedMenu
          }
          button
          onClick={handleClickInventory}
        >
            <ListAltSharp className={classes.icon}></ListAltSharp>
          {(viewMenu || viewMenuHover) && (
            <ListItemText
              classes={{ primary: classes.menuText }}
              className={
                inventorySubMenu === true
                  ? classes.selectedMenuText
                  : classes.unSelectedMenuText
              }
              primary="Inventory"
            />
          )}
          <ArrowForwardIosRounded className={clsx(classes.arrowRight, 
                        {[classes.hide]: inventorySubMenu})}></ArrowForwardIosRounded>
                    <ExpandMore className={clsx(classes.arrowDown, {[classes.hide]:!inventorySubMenu})}></ExpandMore>
        </ListItem>
        <Collapse in={inventorySubMenu} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem
              button
              component={Link}
              to="/warehouse"
              className={`${classes.nested} ${classes.done} ${
                location.pathname === "/warehouse"
                  ? classes.selectedSubMenu
                  : classes.unSelectedSubMenu
              }`}
            >
                {
                    (location.pathname === "/warehouse") && 
                    <RadioButtonChecked className={classes.iconPointSubmenu}/>
                }
              {(viewMenu || viewMenuHover) && (
                <ListItemText
                  primary="Inventory"
                  classes={{ primary: classes.subMenuText }}
                />
              )}
            </ListItem>
            </List>
        </Collapse>

                            
                {/** Inventory SubMenu */}
                {/* <ListItem
                    className={
                        inventorySubMenu === true
                            ? classes.selectedMenu
                            : classes.unSelectedMenu
                    }
                    button
                    onClick={handleClickInventory}
                >
                    {menu && (
                        <ListItemText
                            classes={{ primary: classes.menuText }}
                            className={
                                inventorySubMenu === true
                                    ? classes.selectedMenuText
                                    : classes.unSelectedMenuText
                            }
                            primary="INVENTORY"
                        />
                    )}
                    <div className={classes.icon}>
                        {inventorySubMenu === true ? (
                            <img
                                src={CustomerIconSelected}
                                alt="..."
                                style={{ marginTop: -10 }}
                            />
                        ) : (
                            <img
                                src={CustomersIcon}
                                alt="..."
                                style={{ marginTop: -10 }}
                            />
                        )}
                    </div>
                </ListItem>
                <Collapse in={inventorySubMenu} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem
                            button
                            component={Link}
                            to="/service"
                            className={`${classes.nested} ${classes.done} ${
                                location.pathname === "/service"
                                    ? classes.selectedSubMenu
                                    : classes.unSelectedSubMenu
                            }`}
                        >
                            {menu && (
                                <ListItemText
                                    primary="SERVICE"
                                    classes={{ primary: classes.subMenuText }}
                                />
                            )}

                            {location.pathname === "/service" && (
                                <div className={classes.iconSizes}>
                                    <img src={SelectionIcon} alt="..." />
                                </div>
                            )}
                        </ListItem>
                    </List>
                </Collapse> */}
                <ListItem
                    className={
                        nominaSubMenu === true
                            ? classes.selectedMenu
                            : classes.unSelectedMenu
                    }
                    button
                    onClick={handleClickNomina}
                >
                    <AttachMoney className={classes.icon}></AttachMoney>
                    {(viewMenu || viewMenuHover) && (
                        <ListItemText
                            classes={{ primary: classes.menuText }}
                            className={
                                nominaSubMenu === true
                                    ? classes.selectedMenuText
                                    : classes.unSelectedMenuText
                            }
                            primary="Nomina"
                        />
                    )}
                    <ArrowForwardIosRounded className={clsx(classes.arrowRight, 
                        {[classes.hide]: nominaSubMenu})}></ArrowForwardIosRounded>
                    <ExpandMore className={clsx(classes.arrowDown, {[classes.hide]:!nominaSubMenu})}></ExpandMore>
                  
                </ListItem>
                <Collapse in={nominaSubMenu} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem
                            button
                            component={Link}
                            disabled={true}
                            to="/temp"
                            className={`${classes.nested} ${classes.done} ${
                                location.pathname === "/temp"
                                    ? classes.selectedSubMenu
                                    : classes.unSelectedSubMenu
                            }`}
                        >
                            {
                                (location.pathname === "/temp") && 
                                <RadioButtonChecked className={classes.iconPointSubmenu}/>
                            }
                            {(viewMenu || viewMenuHover) && (
                                <ListItemText
                                    classes={{ primary: classes.subMenuText }}
                                    primary="Periodos de Nomina"
                                />
                            )}
                        </ListItem>
                        <ListItem
                            button
                            component={Link}
                            disabled={true}
                            to="/temp"
                            className={`${classes.nested} ${classes.done} ${
                                location.pathname === "/temp"
                                    ? classes.selectedSubMenu
                                    : classes.unSelectedSubMenu
                            }`}
                        >
                            {
                                (location.pathname === "/temp") && 
                                <RadioButtonChecked className={classes.iconPointSubmenu}/>
                            }
                            {(viewMenu || viewMenuHover) && (
                                <ListItemText
                                    primary="Finiquitos"
                                    classes={{ primary: classes.subMenuText }}
                                />
                            )}

                        </ListItem>
                        <ListItem
                            button
                            component={Link}
                            disabled={true}
                            to="/temp"
                            className={`${classes.nested} ${classes.done} ${
                                location.pathname === "/temp"
                                    ? classes.selectedSubMenu
                                    : classes.unSelectedSubMenu
                            }`}
                        >
                            {
                                (location.pathname === "/temp") && 
                                <RadioButtonChecked className={classes.iconPointSubmenu}/>
                            }
                            {(viewMenu || viewMenuHover) && (
                                <ListItemText
                                    primary="Conceptos de nómina"
                                    classes={{ primary: classes.subMenuText }}
                                />
                            )}

                        </ListItem>
                        <ListItem
                            button
                            component={Link}
                            disabled={true}
                            to="/temp"
                            className={`${classes.nested} ${classes.done} ${
                                location.pathname === "/temp"
                                    ? classes.selectedSubMenu
                                    : classes.unSelectedSubMenu
                            }`}
                        >
                            {
                                (location.pathname === "/temp") && 
                                <RadioButtonChecked className={classes.iconPointSubmenu}/>
                            }
                            {(viewMenu || viewMenuHover) && (
                                <ListItemText
                                    primary="Condiciones de nómina"
                                    classes={{ primary: classes.subMenuText }}
                                />
                            )}

                        </ListItem>
                        <ListItem
                            button
                            component={Link}
                            disabled={true}
                            to="/temp"
                            className={`${classes.nested} ${classes.done} ${
                                location.pathname === "/temp"
                                    ? classes.selectedSubMenu
                                    : classes.unSelectedSubMenu
                            }`}
                        >
                            {
                                (location.pathname === "/temp") && 
                                <RadioButtonChecked className={classes.iconPointSubmenu}/>
                            }
                            {(viewMenu || viewMenuHover) && (
                                <ListItemText
                                    primary="Configuraciones Layout"
                                    classes={{ primary: classes.subMenuText }}
                                />
                            )}

                        </ListItem>
                        <ListItem
                            button
                            component={Link}
                            disabled={true}
                            to="/temp"
                            className={`${classes.nested} ${classes.done} ${
                                location.pathname === "/temp"
                                    ? classes.selectedSubMenu
                                    : classes.unSelectedSubMenu
                            }`}
                        >
                            {
                                (location.pathname === "/temp") && 
                                <RadioButtonChecked className={classes.iconPointSubmenu}/>
                            }
                            {(viewMenu || viewMenuHover) && (
                                <ListItemText
                                    primary="Movimientos Afiliatorios"
                                    classes={{ primary: classes.subMenuText }}
                                />
                            )}

                        </ListItem>
                        <ListItem
                            button
                            component={Link}
                            disabled={true}
                            to="/temp"
                            className={`${classes.nested} ${classes.done} ${
                                location.pathname === "/temp"
                                    ? classes.selectedSubMenu
                                    : classes.unSelectedSubMenu
                            }`}
                        >
                            {
                                (location.pathname === "/temp") && 
                                <RadioButtonChecked className={classes.iconPointSubmenu}/>
                            }
                            {(viewMenu || viewMenuHover) && (
                                <ListItemText
                                    primary="Retenciones a empleados"
                                    classes={{ primary: classes.subMenuText }}
                                />
                            )}

                        </ListItem>
                        <ListItem
                            button
                            component={Link}
                            disabled={true}
                            to="/temp"
                            className={`${classes.nested} ${classes.done} ${
                                location.pathname === "/temp"
                                    ? classes.selectedSubMenu
                                    : classes.unSelectedSubMenu
                            }`}
                        >
                            {
                                (location.pathname === "/temp") && 
                                <RadioButtonChecked className={classes.iconPointSubmenu}/>
                            }
                            {(viewMenu || viewMenuHover) && (
                                <ListItemText
                                    primary="Vacaciones"
                                    classes={{ primary: classes.subMenuText }}
                                />
                            )}

                        </ListItem>
                        <ListItem
                            button
                            component={Link}
                            disabled={true}
                            to="/temp"
                            className={`${classes.nested} ${classes.done} ${
                                location.pathname === "/temp"
                                    ? classes.selectedSubMenu
                                    : classes.unSelectedSubMenu
                            }`}
                        >
                            {
                                (location.pathname === "/temp") && 
                                <RadioButtonChecked className={classes.iconPointSubmenu}/>
                            }
                            {(viewMenu || viewMenuHover) && (
                                <ListItemText
                                    primary="Incapacidades"
                                    classes={{ primary: classes.subMenuText }}
                                />
                            )}

                        </ListItem>
                        <ListItem
                            button
                            component={Link}
                            disabled={true}
                            to="/temp"
                            className={`${classes.nested} ${classes.done} ${
                                location.pathname === "/temp"
                                    ? classes.selectedSubMenu
                                    : classes.unSelectedSubMenu
                            }`}
                        >
                            {
                                (location.pathname === "/temp") && 
                                <RadioButtonChecked className={classes.iconPointSubmenu}/>
                            }
                            {(viewMenu || viewMenuHover) && (
                                <ListItemText
                                    primary="Layouts"
                                    classes={{ primary: classes.subMenuText }}
                                />
                            )}

                        </ListItem>
                        <ListItem
                            button
                            component={Link}
                            disabled={true}
                            to="/temp"
                            className={`${classes.nested} ${classes.done} ${
                                location.pathname === "/temp"
                                    ? classes.selectedSubMenu
                                    : classes.unSelectedSubMenu
                            }`}
                        >
                            {
                                (location.pathname === "/temp") && 
                                <RadioButtonChecked className={classes.iconPointSubmenu}/>
                            }
                            {(viewMenu || viewMenuHover) && (
                                <ListItemText
                                    primary="Integracion de Salarios"
                                    classes={{ primary: classes.subMenuText }}
                                />
                            )}

                        </ListItem>
                        <ListItem
                            button
                            component={Link}
                            disabled={true}
                            to="/temp"
                            className={`${classes.nested} ${classes.done} ${
                                location.pathname === "/temp"
                                    ? classes.selectedSubMenu
                                    : classes.unSelectedSubMenu
                            }`}
                        >
                            {
                                (location.pathname === "/temp") && 
                                <RadioButtonChecked className={classes.iconPointSubmenu}/>
                            }
                            {(viewMenu || viewMenuHover) && (
                                <ListItemText
                                    primary="Configuración de Contratos"
                                    classes={{ primary: classes.subMenuText }}
                                />
                            )}

                        </ListItem>
                        <ListItem
                            button
                            component={Link}
                            disabled={true}
                            to="/temp"
                            className={`${classes.nested} ${classes.done} ${
                                location.pathname === "/temp"
                                    ? classes.selectedSubMenu
                                    : classes.unSelectedSubMenu
                            }`}
                        >
                            {
                                (location.pathname === "/temp") && 
                                <RadioButtonChecked className={classes.iconPointSubmenu}/>
                            }
                            {(viewMenu || viewMenuHover) && (
                                <ListItemText
                                    primary="Configuración Impuestos"
                                    classes={{ primary: classes.subMenuText }}
                                />
                            )}

                        </ListItem>
                    </List>
                </Collapse>


                <ListItem
                    component={Link}
                    to="/contact"
                    button
                    className={
                        location.pathname === "/contact"
                            ? classes.selectedMenu
                            : classes.unSelectedMenu
                    }
                >
                    <LocalPhone className={classes.icon}></LocalPhone>
                    {(viewMenu || viewMenuHover) && (
                        <ListItemText
                            primary="Contact"
                            classes={{ primary: classes.menuText }}
                            className={
                                location.pathname === "/contact"
                                    ? classes.selectedMenuText
                                    : classes.unSelectedMenuText
                            }
                        />
                    )}

                
                </ListItem>
               {/*  <ListItem
                    component={Link}
                    to="/login"
                    button
                    className={classes.unSelectedMenu}
                >
                    {menu && (
                        <ListItemText
                            primary="LOGOUT"
                            classes={{ primary: classes.menuText }}
                            onClick={() => {
                                localStorage.removeItem("token");
                                window.location.reload();
                            }}
                            className={classes.unSelectedMenuText}
                        />
                    )}

                    <div>
                        <img
                            src={logoutIcon}
                            alt="..."
                            style={{ width: 40, height: 40 }}
                        />
                    </div>
                </ListItem> */}
            </List>
        </Drawer>
    );
};

export default Menu;
