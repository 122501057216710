export const initialState = {
    tax: [],
};

const allTAXReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_TAX":
            return {
                ...state,
                tax: action.tax
            };

        default:
            return state;
    }
};

export default allTAXReducer;