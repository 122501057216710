import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
    palette: {
        primary: {
            main: "#007bff",
            mainGradient: "red",
            dark: "#004f9a",
            light: "#5ca9ff",
            contrastText: "#FFF",
            gray: "#969696",
            black: "#232323",
            lightblack: "#3c3a37",
            golden: "#FFC843",
            lightgolden: "#FFD162",
            blue: "#3f4d67",
            hoverBlue: "#648393",
            colorGray: "#648393",
            white:"#fff"
        },
    },
    colors: {
        main: "#FFF7E4",
    },
    typography : {
        fontFamily: "Helvetica",
    },
    buttons:{
        blue:"#04a9f5",
        blueHover:"#038fcf",
        gray:"#5a6268",
        grayHover:"#62747d"
    }
});

export default theme;
