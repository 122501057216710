import {
  Avatar,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Tabs,
  Tab,
  IconButton,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import "./AddCustomer.css";
import ContactTableComponent from "./ContactTableComponent";
import DocumentTableComponent from "./DocumentTableComponent";
import LockSmithSwitch from "../ReusableComponents/LockSmithSwitch";
import AddAddress from "../Address/AddAddress";
import AddMoney from "../Money/AddMoney";
import AddTAX from "../TAX/AddTAX";
import AddContact from "../Contact/AddContact";
import { useForm } from "../../hooks/useForm";
import CustomButton from "../../Utilities/CustomButton";
import DropDownMenu from "../../Utilities/DropDownMenu";
import ProfileIcon from "../../Assets/Icons/Profile.svg";

import Save from "@material-ui/icons/Save";
import Close from "@material-ui/icons/Close";
import { useStateValue } from "../../Context/store";
import {
  getAllAddressByType,
  getAllCustomerAddressesByCustId,
  insCustomerAddress,
} from "../../Actions/UserAction";

/** import Tabs */
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";

import clsx from "clsx";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    "aria-controls": `nav-tabpanel-${index}`,
  };
}

function LinkTab(props) {
  return (
    <Tab
      component="a"
      onClick={(event) => {
        event.preventDefault();
      }}
      {...props}
    ></Tab>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {},
  textForm: {
    fontSize: 14,
    fontWeight: "400",
    color: "rgb(136,136,136)",
  },
  borderForm: {
    border: "solid 1px rgba(0, 0, 0, 0.23) !important",
    padding: "9px !important",
    width: "95% !important",
  },
  textForm: {
    fontSize: 14,
    fontWeight: "400",
    color: "rgb(136,136,136)",
    width: "100%",
  },
  cardMargin: {
    margin: "10px 0",
  },
  subtabs: {
    boxShadow: "none",
  },
  subtabOption: {
    backgroundColor: "transparent !important",
  },
  hide: {
    display: "none",
  },
}));

const CustomerForm = ({
  onSave,
  onCancel,
  onInvertColors,
  onChange,
  isUpdate,
  data,
  addressesIds,
}) => {
  const classes = useStyles();
  const [
    {
      allMoney,
      allTAX,
      allPriceList,
      allContact,
      allAddress,
      allDocumentC,
      userAuth,
    },
    dispatch,
  ] = useStateValue();
  const { formData, handleInputChange, setFormData } = useForm();
  const [value, setValue] = React.useState(0);
  /*Contact Tab States */
  const [filterableContact, setFilterableContact] = useState({});
  /*Address Tab States */
  const [addressData, setAddressData] = useState({
    pIdCustomer: data.pIdCustomer,
    details: [],
  });
  const [addressTypeID, setAddressTypeID] = useState(0);
  const [fiscalAddresses, setFiscalAddresses] = useState({});
  const [deliveryAddresses, setDeliveryAddresses] = useState({});
  const [otherAddresses, setOtherAddresses] = useState({});
  /*Document Tab States */
  const [filterableDocument, setFilterableDocument] = useState({});
  /*TableComponent Controls */
  const [addDisabled, setAddDisabled] = useState(true);
  const [saveAddressDisabled, setSaveAddressDisabled] = useState(true);
  const [contactObject, setContactObject] = useState([]);
  const [documentObject, setDocumentObject] = useState([]);
  const contactColumnName = ["Id", "Name", "Details"];
  const documentColumnName = ["Id", "Document", "Date", "Path"];
  /* Images Handler */
  const [eImages, setEImages] = useState([]);
  const [image, setImage] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [saveImgState, setSaveImgState] = useState(true);
  /*DropDown menu Fields */
  const taxFields = ["IdTax", "Descr"];
  const moneyFields = ["IdMoney", "Descr"];
  const priceListFields = ["IdListPrice", "Name"];
  const contactFields = ["IdContact", "FistName", "LastName"];
  const documentFields = ["IdDocument", "Desc"];
  const addressFields = ["IdAddress", "Street"];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const imgHandler = (e) => {
    //console.log("call image handler" , e.target.files)
    onChange(e);
    setImage(e.target.files);
    if (e.target.files) {
      const fileArray = Array.from(e.target.files).map((file) =>
        window.URL.createObjectURL(file)
      );
      setSelectedImages(fileArray);
      Array.from(e.target.files).map((file) =>
        window.URL.revokeObjectURL(file)
      );
      setSaveImgState(false);
    }
  };

  const clearImages = () => {
    if (selectedImages.length > 0) {
      setSelectedImages([]);
    }
  };

  const renderImages = (source) => {
    //console.log("render images")
    if (source.length !== 0) {
      /* Render Avatar with image selected */
      return source.map((image, index) => {
        return (
          <div className="img-container">
            <img src={image} alt="avatar" />
          </div>
        );
      });
    } else {
      if (eImages.length !== 0) {
        return eImages.slice(0, 3).map((item, index) => {
          var path = item.Path;
          return (
            <Grid item sm={12}>
              <Avatar
                src={path}
                style={{
                  width: 100,
                  height: 100,
                }}
              />
            </Grid>
          );
        });
      } else {
        /* Render Avatar without any image */
        return (
          <div className="img-container">
            <img src={ProfileIcon} alt="avatar" />
          </div>
        );
      }
    }
  };

  /** Address Controls */
  const [newAddress, setNewAddress] = useState(false);
  const openNewAddress = (id) => {
    if (id == "pIdFiscAddress") {
      setAddressTypeID(19);
    } else if (id == "pIdDelAddress") {
      setAddressTypeID(18);
    } else if (id == "pIdOtherAddress") {
      setAddressTypeID(20);
    }
    setNewAddress(true);
  };
  const closeNewAddress = () => {
    setNewAddress(false);
  };

  const handleAddressChange = (e) => {
    setSaveAddressDisabled(false);
    handleInputChange(e);
  };

  /** TAX Controls */
  const [newTAX, setNewTAX] = useState(false);
  const openNewTAX = () => {
    setNewTAX(true);
  };
  const closeNewTAX = () => {
    setNewTAX(false);
  };

  /** Money Controls */
  const [newMoney, setNewMoney] = useState(false);
  const openNewMoney = () => {
    setNewMoney(true);
  };
  const closeNewMoney = () => {
    setNewMoney(false);
  };

  /** Contact Controls */
  const [newContact, setNewContact] = useState(false);
  const openNewContact = () => {
    setNewContact(true);
  };
  const closeNewContact = () => {
    setNewContact(false);
  };

  /** Document Controls */
  const [newDocument, setNewDocument] = useState(false);
  const openNewDocument = () => {
    setNewDocument(true);
  };
  const closenewDocument = () => {
    setNewDocument(false);
  };

  /*Tab Buttons Controls */
  const [addressToggle, setAddressToggle] = useState(true);
  const [contactToggle, setContactToggle] = useState(false);
  const [documentsToggle, setDocumentsToggle] = useState(false);

  const handleContactToggle = (e) => {
    setContactToggle(true);
    setAddressToggle(false);
    setDocumentsToggle(false);
  };
  const handleAddressToggle = (e) => {
    setAddressToggle(true);
    setContactToggle(false);
    setDocumentsToggle(false);
  };
  const handleDocumentsToggle = (e) => {
    setDocumentsToggle(true);
    setContactToggle(false);
    setAddressToggle(false);
  };

  /* Current User State and Effect*/
  const [user, setUser] = useState({});
  useEffect(() => {
    setUser(userAuth ? userAuth.userAuth[0] : {});
    //console.log(userAuth.userAuth[0])
  }, [userAuth]);

  /**Helper Functions */
  const removeAddedContact = (id) => {
    const newContactList = filterableContact.contact.filter(
      (contact) => contact.IdContact !== id
    );
    //console.log("newContactList", newContactList)
    setFilterableContact({ contact: newContactList });
    setAddDisabled(true);
  };

  const removeAddedDocument = (id) => {
    const newDocumentList = filterableDocument.documentc.filter(
      (document) => document.IdDocument !== id
    );
    setFilterableDocument({ documentc: newDocumentList });
    setAddDisabled(true);
  };

  const getAllAddressByTypeId = async (id) => {
    await getAllAddressByType(id).then((response) => {
      if (response.status === 200) {
        switch (id) {
          case 19:
            setFiscalAddresses({ addresses: response.data });
            break;
          case 18:
            setDeliveryAddresses({ addresses: response.data });
            break;
          case 20:
            setOtherAddresses({ addresses: response.data });
            break;
          default:
            break;
        }
      } else {
      }
    });
  };

  /**Get all Customer Addresses to populate 3 drop down menus */
  const getAllCustomerAddress = async () => {
    //console.log("getAllCustomerAddress" , data.pIdCustomer)
    await getAllCustomerAddressesByCustId(data.pIdCustomer).then((response) => {
      if (response.status === 200) {
        //console.log(response.data.recordset)
        let tempObj = {};
        let tempArray = [];
        response.data.map((address) => {
          if (address.IdType === 19) {
            tempObj = { ...tempObj, pIdFiscAddress: address.IdAddress };
            tempArray.push({ pIdFiscAddress: address.IdAddress });
          } else if (address.IdType === 18) {
            tempObj = { ...tempObj, pIdDelAddress: address.IdAddress };
            tempArray.push({ pIdDelAddress: address.IdAddress });
          } else if (address.IdType === 20) {
            tempObj = { ...tempObj, pIdOtherAddress: address.IdAddress };
            tempArray.push({ pIdOtherAddress: address.IdAddress });
          }
        });
        //console.log("tempObj", tempObj)
        //console.log("tempArray", tempArray)
        setFormData(tempObj);
        setAddressData({ ...addressData, details: tempArray });
      } else {
      }
    });
  };

  const initContactList = (data) => {
    if (data) {
      let filteredContacts = allContact.contact.filter((contact) => {
        return !data.find((element) => {
          return element.id === contact.IdContact;
        });
      });
      //console.log("filteredContacts", filteredContacts)
      setFilterableContact({ contact: filteredContacts });
    }
  };

  const initDocumentList = (data) => {
    if (data) {
      let filteredDocuments = allDocumentC.documentc.filter((document) => {
        return !data.find((element) => {
          return element.id === document.IdDocument;
        });
      });
      //console.log("filteredDocuments", filteredDocuments)
      setFilterableDocument({ document: filteredDocuments });
    }
  };

  const saveAddressData = async (e) => {
    //console.log(addressData)
    await insCustomerAddress(addressData).then((response) => {
      if (response.status === 200) {
        setSaveAddressDisabled(true);
        dispatch({
          type: "OPEN_SNACKBAR",
          openMessage: {
            open: true,
            title: "Success",
            message: response.data.output.pmensaje,
            alert: "success",
          },
        });
      } else {
        //closeLoading();
        dispatch({
          type: "OPEN_SNACKBAR",
          openMessage: {
            open: true,
            title: "Error",
            message: response.msg,
            alert: "error",
          },
        });
      }
    });
  };
  /**Populate AddressData(sent to StoreProcedure) based on FormData */
  useEffect(() => {
    if (
      formData.pIdFiscAddress ||
      formData.pIdDelAddress ||
      formData.pIdOtherAddress
    ) {
      let details = [];
      let objectKeys = Object.keys(formData);
      objectKeys.forEach((key, index) => {
        let tempObj = {};
        if (formData[key] != "") {
          tempObj[key] = formData[key];
          details.push(tempObj);
        }
      });
      //console.log("details", details)
      setAddressData({ ...addressData, details: details });
    }
  }, [formData]);

  /*Use Effect for TableComponent data*/
  useEffect(() => {
    if (data.pIdContact == undefined || data.pIdContact == null) {
      return;
    } else if (data.pIdContact == 0 || data.pIdContact == "NONE") {
      setAddDisabled(true);
    } else {
      setAddDisabled(false);
      const contact = allContact.contact.filter(
        ({ IdContact }) => IdContact == data.pIdContact
      );
      //console.log("filtered contact",contact)
      setContactObject(contact[0]);
    }
  }, [data.pIdContact]);

  useEffect(() => {
    if (data.pIdDocumentC == undefined || data.pIdDocumentC == null) {
      return;
    } else if (data.pIdDocumentC == 0 || data.pIdDocumentC == "NONE") {
      setAddDisabled(true);
    } else {
      setAddDisabled(false);
      const document = allDocumentC.documentc.filter(
        ({ IdDocument }) => IdDocument == data.pIdDocumentC
      );
      //console.log("filtered document",document)
      setDocumentObject(document[0]);
    }
  }, [data.pIdDocumentC]);

  useEffect(() => {
    //console.log(allContact)
    if (allContact != undefined && allContact.contact.length > 0) {
      initContactList();
    }
  }, [allContact]);

  useEffect(() => {
    if (allDocumentC != undefined && allDocumentC.documentc.length > 0) {
      initDocumentList();
    }
  }, [allDocumentC]);

  useEffect(() => {
    if (isUpdate) {
      getAllAddressByTypeId(addressesIds[0]);
    }
  }, []);

  useEffect(() => {
    if (isUpdate) {
      getAllAddressByTypeId(addressesIds[1]);
    }
  }, []);

  useEffect(() => {
    if (isUpdate) {
      getAllAddressByTypeId(addressesIds[2]);
    }
  }, []);

  useEffect(() => {
    if (isUpdate) {
      getAllCustomerAddress();
    }
  }, []);

  return (
    <React.Fragment>
      <AppBar position="static" className={classes.subtabs}>
        <Tabs
          value={value}
          onChange={handleChange}
          style={{
            backgroundColor: "#fff",
          }}
          indicatorColor="primary"
          aria-label="nav tabs example"
        >
          <LinkTab
            label="General"
            {...a11yProps(0)}
            className={classes.subtabOption}
          ></LinkTab>
          <LinkTab
            label="Address"
            {...a11yProps(1)}
            className={classes.subtabOption}
          ></LinkTab>
          <LinkTab
            label="Contact"
            {...a11yProps(2)}
            className={classes.subtabOption}
          ></LinkTab>
        </Tabs>
      </AppBar>

      <TabPanel value={value} index={0}>
        <form onSubmit={onSave} className="form customer-container">
          <Grid
            container
            spacing={2}
            alignContent="center"
            justifyContent="center"
          >
            <Grid item xs={12} lg={4}>
              <div className="sub-title">
                <h3>
                  <span className={classes.textForm}>ID Customer: </span>
                  {data.pIdCustomer}
                </h3>
              </div>
            </Grid>
            <Grid item xs={12} lg={4}>
              {isUpdate ? (
                <>
                  <h3>
                    <span className={classes.textForm}>Active</span>
                  </h3>
                  <LockSmithSwitch
                    checked={data.pActive}
                    onChange={onChange}
                    name="pActive"
                    value={data.pActive}
                  />
                </>
              ) : null}
            </Grid>
            <Grid item xs={12} lg={4}>
              <div className="identity">
                <div className="sub-title">
                  <h3>
                    <span className="label">Current user: </span>
                    {user.UserName}
                  </h3>
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            alignContent="center"
            justifyContent="center"
          >
            <Grid item xs={12} lg={4}>
              <label className={classes.textForm}>ein</label>
              <input
                className={classes.borderForm}
                onChange={onChange}
                type="text"
                name="pRFC"
                value={data.pRFC}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <label className={classes.textForm}>name*</label>
              <input
                className={classes.borderForm}
                onChange={onChange}
                name="pName"
                value={data.pName}
                required
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <label className={classes.textForm}>phone*</label>
              {/* <input 
                                        className="contact-code" 
                                        style={{ width: "30px" }} 
                                        onChange={handleInputChange}
                                        name="phoneCode" 
                                        value={customerData.phoneCode}
                                    /> */}
              <input
                className={classes.borderForm}
                onChange={onChange}
                name="pPhone"
                type="number"
                minLength="10"
                value={data.pPhone}
                required
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            alignContent="center"
            justifyContent="center"
          >
            <Grid item xs={12} lg={4}>
              <label className={classes.textForm}>email</label>
              <input
                className={classes.borderForm}
                onChange={onChange}
                type="email"
                name="pEmail"
                value={data.pEmail}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <label className={classes.textForm}>url</label>
              <input
                className={classes.borderForm}
                onChange={onChange}
                type="text"
                name="pURL"
                value={data.pURL}
              />
            </Grid>
            <Grid item xs={12} lg={4}></Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            alignContent="center"
            justifyContent="center"
          >
            <Grid item xs={12} lg={4}>
              <label className={classes.textForm}>credit </label>
              <LockSmithSwitch
                checked={data.pCredit}
                onChange={onChange}
                name="pCredit"
                value={data.pCredit}
                //required={true}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <label className={classes.textForm}>credit limit</label>
              <input
                className={classes.borderForm}
                onChange={onChange}
                type="number"
                name="pCreditLimit"
                value={data.pCreditLimit}
                disabled={data.pCredit ? "" : true}
                //required={true}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <label className={classes.textForm}>credit days</label>
              <input
                className={classes.borderForm}
                onChange={onChange}
                type="number"
                name="pCreditDays"
                value={data.pCreditDays}
                disabled={data.pCredit ? "" : true}
                //required={true}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            alignContent="center"
            justifyContent="center"
          >
            <Grid item xs={12} lg={4}>
              <label className={classes.textForm}>balance</label>
              <input
                className={classes.borderForm}
                onChange={onChange}
                type="number"
                name="pBalance"
                value={data.pBalance}
                //required={true}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <DropDownMenu
                label="money*"
                options={allMoney}
                optionFields={moneyFields}
                showAddBtn={true}
                className="small-input"
                onDropDownChange={onChange}
                onAddBtnClick={openNewMoney}
                name="pIdMon"
                value={data.pIdMon}
                onInvertColors={onInvertColors}
                required={true}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <DropDownMenu
                label="tax*"
                showAddBtn={true}
                options={allTAX}
                optionFields={taxFields}
                className="small-input"
                name="pIdTax"
                onDropDownChange={onChange}
                onAddBtnClick={openNewTAX}
                value={data.pIdTax}
                onInvertColors={onInvertColors}
                required={true}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            alignContent="center"
            justifyContent="center"
          >
            <Grid item xs={12} lg={4}>
              <label className={classes.textForm}>Discounts 1</label>
              <input
                className={classes.borderForm}
                onChange={onChange}
                type="number"
                name="pDiscount"
                value={data.pDiscount}
                placeholder="%"
                //required={true}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <label className={classes.textForm}>Discounts 2</label>
              <input
                className={classes.borderForm}
                onChange={onChange}
                type="number"
                name="pDiscount2"
                value={data.pDiscount2}
                placeholder="%"
                //required={true}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <label className={classes.textForm}>Discounts 3</label>
              <input
                className={classes.borderForm}
                onChange={onChange}
                type="number"
                name="pDiscount3"
                value={data.pDiscount3}
                placeholder="%"
                //required={true}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            alignContent="center"
            justifyContent="center"
          >
            <Grid item xs={12} lg={4}>
              <DropDownMenu
                label="price list*"
                options={allPriceList}
                optionFields={priceListFields}
                name="pIdListPrice"
                onDropDownChange={onChange}
                value={data.pIdListPrice}
                //onAddBtnClick={handleAddButtonClicked}
                onInvertColors={onInvertColors}
                required={true}
              />
            </Grid>
            <Grid item xs={12} lg={8}>
              <label className={classes.textForm}>comments</label>
              <textarea
                className={classes.borderForm}
                rows="4"
                onChange={onChange}
                value={data.pComments}
                name="pComments"
              ></textarea>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={2}
            alignContent="center"
            justifyContent="flex-end"
          >
            <Grid item xs={6} lg={3}>
              <CustomButton
                clas=""
                Icon={Save}
                text="save"
                //handleClick={onSave}
                type="submit"
              />
            </Grid>
            <Grid item xs={6} lg={3}>
              <CustomButton
                clas="invert"
                Icon={Close}
                text="Reset"
                handleClick={onCancel}
              />
            </Grid>
          </Grid>
        </form>
      </TabPanel>

      <TabPanel value={value} index={1}>
        <Grid
          container
          spacing={2}
          alignContent="center"
          justifyContent="center"
        >
          <Grid item xs={12} lg={4}>
            <DropDownMenu
              label="Fiscal Address"
              labelWidth={"300px"}
              showAddBtn={true}
              onDropDownChange={handleAddressChange}
              onAddBtnClick={openNewAddress}
              //Showsearch={true}
              options={fiscalAddresses}
              optionFields={addressFields}
              name="pIdFiscAddress"
              value={formData.pIdFiscAddress}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <DropDownMenu
              label="Delivery Address"
              labelWidth={"300px"}
              showAddBtn={true}
              onDropDownChange={handleAddressChange}
              onAddBtnClick={openNewAddress}
              //Showsearch={true}
              options={deliveryAddresses}
              optionFields={addressFields}
              name="pIdDelAddress"
              value={formData.pIdDelAddress}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <DropDownMenu
              label="Other Address"
              labelWidth={"300px"}
              showAddBtn={true}
              onDropDownChange={handleAddressChange}
              onAddBtnClick={openNewAddress}
              //Showsearch={true}
              options={otherAddresses}
              optionFields={addressFields}
              name="pIdOtherAddress"
              value={formData.pIdOtherAddress}
            />
          </Grid>
        </Grid>

        <Grid
          container
          spacing={2}
          alignContent="center"
          justifyContent="flex-end"
        >
          <Grid item xs={6} lg={3}>
            <CustomButton
              clas=""
              Icon={Save}
              text="save address"
              handleClick={saveAddressData}
              disabled={saveAddressDisabled}
              //type="submit"
            />
          </Grid>
        </Grid>
      </TabPanel>

      <TabPanel value={value} index={2}>
        {/*Contact*/}
        <Grid
          container
          spacing={2}
          alignContent="center"
          justifyContent="center"
        >
          <Grid item xs={12} sm={12} lg={12}>
            <DropDownMenu
              label="Contact"
              labelWidth={"300px"}
              showAddBtn={true}
              onDropDownChange={onChange}
              onAddBtnClick={openNewContact}
              //Showsearch={true}
              options={filterableContact}
              optionFields={contactFields}
              name="pIdContact"
              value={data.pIdContact}
            />
          </Grid>
          <Grid item xs={12} sm={12} lg={12}>
          <ContactTableComponent
            idCustomer={data.pIdCustomer}
            data={contactObject}
            columnNames={contactColumnName}
            addDisabled={addDisabled}
            callback={removeAddedContact}
            initDropDown={initContactList}
            onCancel={onCancel}
          />
          </Grid>
        </Grid>
      </TabPanel>

      <Dialog open={newAddress} onClose={closeNewAddress} fullWidth>
        <DialogTitle className={classes.dialogTitle}>
          <Box className={classes.dialogTitleHead}>
            <Box
              style={{
                flexDirection: "row",
                display: "flex",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <text
                style={{
                  fontSize: 28,
                  fontWeight: "bold",
                  margin: "0 auto",
                }}
              >
                Add Address
              </text>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <AddAddress
            openDialog={newAddress}
            closeDialog={closeNewAddress}
            defaultAddress={addressTypeID}
            callback={getAllAddressByTypeId}
          />
        </DialogContent>
      </Dialog>

      <Dialog open={newContact} onClose={closeNewContact} fullWidth>
        <DialogTitle className={classes.dialogTitle}>
          <Box className={classes.dialogTitleHead}>
            <Box
              style={{
                flexDirection: "row",
                display: "flex",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <text
                style={{
                  fontSize: 28,
                  fontWeight: "bold",
                  margin: "0 auto",
                }}
              >
                Add Contact
              </text>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <AddContact
            openDialog={newContact}
            closeDialog={closeNewContact}
            hide={true}
          />
        </DialogContent>
      </Dialog>

      <Dialog open={newTAX} onClose={closeNewTAX} fullWidth>
        <DialogTitle className={classes.dialogTitle}>
          <Box className={classes.dialogTitleHead}>
            <Box
              style={{
                flexDirection: "row",
                display: "flex",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <text
                style={{
                  fontSize: 28,
                  fontWeight: "bold",
                  margin: "0 auto",
                }}
              >
                Add Tax
              </text>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <AddTAX openDialog={newTAX} closeDialog={closeNewTAX} />
        </DialogContent>
      </Dialog>

      <Dialog open={newMoney} onClose={closeNewMoney} fullWidth>
        <DialogTitle className={classes.dialogTitle}>
          <Box className={classes.dialogTitleHead}>
            <Box
              style={{
                flexDirection: "row",
                display: "flex",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <text
                style={{
                  fontSize: 28,
                  fontWeight: "bold",
                  margin: "0 auto",
                }}
              >
                Add Money
              </text>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <AddMoney openDialog={newMoney} closeDialog={closeNewMoney} />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default CustomerForm;
