export const initialState = {
    category: [],
};

const allCategoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_CATEGORY":
            return {
                ...state,
                category: action.category
            };

        default:
            return state;
    }
};

export default allCategoryReducer;