import React, { useEffect, useState } from "react";
import { useStateValue } from "../../Context/store";
import { delProduct, getAllProduct } from "../../Actions/UserAction";
import LockSmithMainTable from "../ReusableComponents/LockSmithMainTable";
import AddProduct from "./AddProduct";
import LockSmithDeleteComponent from "../ReusableComponents/LockSmithDeleteComponent";
import UpdateProduct from "./UpdateProduct";

const columns = () => { 
    return [
    { title: "ID", field: "IdProduct",cellStyle: {height: 40,padding:"0px 16px"} },
    { title: "Cve", field: "CveProd",cellStyle: {height: 40,padding:"0px 16px"} },
    { title: "Description", field: "Descr",cellStyle: {height: 40,padding:"0px 16px"} },
    { title: "Cost", field: "Cost",cellStyle: {height: 40,padding:"0px 16px"} },
    { title: "CodeBar", field: "CodeBar",cellStyle: {height: 40,padding:"0px 16px"} },
    { title: "SerialN", field: "SerialN",cellStyle: {height: 40,padding:"0px 16px"} },
    //{ title: "Weight", field: "Weight" },
    //{ title: "Dimension", field: "Dimension" },
    //{ title: "Lot", field: "Lot" },
    { title: "ModDate", field: "ModDate", type: "date",cellStyle: {height: 40,padding:"0px 16px"} },
    { title: "Model", field: "Model",cellStyle: {height: 40,padding:"0px 16px"} },
    { title: "Active", field: "Active",cellStyle: {height: 40,padding:"0px 16px"} },
]};

const Product = () => {
    const [{ allProduct }] = useStateValue();
    const [product, setProduct] = useState(null);

    useEffect(() => {
        setProduct(allProduct ? allProduct.product : []);
    }, [allProduct]);

    return (
        <React.Fragment>
            <LockSmithMainTable
                title="Product"
                data={product}
                columns={columns()}
                addComponent={AddProduct}
                updateComponent={UpdateProduct}
                deleteComponent={LockSmithDeleteComponent}
                deleteAction={delProduct}
                reducer={getAllProduct}
                itemId={columns().field}
            />
        </React.Fragment>
    );
};

export default Product;
