import {
    Box,
    Button,
    Grid,
    IconButton,
    InputAdornment,
    TextField
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useStateValue } from "../../Context/store";
import { insUser, getAllUser, getAllActiveEmployees } from "../../Actions/UserAction";

/** Import Icons */
import LockSmithTextField from "../ReusableComponents/LockSmithTextField";
import LockSmithSelect from "../ReusableComponents/LockSmithSelect";

import Save from "@material-ui/icons/Save";
import Close from "@material-ui/icons/Close";
/** Theme & Styles Import */
import { dialogStyle } from "../../Assets/styles/Styles";

const AddUser = ({ openDialog, closeDialog }) => {
    const classes = dialogStyle();
    const [openBackdrop, setOpenBackdrop] = useState(false);

    const employeeFields = ["IdEmployee", "Name", "LastName"];
    const branchFields = ["IdBranch", "Descr"];
    const roleFields = ["IdRole", "Descr"];
    // eslint-disable-next-line no-empty-pattern
    const [{ allBranch, allRole, allEmployee }, dispatch] = useStateValue();
    const [userData, setuserData] = useState({});
    const [showPass, setShowPass] = useState(false);
    const [activeEmployees, setActiveEmployees] = useState({activeEmployees: []});
    /** Show Pass Control */
    const handleShowPass = () => {
        setShowPass(!showPass);
    };
    const dataValues = (e) => {
        const { name, value } = e.target;
        setuserData((last) => ({
            ...last,
            [name]: value,
        }));
    };



    const saveFunction = async (e) => {
        e.preventDefault();
        setOpenBackdrop(true);
        await insUser(userData).then((response) => {
            if (response.status === 200) {
                setOpenBackdrop(false);
                dispatch({
                    type: "OPEN_SNACKBAR",
                    openMessage: {
                        open: true,
                        title: "Success",
                        message: response.data.output.pmensaje,
                        alert: "success",
                    },
                });
                getAllUser(dispatch);
                resetFunction();
            } else {
                setOpenBackdrop(false);
                dispatch({
                    type: "OPEN_SNACKBAR",
                    openMessage: {
                        open: true,
                        title: "Error",
                        message: response.data.msg,
                        alert: "error",
                    },
                });
            }
        });
    };

    const resetFunction = () => {
        setuserData({});
        Array.from(document.querySelectorAll("form input")).forEach(
            input => (input.value = "")
          );
    };

    useEffect(() => {
        if(openDialog && activeEmployees.activeEmployees.length === 0){
            getAllActiveEmployees().then((response) => {
                if (response.status === 200) {
                    setActiveEmployees({activeEmployees: response.data});
                }
            });
        }

    },[openDialog])

    return (
            <form onSubmit={saveFunction}>
                    <Grid
                        container
                        spacing={2}
                        alignContent="center"
                        justifyContent="center"
                    >
                         <Grid item xs={12} lg={6}>
                            <LockSmithTextField
                                label="Username"
                                labelWidth="32%"
                                name="pUsername"
                                value={userData.pUsername}
                                onChange={dataValues}
                                required
                            />
                        </Grid>

                        <Grid item xs={12} lg={6}>                              
                                <text
                                    style={{ fontSize: 14, fontWeight: "400",color:"#888", fontFamiliy:"'Open Sans',sans-serif" }}
                                    >
                                    Password
                                </text>
                                
                                <div style={{marginTop:"-2px"}}>
                                <TextField
                                    fullWidth
                                    margin="normal"
                                    name="pPassword"
                                    style={{
                                        backgroundColor: "#fff",
                                        borderRadius: 5
                                    }}
                                    value={userData.pPassword}
                                    onChange={dataValues}
                                    variant="outlined"
                                    type={showPass ? "text" : "password"}
                                    required={true}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleShowPass}
                                                >
                                                    {showPass ? (
                                                        <VisibilityOff />
                                                    ) : (
                                                        <Visibility />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                </div>
                               
                        </Grid>
                    </Grid> 
                    <Grid
                        container
                        spacing={2}
                        alignContent="center"
                        justifyContent="center"
                    >
                        <Grid item xs={6}>
                            <LockSmithSelect 
                                label="Select Employee"
                                name="pIdEmployee"
                                value={userData.pIdEmployee}
                                valueId="IdEmployee"
                                data={activeEmployees}
                                handler={dataValues}
                                fields={employeeFields}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <LockSmithSelect
                                label="Select branch"
                                name="pIdBranch"
                                value={userData.pIdBranch}
                                valueId="IdBranch"
                                data={allBranch}
                                handler={dataValues}
                                fields={branchFields}
                                required
                            />
                        </Grid>
                    </Grid>  
                        <LockSmithSelect 
                            label="Select role" 
                            name="pIdRole" 
                            value={userData.pIdRole} 
                            valueId="IdRole" 
                            data={allRole} 
                            handler={dataValues} 
                            fields={roleFields}
                            required
                        />
                    
                    <Grid
                        container
                        spacing={2}
                        justifyContent="flex-end"
                    >
                        <Grid item xs={6} lg={3}>
                                    <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    classes={{ root: classes.saveButton }}
                                    size="large"
                                    fullWidth
                                    endIcon={
                                        <div
                                            style={{
                                                width: 20,
                                                height: 20,
                                                marginTop: -9,
                                                marginLeft: 10,
                                            }}
                                        >
                                            <Save></Save>
                                        </div>
                                    }
                                >
                                    Save
                                </Button>
                        </Grid>
                        <Grid item xs={6} lg={3}>
                                <Button
                                variant="contained"
                                onClick={resetFunction}
                                classes={{ root: classes.cancelButton }}
                                size="large"
                                fullWidth
                                endIcon={
                                    <div
                                        style={{
                                            width: 20,
                                            height: 20,
                                            marginTop: -9,
                                            marginLeft: 10,
                                        }}
                                    >
                                        <Close/>
                                    </div>
                                }
                            >
                                Reset
                            </Button>

                        </Grid>

                    </Grid>
                    
                    

            </form>

    );
};

export default AddUser;
