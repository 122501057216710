import {
    Button,
    Grid,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { getAllSkills, updateSkill } from "../../Actions/UserAction";
import { useStateValue } from "../../Context/store";

/**Import Icons */
import Save from "@material-ui/icons/Save";
import Close from "@material-ui/icons/Close";
/**Import Components */
import LockSmithTextField from "../ReusableComponents/LockSmithTextField";
import LocksmithEntryId from "../ReusableComponents/LockSmithEntryId";

/** Theme & Styles Import */
import {dialogStyle} from '../../Assets/styles/Styles'

const UpdateSkill = ({ openDialog, closeDialog, data }) => {
    const classes = dialogStyle();
    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [skillData, setSkillData] = useState({});
    // eslint-disable-next-line no-empty-pattern
    const [{}, dispatch] = useStateValue();

    const dataValues = (e) => {
        const { name, value } = e.target;
        setSkillData((last) => ({
            ...last,
            [name]: value,
        }));
    };

    const saveSkill = async (e) => {
        e.preventDefault();
        setOpenBackdrop(true);
        await updateSkill(skillData).then((response) => {
            if (response.status === 200) {
                setOpenBackdrop(false);
                dispatch({
                    type: "OPEN_SNACKBAR",
                    openMessage: {
                        open: true,
                        title: "Success",
                        message: response.data.output.pmensaje,
                        alert: "success",
                    },
                });
                getAllSkills(dispatch);
                cancelSkill();
            } else {
                setOpenBackdrop(false);
                dispatch({
                    type: "OPEN_SNACKBAR",
                    openMessage: {
                        open: true,
                        title: "Error",
                        message: response.data.msg,
                        alert: "error",
                    },
                });
            }
        });
    };

    const cancelSkill = () => {
        closeDialog();
    };

    useEffect(() => {
        setSkillData({
            pidSkill: data.KEY,
            pDescr: data.DESCRIPTION,
        });
    }, [data]);
    const resetForm = () =>{
        setSkillData({
            pidSkill: data.KEY,
            pDescr: data.DESCRIPTION,
        });
    };
    return (
        <React.Fragment>
                <form onSubmit={saveSkill}>
                    
                        <Grid
                            container
                            spacing={2}
                            alignContent="center"
                            justifyContent="center"
                        >
                            <Grid item xs={12} lg={6}>
                                <LocksmithEntryId 
                                label="Id skil" 
                                id={skillData.pidSkill}
                                required={true}
                                />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <LockSmithTextField
                                label="Description"
                                labelWidth="32%"
                                name="pDescr"
                                value={skillData.pDescr}
                                onChange={dataValues}
                                required={true}
                                />

                            </Grid>     
                        </Grid>
                        <Grid
                            container
                            spacing={2}
                            alignContent="center"
                            justifyContent="flex-end"
                        >
                            <Grid item xs={6} lg={3}>
                                    <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    classes={{ root: classes.saveButton }}
                                    size="large"
                                    fullWidth
                                    endIcon={
                                        <div
                                            style={{
                                                width: 20,
                                                height: 20,
                                                marginTop: -14,
                                                marginLeft: 10,
                                            }}
                                        >
                                            <Save/>
                                        </div>
                                    }
                                >
                                    Save
                                </Button>
                            </Grid>
                            <Grid item xs={6} lg={3}>
                                    <Button
                                    variant="contained"
                                    onClick={resetForm}
                                    classes={{ root: classes.cancelButton }}
                                    size="large"
                                    fullWidth
                                    endIcon={
                                        <div
                                            style={{
                                                width: 20,
                                                height: 20,
                                                marginTop: -14,
                                                marginLeft: 10,
                                            }}
                                        >
                                            <Close/>
                                        </div>
                                    }
                                >
                                    Reset
                                </Button>
                            </Grid>
                    
                        </Grid>
                        
                        
                    
                </form>
        </React.Fragment>
    );
};

export default UpdateSkill;
